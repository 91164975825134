import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[usbLazyLoadImage]'
})
export class LazyLoadImageDirective implements OnInit {

  @Input() lazyImage!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const imgElement = this.el.nativeElement;
    const placeholder = imgElement.nextElementSibling; // Selecciona el placeholder-glow

    // Mostrar placeholder inicialmente
    if(placeholder) {
      this.renderer.setStyle(placeholder, 'display', 'flex');
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        imgElement.onload = () => {
          // Ocultar placeholder después de cargar la imagen
          if(placeholder) {
            this.renderer.setStyle(placeholder, 'display', 'none');
          }
        };

        imgElement.onerror = () => {
          if(placeholder) {
            this.renderer.setStyle(placeholder, 'display', 'none'); // Ocultar si hay error
          }
        };

        this.renderer.setAttribute(imgElement, 'src', this.lazyImage);
        observer.unobserve(imgElement);
      }
    });

    observer.observe(imgElement);
  }
}
