<div class="container-fluid">
    <div class="row" style="background-color: black; position: relative;">
        <div class="col col-xg-12 col-lg-12 col-md-12 col-sm-12 p-0">
                  <!-- <img loading="lazy"   class="img-responsive video-imag" src="assets/img/app/bg-video.jpg" onclick="playVid()" />
                    <a> </a>-->
                  <video
                    style="cursor: pointer"
                    id="video"
                    class="embed-responsive-item"
                    controls="controls"
                    poster="assets/img/app/shortcuts/imgpsh_fullsize_anim.png"
                    (click)="loadVideo($event)">
                    <!-- <img loading="lazy" src="../../../assets/video/play.png" class="btn-play" alt=""> -->
                    <source [src]="videoRoute" type="video/mp4">
                  </video>
        </div>

        <!-- <div id=note style=" margin-top: 0px !important;"
          class="align-self-start container align-self-sm-center mt-0 col-xg-4 col-lg-4 col-md-9 col-sm-9 align-content-end align-right align-items-end"
          [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
           <usb-note></usb-note>
        </div> -->
    </div>
</div>
