import { FormPaxesReservationData, formPaxesReservationData, PaxEmpty, paxEmpty, routes, RoutesAPI, Titles, titles } from './form-paxes-reservation-data';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren,
  HostListener,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import { RestService } from "../../../shared/rest.service";
import { NavigationExtras, Router } from "@angular/router";
import { BsDatepickerDirective, BsLocaleService, BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { ItineraryService } from "../../../shared/itinerary.service";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { setting } from "../../../../../setting";
import { Subject, Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { ClientData, clientData } from './client-data';
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
import { MaterialModalConfirmComponent } from 'app/shared/material-modal-confirm/material-modal-confirm.component';
;

@Component({
  selector: "usb-form-paxes-reservation",
  templateUrl: "./form-paxes-reservation.component.html",
  styleUrls: ["./form-paxes-reservation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class FormPaxesReservationComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChildren("document_number_child", { read: ElementRef }) documentNumberChild: QueryList<ElementRef>;
  @ViewChildren("document_number", { read: ElementRef }) documentNumber: QueryList<ElementRef>;
  @ViewChildren("email_children", { read: ElementRef }) emailChildren: QueryList<ElementRef>;
  @ViewChildren("address_children", { read: ElementRef }) addressChildren: QueryList<ElementRef>;
  @ViewChildren("city_children", { read: ElementRef }) cityChildren: QueryList<ElementRef>;
  @ViewChildren("typeDocumentChild", { read: ElementRef }) typeDocumentChildren: QueryList<ElementRef>;
  @ViewChildren("countryChild", { read: ElementRef }) countryChildren: QueryList<ElementRef>;
  @ViewChildren("stateChild", { read: ElementRef }) stateChildren: QueryList<ElementRef>;
  @ViewChildren("phoneChild", { read: ElementRef }) phoneChildren: QueryList<ElementRef>;
  @ViewChildren("phoneCode", { read: ElementRef }) phoneCode: QueryList<ElementRef>;
  @ViewChildren("document_type_id", { read: ElementRef }) document_type_id: QueryList<ElementRef>;
  @ViewChild('selectClient') selectClient: QueryList<ElementRef>;
  @ViewChild("emailInput") emailInput: ElementRef;
  @ViewChild("startDateDp", { static: false }) startDateDp?: BsDatepickerDirective;
  @HostListener("window:scroll")
  onScrollEvent() {
    this.startDateDp?.hide();
  }
  @Input() adults: any;
  @Input() children: any;
  @Input() formSended: boolean;
  @Input() permitedConfiguration: boolean;
  @Input() paxes: any = [];
  @Input() from: string;
  @Input() paxNumber: Number;
  @Input() itineraryId: any;
  @Input() step: string;
  @Input() itineraryItems: any = [];
  @Input() itineraryTotal: any;
  @Input() clientOwner: any;
  @Input() itinerary: any;
  @Input() clientSelecFormPaxes: any[];

  @Output() _loading = new EventEmitter<any>();
  @Output() numberPositionChanged = new EventEmitter<number>();
  @Output() clientSelectedArr = new EventEmitter<any[]>();
  @Output() confirmResult = new EventEmitter<boolean>();

  selectedIsCountry: any[] = [];
  selectedIsState: any[] = [];
  selectedIsCity: any[] = [];
  phone_code: any[] = [];
  phone_2_code: any[] = [];

  public formPaxesReservationData: FormPaxesReservationData = formPaxesReservationData;
  public clientData: ClientData = clientData;
  public paxEmpty: PaxEmpty = paxEmpty;

  public dpConfig = this.formPaxesReservationData.dpConfig;

  public _form: FormGroup;

  private countriesSubscription: Subscription;
  private stateByCountrySubscription: Subscription;

  loadedClientInfoForm: boolean = false;

  showDropdown = false;
  selectedCountryName = '';
  filteredCountries = [];
  stateFiltered = [];
  citiesFiltered = [];


  titles: Titles[] = titles;

  data: any;
  typeAction: string;
  fromAssociates: boolean;
  index: number;
  mainClient: any;
  clientAssociatedArr: any[] = [];
  clients: any[] = [];
  associateClient: any;
  associateClientIndex: number;
  firstCharge: boolean;
  selectedClientAssociated: boolean = false;
  selectClientFormPaxes: boolean = false;

  appLoading: boolean = true;
  dataClientBefore: boolean = false;
  private intervalId: any;
  newClientSelectedList: any;
  formStyles: { backgroundColor: string, pointerEvents: string }[] = [];

  private routes: RoutesAPI = routes;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    public _restService: RestService,
    private router: Router,
    private _itineraryService: ItineraryService,
    public userService: UserService,
    private localeService: BsLocaleService,
    public ngxSpinner: NgxSpinnerService,
    public toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef
  ) {
    this.data = {};
    this.dpConfig.dateInputFormat = "DD-MM-YYYY";
    this.dpConfig.showWeekNumbers = true;
    var currentDate = new Date();
    // Calcula la fecha mínima para adultos (18 años antes)
    this.formPaxesReservationData.minDateAdult = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    // Calcula la fecha máxima para niños (18 años antes)
    this.formPaxesReservationData.maxDateChild = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());


    this.formPaxesReservationData.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.formPaxesReservationData.countries = [];
    this.formPaxesReservationData.states = [];
    this.selectedIsCity = [];
    this.selectedIsCountry = [];
    this.selectedIsState = [];
    this.phone_code = [];
    this.phone_2_code = [];
    this.clientData.states = []
    // this.getCountries();
    this.getRelationShip();
    this.getClients();

    this.clientData.styleFormDisable = false;
    this.clientData.checkboxChecked = false;

    this.selectedClientAssociated = false;
  }


  ngOnInit() {
    console.log(this.clients)
    this.data.typeAction = this.typeAction;
    this.data.fromAssociates = this.fromAssociates;
    this.data.index = this.index;
    if(this.associateClientIndex) {
      this.data.index = this.associateClientIndex;
    }
    this.data.mainClient = this.mainClient;
    this.data.clients = this.clients;
    this.data.clientAssociatedArr = this.clientAssociatedArr;
    this.data.assosiateClient = this.associateClient;
    console.log(this.data)
    this.localeService.use("es");
    if(this.data.typeAction) {
      this.clientData.styleFormDisable = false;
      // this.getCountriesData();
      this.selectClientFormPaxes = false;
      this.loadingFormClient();
    } else {
      this.selectClientFormPaxes = true;
      this.clientData.type = 'adult';
      this.clientData.clientAssociated = true;
      const existsClientOwnerId = this.formPaxesReservationData.clientIdArr.find(c => c === this.clientOwner.id);

      if(!existsClientOwnerId) {
        this.formPaxesReservationData.clientIdArr.push(this.clientOwner.id);
      }


      this.formPaxesReservationData.numberDocumentClient = [];



      if (this.itinerary.paxList != null) {
        this.formPaxesReservationData.paxList = this.itinerary.paxList;
        console.log("paxes ngOnInit", this.paxes);
      } else {
        console.log("paxes ngOnInit", this.paxes);

      }

      console.log("itinerary paxlist -> ngOnInit", this.formPaxesReservationData.paxList);

      // verificar si hay un conductor adicional
      this.itineraryItems.forEach((item) => {
        if (item.categoryName == "RENT-A-CAR") {
          if (this.paxes.length < 2) {
            if (item?.additionalDriver) {
              let holder = "";
              this.paxes.push({
                title: "Adult " + 2 + holder,
                type: "adult",
                number: 2,
              });
            }
          }
        }
      });
      // this.getCountriesData();
      // this.getCountries();
      this.clientData.fromAssociates = false;
      this.clientData.typeAction = undefined;
      this.checkAmountPaxes();
      this.checkOwner();
      this.setForm();
      this.startInterval();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkOwner();
      this.updateInputClasses(true);
      this.cdr.detectChanges();
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.formPaxesReservationData.booleanSelectCountries == true) {
      this.countriesSubscription.unsubscribe();
    }
    //  this.associateClientSubscription.unsubscribe();

    if (this.formPaxesReservationData.booleanSelectState == true) {
      this.stateByCountrySubscription.unsubscribe();
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startInterval() {
    // Ejecuta la función cada 1 minuto y 30 segundos (90,000 ms)
    this.intervalId = setInterval(() => {
      this.toastr.info(`autoguardado iniciado`);
      this._onSubmit(true);
    }, 90000);
  }

  //** ------------------------------------------------------------------- */

//** -------------=== Índice de funciones del componente ===------------ */

//** -------------=== Formulario de pasajeros de reserva ===------------ */

// ** SETEAR FORMULARIO DE PASAJEROS **
// setForm()

// ** VALIDA USUARIO DE ITINERARIO Y HACE SOLICITUD DE LOS CLIENTES Y ASOCIADOS **
// checkOwner()
// getClients()
// getAssociateClient()
// selectClientFunction(client: any, i: any)
// setClient(client: any, i: any)
// toStr(client: string)
// resetClient(i: any)
// verifyClientExist(i: any)

// ** LISTADO DE PAISES Y ESTADOS **
// getCountries(i?: number, setting?: boolean, client?: any)
// getStates(i: any, setting?: boolean, client?: any, country_id?: any)

// ** LISTADO DE RELACIONES **
// getRelationShip()

// ** VALIDACIÓN TIPO (POR HACER) Y NÚMERO DE DOCUMENTO **
// selectTypeDocument(event: any, index: number)
// inputNumberDocument(event: any, index: number)

// ** VALIDACIÓN FORMULARIO Y BOTON CONTINUAR **
// checkProductConfirm(event: any)
// isFormValid()
// validateEmail(email: any)
// blockInvalidKeys(evenet: any)

// ** COPIA INPUT DE HOLDER A NIÑOS (EMAIL, DIRECCIÓN, CIUDAD, TIPO DE DOCUMENTO, PAÍS Y ESTADO) **
// onEmailParent(value: string)
// onAddressParent(value: string)
// onCityParent(value: string)
// onChangeTypeDocumentParent(value: string)
// onChangeCountrytParent(value: string)
// onChangeStateParent(value: string)

// ** UTILS FORMULARIO **
// getPhoneCode(code: any, type: any, i: any)
// setRelationShip(value: any)
// getRandom()

// ** SUBMIT FORMULARIO PASAJEROS **
// _onSubmit(event: Event)
//  processPaxesData()
// formatPaxesData(paxesData: any[])
// goReservation(paxes: any)
// _payload(item: any, paxes: any)
// setAdditionalInfo(item: any, paxes: any)
// makeReserve(payload: any, item: any, paxes: any)
// setPaxesReserve(pax: any, insurance: boolean = false)
// saveReservation(reserve: any)
// confirmationRedirect(reservationId: number)

//** -------------=== Formulario de cliente ===------------ */

// ** SETEAR DATOS PARA FORMULARIO DE PASAJEROS **
// setDataClientNew(clientData: any)
// setDataClientNewAssociated(clientData: any)
// setDataClientEdit(clientData: any)

// ** SETEAR FORMULARIO DE CLIENTE **
// setFormClient()

// ** SETEAR DATOS PARA FORMULARIO DE CLIENTE (EDITAR) **
// setEditForm()

// ** SETEAR DATOS PARA FORMULARIO DE CLIENTE ASOCIADO POR SELECTOR - EXISTENTES(CREAR) **
// associatedSelectFilter()
// toggleSelect(checkbox)
// clientSelect(clientId)

// ** SETEAR DATOS PARA FORMULARIO DE CLIENTE ASOCIADO (EDITAR) **
// setEditAssosiateForm()
// associatedAge(birth_date)
// getRelationShipAssociated(age)
// changeDateClientAssociated(date)

//** ------------------------------------------------------------------- */

//** -------------=== Formulario de pasajeros de reserva ===------------ */


  //** SETEAR FORMULARIO DE PASAJEROS */

  /**
 * Establece y configura el formulario `FormArray` para los datos de los pasajeros.
 * Crea y agrega dinámicamente formularios individuales para cada pasajero al formulario `FormArray`.
 * Configura las validaciones y los valores iniciales para los campos de cada formulario.
 */
setForm() {
  // Variable para almacenar la relación entre pasajeros
  let relation_ship = null;
  let birthDateAutoSave: boolean = false;
  // Inicializa el formulario como un FormArray vacío
  this._form = this.fb.group({
    paxes: this.fb.array([]), // FormArray para los datos de los pasajeros
  });

  // Obtiene el FormArray 'paxes'
  let guest = this._form.get("paxes") as FormArray;

  // Itera sobre los datos de los pasajeros
  this.paxes.forEach((i, index) => {
    // Agrega un objeto vacío al arreglo de estados y ciudades
    this.formPaxesReservationData.states.push({ states: [] });
    this.formPaxesReservationData.cities.push({ cities: [] });

    // Si hay un cliente asociado
    if (this.formPaxesReservationData.paxList[index]) {
      // Si la relación del pasajero es nula, se establece como un espacio en blanco
      if (this.formPaxesReservationData.paxList[index].relation_ship == null) {
        this.formPaxesReservationData.paxList[index].relation_ship = " ";
        relation_ship = " ";
      }
    }

    // Verifica si hay una fecha de nacimiento disponible para el pasajero actual
    let birthDate: any = null;
    if (this.formPaxesReservationData.contadorBirthDate < this.formPaxesReservationData.paxList.length) {
      birthDate = this.formPaxesReservationData.paxList[index].birth_date;
      this.formPaxesReservationData.contadorBirthDate++;
    }

    // Calcula la diferencia entre la cantidad esperada de pasajeros y la cantidad actual
    const difference = this.paxes.length - this.formPaxesReservationData.paxList.length;

    // Ajusta el tamaño de la lista de pasajeros si hay una diferencia
    for (let i = 0; i < difference; i++) {
      this.paxEmpty.birth_date = new DatePipe("en-US").transform(this.formPaxesReservationData.minDateAdult, "dd-MM-yyyy");
      this.formPaxesReservationData.paxList.push({ ...this.paxEmpty });
    }

    // Verifica si la fecha de nacimiento está en el formato 'yyyy-mm-dd' y la transforma si es necesario
    if (/^\d{4}-\d{2}-\d{2}$/.test(birthDate)) {
      const transformedDate = new DatePipe("en-US").transform(birthDate, "dd-MM-yyyy");
      this.formPaxesReservationData.paxList[index].birth_date = transformedDate;

    }
console.log(this.formPaxesReservationData.paxList[index].birth_date)
    if(this.formPaxesReservationData.paxList[index].birth_date === 'Invalid date' || this.formPaxesReservationData.paxList[index].birth_date === null) {
      birthDateAutoSave = true;
    }

    // Agrega un FormGroup al FormArray 'paxes' para cada pasajero
    guest.push(
      this.fb.group({
        gender: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].gender : null, Validators.required],
        title: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].title : null, Validators.required],
        first_name: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].first_name : null, [Validators.required,Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/)]],
        last_name: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].last_name : null, [Validators.required, Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/)]],
        birth_date: [[this.formPaxesReservationData.paxList[index].birth_date ? this.formPaxesReservationData.paxList[index].birth_date : null, Validators.required]],
        document_type_id: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].document_type_id : null, Validators.required],
        document_number: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].document_number : null, [Validators.required, Validators.pattern(/^[0-9]+$/), this.nonNegativeValidator()]],
        email: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].email : null, [Validators.required, Validators.email, this.validateEmail.bind(this)]],
        phone: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone : null, Validators.pattern(/^\d{6,}$/)],
        phone_code: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone_code : null, Validators.required],
        phone_code_selected: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone_code : null],
        phone_2: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone_2 : null, Validators.pattern(/^\d{6,}$/)],
        phone_2_code: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone_2_code : null],
        phone_2_code_selected: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone_2_code : null],
        country_id: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].country_id : null, Validators.required],
        state_id: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].state_id : null, Validators.required],
        city: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].city : null, Validators.required],
        address: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].address : null, Validators.required],
        relation_ship: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].relation_ship_catalogue_id : relation_ship, Validators.required],
        postal_code: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].postal_code : null, Validators.pattern("^[0-9]*$")],
        clientSelected: [this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].phone_2_code : null],
        idUnique: this.getRandom(),
        type: [this.formPaxesReservationData.paxList[index].type],
      })
    );

    // this.selectedIsState[index] = this.formPaxesReservationData.paxList[index].country_id;

    // Si hay un cliente asociado, se obtiene la información del país, estado y ciudad

      // this.getCountries(index, true, this.formPaxesReservationData.paxList[index].country_id);
      const faControl = (<FormArray>this._form.controls["paxes"]).at(index);
      if(birthDateAutoSave === true) {
      if(this.formPaxesReservationData.paxList[index].type === 'adult') {
        console.log(this.formPaxesReservationData.minDateAdult)
        this.formPaxesReservationData.paxList[index].birth_date = this.formPaxesReservationData.minDateAdult;
        console.log(this.formPaxesReservationData.paxList[index].birth_date)
      } else if (this.formPaxesReservationData.paxList[index].type === 'child') {
        this.formPaxesReservationData.paxList[index].birth_date = this.formPaxesReservationData.maxDateChild;
      }


      console.log(faControl);
      faControl['controls']['birth_date']
      const birthDateControl = faControl['controls']['birth_date'];

        if (birthDateControl) {
          birthDateControl.setErrors({ invalidBirthDate: true });
          birthDateControl.markAsTouched(); // Marca el control como tocado, útil para mostrar mensajes de error
          birthDateControl.updateValueAndValidity(); // Asegura que se vuelvan a evaluar las validaciones
        }
    }
        this.getCountriesData(index, true, faControl['controls']['country_id'].value);



        // verifyExist(text: string, type: string, index: number)
    setTimeout(() => {
      const documentValue = faControl['controls']['document_number'].value;
      const emailValue = faControl['controls']['email'].value;

      if(documentValue && !emailValue) {
        this.verifyExist(documentValue, 'document_number', index);
      }
      if(emailValue && !documentValue) {
        this.verifyExist(emailValue, 'email', index);
      }
      if(emailValue && documentValue) {
        this.verifyExist({email:emailValue, document_number: documentValue}, 'full', index);
      }
    }, 500);




    // Agrega el número de documento del pasajero actual al arreglo auxiliar
    this.formPaxesReservationData.documentNumberPaxlistArrAux.push({ document_number: this.formPaxesReservationData.paxList[index] ? this.formPaxesReservationData.paxList[index].document_number : null });
  });

  // Configuración inicial de valores compartidos entre pasajeros

  console.log("this._form", this._form);
  let emailValue = this._form.value.paxes;
  console.log("emailValue ->", emailValue);


  // Configuración inicial de valores compartidos entre pasajeros
  const emailParent = this.formPaxesReservationData.paxList[0].email;
  const phoneCodeParent = Number(this.formPaxesReservationData.paxList[0].phone_code);
  const phoneParent = this.formPaxesReservationData.paxList[0].phone;
  const countryId = this.formPaxesReservationData.paxList[0].country_id;
  const stateId = this.formPaxesReservationData.paxList[0].state_id;
  const cityParent = this.formPaxesReservationData.paxList[0].city;
  const addressParent = this.formPaxesReservationData.paxList[0].address;
  const childIndices = this.paxes.map((pax, index) => (pax.type === "children" ? index : -1)).filter((index) => index !== -1);

  // Recorre los controles del FormArray "paxes"
  const paxesArray: any = this._form.get("paxes") as FormArray;

  // Configura los valores iniciales compartidos entre pasajeros
  if(emailParent) {
    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("email").setValue(emailParent);
      }
    });
  }

  if(phoneCodeParent) {
    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("phone_code_selected").setValue(phoneCodeParent);
      }
    });
  }

  if(phoneCodeParent) {
    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("phone_code").setValue(phoneCodeParent);
      }
    });
  }

  if(phoneParent) {
    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("phone").setValue(phoneParent);
      }
    });
  }

  if(countryId) {
    console.log(countryId)

    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("country_id").patchValue(countryId);
        this.getCountriesData(index,true, countryId, 'child');
      }
    });
  }

  if(stateId) {
    this.formPaxesReservationData.state_parent = stateId;
      paxesArray.controls.forEach((control: FormGroup, index) => {
        if (index === 0 || childIndices.includes(index)) {
          control.get("state_id").setValue(stateId);
          this.getStatesData(index, countryId);
        }
      });
  }

  if(cityParent) {
    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("city").setValue(cityParent);
        this.getCitiesData(index, stateId, true);
      }
    });
  }

  if(addressParent) {
    paxesArray.controls.forEach((control: FormGroup, index) => {
      if (index === 0 || childIndices.includes(index)) {
        control.get("address").setValue(addressParent);
      }
    });
  }


  // paxesArray.controls.forEach((control: FormGroup, index) => {
  //   this.disableControlsIfNeeded(control, index);
  // })

  console.log(this._form)
}

nonNegativeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return value !== null && value < 0 ? { nonNegative: true } : null;
  };
}

disableControlsIfNeeded(control: FormGroup, index: number) {
  const documentNumberControl = control.get("document_number");
  const documentTypeIdControl = control.get("document_type_id");

  const requiredValidator = (control: AbstractControl): ValidationErrors | null => {
    return control.value ? null : { required: true };
  };

  documentNumberControl.setValidators([requiredValidator]);
  documentTypeIdControl.setValidators([requiredValidator]);
console.log(documentNumberControl)
console.log(documentTypeIdControl)
if (documentNumberControl.value !== null && documentTypeIdControl.value !== null) {
  documentNumberControl.clearValidators();
  documentTypeIdControl.clearValidators();
  documentNumberControl.updateValueAndValidity({ emitEvent: false });
  documentTypeIdControl.updateValueAndValidity({ emitEvent: false });
  // Añadir lógica adicional para que se comporten como deshabilitados visualmente si es necesario
} else {
  documentNumberControl.setValidators([requiredValidator]);
  documentTypeIdControl.setValidators([requiredValidator]);
  documentNumberControl.updateValueAndValidity({ emitEvent: false });
  documentTypeIdControl.updateValueAndValidity({ emitEvent: false });
}

let formValue = this._form.getRawValue();
formValue.document_number = documentNumberControl.value;
formValue.document_type_id = documentTypeIdControl.value;
console.log('Final form state:', formValue);


}
private payloadValid = {
  email: null,
  type: null,
  document_number: null
}
private validDataForm: any[] = [];

verifyExist(text: any, type: string, index: number): void {

  const paxesArray = this._form.get('paxes') as FormArray;
  const formGroupAtIndex = paxesArray.at(index) as FormGroup;

    // Inicializar posición si no existe
    if (!this.validDataForm[index]) {
      this.validDataForm[index] = { document_number: null, email: null, type: null };
    }

  if(text !== undefined && text !== '') {

    switch (type) {
      case 'full':
        this.validDataForm[index].document_number = text.document_number;
        this.validDataForm[index].email = text.email;
        break;
      case 'email':
        this.validDataForm[index].email = formGroupAtIndex.get('email').value;
        break;
      case 'type_document':
        this.validDataForm[index].type = formGroupAtIndex.get('document_type_id').value;
        break;
      case 'document_number':
        this.validDataForm[index].document_number = formGroupAtIndex.get('document_number').value;
        break;
    }

    console.log(this.validDataForm)
    // this.validDataForm[index] = this.payloadValid;

    this._restService.doPost('app', 'checkClient', this.validDataForm[index])
      .subscribe({
        next: (responseCheck: any) => {
          console.log(responseCheck)
          if (responseCheck) {
            if (responseCheck.exist === false) {
              if(!this.data.typeAction) {


                    const paxesArray = this._form.get('paxes') as FormArray;
                    const formGroupAtIndex = paxesArray.at(index) as FormGroup;

                    if(formGroupAtIndex) {
                              const documentNumberControl = formGroupAtIndex.get('document_number');
                              if(documentNumberControl) {
                                documentNumberControl.setErrors(null);

                          }
                    if(formGroupAtIndex) {

                            const emailControl = formGroupAtIndex.get('email');
                            if(emailControl) {
                              emailControl.setErrors(null);

                        }

              }
            }}
            } else {

                const paxesArray = this._form.get('paxes') as FormArray;
                const formGroupAtIndex = paxesArray.at(index) as FormGroup;
                const documentNumberControl = formGroupAtIndex.get('document_number');
                const emailControl = formGroupAtIndex.get('email');
                const typePaxValue = formGroupAtIndex.get('type').value;
                const customErrors = { 'customErrorKey': true };
                const clientId = this.formPaxesReservationData.paxList[index]?.clientId;

                if(moment(responseCheck.client.birth_date).isBefore(this.formPaxesReservationData.minDateAdult)) {
                  if(typePaxValue === 'adult') {
                    console.log('aca')
                      if(responseCheck.client?.id === clientId) {
                        console.log('mismo dato, pero mismo cliente id')
                        if(formGroupAtIndex) {
                          if(documentNumberControl && responseCheck.client?.document_number == documentNumberControl.value) {
                            documentNumberControl.setErrors(null);
                            // return;

                          }
                          if(emailControl && responseCheck.client?.email === emailControl.value) {
                            emailControl.setErrors(null);
                            // return;
                          }
                        }
                      }
                    }

                      if(!this.data.typeAction && responseCheck.client?.id !== clientId) {
                        const url = `clients/associates/${this.formPaxesReservationData.paxList[0]?.clientId}`;
                        this._restService.doGet("app", url).subscribe({
                          next: (res: any) => {
                                console.log(res);
                                if(res.data && res.data.length > 0) {
                                  res.data.forEach((associate: any) => {
                                    if(
                                      (this.payloadValid.document_number === associate.document_number ||
                                      this.payloadValid.email === associate.email) && index > 0 && !this.firstCharge
                                      ) {
                                              const initialState: ModalOptions = {
                                                initialState: {
                                                  message: 'Asociado sugerido' // Mensaje en el modal de confirmación.
                                                }
                                              };

                                                this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);

                                                this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
                                                  if (result) {

                                                  }

                                                });
                                        console.log('encontramos un asociado para sugerir');
                                        return;
                                      }  else {
                                        if(typePaxValue === 'adult') {
                                          console.log('Es otro formulario y ya existe')
                                          console.log(res.client?.document_number)
                                          console.log(documentNumberControl.value)
                                          if(formGroupAtIndex) {
                                            if(documentNumberControl && responseCheck.client?.document_number == documentNumberControl.value) {
                                              documentNumberControl.setErrors(customErrors);

                                            }
                                            if(emailControl && responseCheck.client?.email === emailControl.value) {
                                              emailControl.setErrors(customErrors);
                                            }
                                          }
                                        } else {
                                          if(formGroupAtIndex) {
                                            if(documentNumberControl && responseCheck.client?.document_number == documentNumberControl.value) {
                                              documentNumberControl.setErrors(null);

                                            }
                                            if(emailControl && responseCheck.client?.email === emailControl.value) {
                                              emailControl.setErrors(null)
                                            }
                                          }
                                        }
                                      }
                                  });
                                };
                          },
                          error: (error: HttpErrorResponse) => {
                            console.log(error);
                          }
                    })
                }



                }
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
        }
      });
  }
}

updateInputClasses(firstCharge: boolean) {

  if(firstCharge === true && !this.data.typeAction) {

    // Asegúrate de que las referencias estén disponibles
    if (this.document_type_id && this.documentNumber && this.documentNumberChild) {
      // Iterar sobre document_type_id
      this.document_type_id.forEach((inputElement: ElementRef, index: number) => {
        const element = inputElement.nativeElement;
        const controlDocumentType = this._form.get(`paxes.${index}.document_type_id`);
        const controlDocumentNumber = this._form.get(`paxes.${index}.document_number`);
        const controlDocumentNumberChild = this._form.get(`paxes.${index}.document_number_child`);

        if (controlDocumentType && controlDocumentType.value !== null &&
            (controlDocumentNumber && controlDocumentNumber.value !== null ||
             controlDocumentNumberChild && controlDocumentNumberChild.value !== null)) {
          element.classList.remove('is-invalid');
          element.classList.add('is-valid');
          if (controlDocumentType.disabled) {
            element.style.backgroundColor = '#d4edda'; // Verde claro para deshabilitados válidos
            element.style.color = '#000000';
            element.style.pointerEvents = 'none';
          }
          this.dataClientBefore = true;
        } else {
          element.classList.remove('is-valid');
          element.classList.add('is-invalid');
        }
      });

      // Iterar sobre document_number
      this.documentNumber.forEach((inputElement: ElementRef, index: number) => {
        const element = inputElement.nativeElement;
        const controlDocumentType = this._form.get(`paxes.${index}.document_type_id`);
        const controlDocumentNumber = this._form.get(`paxes.${index}.document_number`);

        if (controlDocumentNumber && controlDocumentNumber.value !== null &&
            (controlDocumentType && controlDocumentType.value !== null)) {
          element.classList.remove('is-invalid');
          element.classList.add('is-valid');
          if (controlDocumentNumber.disabled) {
            element.style.backgroundColor = '#d4edda';
            element.style.color = '#000000';
            element.style.pointerEvents = 'none';
          }
          this.dataClientBefore = true;
        } else {
          element.classList.remove('is-valid');
          element.classList.add('is-invalid');
          this.dataClientBefore = false;
        }
      });

      // Iterar sobre document_number_child
      this.documentNumberChild.forEach((inputElement: ElementRef, index: number) => {
        const element = inputElement.nativeElement;
        const controlDocumentType = this._form.get(`paxes.${index}.document_type_id`);
        const controlDocumentNumberChild = this._form.get(`paxes.${index}.document_number`);

        if (controlDocumentNumberChild && controlDocumentNumberChild.value !== null &&
          (controlDocumentType && controlDocumentType.value !== null)
        ) {
          element.classList.remove('is-invalid');
          element.classList.add('is-valid');
          if (controlDocumentNumberChild.disabled) {
            element.style.backgroundColor = '#d4edda';
            element.style.color = '#000000';
            element.style.pointerEvents = 'none';
          }
          this.dataClientBefore = true;
        } else {
          element.classList.remove('is-valid');
          element.classList.add('is-invalid');
          this.dataClientBefore = false;
        }
      });
    }

    this.firstCharge = false;
  }
}

enterInputDocument(i: number) {
  console.log(i)
}



  //** VALIDA USUARIO DE ITINERARIO Y TRAE LOS HACE SOLICITUD DE LOS CLIENTES ASOCIADOS */
  checkOwner() {
    this.getAssociateClient();
    // console.log("clientOwner", this.clientOwner);
    // console.log("paxes", this.paxes);
    // console.log("paxes paxList", this.formPaxesReservationData.paxList);
    // console.log("itinerary", this.itinerary);
    // console.log("this.userService.itineraryActive[0]", this.userService.itineraryActive[0]);
  }

   /**
 * Obtiene la lista de clientes asociados a la agencia actual.
 *
 * Realiza una solicitud HTTP para obtener los clientes asociados a la agencia actual
 * y procesa la respuesta para extraer los números de documento de los clientes y
 * los paxes de sus itinerarios, almacenándolos en el array numberDocumentClient.
 */
getClients(): void {
  // Construye la URL para la solicitud HTTP utilizando el ID de la agencia del usuario actual
  const url = 'clients?agency_id=' + this.userService._agencyID;

  // Realiza una solicitud GET al servicio REST para obtener los clientes
  this._itineraryService._restService.doGet_('app', url).subscribe({
    next: (resp: any) => {
      console.log(resp); // Imprime la respuesta del servidor en la consola
      this.formPaxesReservationData.clientArr = resp; // Almacena la respuesta en el array clientArr
      // Verifica si se obtuvieron clientes en la respuesta
      if(this.formPaxesReservationData.clientArr.length > 0) {
        // Recorre cada cliente en el array clientArr
        this.formPaxesReservationData.clientArr.forEach((client: any, index: number) => {
          // Verifica si el cliente tiene itinerarios asociados
          // console.log(client)
          if(client.itineraries.length > 0) {
            // Si el cliente tiene itinerarios, recorre cada itinerario
            client.itineraries.forEach((items: any) => {
              // Parsea la lista de paxes del itinerario a un objeto JSON
              const paxList = JSON.parse(items.paxList);
              // Recorre cada pax en la lista de paxes
              // console.log(typeof paxList, 'paxlist: ',paxList)

              if (paxList !== null) {
                paxList.forEach((paxes) => {
                  // console.log(paxes.document_number); // Imprime el número de documento del pax en la consola
                  // Agrega el número de documento del pax al array numberDocumentClient
                  this.formPaxesReservationData.numberDocumentClient.push({
                    index: index,
                    client_id: Number(paxes.clientId) ? Number(paxes.clientId) : null,
                    document_number: Number(paxes.document_number) ? Number(paxes.document_number) : null
                  });
                });
              }

            });
          } else {
            // Si el cliente no tiene itinerarios, agrega su número de documento al array numberDocumentClient
            this.formPaxesReservationData.numberDocumentClient.push({
              index: index,
              client_id: Number(client.clientId) ? Number(client.clientId) : null,
              document_number: Number(client.document_number) ? Number(client.document_number) : null
            });
          }
        });
      }
    }
  });
}


  getAssociateClient() {
    console.log(this.clientOwner)
    if(this.clientOwner &&  this.clientOwner.id) {

      this._itineraryService._restService
        .doGet("app", "clients/associates/" + this.clientOwner.id)
        .subscribe(
          (response: any) => {
            console.log("response clients", response);
            if(Array.isArray(response.data)) {


            if (response.data.length > 0) {
              console.log('reponse array')
              this.formPaxesReservationData.booleanHeadline = true;

              this.formPaxesReservationData.clientsAssociated = response.data;
                this.formPaxesReservationData.clientsAssociated.forEach((element) => {
                  element.selected = false;
                  element.paxSelect = null;
                });

              console.log(this.formPaxesReservationData.clientsAssociated);
            } else {
            }
            }
          },
          (err) => {
            console.log("No existe informacion, clientes!");
          }
        );

        if(this.clientSelecFormPaxes.length > 2) {
          this.clientSelecFormPaxes.forEach((client: any, index: number) => {
            const selectToManipulate = this.selectClient.toArray()[index];
            const indexSelect = index + 1;
            if(selectToManipulate) {
              selectToManipulate.nativeElement.selectedIndex = client[indexSelect];
            }
          });
        };
    // } else {
    //   this.formPaxesReservationData.booleanHeadline = true;
    }

  }

  listAssociatedAge(birth_date: string): Date {
    return new Date(birth_date);
  }

  /**
 * Actualiza la información del cliente seleccionado en el formulario de paxes.
 * @param client Información del cliente seleccionado.
 * @param i Índice del formulario de paxes al que se aplicarán los cambios.
 */
selectClientFunction(client: any, i: any) {
  // Registra la información del cliente seleccionado
  console.log("client ->", client);
  // client = JSON.parse(client);
  const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
  const clientSelected: any[] = [null];

  // Clona la lista de clientes asociados en el formulario de paxes para evitar mutaciones
  this.formPaxesReservationData.clientsAssociated = this.formPaxesReservationData.clientsAssociated.map((pax) => ({
    ...pax
  }));

  // Busca el cliente seleccionado en la lista de clientes asociados
  let clientAux = this.formPaxesReservationData.clientsAssociated.find((element) => element.id == client);
  console.log("clientAux ->", clientAux);
  clientSelected.push(clientAux);
  this.clientSelectedArr.emit(clientSelected);
  // Establece el cliente seleccionado en el formulario de paxes
  this.setClient(clientAux, i);

  // console.log("this.clients", this.formPaxesReservationData.clientsAssociated);
  // console.log("client", clientAux);
  // console.log("i", i);

  // Busca el índice del cliente seleccionado en la lista de clientes asociados
  let clientFoundIndex: number = this.formPaxesReservationData.clientsAssociated.indexOf(clientAux);
  // console.log("clientFoundIndex", clientFoundIndex);

  // Busca si hay otro cliente seleccionado y lo deselecciona si es necesario
  let clientSelectedFound = this.formPaxesReservationData.clientsAssociated.find((element) => element.paxSelect == i);
  // console.log("clientFound", clientSelectedFound);

  if (clientSelectedFound) {
    let clientFoundIndexNew = this.formPaxesReservationData.clientsAssociated.indexOf(clientSelectedFound);
    this.formPaxesReservationData.clientsAssociated[clientFoundIndexNew].selected = false;
    this.formPaxesReservationData.clientsAssociated[clientFoundIndexNew].paxSelect = null;
  }

  // Marca el cliente seleccionado en la lista de clientes asociados
  this.formPaxesReservationData.clientsAssociated[clientFoundIndex].selected = true;
  this.formPaxesReservationData.clientsAssociated[clientFoundIndex].paxSelect = i;
  console.log("this.clients", this.formPaxesReservationData.clientsAssociated);
  this.formPaxesReservationData.paxList[i].state_id = clientAux.state_id;
  this.formPaxesReservationData.paxList[i].city = clientAux.city;
  this.getCountriesData(i, false, clientAux.country_id);

  // this.clientData.styleFormDisable = true;

    // Actualiza el estilo del input correspondiente
    this.formStyles[i] = {
      backgroundColor: '#e9ecef',
      pointerEvents: 'none'
    };
}

getStyles(i: number) {
  return {
    'height': this.data.typeAction ? '35px' : '42px',
    'background-color': this.clientData.styleFormDisable ? '#e9ecef' : 'white',
    'pointer-events': this.clientData.styleFormDisable ? 'none' : 'all',
    ...this.formStyles[i]
  };
}


/**
 * Establece la información del cliente en el formulario de paxes.
 * @param client Información del cliente a establecer.
 * @param i Índice del formulario de paxes al que se aplicarán los cambios.
 */
setClient(client: any, i: any) {
  // Imprime la información del cliente
  console.log("client", client);

  // Obtiene el formulario de paxes en el índice especificado
  const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
  console.log(faControl);

  // Establece los valores del cliente en el formulario de paxes
  faControl["controls"]["title"].setValue(client.title);
  faControl["controls"]["gender"].setValue(client.gender);
  faControl["controls"]["first_name"].setValue(client.first_name);
  faControl["controls"]["last_name"].setValue(client.last_name);
  faControl["controls"]["birth_date"].setValue(moment(client.birth_date, ['YYYY-DD-MM', 'DD-MM-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY'));
  const birthDate = moment(client.birth_date, ['YYYY-DD-MM', 'DD-MM-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY');
  faControl["controls"]["birth_date"].setValue(birthDate);
  faControl["controls"]["birth_date"].markAsTouched(); // Marcar el control como "touched"
  faControl["controls"]["country_id"].setValue(client.country_id);
  faControl["controls"]["state_id"].setValue(client.state_id);
  faControl["controls"]["document_type_id"].setValue(client.document_type_id);
  faControl["controls"]["document_number"].setValue(client.document_number);
  faControl["controls"]["email"].setValue(client.email ? client.email : "");
  faControl["controls"]["phone"].setValue(client.phone ? client.phone : "");
  faControl["controls"]["phone_code"].setValue(client.phone_code ? client.phone_code : "");
  faControl["controls"]["phone_2"].setValue(client.phone_2 ? client.phone_2 : "");
  faControl["controls"]["phone_2_code"].setValue(client.phone_2_code ? client.phone_2_code : "");
  faControl["controls"]["address"].setValue(client.address ? client.address : "");
  faControl["controls"]["postal_code"].setValue(client.postal_code ? client.postal_code : "");
  faControl["controls"]["relation_ship"].setValue(client.relation_ship_catalogue_id ? client.relation_ship_catalogue_id : "");
  faControl["controls"]["city"].setValue(client.city ? client.city : "");

  // Busca el país correspondiente al código del cliente
  let country_id = this.formPaxesReservationData.countries.find((element: any) => element.code == client.country_id);
  console.log("country_id", country_id);

  // Establece el país en el formulario de paxes si se encuentra
  if (country_id) {
      this.getCountriesData(i, true, client);
    // faControl["controls"]["country_id"].setValue(country_id);
  }

  console.log(faControl["controls"]["birth_date"])
  this.formPaxesReservationData.clientsAssociatedSelected = true;

  // Busca los códigos de teléfono correspondientes a los del cliente
  let phone_code = this.formPaxesReservationData.countries.find((element) => element.phonecode === client.phone_code);
  let phone_2_code = this.formPaxesReservationData.countries.find((element) => element.phonecode === client.phone_2_code);

  // Establece los códigos de teléfono seleccionados en el formulario de paxes
  if(phone_code && phone_2_code) {
    faControl["controls"]["phone_code_selected"].setValue(phone_code.phonecode);
    faControl["controls"]["phone_2_code_selected"].setValue(phone_2_code.phonecode);
  }

  // Obtiene y establece los estados correspondientes al país del cliente
  // this.getStates(i, true, client);
  const document_number = faControl["controls"]["document_number"].value;
  this.consultClientId(document_number, i);
}


  toStr(client: string) {
    return client.toString();
  }

  prueba: boolean = true;

 // Restablece los valores del cliente en el formulario de paxes al estado inicial
resetClient(i: any) {
  const errorCustom = { 'customErrorBirthDate': true };
  // Obtiene el elemento <select> del DOM con el ID "my-select"
  const select = document.getElementById("my-select") as HTMLSelectElement | null;

  // Si el elemento <select> existe, establece su índice seleccionado en 0
  if (select != null) {
    select.selectedIndex = 0;
  }

  // Busca el cliente seleccionado en la lista clientsAssociated usando el índice proporcionado (i)
  let clientSelectedFound = this.formPaxesReservationData.clientsAssociated.find((element) => element.paxSelect == i);
  console.log("clientFound", clientSelectedFound);

  // Si se encuentra el cliente seleccionado
  if (clientSelectedFound) {

    // Actualiza las propiedades selected y paxSelect del cliente en la lista clientsAssociated a false y null respectivamente
    let clientFoundIndexNew = this.formPaxesReservationData.clientsAssociated.indexOf(clientSelectedFound);
    this.formPaxesReservationData.clientsAssociated[clientFoundIndexNew].selected = false;
    this.formPaxesReservationData.clientsAssociated[clientFoundIndexNew].paxSelect = null;

    // Obtiene el FormGroup correspondiente al índice i del FormArray _form.controls["paxes"]
    const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
    console.log(faControl);

    // Establece los valores de los controles del FormGroup al estado inicial (null)
    faControl["controls"]["title"].setValue(null);
    faControl["controls"]["gender"].setValue(null);
    faControl["controls"]["first_name"].setValue(null);
    faControl["controls"]["last_name"].setValue(null);

    if(faControl["controls"]["last_name"].value === "adult") {
      faControl["controls"]["birth_date"].setValue(this.formPaxesReservationData.minDateAdult);
    } else {
      faControl["controls"]["birth_date"].setValue(this.formPaxesReservationData.maxDateChild);
    }

    faControl["controls"]["birth_date"].setErrors(errorCustom);
    faControl["controls"]["birth_date"].markAsUntouched(); // Marcar el control como "touched"
    faControl["controls"]["document_type_id"].setValue(null);
    faControl["controls"]["document_number"].setValue(null);
    faControl["controls"]["email"].setValue(null);
    faControl["controls"]["phone"].setValue(null);
    faControl["controls"]["relation_ship"].setValue(null);

    // Establece los valores de otros controles nuevos al estado inicial (null)
    faControl["controls"]["country_id"].setValue(null);
    faControl["controls"]["state_id"].setValue(null);
    faControl["controls"]["city"].setValue(null);
    faControl["controls"]["address"].setValue(null);
    faControl["controls"]["postal_code"].setValue(null);
    faControl["controls"]["phone_code"].setValue(null);
    faControl["controls"]["phone_code_selected"].setValue(null);
    faControl["controls"]["phone_2_code_selected"].setValue(null);
    faControl["controls"]["phone_2"].setValue(null);
    faControl["controls"]["phone_2_code"].setValue(null);
    faControl["controls"]["clientSelected"].setValue(null);

  }
  this.formPaxesReservationData.paxList[i].state_id = null;
  this.formPaxesReservationData.paxList[i].city = null;

  this.formStyles[i] = {
    backgroundColor: 'white',
    pointerEvents: 'all'
  };
}

// Verifica si existe un cliente asociado en el formulario de paxes en el índice especificado
verifyClientExist(i: any) {
  // Verifica si la lista clientsAssociated tiene algún cliente
  // console.log(this.formPaxesReservationData.clientsAssociated )
  if (this.formPaxesReservationData.clientsAssociated && Array.isArray(this.formPaxesReservationData.clientsAssociated)) {
    // Busca un cliente asociado en la lista usando el índice proporcionado (i)
    let clientSelectedFound = this.formPaxesReservationData.clientsAssociated.find((element) => element.paxSelect == i);
    // console.log("clientFound", clientSelectedFound);

    // Si se encuentra un cliente asociado en el índice especificado, devuelve true, de lo contrario, devuelve false
    if (clientSelectedFound) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}



  //* LISTADO DE PAISES Y ESTADOS *//

 /**
 * Obtiene la lista de países desde el servicio de itinerario.
 * También puede actualizar los controles del formulario con la información del país si se proporciona un cliente.
 *
 * @param i (Opcional) El índice del pasajero en el formulario de pasajeros.
 * @param setting (Opcional) Indica si se debe configurar el formulario con la información del país.
 * @param client (Opcional) El objeto cliente del cual obtener el país.
 */
  getCountriesData(i?: number, setting?: boolean, countryId?: any, type?: string): void {
    this.countriesSubscription = this._itineraryService._restService.doGet(routes.api, routes.countries)
    // .pipe(takeUntil(this._unsubscribeAll))
    .subscribe({
      next: (response: any) => {
          // this.formPaxesReservationData.booleanSelectCountries = faControl.touched;
          const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
          // if(!setting) {
            this.formPaxesReservationData.countries = response["Countrys"];
            this.filteredCountries = this.formPaxesReservationData.countries;
            this.filteredCountries =  this.filteredCountries.map((country: any) => ({
              ...country,
              displayValue: `${country.country_name} (+${country.phone_code})`
            }));
            this.loadedClientInfoForm = true;
            this.appLoading = false;
          // } else {
            let country;
            if(faControl?.value?.country_id > 0 && setting) {
               country = this.filteredCountries.find(c => c.country_code === faControl.value.country_id);
            }
            if(countryId) {
               country = this.filteredCountries.find(c => c.country_code === countryId);
               if(!country) {
                country = this.filteredCountries.find(c => c.country_name === countryId);
               }
            }
              if(country && country.country_name) {
                if(this.data.typeAction) {

                  faControl['controls']['country_id'].setValue(country.country_name);
                  this.selectedIsCountry[0] = country.country_name;
                  if(this.data.fromAssociates === true && (this.data.typeAction === 'Edit' || this.selectedClientAssociated )) {
                    this.getStatesData(i, country.country_code)
                  } else {
                    this.getStatesData(i);
                  }
                } else {
                  let phone_code_data;
                  faControl['controls']['country_id'].setValue(country.country_name);
                  this.selectedIsCountry[i] = country.country_name;
                  if(type === 'child') {
                    this.formPaxesReservationData.country_parent = country.country_name;
                  }
                  if(this.formPaxesReservationData.paxList[i].phone_code) {
                    phone_code_data = this.filteredCountries.find(c => c.displayValue === this.formPaxesReservationData.paxList[i].phone_code);
                  } else {
                    phone_code_data = this.filteredCountries.find(c => c.displayValue === this.formPaxesReservationData.paxList[0].phone_code);
                  }
                  this.phone_code[i] = phone_code_data.displayValue;
                  this.getStatesData(i, countryId, countryId, type);
                }
              }
          // }
              // if(setting && this.filteredCountries && this.filteredCountries.length > 0) {




              // Si se encuentran los códigos telefónicos en la lista de países, actualiza los controles del formulario con esos códigos
              if(this.clientData.checkboxChecked === true && faControl.get('document_number').value !== null) {
                this.clientData.styleFormDisable = true;
                this.cdRef.detectChanges();
              }
              else {
                this.clientData.styleFormDisable = false;
                 this.cdRef.detectChanges();
               }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
    // this.countriesSubscription.unsubscribe();

}



loadingFormClient(): void {
  switch (this.data.typeAction) {
    case 'New':
      this.getCountriesData();
      this.selectedIsCity = [];
      this.selectedIsCountry = [];
      this.selectedIsState = [];
      this.clientData.mainClient = [];
      this.clientData.assosiateClient = [];
      if(this.data.fromAssociates === false) {
        this.setDataClientNew(this.data);
      } else {
        this.setDataClientNewAssociated(this.data);
      }
      this.loadedClientInfoForm = true;
      this.ngxSpinner.hide();
      break;
    case 'Edit':
      this.setDataClientEdit(this.data);
  }
}

getStatesData(i: any, country_id?: number, client?: any, type?: string): void {
  this.selectedIsState[i] = [];
  this.selectedIsCity[i] = [];
  const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
  if(faControl.value.country_id || country_id) {

    let country;
    if(country_id) {
      country = country_id
    } else {
      let countryFilter;
      countryFilter = this.filteredCountries.find(c => c.country_name === faControl.value.country_id);
      if(countryFilter) {
        country = countryFilter.country_code;
      } else {
        countryFilter = faControl.value.country_id;
        country = countryFilter;

      }
    }
    this.stateByCountrySubscription = this._itineraryService._restService
      .doGet(routes.api, routes.states + country )
            .subscribe( {
             next: (response: any) => {
                response = response.destinations;
                this.stateFiltered = response;
                let state;
                if(this.data.typeAction) {
                  this.clientData.states = this.stateFiltered;
                    if(this.clientData.assosiateClient && this.clientData.assosiateClient.state_id > 0) {
                      state =  this.stateFiltered.find(c => c.destination_id ===  this.clientData.assosiateClient.state_id);
                    }
                    if(this.clientData.mainClient && this.clientData.mainClient.state_id > 0 && this.clientData.typeAction === 'Edit') {
                      state =  this.stateFiltered.find(c => c.destination_id ===  this.clientData.mainClient.state_id);
                    }
                    if(this.newClientSelectedList.state_id > 0 && this.selectedClientAssociated) {
                      state =  this.stateFiltered.find(c => c.destination_id ===  this.newClientSelectedList.state_id);

                    }
                    if(!state) {
                      state =  this.stateFiltered.find(c => c.destination_id ===  Number(faControl.value.state_id));
                    }
                } else {
                  this.formPaxesReservationData.states[i].states = response;
                  if(this.formPaxesReservationData?.paxList[i]?.state_id) {
                    state = this.formPaxesReservationData?.states[i]?.states.find(c => c.destination_id === this.formPaxesReservationData?.paxList[i]?.state_id);

                  }
                  if(type === 'child') {
                    state = this.formPaxesReservationData?.states[i]?.states.find(c => c.destination_id === this.formPaxesReservationData?.paxList[0]?.state_id);
                  }
                }
                if(response && response.length > 0) {
                  if(state) {
                    faControl.get('state_id').setValue(state.destination_name);
                    this.selectedIsState[i] = state.destination_name;
                    if(this.data.typeAction) {
                      if(this.data.typeAction === 'Edit' || this.selectedClientAssociated) {
                        this.getCitiesData(i, state.destination_id);
                      } else {
                        this.getCitiesData(i);
                      }
                    } else {
                      this.getCitiesData(i, state.destination_id);
                    }
                  }
                }
            },
            error: (error: HttpErrorResponse) => {
              console.log(error);
            }
    });
  }

}

  getCitiesData(i, state?, children?): void {
    const faControl = (<FormArray>this._form.controls["paxes"]).at(i);
    let state_id;
    if(state > 0) {
      state_id = state;
    } else {
      const stateData = this.stateFiltered.find(c => c.destination_name === faControl.value.state_id)
      state_id = stateData.destination_id;
    }
    this._itineraryService._restService
    .doGet(routes.api, routes.cities + state_id )
    .subscribe({
      next: (res: any) => {
        console.log(this.selectedClientAssociated)
        if(this.data.typeAction === 'Edit' || this.data.typeAction === 'New') {
          this.citiesFiltered = res.cities;
        } else {
          this.formPaxesReservationData.cities[i].cities = res.cities;
        }
        if(state || this.selectedClientAssociated === true) {
          let stateData;

            if((this.data.typeAction && this.data.typeAction === 'Edit')) {
              if(this.data.fromAssociates === true && this.data.typeAction === 'Edit') {
                stateData = this.citiesFiltered.find(c => c.city_id === Number(this.clientData.assosiateClient.city));
              } else {
                if(this.selectedClientAssociated === true) {
                  stateData = this.citiesFiltered.find(c => c.city_id === faControl.value.city);
                } else {
                  stateData = this.citiesFiltered.find(c => c.city_id === Number(this.clientData.mainClient.city));
                }
              }
              if(stateData) {
                faControl.get('city').setValue(stateData.city_name);
                this.selectedIsCity[0] = stateData.city_name;
              }
          } else {
            if(this.selectedClientAssociated === true){
              if(Number(this.newClientSelectedList.city) > 0 && this.selectedClientAssociated) {
                stateData =  this.citiesFiltered.find(c => c.city_id ===  Number(this.newClientSelectedList.city));
              }
            } else {
              if(children) {
                if(this.formPaxesReservationData.paxList[0].city) {
                  stateData = formPaxesReservationData?.cities[0].cities.find(c => c.city_id === Number(this.formPaxesReservationData.paxList[0].city));
                }

              } else {
                if(this.formPaxesReservationData.paxList[i].city) {
                  stateData = formPaxesReservationData?.cities[i].cities.find(c => c.city_id === Number(this.formPaxesReservationData.paxList[i].city));
                }
              // } else {
              //   stateData = formPaxesReservationData?.cities[i].cities.find(c => c.city_id === this.formPaxesReservationData.paxList[0].city);
              }
            }
            if(stateData) {
              faControl.get('city').setValue(stateData.city_name);
              this.selectedIsCity[i] = stateData.city_name;
            }
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
  }

//** LISTADO DE RELACIONES */

/**
 * Obtiene las relaciones disponibles para la reserva y las almacena en el formulario de paxes.
 */
getRelationShip() {
  // Realiza una solicitud GET para obtener las relaciones disponibles
  this._itineraryService._restService.doGet(routes.app, routes.relation_ship_catalogue).subscribe({
      next: (res: any) => { // Función que se ejecuta cuando se recibe una respuesta exitosa

          this.formPaxesReservationData.relationShips = res; // Almacena las relaciones disponibles en el formulario de paxes
          // Filtra las relaciones para encontrar las que no son 'Padre' ni 'Madre'
          const relationChild = res.filter(objeto => objeto.id !== 1 && objeto.id !== 2);
          // Verifica si se encontraron relaciones distintas de 'Padre' y 'Madre'
          if (relationChild.length > 0) {
              this.formPaxesReservationData.relationChild = relationChild; // Almacena las relaciones distintas de 'Padre' y 'Madre'
          } else { // Mensaje si no se encuentran relaciones distintas de 'Padre' y 'Madre'
          }
          // console.log('relationMenor', this.relationChild); // Imprime en la consola las relaciones filtradas
      },
      error: (error: Error) => { // Función que se ejecuta cuando ocurre un error en la solicitud
          console.error(error); // Imprime en la consola el error ocurrido
      }
  });
}



 //** VALIDACIÓN TIPO (POR HACER) Y NÚMERO DE DOCUMENTO */

  selectTypeDocument(event: any, index: number) {
    const typeDocumentSelect = event;

    if(typeDocumentSelect) {
      this.formPaxesReservationData.typeDocumentSelect = typeDocumentSelect;
      this.formPaxesReservationData.indexPaxTypeDocumentSelect = index;
    }
  }


/**
 * Maneja el evento de entrada de número de documento.
 *
 * @param event El evento de entrada.
 * @param index El índice del pax al que se está asociando el número de documento.
 */
inputNumberDocument(event: any, index: number): void {
  const input = event.target.value;

  const paxesArray = this._form.get('paxes') as FormArray;
  const formGroupAtIndex = paxesArray.at(index) as FormGroup;
  const customErrors = { 'customErrorKey': true };

  if (formGroupAtIndex) {
    const documentNumberControl = formGroupAtIndex.get('document_number');
    if (documentNumberControl) {
      console.log(documentNumberControl)
      const currentDocumentNumber = Number(documentNumberControl.value);
      // Validar si el número de documento actual ya está en uso en otro pax
      const isCurrentDocumentNumberDuplicate = paxesArray.controls
        .filter((control, idx) => idx !== index) // Excluye el pax actual del chequeo
        .some(control => control.get('document_number').value === currentDocumentNumber);
      if (isCurrentDocumentNumberDuplicate) {
        documentNumberControl.setErrors(customErrors);
      } else {
        documentNumberControl.setErrors(null);
      }

      // Validar si el número de documento ingresado ya está en uso por otro pax
      const isInputDocumentNumberDuplicate = paxesArray.controls
        .some(control => control.get('document_number').value === Number(input) && index !== paxesArray.controls.indexOf(control));

      if (isInputDocumentNumberDuplicate) {
        documentNumberControl.setErrors(customErrors);
      } else {
        documentNumberControl.setErrors(null);
      }

      // Validar si el número de documento ingresado ya existe entre los documentos de clientes existentes
      const isInputDocumentNumberExists = this.formPaxesReservationData.numberDocumentClient
        .some(c => c && c.document_number === Number(input) && c.client_id === null);

      if (isInputDocumentNumberExists) {
        documentNumberControl.setErrors(customErrors);
      } else if (!isCurrentDocumentNumberDuplicate && !isInputDocumentNumberDuplicate && !isInputDocumentNumberExists){

        if(documentNumberControl.value === '' || documentNumberControl.value === null) {

          documentNumberControl.setValue('');
        } else if (documentNumberControl.value !== '' || documentNumberControl.value !== null) {
          // Si el valor no existe en el array, establece documentClientExists en false
          documentNumberControl.setErrors(null);
          this.consultClientId(input, index);
          console.log(documentNumberControl)
        }
      }
    }
  }
}


consultClientId(document_number: number, index: number) {
  let clientAssociated;
if(this.formPaxesReservationData.clientArr && this.formPaxesReservationData.clientArr.length > 0) {
  const client: any = this.formPaxesReservationData.clientArr.find((client: any) => Number(client.document_number) === Number(document_number));

  if(this.formPaxesReservationData.clientsAssociated && Array.isArray(this.formPaxesReservationData.clientsAssociated)) {
    clientAssociated = this.formPaxesReservationData.clientsAssociated.find((client: any) => Number(client.document_number) === Number(document_number));
  }

  // Si se encuentra el cliente, guardar su ID en clientIdArr; de lo contrario, guardar null
  if (client && client.document_number !== null || clientAssociated && clientAssociated.document_number !== null) {
      client ? this.formPaxesReservationData.clientIdArr[index] = client.id
             : this.formPaxesReservationData.clientIdArr[index] = clientAssociated.client_id;
  } else {
      this.formPaxesReservationData.clientIdArr[index] = null;
  }
}
}



//** VALIDACIÓN FORMULARIO Y BOTON CONTINUAR */

/**
 * Verifica si los campos del formulario de confirmación de producto están completos y válidos.
 * @param event El índice del formulario de confirmación de producto en el arreglo de formularios.
 * @returns Devuelve true si todos los campos del formulario son válidos y están completos, de lo contrario devuelve false.
 */
checkProductConfirm(event: any): boolean {
  let checked = true; // Inicializa la variable de verificación como verdadera
  this.formPaxesReservationData.formInvalid = false; // Reinicia el estado de formulario inválido a falso
  if(!this.data.typeAction) {
    // Obtiene el formulario de confirmación de producto en la posición especificada por el evento
    this.formPaxesReservationData.generalForm = (<FormArray>this._form.get("paxes")).at(event);

    let formValues: any = (<FormArray>this._form.get("paxes")).at(event); // Obtiene los valores del formulario

    if (formValues != undefined) {
        // Verifica la validez de cada campo del formulario y actualiza la variable 'checked' si es necesario
        if (!formValues.get("gender").valid) checked = false;
        if (!formValues.get("first_name").valid) checked = false;
        if (!formValues.get("last_name").valid) checked = false;
        if (!formValues.get("email").valid) checked = false;
        if (!formValues.get("address").valid) checked = false;
        if (!formValues.get("title").valid) checked = false;
        if (!formValues.get("birth_date").valid) checked = false;
        if (!formValues.get("document_type_id").valid) checked = false;
        if (!formValues.get("document_number").valid) checked = false;
        if (!formValues.get("country_id").valid) checked = false;
        if (!formValues.get("state_id").valid) checked = false;
        if (!formValues.get("city").valid) checked = false;
        if (!formValues.get("phone").valid) checked = false;
        if (!formValues.get("phone_code").valid) checked = false;
        if (!formValues.get("phone_2").valid) checked = false;
        if (!formValues.get("phone_2_code").valid) checked = false;

        // Verifica si la relación está presente en formValues y es null, si es así, establece 'checked' en false y marca el formulario como inválido
        if (formValues.get("relation_ship").value === null && event > 0) {
            checked = false;
            this.formPaxesReservationData.formInvalid = true;
        }
    }
      return checked; // Devuelve el estado de verificación final
  } else {
      console.log("Ocurrió un problema al cargar los paxes"); // Mensaje de registro en caso de problemas al cargar los paxes
      return false; // Devuelve false si hay un problema al cargar los paxes
  }
}

clientExist(data): boolean {
  if(Array.isArray(data)) {
    return true;
  } else {
    return false;
  }
}

// Verifica si el formulario es válido
isFormValid(): boolean {
  // Obtener el FormArray 'paxes'
  const paxesArray = this._form.get('paxes') as FormArray;

  // Verificar la validez de cada FormGroup en el FormArray
  const areSpecificControlsValid = paxesArray.controls.every((formGroup: FormGroup) => {
    // Verificar la validez de controles específicos en cada FormGroup
    const isGenderValid = formGroup.get('gender').valid;
    const isTitleValid = formGroup.get('title').valid;
    const isFirstNameValid = formGroup.get('first_name').valid;
    const isLastNameValid = formGroup.get('last_name').valid;
    const isPostalCodeValid = formGroup.get('postal_code').valid;
    const isBirthDateValid = formGroup.get('birth_date').valid;
    const isDocumentTypeIdValid = formGroup.get('document_type_id').valid;
    const isDocumentNumberValid = formGroup.get('document_number').valid;
    const isEmailValid = formGroup.get('email').valid;
    const isRelationShipValid = formGroup.get('relation_ship').valid ? formGroup.get('relation_ship').valid : true;
    const isCoutryIdValid = formGroup.get('country_id').valid;
    const isStateIdValid = formGroup.get('state_id').valid;
    const isCityValid = formGroup.get('city').valid;
    const isAddressValid = formGroup.get('address').valid;
    const isPhoneValid = formGroup.get('phone').valid;
    const isCodeValid = formGroup.get('phone_code').valid;
    const isPhone_2Valid = formGroup.get('phone_2').valid;
    const isPhone_2_codeValid = formGroup.get('phone_2_code').valid;

    // Devuelve true si todos los controles específicos son válidos para este FormGroup
    return isGenderValid &&
           isTitleValid &&
           isFirstNameValid &&
           isLastNameValid &&
           isPostalCodeValid &&
           isBirthDateValid &&
           isDocumentTypeIdValid &&
           isDocumentNumberValid &&
           isEmailValid &&
           isRelationShipValid &&
           isCoutryIdValid &&
           isStateIdValid &&
           isCityValid &&
           isAddressValid &&
           isPhoneValid &&
           isCodeValid &&
           isPhone_2Valid &&
           isPhone_2_codeValid;
  });

  // Devuelve true si todos los controles específicos son válidos
  // También guarda los datos de pasajeros en el servicio RestService
  return areSpecificControlsValid;
}

/**
 * Valida si la cadena de correo electrónico proporcionada es válida según un patrón regex y utilizando la validación integrada de Angular para correos electrónicos.
 * @param email La cadena de correo electrónico a validar.
 * @returns Devuelve `null` si el correo electrónico es válido o un objeto `{ invalidEmail: true }` si es inválido.
 */
validateEmail(email: any) {
  // Expresión regular para validar el formato del correo electrónico
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[_a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Utiliza la validación integrada de Angular para verificar la validez del correo electrónico
  const isValid = Validators.email(email);

  // Verifica si el correo electrónico coincide con el patrón de la expresión regular
  const patternValid = regex.test(email.value);

  // Devuelve null si el correo electrónico es válido según la validación integrada de Angular o el patrón regex, de lo contrario devuelve un objeto { invalidEmail: true }
  return isValid || patternValid ? null : { invalidEmail: true };
}


/**
 * Bloquea la entrada de caracteres no deseados (`e`, `E`, `+`, `-`) en campos numéricos.
 * @param event El evento de teclado.
 */
blockInvalidKeys(event: KeyboardEvent) {
  if (['e', 'E', '+', '-'].includes(event.key)) {
    event.preventDefault();
  }
}



//** COPIA INPUT DE HOLDER A NIÑOS (EMAIL, DIRECCIÓN, CIUDAD, TIPO DE DOCUMENTO, PAÍS Y ESTADO) */

onChangeParentData(value: string, data: string): void {
  console.log(this.countryChildren)
  if(value && data) {

    let valueParent;
    let childArr;
    switch (data) {
      case 'email':
        this.formPaxesReservationData.email_parent = value;
        valueParent = this.formPaxesReservationData.email_parent;
        childArr =  this.emailChildren;
        break;
      case 'country':
        this.formPaxesReservationData.country_parent = value['item']['country_name'];
        valueParent = this.formPaxesReservationData.country_parent;
        childArr = this.countryChildren;
        break;
      case 'destination':
        this.formPaxesReservationData.state_parent = value['item']['destination_name'];
        valueParent = this.formPaxesReservationData.state_parent;
        childArr = this.stateChildren;
        break;
      case 'city':
        this.formPaxesReservationData.city_parent = value['item']['city_name'];
        valueParent = this.formPaxesReservationData.city_parent;
        childArr = this.cityChildren;
        break;
      case 'address':
        this.formPaxesReservationData.address_parent = value;
        valueParent = this.formPaxesReservationData.address_parent;
        childArr = this.addressChildren;
        break;
      case 'phone_code':
        this.formPaxesReservationData.phone_code_parent = value['item']['displayValue'];
        valueParent = this.formPaxesReservationData.phone_code_parent;
        childArr = this.phoneChildren;
        break;
      case 'phone':
        break;
    }
    childArr.forEach((childInput: ElementRef) => {
      console.log(childInput)
      childInput.nativeElement.value = valueParent;
      childInput.nativeElement.dispatchEvent(new Event("value"));
    });

    this.cdr.detectChanges();
  }
}

//* UTILS FORMULARIO */

/**
 * Actualiza el código de país en el formulario de acuerdo al tipo de número telefónico (principal o secundario).
 * @param code El código de país para actualizar en el formulario.
 * @param type El tipo de número telefónico ('phone' para principal, 'phone2' para secundario).
 * @param i El índice del formulario de paxes en el arreglo de formularios.
 */
getPhoneCode(code: any, type: any, i: any) {
  code = Number(code); // Convierte el código a número si no lo es
  const faControl = (<FormArray>this._form.controls["paxes"]).at(i); // Obtiene el control del formulario de paxes en la posición especificada


  // Actualiza el código de país en el formulario según el tipo de número telefónico
  if(type === 'phone') {
      this.formPaxesReservationData.phone_code = code; // Actualiza el código de país en los datos del formulario
      faControl["controls"]["phone_code"].setValue(this.formPaxesReservationData.phone_code); // Establece el nuevo código de país en el control del formulario
  }
  if(type === 'phone2') {
      this.formPaxesReservationData.phone_code_2 = code; // Actualiza el código de país en los datos del formulario
      faControl["controls"]["phone_2_code"].setValue(this.formPaxesReservationData.phone_code_2); // Establece el nuevo código de país en el control del formulario
  }
}


  setRelationShip(value: any) {
    // console.log(this.paxes)
    this.clientData.relationId = value;
    this.formPaxesReservationData.relationShip = value;
  }


  getRandom() {
    return Math.floor(Math.random() * 20000000000000) + Date.now();
  }

  enableControlsBeforeSubmit() {
    const paxesArray = this._form.get('paxes') as FormArray;

    paxesArray.controls.forEach((control: FormGroup) => {
      const documentNumberControl = control.get("document_number");
      const documentTypeIdControl = control.get("document_type_id");

      // Reactiva los controles
      documentNumberControl.enable({ emitEvent: false });
      documentTypeIdControl.enable({ emitEvent: false });
    });
  }

  //** SUBMIT FORMULARIO PASAJEROS */

  async _onSubmit(autoSave?: boolean, event?: Event) {
    this.enableControlsBeforeSubmit();
    console.log(autoSave)

        // Procesar los datos de los pasajeros
        // const paxesData = await this.processPaxesData();
        // console.log(paxesData)
    // try {
        // Prevenir el comportamiento predeterminado del evento
        if (event) {
            event.preventDefault();
        }

        // Procesar los datos de los pasajeros
        const paxesData = await this.processPaxesData(autoSave);

        // Formatear los datos de los pasajeros
        const formattedPaxesData = await this.formatPaxesData(paxesData);
        let formattedPaxesDataAutoSave: any = formattedPaxesData;
        if(formattedPaxesDataAutoSave && formattedPaxesDataAutoSave.length > 0 && autoSave === true) {
          formattedPaxesDataAutoSave = await this.formatPaxesDataAutoSave(formattedPaxesDataAutoSave);
        }
        console.log(formattedPaxesDataAutoSave)
        // Crear el payload a enviar
        const payload = {
            paxList: formattedPaxesDataAutoSave,
        };



        // Mostrar el payload en la consola


        // Enviar los datos a la API para su procesamiento
        this._restService.doPut(routes.app, routes.updatePaxlist + this.itineraryId, payload).subscribe(
            (response: any) => {
                console.log(response);
              if(autoSave === false) {
                // Si la solicitud es exitosa
                if (response.success) {
                    // Si se permite la configuración, actualizar la lista de pasajeros localmente
                    if (this.permitedConfiguration) {
                        this.itinerary.paxList = formattedPaxesData;
                        this.userService.itineraryActive[0].paxList = formattedPaxesData;
                        this.userService.stepReservation = "three"; // Cambiar al paso tres de la reserva

                    } else {
                        // Si no se permite la configuración, iniciar el proceso de reserva
                        this.formPaxesReservationData.reserving = true;
                        this.goReservation(formattedPaxesData, autoSave);
                    }
                }
              } else {
                this.toastr.success(`autoguardado finalizado`);
              }
            },
            (error: any) => {
                console.log("Save-Error: " + error); // Manejar errores en caso de que la solicitud falle
            }
        );
    // } catch (error) {
    //     console.log("Save-Error: " + error); // Manejar errores en caso de que la solicitud falle
    // }
}



  async processPaxesData(autoSave: boolean) {

    // Arreglo para almacenar los IDs de los clientes asociados
    const clientIdArr: any[] = [];
    const paxesData = [];


      clientIdArr[0] = this.clientOwner.id;
// console.log(this.formPaxesReservationData)
//       // Iterar sobre cada pasajero en paxList

//         let client: any;
//         // Buscar el cliente en clientArr usando el número de documento
//         let clientAssociated;
//         const validateClient = this.validDataForm;
//         validateClient.forEach((payloadClient: any, index: number) => {
//           payloadClient.email = null;
//           payloadClient.type = null;
//           this._restService.doPost('app', 'checkClient', payloadClient).subscribe({
//             next: (res: any) => {
//               console.log(res);
//               if(res.exist === true) {
//                 client = res.client;
// console.log(client)
//                           // Si se encuentra el cliente, guardar su ID en clientIdArr; de lo contrario, guardar null
//                   if (client && client.document_number !== null) {
// console.log(client)
//                     clientIdArr.push(client.id);
//                 } else {
//                   clientIdArr.push(null);
//                 }
//               } else {
//                 clientIdArr.push(null);
//               }



//             }


//           })
//           console.log('clientId Array: ', clientIdArr);

//           // Iterar sobre los datos de los pasajeros en el formulario

            for (let index = 0; index < this._form.value.paxes.length; index++) {
            // Obtener el pasajero actual
            const i = this._form.value.paxes[index];
            console.log(this._form.value.paxes)
            console.log(i)
            const country = this.filteredCountries.find(c => c.country_name === i.country_id);
            const state = this.formPaxesReservationData.states[index].states.find(c => c.destination_name === i.state_id);
            const city = formPaxesReservationData?.cities[index].cities.find(c => c.city_name === i.city);
            if(index > 0) {

              const client = this.formPaxesReservationData.clientsAssociated.find(c => c.document_number == i.document_number);

              if(client) {
                clientIdArr.push(client.client_id ? client.client_id : null);
              } else {
                clientIdArr.push(null);
              }
            }
            console.log(clientIdArr);
            if(autoSave) {
              if(i.phone < 0) {
                i.phone = null;
              }
              if(i.phone_2 < 0) {
                i.phone_2 = null;
              }
              if(i.document_number < 0) {
                i.document_number = null;
              }
              if (i.first_name && !/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(i.first_name)) {
                i.first_name = null;
              }
              if (i.last_name && !/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(i.last_name)) {
                i.last_name = null;
              }
              if (i.email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(i.email)) {
                i.email = null;
              }
            }

            // Asignar el código de país al campo country_id
            i.country_id = country?.country_code || null;

            // Asignar el código de estado al campo state_id
            i.state_id = state?.destination_id || null;

            i.city = city?.city_id || null;

            // Asignar el tipo de pasajero
            i.type = this.paxes[index].type;

            // Asignar el ID del cliente asociado al pasajero

            // if(index === 0) {
              i.clientId = clientIdArr[index];
            // } else {
            //   console.log(this.formPaxesReservationData.clientsAssociated);
            //
            //   i.clientId = client.client_id ? client.client_id : null;
            // }

            // Agregar los datos del pasajero al arreglo paxesData
            paxesData.push(i);
          }
//         })

        return paxesData;
        // if(this.formPaxesReservationData.clientsAssociated && Array.isArray(this.formPaxesReservationData.clientsAssociated)) {
        //   clientAssociated = this.formPaxesReservationData.clientsAssociated.find((client: any) => Number(client.document_number) === Number(pax.document_number));
        // }



}

async formatPaxesData(paxesData: any[]) {
    return paxesData.map((pax) => {
        // Desestructurar el objeto pax, excluyendo la propiedad relation_ship
        const { relation_ship, ...rest } = pax;

        // Formatear la fecha de nacimiento al formato 'YYYY-MM-DD'
        return {
            ...rest,
            birth_date: moment(pax.birth_date, ['YYYY-DD-MM', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY']).format('YYYY-MM-DD'),
            relation_ship_catalogue_id: relation_ship, // Asignar la relación al catálogo de relación
        };
    });
}

formatPaxesDataAutoSave(formattedPaxesData: any): any {
  let formatPaxesDataAuto: any;

  formatPaxesDataAuto = formattedPaxesData.map((paxes: any) => {
    paxes.idUnique = null;
    if(paxes.type === 'adult') {
      const birth_date_str = paxes.birth_date; // Asumiendo que es una cadena en formato "YYYY-MM-DD"
      const [year, month, day] = birth_date_str.split('-').map(Number);
      const birth_date = new Date(year, month - 1, day); // El mes es 0 indexado
      if(birth_date.getTime() === this.formPaxesReservationData.minDateAdult.getTime()) {
        console.log(paxes)
        paxes.birth_date = null;
      }
    } else {
      const birth_date = new Date(paxes.birth_date)
      if(birth_date.getTime() === this.formPaxesReservationData.maxDateChild.getTime()) {
        paxes.birth_date = null;
      }
    }
    return paxes;
  });

  return formatPaxesDataAuto;
}

  goReservation(paxes: any, autoSave?: boolean) {
    // Reiniciar el arreglo de resultados de la reserva
    this.formPaxesReservationData.resultReserve = [];

    // Agregar un objeto de reserva inicial al arreglo resultReserve
    this.formPaxesReservationData.resultReserve.push({
      itinerary_id: this.itineraryId, // ID del itinerario
      starDate: moment(), // Fecha de inicio (momento actual)
      endDate: moment(), // Fecha de finalización (momento actual)
      status_id: 4, // ID del estado de la reserva (4 para "En proceso")
      qtyReservedProduct: 0, // Cantidad de productos reservados (inicialmente 0)
      totalNetPrice: 0, // Precio neto total de la reserva (inicialmente 0)
    });

    // Iterar sobre cada elemento del itinerario
    this.itineraryItems.forEach((item) => {
      // Generar el payload para la reserva
      let _payload = this._payload(item, paxes);
      // Realizar la reserva del elemento actual del itinerario
      this.makeReserve(_payload, item, paxes, autoSave);
    });

    return true; // Retorna verdadero como indicación de que la función se ejecutó correctamente
}



_payload(item: any, paxes: any): any {
  // Inicializar las variables necesarias para el payload
  let productId =
    item.categoryName == "INSURANCE" && item.nameAdapter == "Universal_Assistance"
      ? item.title
      : item.categoryName == "DISNEY"
      ? item.id
      : item.idProduct;
  let searchId = item.categoryName == "DISNEY" ? item.arrayResults[0]["searchId"] : item.searchId;
  let providerId = item.categoryName == "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId;
  let _id: string = item.id;

  // Ajustar el ID del producto en caso de que sea un seguro
  if (item.categoryName == "INSURANCE") {
    let idProduct = _id.split("/-/");
    _id = idProduct[0];
  }

  // Construir el payload de reserva
  const payload = [
    {
      product: {
        searchId: searchId, // ID de búsqueda del producto
        id: _id, // ID del producto
        providerId: providerId, // ID del proveedor
        providerName: item.providerName, // Nombre del proveedor
        title: item.title, // Título del producto
        description: item.description, // Descripción del producto
        categoryId: item.category_id, // ID de la categoría del producto
        productId: productId, // ID del producto específico
        origin:
          item.categoryName != "DISNEY" ? (item.payloadUpdate.products[0].origin ? item.payloadUpdate.products[0].origin : null) : null, // Origen del producto
        destination:
          item.categoryName != "DISNEY"
            ? item.payloadUpdate.products[0].destination
              ? item.payloadUpdate.products[0].destination
              : "KOA"
            : item.destination.iata_code, // Destino del producto
        dateIni:
          item.categoryName == "DISNEY"
            ? new DatePipe("en-US").transform(item.destination.destination_info.arrival, "yyyy-MM-dd")
            : item.arrivalDate, // Fecha de inicio del producto
        dateEnd:
          item.categoryName == "DISNEY"
            ? new DatePipe("en-US").transform(item.destination.destination_info.departure, "yyyy-MM-dd")
            : item.departureDate, // Fecha de finalización del producto
        serviceResponse: null, // Respuesta del servicio (inicialmente nula)
        nameAdapter: item.categoryName != "DISNEY" ? item.payloadUpdate.products[0].nameAdapter : "Majestic_Disney", // Adaptador de nombre del producto
        // Información adicional dependiente de cada categoría para reservar
        additionalInfo: this.setAdditionalInfo(item, paxes), // Información adicional para la reserva
      },
      id: null, // ID de la reserva (inicialmente nulo)
      serviceResponse: null, // Respuesta del servicio (inicialmente nula)
    },
  ];
  return payload; // Retorna el payload construido
}

setAdditionalInfo(item: any, paxes: any): any {
  let additionalInfo; // Variable para almacenar la información adicional

  // Selecciona el tipo de información adicional según la categoría del ítem
  switch (item.categoryName) {
      case "HOTELS": // Caso para hoteles
          additionalInfo = {
              hotelId: item.hotelId, // ID del hotel
              roomQty: 1, // Cantidad de habitaciones
              arrival: item.arrivalDate, // Fecha de llegada
              departure: item.departureDate, // Fecha de salida
              rooms: [
                  {
                      roomCode: item.RoomCode,
                      bookParam: item.bookParam,
                      roomId: item.roomId ? item.roomId : item.hotelId,
                      optionId: item.optionId ? item.optionId : null,
                      accomodationId: item.accomodationId ? item.accomodationId : null,
                      maxPax: 1,
                      refPrice: item.refPrice,
                      paxList: {
                          pax: this.setPaxesReserve(paxes),
                      },
                  },
              ],
          };
          break;
      case "TOURS": // Caso para tours
          additionalInfo = {
              tours: [
                  {
                      company: item.company ? item.company : "Agencia de pruebas",
                      optionId: item.optionId,
                      paxAdults: item.paxAdults,
                      paxChildren: item.paxChildren,
                      bookingCode: item.bookingCode ? item.bookingCode : null,
                      paxList: this.setPaxesReserve(paxes),
                      answers: item.questions.length > 0 ? [] : [],
                      sessionCode: item.questions.length > 0 ? [] : null,
                      languageCode: item.questions.length > 0 ? [] : null,
                  },
              ],
          };
          break;
      case "TRANSPORTS": // Caso para transportes
          additionalInfo = {
              transfers: [
                  {
                      oneWay: item.payload.others.transfers.oneWay,
                      contractList: item.contractList,
                      pickupTime: item.pickupTime,
                      dropoffTime: item.dropoffTime,
                      pickupLocation: {
                          id: item.payload.others.transfers.pickupLocation.id,
                          type: item.payload.others.transfers.pickupLocation.type,
                      },
                      dropoffLocation: {
                          id: item.payload.others.transfers.dropoffLocation.id,
                          type: item.payload.others.transfers.dropoffLocation.type,
                      },
                      goingWay: {
                          transfersTypeLeg: item.vehicles[0].transfersTypeLeg ? item.vehicles[0].transfersTypeLeg : "IN",
                          transferCode: item.vehicles[0].transferCode ? item.vehicles[0].transferCode : "0|0|0",
                          transferType: item.vehicles[0].transferType ? item.vehicles[0].transferType : "P",
                          vehicleType: item.vehicles[0].transferType ? item.vehicles[0].transferType : 9,
                          travelInfo: "xyz",
                          travelCompanyName: "avior airline",
                          travelDate: item.pickupTime,
                          travelTime: item.pickupTime,
                      },
                      returnWay: {
                          transfersTypeLeg: item.vehicles[1].transfersTypeLeg ? item.vehicles[1].transfersTypeLeg : "OUT",
                          transferCode: item.vehicles[1].transferCode ? item.vehicles[1].transferCode : "1|0|0",
                          transferType: item.vehicles[1].transferType ? item.vehicles[1].transferType : "P",
                          vehicleType: item.vehicles[1].transferType ? item.vehicles[1].transferType : 9,
                          travelInfo: "abc",
                          travelCompanyName: "avianca",
                          travelDate: item.dropoffTime,
                          travelTime: item.dropoffTime,
                      },
                      paxList: this.setPaxesReserve(paxes),
                  },
              ],
          };
          break;
      case "INSURANCE": // Caso para seguros
          additionalInfo = {
              insurance: {
                  price: item.totalPrice,
                  paxList: this.setPaxesReserve(paxes, true),
              },
          };
          break;
      case "PTHEMES": // Caso para temas de parques
          additionalInfo = {
              arrival: item.arrivalDate,
              departure: item.departureDate,
              paxList: {
                  pax: this.setPaxesReserve(paxes),
              },
              optionId: item.optionId,
          };
          break;
      case "DISNEY": // Caso para Disney
          additionalInfo = {
              searchId: item.hotel["additionalInfo"]["searchId"],
              hotelId: item.hotel.id,
              roomId: item.room.roomId,
              accId: item.room.accomodationId,
              rateId: item.plan.rateId,
              ticketTypeId: item.ticket !== undefined ? item.ticket.id : 0,
              mealPlanId: item.mealPlan !== null ? item.mealPlan.id : 0,
              arrivalDate: item.destination.destination_info.arrival,
              departureDate: item.destination.destination_info.departure,
              days: item.days,
              noExpiration: false,
              paxList: this.setPaxesReserve(paxes, true),
          };
          break;
      default:
          break;
  }
  return additionalInfo
}


makeReserve(payload: any, item: any, paxes: any, autoSave?: boolean) {
  // Realiza una solicitud POST para realizar una reserva utilizando el servicio REST
  console.log(payload);
  this._restService.doPost(routes.api, routes.reservation, payload).subscribe(
    (response: any) => { // Maneja la respuesta de la solicitud
      if (response !== null && response.results !== null && response.status !== false) { // Verifica si la respuesta es válida
        // Guarda los resultados de la reserva en la variable resultReserve
        this.formPaxesReservationData.resultReserve.push({
          status_id: response[0].status === true ? 1 : 0, // Estado de la reserva (1: éxitosa, 0: fallida)
          resultReserve: response[0].status === true ? response[0].result : response[0].message, // Resultado de la reserva
          category_id: item.category_id, // ID de la categoría del elemento del itinerario
          providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId, // ID del proveedor
          netPrice: response[0].status === true ? item.totalPrice : 0, // Precio neto de la reserva (si la reserva fue exitosa)
          paxList: this.setPaxesReserve(paxes), // Lista de pasajeros asociados a la reserva
          uid: item.id, // ID único del elemento del itinerario
        });
        if (this.formPaxesReservationData.resultReserve.length - 1 >= this.itineraryItems.length) {
          // Guarda la reserva en la API si se han procesado todas las reservas
          this.saveReservation(this.formPaxesReservationData.resultReserve, autoSave);
        }
      } else { // Si la respuesta no es válida
        // Agrega la información de la reserva fallida a resultReserve
        this.formPaxesReservationData.resultReserve.push({
          status_id: 0, // Estado de la reserva fallida
          resultReserve: response.message, // Mensaje de error de la reserva
          category_id: item.category_id, // ID de la categoría del elemento del itinerario
          netPrice: 0, // Precio neto de la reserva (0 en caso de falla)
          providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.providerId, // ID del proveedor
          paxList: this.setPaxesReserve(paxes), // Lista de pasajeros asociados a la reserva
          uid: item.id, // ID único del elemento del itinerario
        });

        if (this.formPaxesReservationData.resultReserve.length - 1 >= this.itineraryItems.length) {
          // Guarda la reserva en la API si se han procesado todas las reservas
          this.saveReservation(this.formPaxesReservationData.resultReserve, autoSave);
        }
      }
      console.log("algo paso reserva: ", response); // Imprime la respuesta de la reserva en la consola
    },
    (error) => { // Maneja los errores de la solicitud
      // Agrega la información de la reserva fallida a resultReserve
      this.formPaxesReservationData.resultReserve.push({
        status_id: 0, // Estado de la reserva fallida
        resultReserve: error, // Mensaje de error de la reserva
        category_id: item.category_id, // ID de la categoría del elemento del itinerario
        netPrice: 0, // Precio neto de la reserva (0 en caso de falla)
        paxList: this.setPaxesReserve(paxes), // Lista de pasajeros asociados a la reserva
        uid: item.id, // ID único del elemento del itinerario
      });

      if (this.formPaxesReservationData.resultReserve.length - 1 >= this.itineraryItems.length) {
        // Guarda la reserva en la API si se han procesado todas las reservas
        this.saveReservation(this.formPaxesReservationData.resultReserve, autoSave);
      }
      console.log(error); // Imprime el error en la consola
    }
  );
}

/**
 * Genera un arreglo de objetos que representan a los pasajeros para una reserva, con la información proporcionada.
 * @param pax Arreglo que contiene la información de los pasajeros.
 * @param insurance Indica si la reserva es para un seguro.
 * @returns Un arreglo de objetos que representan a los pasajeros para la reserva.
 */
setPaxesReserve(pax: any, insurance: boolean = false): any {
  let paxes = []; // Arreglo para almacenar la información de los pasajeros
  let i = 0; // Variable para llevar el conteo de pasajeros
  pax.forEach((pax) => { // Itera sobre cada pasajero
      let Age = moment().diff(pax.birth_date, "years"); // Calcula la edad del pasajero
      if (insurance == true) { // Si la reserva es para un seguro
          // Agrega la información del pasajero al arreglo de pasajeros
          paxes.push({
              gender: pax.gender, // Género del pasajero
              first_name: pax.first_name, // Nombre del pasajero
              last_name: pax.last_name, // Apellido del pasajero
              identificationType: pax.document_type_id, // Tipo de documento de identificación del pasajero
              identification: pax.document_number, // Número de documento de identificación del pasajero
              birthDate: moment(pax.birth_date, ['YYYY-DD-MM', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY']).format('DD-MM-YYYY'), // Fecha de nacimiento del pasajero
              age: Age, // Edad del pasajero
              email: "agency@demo.com", // Correo electrónico del pasajero (predeterminado)
              phone: "7236229081", // Número de teléfono del pasajero (predeterminado)
              address: "Agencia de prueba AR", // Dirección del pasajero (predeterminado)
              country_id: "AR", // ID del país del pasajero (predeterminado)
              city: "BUENOS AIRES", // Ciudad del pasajero (predeterminado)
              state_id: "MENDOZA", // ID del estado/provincia del pasajero (predeterminado)
              holder: i == 0 ? "holder" : "", // Indica si el pasajero es el titular de la reserva
          });
      } else { // Si la reserva no es para un seguro
          // Agrega la información del pasajero al arreglo de pasajeros
          paxes.push({
              title: pax.title, // Título del pasajero
              first_name: pax.first_name, // Nombre del pasajero
              last_name: pax.last_name, // Apellido del pasajero
              birthDate: moment(pax.birth_date, ['YYYY-DD-MM', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY']).format('DD-MM-YYYY'), // Fecha de nacimiento del pasajero
              age: Age, // Edad del pasajero
              email: pax.email, // Correo electrónico del pasajero
              phone: pax.phone, // Número de teléfono del pasajero
              phone_2: pax.phone_2, // Segundo número de teléfono del pasajero
              address: pax.address, // Dirección del pasajero
              country_id: pax.county, // ID del país del pasajero
              city: pax.city, // Ciudad del pasajero
              state_id: pax.state_id, // ID del estado/provincia del pasajero
              holder: i == 0 ? "holder" : "", // Indica si el pasajero es el titular de la reserva
          });
      }
      i = i + 1; // Incrementa el contador de pasajeros
  });
  return paxes; // Retorna el arreglo de pasajeros
}



saveReservation(reserve: any, autoSave?: boolean) {
  // Agrega el ID del itinerario al objeto de reserva
  reserve["itinerario_id"] = this.itineraryId;

  // Realiza una solicitud POST para guardar la reserva en la API
  this._restService.doPost("app", "reservations", reserve).subscribe(
    (response: any) => { // Maneja la respuesta de la solicitud
      if (response.success == true) { // Verifica si la reserva se guardó correctamente
        // Redirecciona a la confirmación de la reserva
        if(autoSave === false) {
          console.log('entre')
          this.confirmationRedirect(response.data.reservation_id);
        }
      }
    },
    (error) => { // Maneja los errores de la solicitud
      console.log("error en API: ", error); // Imprime el error en la consola
    }
  );
}

 /**
 * Redirige a la página de confirmación de reserva, pasando parámetros adicionales en la URL.
 * @param reservationId El ID de la reserva que se pasa como parámetro en la URL.
 */
confirmationRedirect(reservationId: number) {
  let navigationExtras: NavigationExtras = {
      queryParams: {
          id: this.itineraryId, // ID del itinerario que se pasa como parámetro en la URL
          stat: "true", // Estado de la reserva que se pasa como parámetro en la URL
          screen: "reservation", // Pantalla de reserva que se pasa como parámetro en la URL
          reservationId: reservationId, // ID de la reserva que se pasa como parámetro en la URL
      },
  };
  // Redirige a la página de confirmación de reserva con los parámetros adicionales en la URL
  this.router.navigate([routes.reservation_confirmation], navigationExtras);
}




  /**
   * Metodo donde se obtiene el valor maximo de paxAdults y paxChi
   */
  checkAmountPaxes() {
    let adultsArr = [];
    let childrenArr = [];

    for (let i = 0; i < this.itineraryItems.length; i++) {
      let paxAdults = this.itineraryItems[i].paxAdults;
      let paxChildren = this.itineraryItems[i].paxChildren;
      // console.log('paxadults -->', paxAdults);
      // console.log('paxChildren -->', paxChildren);

      adultsArr.push(paxAdults);
      childrenArr.push(paxChildren);
    }

    // console.log('adultsArr ->', adultsArr);
    // console.log('childrenArr ->', childrenArr);

    let adultsValueMax = Math.max(...adultsArr);
    // console.log('value max adults -->', adultsValueMax);
    let childrenValueMax = Math.max(...childrenArr);
    // console.log('value max adults -->', childrenValueMax);

    this.itineraryItems.forEach((element: any) => {
      // console.log('elemento del forEach -->', element);
      if (element.paxAdults == adultsValueMax && element.paxChildren == childrenValueMax) {
        let holder = "";
      }
    });
    this.formPaxesReservationData.adultsPax = adultsArr;
  }

//** -------------=== Formulario de cliente ===------------ */


/**
 * Configura los datos del cliente cuando se crea un nuevo cliente.
 *
 * @param clientData - Objeto que contiene información sobre el cliente.
 *
 * Este método se utiliza para configurar los datos iniciales de un cliente cuando se está creando
 * un nuevo registro. Configura el tipo de cliente como "adulto", inicializa el formulario del cliente,
 * obtiene la lista de países y establece varias propiedades en `clientData` relacionadas con la acción
 * y el estado del cliente asociado.
 */
private setDataClientNew(clientData: any): void {
  this.clientData.type = "adult";
  this.setFormClient();
  this.clientData.typeAction = clientData.typeAction;
  this.clientData.clientAssociated = true;
  this.clientData.fromAssociates = clientData.fromAssociates;
}


/**
 * Configura los datos del cliente cuando se crea un nuevo cliente asociado.
 *
 * @param clientData - Objeto que contiene información sobre el cliente.
 *
 * Este método se utiliza para configurar los datos iniciales de un cliente cuando se está creando
 * un nuevo registro asociado. Configura el tipo de cliente como "adulto", inicializa el formulario
 * del cliente, obtiene la lista de países y establece varias propiedades en `clientData` relacionadas
 * con la acción, el estado del cliente principal y los clientes asociados.
 */
private setDataClientNewAssociated(clientData: any): void {
  this.clientData.type = "adult";
  this.clientData.clientAssociated = false;
  this.clientData.typeAction = clientData.typeAction;
  this.clientData.fromAssociates = clientData.fromAssociates;
  this.clientData.mainClient = clientData.mainClient;
  this.clientData.clients = clientData.clients;
  this.clientData.clientAssociatedArr = clientData.clientAssociatedArr;
  console.log(this.clientData)
  this.setFormClient();
  this.associatedSelectFilter();
}


/**
 * Configura los datos del cliente cuando se edita un cliente existente.
 *
 * @param clientData - Objeto que contiene información sobre el cliente.
 *
 * Este método se utiliza para configurar los datos de un cliente cuando se está editando
 * un registro existente. Inicializa el formulario del cliente, establece varias propiedades
 * en `clientData` relacionadas con la acción, el estado del cliente principal, los clientes
 * asociados y su información. También determina si el cliente es un "adulto" o un "niño" basado
 * en su fecha de nacimiento y configura el formulario en consecuencia.
 */
private setDataClientEdit(clientData: any): void {
  this.clientData.type = "adult";
  this.clientData.styleFormDisable = false;
  this.clientData.fromAssociates = clientData.fromAssociates;
  this.clientData.typeAction = clientData.typeAction;
  this.clientData.mainClient = clientData.mainClient;
  this.clientData.index = clientData.index;
  if (this.clientData.fromAssociates === true) {

      this.clientData.client = clientData.client;
      this.clientData.clientAssociated = false;
      this.clientData.assosiateClient = clientData.assosiateClient;
      // this.clientData.countriesData = clientData.countriesData.Countrys;
      this.setFormClient();
      this.setEditAssosiateForm();
  } else {
      this.clientData.clientAssociated = true;
      this.setFormClient();
      this.setEditForm();
  }

}


numberValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value == null || value === '') {
    return null; // No se realiza la validación si el valor es nulo o una cadena vacía
  }
  return isNaN(value) ? { notANumber: true } : null;
}


/**
 * Configura el formulario del cliente.
 *
 * Este método se utiliza para inicializar y configurar el formulario del cliente,
 * creando un FormArray de pasajeros (paxes) y añadiendo los controles necesarios
 * para capturar la información del cliente. También configura ciertos controles
 * basados en las propiedades de `clientData`.
 */
private setFormClient(): void {

  // Añade el tipo de cliente al array de pasajeros
  this.paxes.push({type: this.clientData.type});

  // Inicializa el formulario principal con un FormArray vacío llamado 'paxes'
  this._form = this.fb.group({
      paxes: this.fb.array([]), // FormArray para los datos de los pasajeros
  });

  // Obtiene el FormArray 'paxes'
  let guest = this._form.get("paxes") as FormArray;

  // Añade un nuevo FormGroup al FormArray 'paxes' con los controles necesarios
  guest.push(this.fb.group({
      gender: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      first_name: new FormControl(null,  [Validators.required, Validators.pattern(/^[a-zA-ZñÑ\s]*$/)]),
      last_name: new FormControl(null,  [Validators.required, Validators.pattern(/^[a-zA-ZñÑ\s]*$/)]),
      birth_date: new FormControl(null, Validators.required),
      country_id: new FormControl(null, Validators.required),
      document_type_id: new FormControl(null, Validators.required),
      document_number: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+$/), this.nonNegativeValidator()]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^\d{6,}$/)]),
      phone_code: new FormControl(null, Validators.required),
      phone_code_selected: new FormControl(null),
      phone_2: new FormControl(null, Validators.pattern(/^\d{6,}$/)),
      phone_2_code: new FormControl(null),
      phone_2_code_selected: new FormControl(null),
      city: new FormControl(null, Validators.required),
      state_id: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      postal_code: new FormControl(null),
      relation_ship: new FormControl(null, Validators.required),
      selectClientExist: new FormControl(null),
      type: new FormControl(this.clientData.type)
  }));

  // Accede al primer FormGroup dentro del FormArray 'paxes'
  const paxGroup = guest.at(0) as FormGroup;

  // Deshabilita el control 'selectClientExist'
  paxGroup.get('selectClientExist').disable();

  // Deshabilita el control 'relation_ship' si el cliente no es asociado
  if (this.clientData.fromAssociates === false) {
      paxGroup.get('relation_ship').disable();
  }

  // Configura la fecha mínima para adultos si el cliente es asociado y la acción es 'New'
  if (this.clientData.fromAssociates === true && (this.clientData.typeAction === 'New' || this.clientData.typeAction === 'Edit')) {
      this.formPaxesReservationData.minDateAdult = new Date();
  }

}



/**
 * Configura el formulario para la edición de un cliente existente.
 *
 * Este método se utiliza para rellenar los campos del formulario con los datos de
 * un cliente existente cuando se está en modo de edición. Accede al FormArray 'paxes'
 * y actualiza los valores del primer FormGroup con la información del cliente principal.
 */
private setEditForm(): void {
  console.log('document id mainclient ->', this.clientData.mainClient);

  if (this.clientData.mainClient) {
      // Encuentra el país correspondiente al código telefónico secundario
      const countryOfPhoneCode_2 = this.formPaxesReservationData.countries.find(c => c.phonecode === this.clientData.mainClient.phone_2_code);

      // Formatea la fecha de nacimiento del cliente principal
      const birth_date = new DatePipe("en-US").transform(this.clientData.mainClient.birth_date, "dd-MM-yyyy");

      // Accede al FormArray llamado 'paxes'
      const paxesArray = this._form.get('paxes') as FormArray;

      // Asegúrate de que el FormArray no está vacío
      if (paxesArray.length > 0) {
          // Accede al primer FormGroup dentro del FormArray
          const paxGroup = paxesArray.at(0) as FormGroup;
          this.selectedIsCountry[0] = this.clientData.mainClient.country_id;
          this.selectedIsState[0] = this.clientData.mainClient.state_id;
          this.selectedIsState[0] = this.clientData.mainClient.city;
          this.phone_code[0] = this.clientData.mainClient.phone_code;
          // console.log(countryCode.displayValue)
          // Rellena los valores del FormGroup con los datos del cliente principal
          paxGroup.patchValue({
              gender: this.clientData.mainClient.gender,
              title: this.clientData.mainClient.title,
              first_name: this.clientData.mainClient.first_name,
              last_name: this.clientData.mainClient.last_name,
              birth_date: birth_date,
              country_id: this.clientData.mainClient.country_id,
              document_type_id: this.clientData.mainClient.document_type_id,
              document_number: this.clientData.mainClient.document_number,
              email: this.clientData.mainClient.email,
              phone: this.clientData.mainClient.phone,
              phone_code: this.clientData.mainClient.phone_code,
              phone_code_selected: this.clientData.mainClient.phone_code,
              phone_2: this.clientData.mainClient.phone_2,
              phone_2_code: this.clientData.mainClient.phone_2_code,
              city: Number(this.clientData.mainClient.city),
              state_id: Number(this.clientData.mainClient.state_id),
              address: this.clientData.mainClient.address,
              postal_code: this.clientData.mainClient.postal_code,
              type: '',
          });
          // Obtiene la lista de países y establece el país del cliente principal
          this.getCountriesData(0, true, this.clientData.mainClient.country_id);
          // Si el código telefónico secundario no es nulo, establece su valor
          // if (this.clientData.mainClient.phone_2_code !== null) {
          //     paxGroup.patchValue({
          //         selectPhoneCode_2: countryOfPhoneCode_2 ? countryOfPhoneCode_2.phonecode : null,
          //     });
          // }

            // Inicializa el valor del input de país
            // this.filterCountries('');
            // const country = this.formPaxesReservationData.countries.find(c => c.code === this.clientData.mainClient.country_id);
            // if (country) {s
            //   paxGroup.patchValue({
            //     country_id: country.code
            //   });

            // }


            if (this.clientData.mainClient.document_number) {
              this.clientData.styleFormDisable = false;
            }
      }

  }

  this.loadedClientInfoForm = true;
  this.ngxSpinner.hide();
}


/**
 * Filtra las listas de clientes asociados para excluir el cliente principal y otros
 * clientes ya asociados.
 *
 * Esta función se asegura de que los clientes asociados y las listas de clientes no contengan
 * duplicados o el cliente principal.
 */
private associatedSelectFilter(): void {
  // Si la lista de clientes no está indefinida y no está vacía
  if (this.clientData.clients !== undefined && this.clientData.clients.length > 0) {
      // Filtra los clientes para excluir el cliente principal
      this.clientData.clients = this.clientData.clients.filter(c => c.id !== this.clientData.mainClient.id);
  }

  // Si la lista de clientes asociados no está indefinida y no está vacía
  if (this.clientData.clientAssociatedArr !== undefined && this.clientData.clientAssociatedArr.length > 0) {
      // Aplana la matriz de matrices de clientes asociados
      const arrayOfObjects = [].concat(...this.clientData.clientAssociatedArr);

      // Filtra los objetos que tienen el mismo id que el cliente principal
      this.clientData.arrayAssociated = arrayOfObjects.filter(c => c.clientId === this.clientData.mainClient.id);

      // Para cada cliente asociado encontrado, filtra los clientes en la lista principal para excluir los ya asociados
      this.clientData.arrayAssociated.forEach(associatedItem => {
          associatedItem.response.forEach(associatedRes => {
              this.clientData.clients = this.clientData.clients.filter(client => client.document_number !== associatedRes.document_number);
          });
      });
  }
}


/**
 * Habilita o deshabilita el campo de selección del cliente existente basado en el estado del checkbox.
 *
 * Esta función gestiona la activación o desactivación del control 'selectClientExist' dentro del formulario
 * dependiendo del estado del checkbox proporcionado.
 *
 * @param checkbox - El checkbox HTML que controla la selección del cliente existente.
 */
public toggleSelect(checkbox: HTMLInputElement): void {
  this.clientData.styleFormDisable = false;
  // Accede al FormArray llamado 'paxes'
  const paxesArray = this._form.get('paxes') as FormArray;

  // Asegúrate de que el FormArray no está vacío
  if (paxesArray.length > 0) {
      // Accede al primer FormGroup dentro del FormArray
      const paxGroup = paxesArray.at(0) as FormGroup;

      // Obtén el control 'selectClientExist' del FormGroup
      const selectControl = paxGroup.get('selectClientExist');

      // Si el checkbox no está marcado, reinicia y desactiva el control de selección
      if (!checkbox.checked) {
          selectControl.reset();
          selectControl.disable();
          this.clientData.styleFormDisable = false;
          this.clientData.checkboxChecked = false;
      } else {
          // Si el checkbox está marcado, habilita el control de selección
          selectControl.enable();
          this.clientData.checkboxChecked = true;
      }

      // Alterna el estado de 'clientSelectedForm' para reflejar el cambio
      this.clientData.clientSelectedForm = !this.clientData.clientSelectedForm;
  }
}

/**
 * Selecciona un cliente basado en su ID y actualiza el formulario con los datos del cliente seleccionado.
 *
 * Esta función busca un cliente en la lista de clientes usando el ID proporcionado, luego rellena
 * los campos del formulario con los datos del cliente seleccionado.
 *
 * @param clientId - El ID del cliente a seleccionar.
 */
public clientSelect(clientId: any): void {
  // Convierte el ID del cliente a número entero
  const clientIdNumber = parseInt(clientId, 10);

  // Busca el cliente en la lista de clientes usando el ID
  const clientSelected = this.clientData.clients.find(client => client.id === clientIdNumber);
  this.newClientSelectedList = clientSelected;
  console.log(clientSelected);
  // Si se encuentra el cliente y el objeto no está vacío
  if (clientSelected && Object.keys(clientSelected).length > 0) {
      // Busca el código de país del teléfono principal y secundario del cliente
      const countryOfPhoneCode = this.clientData.countriesData.find(c => c.phonecode === clientSelected.phone_code);
      const countryOfPhoneCode_2 = this.clientData.countriesData.find(c => c.phonecode === clientSelected.phone_2_code);

      // Formatea la fecha de nacimiento del cliente
      const date = new Date();
      const birth_date = new DatePipe("en-US").transform(clientSelected.birth_date, "dd-MM-yyyy");

      // Asigna el ID del cliente asociado
      this.clientData.client_associated_id = clientSelected.id;

      // Accede al FormArray llamado 'paxes'
      const paxesArray = this._form.get('paxes') as FormArray;

      // Asegúrate de que el Fo rmArray no está vacío
      if (paxesArray.length > 0) {
          // Accede al primer FormGroup dentro del FormArray
          const paxGroup = paxesArray.at(0) as FormGroup;
          this.selectedIsCountry[0] = clientSelected.country_id;
          this.selectedIsState[0] = clientSelected.state_id;
          this.selectedIsState[0] = clientSelected.city;
          this.phone_code[0] = clientSelected.phone_code;

          // Reinicia los valores del FormGroup
          paxGroup.reset();

          if (date >  clientSelected.birth_date) {
            paxGroup.get('relation_ship').setValue(null);
            this.formPaxesReservationData.relationShips = this.formPaxesReservationData.relationShips.filter(c => c.id !== 1 && c.id !== 2);
          }

          // Actualiza los valores del FormGroup con los datos del cliente seleccionado
          paxGroup.patchValue({
              gender: clientSelected.gender,
              title: clientSelected.title,
              first_name: clientSelected.first_name,
              last_name: clientSelected.last_name,
              birth_date: birth_date,
              country_id: clientSelected.country_id,
              document_type_id: clientSelected.document_type_id,
              document_number: clientSelected.document_number,
              email: clientSelected.email,
              phone: clientSelected.phone,
              phone_code: clientSelected.phone_code,
              selectPhoneCode: clientSelected.phone_code,
              phone2: clientSelected.phone_2,
              phone2_code: clientSelected.phone_2_code,
              selectPhoneCode_2: clientSelected.phone_2_code,
              city: Number(clientSelected.city),
              state_id: Number(clientSelected.state_id),
              address: clientSelected.address,
              postal_code: clientSelected.postal_code
          });
          this.selectedClientAssociated = true;
          // Obtiene los países para actualizar la selección del país en el formulario
          this.getCountriesData(0, false, clientSelected.country_id);

          // Marca el campo de fecha de nacimiento como tocado
          paxGroup.controls['birth_date'].markAsTouched({ onlySelf: true });


          if(clientSelected.document_number !== null) {
            this.clientData.styleFormDisable = true;
          } else {
            this.clientData.styleFormDisable = false;
          }
      }

  }
}

/**
 * Configura el formulario de edición para el cliente asociado.
 *
 * Esta función establece los valores del formulario con los datos del cliente asociado,
 * incluyendo los campos relacionados con el país, el documento, el teléfono y la fecha de nacimiento.
 */
private setEditAssosiateForm(): void {
  console.log("setup form edit");
  console.log("assositeClient postacode ->", this.clientData.assosiateClient);

  if (this.clientData.assosiateClient) {
      // Determina el tipo de relación en función de la edad del cliente asociado
      if (this.associatedAge(this.clientData.assosiateClient.birth_date) > this.formPaxesReservationData.maxDateChild) {
          this.getRelationShipAssociated('children');
      } else {
          this.getRelationShipAssociated('adult');
      }

      // Accede al FormArray llamado 'paxes'
      const paxesArray = this._form.get('paxes') as FormArray;

      // Asegúrate de que el FormArray no está vacío
      if (paxesArray.length > 0) {
          // Accede al primer FormGroup dentro del FormArray
          const paxGroup = paxesArray.at(0) as FormGroup;


          // Busca el código de país del teléfono principal y secundario del cliente asociado
          this.selectedIsCountry[0] = this.clientData.assosiateClient.country_id;
          this.selectedIsState[0] = this.clientData.assosiateClient.state_id;
          this.selectedIsState[0] = this.clientData.assosiateClient.city;
          this.phone_code[0] = this.clientData.assosiateClient.phone_code;
          // Formatea la fecha de nacimiento del cliente asociado
          const birth_date = new DatePipe("en-US").transform(this.clientData.assosiateClient.birth_date, "dd-MM-yyyy");

          // Actualiza la lista de países para la selección de país en el formulario
          this.getCountriesData(0, false, this.clientData.assosiateClient.country_id);

          // Actualiza los valores del FormGroup con los datos del cliente asociado
          paxGroup.patchValue({
              gender: this.clientData.assosiateClient.gender,
              title: this.clientData.assosiateClient.title,
              first_name: this.clientData.assosiateClient.first_name,
              last_name: this.clientData.assosiateClient.last_name,
              birth_date: birth_date,
              country_id: this.clientData.assosiateClient.country_id ? this.clientData?.assosiateClient?.country_id : this.clientData.assosiateClient.country,
              document_type_id: this.clientData.assosiateClient.document_type_id ? this.clientData?.assosiateClient?.document_type_id : this.clientData.assosiateClient.document,
              document_number: this.clientData.assosiateClient.document_number,
              email: this.clientData.assosiateClient.email,
              phone: this.clientData.assosiateClient.phone,
              phone_code: this.clientData.assosiateClient.phone_code,
              selectPhoneCode: Number(this.clientData.assosiateClient.phone_code),
              phone2: this.clientData.assosiateClient.phone_2,
              phone2_code: this.clientData.assosiateClient.phone_2_code ? this.clientData?.assosiateClient?.phone_2_code : null,
              selectPhoneCode_2: this.clientData.assosiateClient.phone_2_code ? Number(this.clientData?.assosiateClient?.phone_2_code) : null,
              city: Number(this.clientData.assosiateClient.city),
              state_id: this.clientData.assosiateClient.state_id ? Number(this.clientData?.assosiateClient?.state_id) : Number(this.clientData.assosiateClient.state),
              address: this.clientData.assosiateClient.address,
              postal_code: this.clientData.assosiateClient.postal_code
          });



          // Asigna el ID de la relación
          this.clientData.relationId = this.clientData.assosiateClient.relation_ship_catalogue_id;
          this.phone_code[0] = this.clientData.assosiateClient.phone_code;
          // Marca el campo de fecha de nacimiento como tocado
          paxGroup.controls['birth_date'].markAsTouched({ onlySelf: true });
      }
  }

  this.loadedClientInfoForm = true;
  // this.ngxSpinner.hide();
}

/**
 * Calcula la fecha de nacimiento del cliente asociado.
 *
 * @param birth_date La fecha de nacimiento en formato string.
 * @returns La fecha de nacimiento como un objeto Date.
 */
private associatedAge(birth_date: string): Date {
  return new Date(birth_date);
}


/**
 * Obtiene las relaciones asociadas basadas en la edad del cliente.
 *
 * @param age La edad del cliente o tipo ('children' o 'adult').
 */
private getRelationShipAssociated(age: any): void {
  this._itineraryService._restService.doGet('app', 'relationShips').subscribe({
    next: (res: any) => {
      this.clientData.relationShips = res;
      if (this.clientData.fromAssociates && this.clientData.typeAction === 'Edit') {
        if (age === 'children') {
          this.clientData.relationShips = this.clientData.relationShips.filter(c => c.id !== 1 && c.id !== 2);
        }
      }
    },
    error: (error: Error) => {
      console.error(error);
    }
  });
}

/**
 * Cambia la fecha del cliente asociado y ajusta la relación en función de la fecha.
 *
 * @param date La nueva fecha seleccionada.
 */
public changeDateClientAssociated(date: any): void {
  if (this.data.fromAssociates === true) {

      // Accede al FormArray llamado 'paxes'
      const paxesArray = this._form.get('paxes') as FormArray;

      // Asegúrate de que el FormArray no está vacío
      if (paxesArray.length > 0) {
          // Accede al primer FormGroup dentro del FormArray
          const paxGroup = paxesArray.at(0) as FormGroup;
          var currentDate = new Date();
          const dateValue = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());

          if (date > dateValue) {
              paxGroup.get('relation_ship').setValue(null);
              this.formPaxesReservationData.relationShips = this.formPaxesReservationData.relationShips.filter(c => c.id !== 1 && c.id !== 2);
          } else {
              paxGroup.get('relation_ship').setValue(null);
              this.getRelationShip();
          }

          if (this.clientData.typeAction === 'Edit') {
              // Accede al FormArray llamado 'paxes'
              const paxesArray = this._form.get('paxes') as FormArray;

              // Asegúrate de que el FormArray no está vacío
              if (paxesArray.length > 0) {
                  // Accede al primer FormGroup dentro del FormArray
                  const paxGroup = paxesArray.at(0) as FormGroup;
                  paxGroup.get('relation_ship').setValue(null);
                  paxGroup.get('relation_ship').setValue(this.clientData.assosiateClient.relation_ship_catalogue_id);
                  // Forzar la detección de cambios
                  this.cdRef.detectChanges();
              }
          }
      }
  }
}

  _onSubmitClient(event: Event) {
    // this.ngxSpinner.show();
    if (event) {
      event.preventDefault();
    }

    console.log('form onsubmit ->', this._form);
    // console.log("tipo de accion ", this.typeAction);
    let selectedValue = this._form.value.paxes[0].document_number;
    console.log('selectedValue ->', selectedValue);

    const rawBirthDate = this._form.value.paxes[0].birth_date;
    const formattedBirthDate = moment(rawBirthDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

    const birthDate = new DatePipe("en-US").transform(formattedBirthDate, "yyyy-MM-dd");
    // const formattedBirthDate = birthDate.toISOString().split('T')[0]; // Obtén la fecha en formato "yyyy-MM-dd"
    // console.log(this._form.value)
    // console.log(this._form.value.state)
// console.log(this.selectedIsState)
// console.log(this.stateFiltered)
    const country = this.filteredCountries.find(c => c.country_name === this.selectedIsCountry[0]);
    const state = this.stateFiltered.find(c => c.destination_name === this.selectedIsState[0]);
    const city = this.citiesFiltered.find(c => c.city_name === this.selectedIsCity[0]);
    const payload = {
      id: null,
      // relation_ship_catalogue_id: null,
      first_name: this._form.value.paxes[0].first_name,
      last_name: this._form.value.paxes[0].last_name,
      email: this._form.value.paxes[0].email,
      phone: String(this._form.value.paxes[0].phone), //lo cambie a String porque ahora booking-api lo pide de esta manera.
      phone_code:   this.phone_code[0],
      country: country.country_code,
      birth_date: birthDate,
      title: this._form.value.paxes[0].title,
      document_type_id: Number(this._form.value.paxes[0].document_type_id),
      document_number: String(this._form.value.paxes[0].document_number), //lo cambie a String porque ahora booking-api lo pide de esta manera.
      gender: this._form.value.paxes[0].gender,
      relation_ship_catalogue_id: this.clientData.relationId >= 0 ? Number(this.clientData.relationId) : Number(this._form.value.paxes[0].relation_ship),
      address: this._form.value.paxes[0].address,
      city: String(city.city_id),
      phone2: this._form.value.paxes[0].phone_2 ? Number(this._form.value.paxes[0].phone_2) : null,
      phone2_code: this._form.value.paxes[0].phone_2_code ? this._form.value.paxes[0].phone_2_code : null,
      postal_code: this._form.value.paxes[0].postal_code,
      state: state.destination_id,
    };
    //this.fromAssociates ? (this.mainClient.client_id ? this.mainClient.client_id : this.mainClient.id) :  this.mainClient.id,

    if (this.data.typeAction == "New" && this.data.fromAssociates === false) {
      this._restService.doPost("app", "clients", payload).subscribe(
        (responseNew) => {
          if(responseNew.message === 'Client Exist') {
            this.ngxSpinner.hide();
            this.toastr.info("Este email y/o número de documento ya fueron registrados");
            this.data.typeAction = '';
          }
          if(responseNew.message === 'Created successfully') {
          if (responseNew.success) {
            this.ngxSpinner.hide();
            let data = {
              type: "_clientNewEmitter",
              client: responseNew.client,
            };
            this.toastr.success("Cliente Creado Satisfactoriamente");
            this.closeModal(data);
            const client = {
              client_id: responseNew.id,
              agency_id: this.userService._agencyID,
            };
            this._itineraryService.addClientToAgency(client).subscribe((response: any) => {
              console.log("RESPONSE CLIENT", response);
            });
          } else {
            this.ngxSpinner.hide();
            this.toastr.info("Ocurrio un problema al crear el cliente. Intentelo nuevamente por favor");
          }
         }
        },
        (error) => {
          this.toastr.warning(error);
        }
      );
    }

    if (this.data.typeAction == "New" && this.data.fromAssociates === true) {
      if(this.clientData.checkboxChecked === true) {
        const payload = {
          id: this.clientData.mainClient.id,
          client_associated_id: this.clientData.client_associated_id,
          relation_ship_catalogue_id: this._form.value.paxes[0].relation_ship,
        }
        console.log(payload);
        this._restService.doPost("app", "clients/associateClient", payload).subscribe({
          next: (res: any) => {
            console.log(res);
            this.ngxSpinner.hide();
            const client = {
              canMod: true,
            }
            let data: any = {
              type: "_clientNewEmitter",
              client: client
            };
            this.toastr.success("Cliente Asociado Satisfactoriamente");
            this.closeModal(data);
          },
          error: (error: any) => {
            console.error(error);
          }
        })
      } else {

          payload.relation_ship_catalogue_id = this._form.value.paxes[0].relation_ship;
          if(!this.clientData.checkboxChecked) {
            const country = this.filteredCountries.find(c => c.country_name === this.selectedIsCountry[0]);
            const state = this.stateFiltered.find(c => c.destination_name === this.selectedIsState[0]);
            payload.country = country.country_code;
            payload.state = state.destination_id;
          }
          payload.id = this.clientData.mainClient.id;
          // console.log("crea cliente nuevo y asocia");
          console.log("payload", payload);
          // console.log("clientsassociateClient");
          this._restService.doPost("app", "clients/associateClient", payload).subscribe(
            (responseNew) => {
              console.log(responseNew);
              if (responseNew.success) {
                this.ngxSpinner.hide();
                if(responseNew.message === 'Client Exist') {
                  this.toastr.info("Este email y/o número de documento ya fueron registrados");
                } else {
                  let data = {
                    type: "_clientNewEmitter",
                    client: responseNew.client,
                  };
                  this.toastr.success("Cliente Creado Satisfactoriamente")
                  this.closeModal(data);
                  const client = {
                    client_id: responseNew.client.original.id,
                    agency_id: this.userService._agencyID,
                  };
                  this._itineraryService.addClientToAgency(client).subscribe((response: any) => {
                    console.log("RESPONSE CLIENT", response);
                  });
                }
              } else {
                this.ngxSpinner.hide();
                this.toastr.info('Ocurrio un problema al crear el cliente. Intentelo nuevamente por favor');
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }

    if (this.data.typeAction == "Edit") {
      payload.id = this.clientData.fromAssociates === true ? this.clientData.assosiateClient.client_id : this.clientData.mainClient.id;
      // payload.country = this._form.value.paxes[0].country_id;
      // payload.state = this._form.value.paxes[0].state_id;
      payload.relation_ship_catalogue_id = this.clientData.relationId;

      if(this.data.fromAssociates === true) {
        payload.relation_ship_catalogue_id = this.clientData.relationId ? this.clientData.relationId : this.clientData.assosiateClient.relation_ship_catalogue_id;
      }

      console.log("payload", JSON.stringify(payload));
      //save to itinerary
      this._restService.doPut("app", "clients/" + payload.id, payload).subscribe(
        (response: any) => {
          console.log("response", response);
          this.ngxSpinner.hide();
          if (response.success) {
            const client: any = this.clientEdit(payload, this.clientData, this._form, birthDate);
            this.toastr.success("Cliente Actualizado Satisfactoriamente")

            if (!this.data.fromAssociates) {
              client.itineraries = this.clientData.mainClient.itineraries;
              let data = {
                type: "_clientNewEmitter",
                client: client,
              };
              this.closeModal(data);
              //this._clientNewEmitter.emit(client);
            } else {
              let data = {
                type: "_clientEmitter",
                client: client,
              };
              this.closeModal(data);
            }
          } else {
            this.ngxSpinner.hide();
            this.toastr.success("Cliente Creado Satisfactoriamente")
            this.closeModal()
            // this.dialog.open(MaterialModalAlertComponent, {
            //   width: "500px",
            //   data:  "Ocurrio un problema AL Modificar el Cliente",
            //   disableClose: true,
            // });
          }
        },
        (error) => {
       //   this.ngxSpinner.hide();
          console.log("Update-Error: " + error);
        }
      );
    }
  }

  clientEdit(payload: any, client: any, form: any,birthDate: any) {
    return {
      id: payload.id,
      index: client.index,
      first_name: form.value.paxes[0].first_name,
      last_name: form.value.paxes[0].last_name,
      email: form.value.paxes[0].email,
      phone: form.value.paxes[0].phone,
      phone_code: form.value.paxes[0].phone_code,
      phone2: form.value.paxes[0].phone_2,
      phone2_code: form.value.paxes[0].phone_2_code,
      country: form.value.paxes[0].country_id ? this._form.value.paxes[0].country_id : null,
      state: form.value.paxes[0].state_id ? this._form.value.paxes[0].state_id : null,
      city: form.value.paxes[0].city ? this._form.value.paxes[0].city : null,
      address: form.value.paxes[0].address,
      birth_date: birthDate,
      title: form.value.paxes[0].title,
      document: form.value.paxes[0].document_type_id,
      document_number: form.value.paxes[0].document_number,
      gender: form.value.paxes[0].gender,
      postal_code: form.value.paxes[0].postal_code,
      relation_ship: this.clientData.relationId,
    }
  }

  closeModal(data?): void {
    this.resetData();
   this.bsModalRef.hide();

     // Emitir el resultado
  this.confirmResult.emit(!!data);
  }

  resetData(): void {
    this.clientData.clientsAssociatedArr = [];
    this.selectedIsCity = [];
    this.selectedIsCountry = [];
    this.selectedIsState = [];
    this.clientData.mainClient = [];
    this.clientData.assosiateClient = [];
    this._form.reset();
  }


  formClientValid(): boolean {
    return false;
  }

}

