<div class="t-content" style="position: relative">
  <form (ngSubmit)="_onSubmit(_form, $event)" [formGroup]="_form" novalidate>
    <div class="form-row pt-3 col-xs-10">
      <div class="form-group d-flex flex-column col-xg-4 col-lg-4 col-md-12 col-sm-12 ml-1" id="form-destination">
        <div>
          <input
                name="destino"
                matInput
                maxlength="20"
                class="form-control input-search-box"
                style="background-color: white;"
                #destination
                formControlName='destination'
                placeholder="Destino"
                
                [matAutocomplete]="autoDestination">
          <mat-autocomplete class="mt-2" #autoDestination="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)='updateMySelection($event.option.value)'>
            <mat-option *ngFor="let name of destinationOptions | async" [value]="name">
              <div class="row" style="padding-top: 13px;">
                <div class="col-2">
                  <img class="imag-redo" [src]="name.pic_mini">
                </div>
                <div class="col-10" style="line-height: 19px;padding-left: 25px;">
                  <span>
                    <span [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}" >{{capitalize(name.city_name)}} </span>
                  </span>
                  <span class="ml-1"> ({{name.country_iata_code}})</span>
                  <br>
                  <!-- <i class="fa fa-map-marker"></i> -->
                  <i class="bi bi-geo-alt-fill" aria-hidden="true" style="font-size: 12px;"></i>
                  <span style="font-size: 13px !important; padding-left: 5px !important;"> Ciudad </span>
                </div>
              </div>
            </mat-option>
            <mat-option *ngFor="let name of destinationOptions_ | async" [value]="name">
              <div class="row" style="padding-top: 13px;">
                <div class="col-2">
                  <img class="imag-redo" src="assets/img/app/aeropuerto-de-miami.jpg">
                </div>
                <div class="col-10" style="line-height: 19px;padding-left: 25px;">
                  <span>
                    <span [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">{{capitalize(name.nameAirport)}} </span>
                    <!-- , Fl, Estados Unidos  -->
                    </span>
                  <span class="ml-1"> ({{name.codeIataAirport}})</span> <br>
                  <img height="12" src="assets/img/app/little-icons/airplane-dark.svg">
                  <span style="font-size: 13px !important; padding-left: 5px !important;"> Aeropuerto </span>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <span class="help-block a-danger" *ngIf="_form.get('destination').touched && _form.get('destination').hasError('required')">Este
            campo
            es requerido.</span>
          <span class="help-block text-danger"
            *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidcountry')">Formato de destino no válido.</span>
          <span class="help-block text-danger"
          *ngIf="_form.get('destination').touched && _form.get('destination').hasError('invalidDestination')">Destino no válido, seleccione un destino válido de la lista.</span>
        </div>
        <div class="mt-4 position-relative">
          <input
                id="nationality"
                name="nationality"
                matInput
                class="form-control input-search-box"
                style="background-color: white;"
                (input)="filterCountries($event.target.value)"
                (focus)="getCountries()"
                formControlName="nationality"
                aria-label="nationality"
                [matAutocomplete]="auto"
                placeholder="Nacionalidad"
                type="text"
          />
          <mat-autocomplete id="matCountry" #matCountry #auto="matAutocomplete" [displayWith]="displayCountry"
          (optionSelected)='updateMySelectionNationality($event.option.value)'>
            <mat-option *ngFor="let country of filteredCountries"
            style="font-family: 'Poppins', sans-serif;padding: 0px 10px !important; height: 26px !important;"
            [value]="country.country_code">
            {{ country.country_name }}</mat-option>
          </mat-autocomplete>
          <!-- <span class="arrow-sort fa fa-sort position-absolute"
                style="top: 37%; right: 1em"
                  [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
          </span>
          <select
                  class="d-flex justify-content-between align-items-center btn-usb-rp ml-0"
                  style="font-family: 'Poppins', sans-serif !important; font-size: 13px !important; font-weight: 600 !important;padding-left: 22px !important;width:100%;border-radius: 0.25rem;border: 1px solid #ced4da; appearance: none; color: #464646;"
                  (change)="selectedNationality($event.target.value)"
                  formControlName="nationality"
                  name="nationality"
          >


            <option value="" disabled selected>Nacionalidad</option>
            <option *ngFor="let country of countriesData" [value]="country.IataCode">{{country.ZoneName}}</option>
          </select> -->
        </div>
      </div>
      <div class="form-row d-flex" style="margin-left: 1px;">
          <div class="form-group pr-3">
            <input type="text"
              readonly
              formControlName="arrivalDateDepartureDate"
              autocomplete="off"
              class="form-control input-search-box"
              ngxDaterangepickerMd
              placeholder="  Check-in - check-out"
              [locale]="{applyLabel: 'ok', format: 'DD/MM/YYYY'}"
              startKey="start"
              endKey="end"
              [(ngModel)]="dateRange"
              [autoApply]="true"
              [closeOnAutoApply]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
              (startDateChanged)="startDateClicked($event)"
              (endDateChanged)="endDateClicked($event)"
              name="daterange"
              style="width: 14rem;"
              [ngStyle]="{':host tr.ng-star-inserted td.active.start-date.available::after': {'background': getClientsSettingAppBooking?.clients_setting_global?.color_main_1},
                          ':host tr.ng-star-inserted td.active.end-date.available::before': {'background': getClientsSettingAppBooking?.clients_setting_global?.color_main_1}}"
              />
          </div>
      </div>
      <div class="form-group col-xg-4 col-lg-3 col-md-12 col-sm-12">
        <button type="button" class="d-flex justify-content-between align-items-center form-control btn-usb-rp" (click)="_openModal()" [attr.aria-expanded]="!isCollapsed" aria-controls="false" style="font-family: 'Poppins', sans-serif !important;; font-size: 14px !important; font-weight: 600 !important;margin-left: -10px; width: 310px; color: #464646;">
          {{roomsAndPassengers}}
          <span class="arrow-sort fa fa-sort" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"></span>
        </button>
        <div *ngFor="let room of _form.value['room']; let i = index">
          <span class="help-block text-danger" *ngIf="((validate || (_close && isCollapsed)) && (room['qtyAdults'] == 0))">Al menos selecciona 1 adulto en la habitación {{i+1}}.</span>
        </div>
        <div (click)="_over()" (collapsed)="clickOutside($event)" (expanded)="($event)" [collapse]="!isCollapsed || _close"
          class="justify-content-end">
          <div class="row">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 w-100" style="position: relative">
              <div class="card">
                <div formArrayName="room" *ngFor="let item of formRoom(_form, 'room'); let i = index"
                  class="d-flex flex-row col-12 align-items-center justify-content-between p-2">
                  <div [formGroupName]="i" class="d-flex flex-row align-items-start justify-content-between p-0" style="width: 100%;">
                    <div>
                      <div>&nbsp;</div>
                    <div class="p-2 mr-2 ml-1" style="padding:25px 0 0 0 !important; width: 50px;">Hab. {{i + 1}}
                    </div>
                    </div>
                    <div class="d-flex flex-column p-0 m-0 mr-2">
                      <label for="adults">Adultos</label>
                      <select formControlName="qtyAdults" class="custom-select form-control-sm " id="adults" name="adults" [value]="1">
                        <option *ngFor="let qty of _maxAdults" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <div class="d-flex flex-column p-0 m-0 mr-2 flex-wrap">
                      <div class="container-label d-flex">
                        <label for="childs">Niños</label>
                        <div class="info-tool d-flex flex-column" style="position: right;">i
                          <span class="tooltip-info" >Los bebes (0 - 2 años sin cumplir) deben indicarse como niños</span>
                        </div>
                      </div>
                      <select formControlName="qtyChilds" class="custom-select form-control-sm" id="childs"
                        (ngModelChange)="_addChild(item)" name="childs" [value]="0">
                        <option *ngFor="let qty of _maxChildren" [value]="qty">{{qty}}</option>
                      </select>
                    </div>
                    <!-- Children age inputs -->
                    <div id="childrenAges" class="d-flex flex-column" *ngIf="item.get('childsage').length > 0" style="min-width:100px;">
                      <label for="age">Edad niños</label>
                      <div class="d-flex flex-row p-0 m-0">
                        <div formArrayName="childsage" *ngFor="let age of formRoom(item, 'childsage'); let j = index;" class="select-ages">
                          <div [formGroupName]="j" class="p-0">
                            <input formControlName="age" min=0 max=17 limit-to="14" class="form-control mr-1 p-3 input-age"
                              value-formatter="0" placeholder="0" name="age" type="number">
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let age of formRoom(item, 'childsage'); let j = index;">
                        <span class="help-block text-danger d-flex w-100 text-nowrap"
                          *ngIf="age.controls['age'].hasError('invalidsoloNumeros') || age.controls['age'].hasError('min') || age.controls['age'].hasError('max') || age.controls['age'].hasError('required')"><br>La edad
                          de los niños {{j+1}} es incorrecta</span>
                      </div>
                    </div>
                    <div class="p-0 d-flex ml-auto" style="margin-top: 29px;">
                      <button (click)="_removeRoom(i)" class="btn btn-outline-danger col-12 input-age btn-sm"
                        [disabled]="_form.get('qtyProduct').value===1">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <hr style="margin-bottom: 0;">
                <div class="d-flex justify-content-around flex-row py-2">
                  <div class="p-2 justify-content-start">
                    <button type="button" [disabled]="_form.get('qtyProduct').value===7" (click)="_addRoom()"
                      class="badge rounded-pill btn-choice-add" [ngStyle]="{'background-color': (isHoverRoom ? bgClientColor : 'white')} "
                      (mouseenter)="isHoverRoom = true; updateButtonColorRoom();"
                      (mouseleave)="isHoverRoom = false; updateButtonColorRoom();">
                      Agregar Habitación
                    </button>

                    <span class="errors" *ngIf="_form.get('qtyProduct').value===7"><br>El número máximo de habitaciones es 7.</span>
                  </div>
                  <div class="p-2 justify-content-end">
                    <button (click)="_confirm()" [attr.aria-expanded]="!isCollapsed" aria-controls="false"
                      class="badge rounded-pill btn-choice-confirm" type="button"  [ngStyle]="{'background-color': (isHoverConfirm ? bgClientColor : 'white')} "
                      (mouseenter)="isHoverConfirm = true; updateButtonColorConfirm();"
                      (mouseleave)="isHoverConfirm = false; updateButtonColorConfirm();">
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-xs-12 col-lg-2 col-md-4 justify-content-start align-middle">
        <button *ngIf="userService.itineraryActive.length == 0"
                class="btn btn-search-hotel rounded-pill d-flex justify-content-center align-items-center"
                (click)="fileActive()"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
                type="button">
                <!-- || userService.itineraryActive.length == 0 -->
                <!-- <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i> -->
          <span>BUSCAR</span>
        </button>
        <button *ngIf="userService.itineraryActive.length > 0"
                class="btn btn-search-hotel rounded-pill d-flex justify-content-center align-items-center"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
                [disabled]="!_form.valid"
                type="submit">
        <!-- || userService.itineraryActive.length == 0 -->
          <!-- <i class="{{_findData?'fa fa-spinner fa-spin fa-sm':''}}"></i> -->
          <span>BUSCAR</span>
        </button>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-xg-4 col-lg-4 col-xs-12 col-md-4 justify-content-start align-middle pb-5">
          <div class="checkbox" style="margin-left: 7px;">
              <!-- <label> -->
     <!--<input formControlName="accessRoom" type="checkbox" value=""> -->
      <!-- <span class="cr">
        <i class="cr-icon fa fa-check"></i>
      </span>
      <label class="form-check-label" style="font-family: 'Poppins' !important; font-size: 14px !important; font-weight: 700 !important; color: #181819; margin-left: -5px; padding-top: 3px;">Multi hotel </label>
              </label> -->
          </div>
      </div>

      <div class="form-group col-lg-5 col-xs-12 col-md-4 justify-content-start align-middle">
      </div>
      <div class="form-group col-lg-4 col-xs-12 col-md-4 justify-content-start align-middle">

      </div>
  </div>
  </form>
</div>

<!-- Template del componente -->
<div class="modal" [ngClass]="{ 'show': showModal }">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Encabezado del modal -->
      <div class="modal-header">
        <h5 class="modal-title mt-2 text-black">{{titleModal}}</h5>
        <!-- <button type="button" class="close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <!-- Cuerpo del modal -->
      <div class="modal-body ">
        <p class="mb-1 text-black">{{messageModal}}</p>
      </div>
      <!-- Pie del modal -->
      <div class="d-flex flex-end justify-content-end mr-3 mb-4">
        <!-- <button type="button" class="btn btn-secondary" (click)="closeModal()">Cerrar</button> -->
        <button type="button"
                class="btn-confirm-modal rounded-pill"
                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
                (click)="confirmAction()">ACEPTAR
        </button>
      </div>
    </div>
  </div>
</div>
