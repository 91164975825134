<div class="row pl-2" *ngIf="itinerary">
  <div class="col-12 content-detail-dark px-3 py-4" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
    <div class="row mx-auto">
      <div *ngIf="permissionsMap['booking.itinerarios.editarfee']" class="col text-center">

        <button   id="buttonSync" class="btn-i btn-actualizar" type="submit"  title="{{timeLeft}}" (click)="updateItinerary()"  [disabled]="sec"  name="btn-update" tooltip="Update" placement="bottom"
        style="
        background-color: transparent;
        border: none;
        width: 57px;
        cursor: pointer"
        >
          <img class="btn-i img-fluid rounded-circle " src="/assets/img/app/little-icons/recycle-2.svg" alt=""  style="cursor: pointer;">
        </button>
        <span style="color: white; font-size:12px;" *ngIf="timeLeft > 0"> {{ timeLeft }} seg</span>
      </div>
      <div class="col text-center">
        <a class="btn-i btn-itinerario" type="submit"  name="btn-pdf"  style="cursor: pointer">
          <img class="btn-i img-fluid rounded-circle" src="/assets/img/app/little-icons/pdf-modif.svg" alt="" >
        </a>
      </div>
      <div class="col text-center">
        <a class="btn-i btn-mensaje" type="submit" (click)="openModal(template)" name="btn-mail"  placement="bottom" style="cursor: pointer">
          <img class="btn-i img-fluid rounded-circle" src="/assets/img/app/little-icons/mail-green.svg" alt="">
        </a>
      </div>
      <div class="col text-center">
        <a class="btn-i btn-liquidacion" type="submit" name="btn-liq" tooltip="Liquidation" placement="bottom" (click)="downloadLiquidation(itinerary.id);">
          <img class="btn-i img-fluid rounded-circle" src="/assets/img/app/little-icons/liquidation.svg" alt="">
        </a>
      </div>
    </div>
    <hr class="divider">
    <div class="row px-4">
      <div class="col-12 text-justify text-white font-poppins f-11 font-gray">
        <p class="text-uppercase">
          ÚLTIMA MODIFICACIÓN: {{getModificationDate()}}
          <br>
          ÚLTIMA ACTUALIZACIÓN: {{getUpdateDate()}}
        </p>
      </div>
      <div class="col-12 text-center text-white">
        <div class="row mt-3">
          <div class="col-6 d-flex align-items-center">
            <p class="font-poppins f-18 font-gray font-regular text-left text-price m-0 d-flex align-items-center">Fee %
              <span class="d-flex">
                <label class="custom-radio-checkbox m-0">
                  <input class="custom-radio-checkbox__input" type="checkbox" [(ngModel)]="userService.activeGlobalFee"
                    [disabled]="((this.itinerary.status_id != 1 && this.itinerary.status_id != 2) || modifications)"
                    (click)="globalFeeClick()">
                  <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                </label>
              </span>
            </p>
          </div>
          <div class="col-6 justify-content-end d-flex align-items-center">
            <input *ngIf="userService.activeGlobalFee" type="text" class="form-control font-poppins f-18"
              [(ngModel)]="userService.globalFee" style="width: 100%; text-align: start;" pattern="/^[1-9]\d{6,10}$/"
              (input)="changeGlobalFee()" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
            <span class="font-poppins f-18 font-gray font-regular text-left text-price d-flex"
              *ngIf="!userService.activeGlobalFee">{{ userService.priceRightFeePercentage | number : '1.2-2'}}%</span>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="col-12 content-detail-dark px-3 py-4 mt-2" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
    <div class="row px-4">
      <div class="col-12 text-center text-white">
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <p class="font-poppins f-18 font-gray font-regular text-left text-price m-0">Tarifa Neta</p>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <!-- <a class="loader text-center justify-content-center" *ngIf="userService.totalPriceNotFee == -1">
              <i class="fa fa-spinner fa-spin"></i>
            </a> -->
            <ng-container *ngIf="netPriceBookingFreeTax  > 0">
              <p *ngIf="userService.totalPriceNotFee != 1 && userService.totalPriceYesFee != -1 && getOrigin != 'RENT-A-CAR'"
                 class="font-poppins f-20 font-gray font-regular text-left m-0"
                 [ngStyle]="{
                  'font-size': netPriceBooking | dynamicFontSize: 20 : fontSizeAdjustments
                }">
                {{currencyItinerary}} {{(netPriceBooking > 0 ? netPriceBooking : netPriceBookingFreeTax) | number : '1.2-2'}}</p>
            </ng-container>

            <ng-container *ngIf="netPriceBookingFreeTax  > 0">
              <p *ngIf="getOrigin == 'RENT-A-CAR'"
              class="font-poppins f-20 font-gray font-regular text-left m-0"
              [ngStyle]="{
                'font-size': netPriceBookingFreeTax | dynamicFontSize: 20 : fontSizeAdjustments
              }">
                {{currencyItinerary}} {{ netPriceBookingFreeTax | number : '1.2-2'}}</p>
            </ng-container>

            <ng-container *ngIf="netPriceBookingFreeTax  === 0">
              <p *ngIf="userService.totalPriceNotFee != 1 && userService.totalPriceYesFee != -1"
              class="font-poppins f-20 font-gray font-regular text-left m-0"
              [ngStyle]="{
                'font-size': userService.totalPriceNotFee | dynamicFontSize: 20 : fontSizeAdjustments
              }">
                {{currencyItinerary}} {{userService.totalPriceNotFee  | number : '1.2-2'}}</p>
            </ng-container>
          </div>
        </div>
        <div *ngIf="taxSum > 0" class="row mt-3">
            <div class="col-4 d-flex align-items-center">
              <p class="font-poppins f-18 font-gray font-regular text-left text-price m-0">Impuesto</p>

            </div>
            <div class="col-7 d-flex justify-content-end align-items-center">
            <!-- <a class="loader text-center justify-content-center" *ngIf="userService.totalPriceNotFee == -1">
              <i class="fa fa-spinner fa-spin"></i>
            </a> -->
              <p *ngIf="userService.totalPriceNotFee != 1 && userService.totalPriceYesFee != -1"
              class="font-poppins f-26 font-gray font-regular text-left m-0"
              [ngStyle]="{
                'font-size': taxSum | dynamicFontSize: 26 : fontSizeAdjustments
              }">
                {{currencyItinerary}} {{ taxSum  | number : '1.2-2'}}</p>
            </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 d-flex align-items-center">
            <p class="font-poppins f-18 font-gray font-regular text-left text-price m-0">
            Fee
            </p>
          </div>
          <div class="col-8 d-flex justify-content-end align-items-center">
            <p class="font-poppins f-20 font-regular text-left m-0"
            [ngStyle]="{
              'font-size': userService.priceRightFee | dynamicFontSize: 20 : fontSizeAdjustments
            }"
            > {{currencyItinerary}} {{userService.priceRightFee | number : '1.2-2'}}</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 d-flex align-items-center">
            <p class="font-poppins f-18 font-regular font-gray text-left text-price m-0">Total</p>
          </div>
          <div class="col-8 d-flex justify-content-end align-items-center">
            <!-- <a class="loader text-center justify-content-center" *ngIf="userService.totalPriceYesFee  == -1">
              <i class="fa fa-spinner fa-spin"></i>
            </a> -->
            <p *ngIf="userService.totalPriceYesFee != 1 && userService.totalPriceYesFee != -1"
            class="font-poppins f-20 font-regular font-gray text-left m-0"
            [ngStyle]="{
              'font-size': userService.totalPriceYesFee | dynamicFontSize: 20 : fontSizeAdjustments
            }">
              {{currencyItinerary}} {{userService.totalPriceYesFee | number : '1.2-2'}}</p>
          </div>
        </div>
        <!-- <div *ngIf="!partialReserve">
          <hr class="divider">
          <div class="row">
            <div class="col-12 text-right">
              <div class="row icon-premium-point">
                <div class="col px-4">
                  <ul class="nav justify-content-between w-100">
                    <li class="nav-item d-flex justify-content-start align-items-center">
                      <img src="/assets/img/app/little-icons/premium-badge-coral.svg" class="img-fluid icon-premium-point" alt="">
                      <p class="font-point font-left f-16 m-0">Blickoins</p>
                    </li>

                    <li class="nav-item d-flex justify-content-end align-items-center">
                      <p  class="font-point font-left f-16 m-0">{{ userService.totalBlickoins ? userService.totalBlickoins : 0 }} Bks.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <!-- *ngIf="partialReserve"-->
  <ng-container *ngIf="showPartialContainer">
    <div class="col-12 content-detail-dark px-3 py-4 mt-2" *ngIf="userService.isActivePartial || validateProductsToPay()"  style="background-color: #f3f3f3;">
      <div class="px-4">
      <p class="text-left font-regular text-price" style="font-family: 'Poppins';font-size: 19px; font-weight: 500; color: black">Reserva Parcial</p>
      </div>
      <div class="row px-4">
        <div class="col-12 text-center text-white">
          <div class="row">
            <div class="col d-flex align-items-center">
              <p class="font-poppins f-18 font-regular text-price text-nowrap text-left m-0" style="color: black">Tarifa <br> Reservar Neta</p>
            </div>
            <div class="col d-flex align-items-center">
              <!-- <a class="loader text-center justify-content-center" *ngIf="userService.totalPriceNotFeeByReserved == -1">
                <i class="fa fa-spinner fa-spin"></i>
              </a> -->

              <ng-container *ngIf="validateProductsToPay()">
                <p *ngIf="userService.totalPriceNotFeeByReserved != -1"
                class="font-poppins f-26 font-regular text-left m-0" style="color: black"
                [ngStyle]="{
                  'font-size':  userService.totalPriceNotFeeByReserved | dynamicFontSize: 26 : fontSizeAdjustments
                }">
                   {{currencyItinerary}} {{ userService.totalPriceNotFeeByReserved | number : '1.2-2'}}
                </p>
              </ng-container>

              <ng-container *ngIf="!validateProductsToPay()">
                <p class="font-poppins f-26 font-regular font-gray text-left m-0" style="color: black"
                [ngStyle]="{
                  'font-size':  priceToPay | dynamicFontSize: 26 : fontSizeAdjustments
                }">
                   {{currencyItinerary}} {{priceToPay | number : '1.2-2'}}
                </p>
              </ng-container>

            </div>
          </div>

          <div class="row mt-3">
            <div class="col d-flex align-items-center">
              <p class="font-poppins f-18 font-regular text-left text-price m-0" style="color: black">Tarifa <br> Reservar</p>
            </div>
            <div class="col d-flex align-items-center">
              <!-- <a class="loader text-center justify-content-center" *ngIf="userService.totalPriceYesFeeByReserved == -1">
                <i class="fa fa-spinner fa-spin"></i>
              </a> -->

              <p *ngIf="userService.totalPriceYesFeeByReserved != -1"
              class="font-poppins f-26 font-regular  text-left m-0"
              style="color: black"
              [ngStyle]="{
                'font-size':  userService.totalPriceYesFeeByReserved | dynamicFontSize: 26 : fontSizeAdjustments
              }">
                {{currencyItinerary}} {{ userService.totalPriceYesFeeByReserved | number : '1.2-2' }}
              </p>
            </div>
          </div>

          <hr class="divider">
          <div class="row">
            <div class="col-12 text-right">
              <div class="row icon-premium-point">
                <div class="col px-4">
                  <ul class="nav justify-content-between w-100">
                    <li class="nav-item d-flex justify-content-start align-items-center">
                      <img src="../../../assets/img/app/little-icons/badge-bks.png" alt="logo bks" style="margin-right: 5px" />
                      <p class="font-point font-left f-16 m-0">Blickoins</p>
                    </li>
                    <li class="nav-item d-flex justify-content-end align-items-center">
                      <p class="font-point font-left f-16 m-0"
                      [ngStyle]="{
                        'font-size':  userService.totalPriceYesFeeByReserved | dynamicFontSize: 16 : fontSizeAdjustments
                      }">{{ itineraryService.parcialBlickoins ? itineraryService.parcialBlickoins : 0 }} Bks.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #template>
  <form method="POST">
    <div class="modal-body text-center">
      <p>"This will save your current itinerary and start a new draft itinerary. Are you sure?"</p>
      <button type="button" data-placement="bottom" class="btn btn-sm btn-usblick" title="Yes" (click)="sendMail()">Yes</button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="closeModal()">No</button>
    </div>
  </form>
</ng-template>
