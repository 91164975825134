<!-- Contenedor principal con un splash de carga -->
<div usbLoadingSplash
[appLoading]="appLoading"
[fullscreen]="true"
[component]="'app'">
  <!-- Directiva personalizada para mostrar un splash de carga -->
  <!-- [appLoading]: Controla si la aplicación está en estado de carga -->
  <!-- [fullscreen]: Define si el splash se muestra en pantalla completa -->

  <!-- Contenido principal que se muestra cuando la configuración de carga ha finalizado -->
  <div *ngIf="!loadSetting">
      <!-- Componente de cabecera -->
       <ng-container *ngIf="clientsSettings">
         <app-header
             class="app-header"
             [clientsSettings]="clientsSettings"
             [permissionsMap]="permissionsMap"
             [namePaymentCondition]="namePaymentCondition"
             [iconPaymentCondition]="iconPaymentCondition"
             [isAgencyPaymentCondition]="isAgencyPaymentCondition"
             [creditDeposits]="creditDeposits"
             [creditBlickoins]="creditBlickoins"
             [creditLimits]="creditLimits"

         ></app-header>
       </ng-container>
      <!-- Explicación:
           - class="app-header": Clase CSS para la cabecera.
           - [clientsSettings]="clientsSettings": Configuraciones del cliente.
           - [permissionsMap]="permissionsMap": Mapa de permisos.
           - [namePaymentCondition]="namePaymentCondition": Nombre de la condición de pago.
           - [iconPaymentCondition]="iconPaymentCondition": Icono para la condición de pago.
           - [isAgencyPaymentCondition]="isAgencyPaymentCondition": Indica si la condición de pago es para agencias.
           - [creditDeposits]="creditDeposits": Créditos en depósitos disponibles.
           - [creditBlickoins]="creditBlickoins": Créditos disponibles en Blickoins.
      -->
      <ng-container *ngIf="clientsSettings">
        <!-- Cuerpo principal de la aplicación -->
        <div class="app-body" style="margin-top: 140px;">
            <!-- Punto de inserción de componentes según la ruta -->
            <router-outlet></router-outlet>
        </div>
      </ng-container>

      <ng-container *ngIf="clientsSettings">
        <!-- Componente de pie de página -->
        <usb-footer [clientsSettings]="clientsSettings"></usb-footer>
        <!-- Explicación:
             - [clientsSettings]="clientsSettings": Configuraciones del cliente para el pie de página.
        -->
      </ng-container>
  </div>
</div>
