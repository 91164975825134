import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from "@angular/core";
import {  FormGroup } from "@angular/forms";
import { ResultsSearchService } from "../../../../home/results-search.service";
import { BsModalRef } from "ngx-bootstrap";
import { ModalsComponent } from "../../../../shared/modals/modals.component";
import { MatDialog } from "@angular/material/dialog";
import { ItineraryService } from "../../../../shared/itinerary.service";
import { ModalViewVideComponent } from "../../../../shared/modal-view-video/modal-view-video.component";
import { RestService } from "../../../../shared/rest.service";
import { setting } from "../../../../../../setting";

@Component({
  selector: "usb-hotel-result",
  templateUrl: "./hotel-result.component.html",
  styleUrls: ["./hotel-result.component.scss"],
})
export class HotelResultComponent implements OnInit {
  @Input() payload: any;
  @Input() result: any;
  @Input() arrayResults: any;
  @Input() index: number;
  @Input() form: FormGroup;
  @Input() origin: string;
  @Output() coordinatesClicked = new EventEmitter<any>();
  @Output() modalClosedemitter = new EventEmitter<any>();
  modal: boolean = false;
  modalRef: BsModalRef;
  ModalRooms: any;
  @Input() mapView: Boolean = false;
  addIcon = "add_circle";

  netPriceProf = 0;
  percentage = 0;
  blickoins = 0;
  creditBlickoins = 0;
  view_hotel = 0;
  isview_hotel = false;
  ameninitiesResul = [];
  viewUpdate:string;

  optionNightsNetTotalProf:number = 0;
  countValueDot:number = 0;

  public getClientsSettingAppBooking: any;
  showMoreButton: boolean = false;
  imagesCoverResults:any[] = [];

  constructor(
    public serviceItinerary: ItineraryService,
    private searchService: ResultsSearchService,
    public _restService: RestService,
    private dialog: MatDialog
  ) {
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));

    }

  ngOnInit() {
    const coverImage = this.result.additionalInfo.images.find((c: any) => c.is_cover === 1);
    this.result.portrait = coverImage ? coverImage.thumbnail : 'assets/img/app/hotel_default.png'; // Usa una URL por defecto si no hay portada
    // this.view_hotel = this.result.view_hotel;
    // console.log(this.result);
    if (this.origin === null || this.origin === undefined) {
      this.origin = this.searchService._getOrigin();
    }
    this.getAmenitiesHotelResul();

    this.multiplyValue();
    this.getValueAfterDot();

  }


 /**
 * Método que filtra y asigna a `ameninitiesResul` los servicios de hotel disponibles.
 *
 * Este método revisa si `this.result` y `this.result.additionalInfo` existen, y luego filtra las comodidades
 * que tienen `onlist == 1` y `ame_type === 'hotel'`. Después, mapea estos servicios a la variable `ameninitiesResul`.
 *
 * @returns Ninguno. Asigna el resultado a la propiedad `ameninitiesResul`.
 */
getAmenitiesHotelResul() {
  if (this.result && this.result.additionalInfo) {
    this.ameninitiesResul = this.result.additionalInfo.amenities.filter(function (amenite: any) {
      return amenite.onlist == 1 && amenite.ame_type === 'hotel';
    })
    .map(function (amenite: any) {
      return amenite;
    });
  }
}

/**
 * Método que devuelve un arreglo con el número de estrellas del hotel.
 *
 * Este método obtiene la propiedad `starRating` de `this.result.additionalInfo` y crea un array con ese número
 * de elementos, representando las estrellas del hotel.
 *
 * @returns `array` que contiene el número de estrellas, o `undefined` si no hay información disponible.
 */
stars() {
  if (this.result && this.result.additionalInfo) {
    const array = [];
    const item = Number.parseInt(this.result.additionalInfo.starRating);
    for (let index = 0; index < item; index++) {
      array.push(index);
    }
    return array;
  }
}

/**
 * Método que abre un modal para mostrar un video.
 *
 * Este método obtiene el `idVideo` de un enlace de YouTube utilizando el método `getIdVideoYt` y luego abre un
 * modal de tipo `ModalViewVideComponent` para mostrar el video. El modal tiene un tamaño de 700px de ancho.
 *
 * @data -> Objeto que contiene la URL del video a mostrar en el modal.
 * @returns Ninguno. Abre un modal para reproducir el video de YouTube.
 */
openVideoModal(data: any) {
  const idVideo = this.getIdVideoYt(data);

  var dialogRef = this.dialog.open(ModalViewVideComponent, {
    width: "700px",
    data: idVideo,
  });
  dialogRef.afterClosed().subscribe((result) => {
    // Puede manejar el resultado aquí si es necesario
  });
}

/**
 * Método que extrae el `id` de un video de YouTube desde su URL.
 *
 * Este método utiliza una expresión regular para extraer el `id` del video de una URL proporcionada de YouTube.
 *
 * @url -> URL del video de YouTube.
 * @returns El `id` del video si se encuentra, o `null` si no se encuentra un `id` válido.
 */
getIdVideoYt(url: any) {
  const getIdVideo = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/;
  const match = url.match(getIdVideo);
  return match ? match[1] : null;
}

/**
 * Método que abre un modal para agregar o editar un producto, en este caso, un hotel.
 *
 * Este método abre un modal para mostrar detalles del hotel o producto seleccionado. Los datos del producto
 * y las configuraciones de la vista son pasados como `data` al modal. El tamaño del modal es 920px de ancho y
 * 680px de alto.
 *
 * @template -> Plantilla del modal a mostrar.
 * @tab -> Nombre de la pestaña activa que se pasa como dato al modal.
 * @returns Ninguno. Abre un modal con los datos del producto y una serie de configuraciones.
 */
openModal(template: TemplateRef<any>, tab: any) {
  this.modal = true;
  this.updteView();
  var dialogRef = this.dialog.open(ModalsComponent, {
    height: "680px",
    width: "920px",
    panelClass: 'custom-container',
    data: {
      modalRoomRef: this.modalRef,
      product: this.result,
      departureDate: this.payload.departureDate,
      productQty: this.payload.qtyProduct,
      form: this.form,
      origin: "HOTELS",
      arrayResults: this.arrayResults,
      tabActive: tab,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    this.modalClosedemitter.emit();
  });
}

/**
 * Método que actualiza las vistas del hotel en la base de datos.
 *
 * Este método hace una solicitud PUT para actualizar la vista de un producto hotelero en la base de datos.
 * Si la actualización es exitosa, actualiza el valor de `view_hotel` y marca `isview_hotel` como `true`.
 *
 * @returns Ninguno. Realiza la actualización de la vista del hotel en el servidor.
 */
updteView() {
  let data = "";
  if (!this.isview_hotel) {
    this._restService.doPut("app", "products/updateView/" + this.result.id + "/" + this.result.searchId, data).subscribe(
      (response: any) => {
        // console.log('response view', response);
        if (response.success) {
          this.view_hotel = response.res.qtyview;
          this.isview_hotel = true;
        }
      },
      (error) => {
        console.log("Save-Error: " + error);
      }
    );
  }
}

/**
 * Método que calcula el valor total multiplicando el número de habitaciones por el precio neto por noche.
 *
 * Este método utiliza la cantidad de habitaciones en `roomConfiguration` y el precio neto por noche para
 * calcular el valor total. El resultado se asigna a `optionNightsNetTotalProf`.
 *
 * @returns Ninguno. Asigna el valor calculado a `optionNightsNetTotalProf`.
 */
multiplyValue(): void {
  if (this.result && this.result.additionalInfo) {
    const valueForMultiply = this.payload.others.hotels.roomConfiguration.length;
    const netPriceProf = this.result.additionalInfo.rooms[0]?.room?.optionNightsNetTotalProf;
    this.optionNightsNetTotalProf = valueForMultiply * netPriceProf;
  }
}

/**
 * Método que calcula el número de dígitos después del punto decimal para un valor dado.
 *
 * Este método calcula la cantidad de dígitos después del punto decimal en el valor total de la opción de noche.
 * Utiliza el número de habitaciones en `roomConfiguration` y el precio por noche.
 *
 * @returns Ninguno. Asigna el número de dígitos a `countValueDot`.
 */
getValueAfterDot() {
  if (this.result && this.result.additionalInfo) {
    let qntyRooms = this.payload.others.hotels.roomConfiguration.length;
    let optionNights = this.result?.additionalInfo?.rooms[0]?.room?.optionNightsNetTotalProf;
    const valueDot = Math.floor(Math.abs(optionNights * qntyRooms));

    this.countValueDot = valueDot.toString().length;
  }
}

/**
 * Método que emite un evento con el hotel seleccionado.
 *
 * Este método emite un evento `coordinatesClicked` con el hotel seleccionado como parámetro.
 *
 * @hotelSelected -> Objeto que representa el hotel seleccionado.
 * @returns Ninguno. Emite el evento con el hotel seleccionado.
 */
getHotelChoiceID(hotelSelected: any) {
  this.coordinatesClicked.emit(hotelSelected);
}


}
