
<!------------------ CARROUSEL --------------------->

<div class=" navbar navbar-expand-xg navbar-expand-lg navbar-expand-md align-items-center top-content p-5">
    <div   class="col-xg-12 col-lg-12 col-md-12 col-xs-12"  style="background-color: #fff">
      <owl-carousel-o [options]="customOptions" class="shortcuts">
        <ng-container *ngFor="let image of images" >
          <ng-template carouselSlide [width]="120">
              <div class="position-relative image-container">
              <img
                        usbLazyLoadImage
                        loading="lazy"
                        [lazyImage]="image">
                          <!-- <div class="spinner-grow position-absolute"
                                [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}"
                                role="status"
                                style=" top: 50%; left: 50%; transform: translate(-50%, -50%);">
                          </div> -->
              <!-- <img loading="lazy"  [src]="image" alt=""> -->
              </div>
            </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
</div>


<!------------------ SHORCUTS --------------------->

<div class=" navbar navbar-expand-xg navbar-expand-lg navbar-expand-md align-items-center top-content p-5" style="max-width: 1500px; margin-top: 250px">
  <div   class="col-xg-12 col-lg-12 col-md-12 col-xs-12"  >
    <div class="flex d-flex justify-content-evenly" >
      <div class="col-xg-2 col-lg-3 col-md-3 col-xs-1 col-icon">
        <a target="_blank" href="https://moebius.tur.ar/eventos">
          <span class="circle-icon img-fluid mx-auto d-block" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
            <img loading="lazy" #icon src="{{ routeUploadFile+sectionShortcuts?.info[0].image }}" alt="">
          </span>
          <span>
            <h5 class="title-site text-uppercase" style="max-width: 270px;">{{ sectionShortcuts?.info[0].title }}</h5></span>
        </a>
      </div>
      <div class="col-xg-2 col-lg-3 col-md-3 col-xs-1 col-icon" >
        <a href="#">
          <span class="circle-icon img-fluid mx-auto d-block" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
            <img loading="lazy" #icon src="{{ routeUploadFile+sectionShortcuts?.info[1].image }}" alt="">
          </span>
          <span>
            <h5 class="title-site text-uppercase" style="max-width: 222px">{{ sectionShortcuts?.info[1].title }}</h5>
          </span>
        </a>
      </div>
      <div class="col-xg-2 col-lg-3 col-md-3 col-xs-1 col-icon">
        <a href="#">
          <span class="circle-icon img-fluid mx-auto d-block" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
            <img loading="lazy" #icon src="{{ routeUploadFile+sectionShortcuts?.info[2].image }}" alt="">
          </span>
          <span>
            <h5 class="title-site text-uppercase" style="max-width: 215px">{{sectionShortcuts?.info[2].title }}</h5>
          </span>
        </a>
      </div>
      <div class="col-xg-2 col-lg-3 col-md-3 col-xs-1 col-icon">
        <a href="#">
          <span class=" circle-icon img-fluid mx-auto d-block" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
            <img loading="lazy" #icon src="{{ routeUploadFile+sectionShortcuts?.info[3].image }}" alt="">
          </span>
          <span>
            <h5 class="title-site text-uppercase" style="max-width: 215px;">{{sectionShortcuts?.info[3].title }}</h5>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
