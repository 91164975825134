import { Component, OnInit } from '@angular/core';
import { FidelityService } from '../../fidelity/fidelity.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AgentsService } from '../../agents/agents.service';

@Component({
  selector: 'usb-nr-prepayment-modal',
  templateUrl: './nr-prepayment-modal.component.html',
  styleUrls: ['./nr-prepayment-modal.component.scss']
})
export class NrPrepaymentModalComponent implements OnInit {

  points: any = 0;
  deposit:any = 0;
  totalPriceNR: number = 0;

  terms: any = false;
  agency:any;

  products:any=[];
  details:any=[];

  cardActive;
  pointsActive:boolean = true;

  constructor(
    public fidelityService: FidelityService,
    public userService: UserService,
    public dialogRef: MatDialogRef<NrPrepaymentModalComponent>,
    private agentsService:AgentsService
  ) {


  }

  ngOnInit() {
    this.points = this.fidelityService.creditPointActives ? this.fidelityService.creditPointActives : 0;
    this.deposit = this.fidelityService.creditDepositActives ? this.fidelityService.creditDepositActives : 0;
    this.agency = JSON.parse(localStorage.getItem('selectedAgency'));

    //this.getPoints();
    this.reviewPricesNR();
  }

  reviewPricesNR() {
    this.totalPriceNR = 0;
    this.userService.itineraryActive[0].items.forEach(element => {
      if (element.room.rateClass === 'NR') {
        console.log("hay producto NR");
        this.totalPriceNR = this.totalPriceNR + element.netPriceBooking;
        this.products.push(element);
        this.details.push({
          id:element.id,
          amount:element.netPriceBooking
        });
      }
    });

    console.log("this.totalPriceNR", this.totalPriceNR);
  }

  close(close?:boolean) {

    let data:any;
    //Case A:

    switch (this.agency.pay_mode) {
      case 1:

        if(this.points !=0){
          data = this.payOnlyPoints();

          console.log("data", data, JSON.stringify(data));
          localStorage.setItem('nrPayment', JSON.stringify(data));
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close(false);
        }

      break;

      case 2:

        if(this.points !=0 && this.points > this.totalPriceNR){
          data = this.payOnlyPoints();
        }

        if(this.points !=0 && this.points < this.totalPriceNR){
          data = {
            paymentMethods: [
              {
                id: 1,
                amount: -(this.points),
                agentId:this.agency.agent_id,
                agencyId: this.agency.agency_id,
                referenceId:null,
                details:this.details
              },
              {
                id: 2,
                amount: -(this.totalPriceNR - this.points),
                agentId:this.agency.agent_id,
                agencyId: this.agency.agency_id,
                referenceId:null,
                details:this.details
              }
            ],
          }
        }

        if(this.points == 0){
          data = {
            paymentMethods: [
              {
                id: 2,
                amount: -(this.totalPriceNR),
                agentId:this.agency.agent_id,
                agencyId: this.agency.agency_id,
                referenceId:null,
                details:this.details
              }
            ],
          }
        }

        console.log("data", data, JSON.stringify(data));
        localStorage.setItem('nrPayment', JSON.stringify(data));

        if(close){
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close();
        }
      break;

      case 3:

        if(this.points !=0){

          if(this.points > this.totalPriceNR){
            //Paga con puntos
            data = this.payOnlyPoints();
          }

          if(this.points < this.totalPriceNR && this.deposit > this.totalPriceNR){
            //Paga con puntos y deposito
            data = this.payOnlyPointsDeposit();
          }

          if( (this.points < this.totalPriceNR && this.deposit < this.totalPriceNR) && ( (this.points +  this.deposit) > this.totalPriceNR ) ){
            //Paga con puntos y deposito
            data = this.payOnlyPointsDeposit();
          }

        }

        if(this.points == 0){
          if(this.deposit > this.totalPriceNR){
            //Paga con deposito
            data = this.payOnlydeposit();
          }
        }

        console.log("data", data, JSON.stringify(data));
        localStorage.setItem('nrPayment', JSON.stringify(data));

        if(close){
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close();
        }
      break;


      case 4:

        if(this.points !=0){
          if(this.points > this.totalPriceNR){
            data = this.payOnlyPoints();
          }

          if(this.points < this.totalPriceNR && this.deposit > this.totalPriceNR){
            data = this.payOnlyPointsDeposit();
          }

          if( (this.points < this.totalPriceNR && this.deposit < this.totalPriceNR) && ( (this.points +  this.deposit) > this.totalPriceNR ) ){
            //Paga con puntos y deposito
            data = this.payOnlyPointsDeposit();
          }

          if( (this.points < this.totalPriceNR && this.deposit < this.totalPriceNR) && ( (this.points +  this.deposit) < this.totalPriceNR) ){
            //Paga con puntos, deposito y tarjeta decredito
            data = {
              paymentMethods: [
                {
                  id: 1,
                  amount: -(this.points),
                  agentId:this.agency.agent_id,
                  agencyId: this.agency.agency_id,
                  referenceId:null,
                  details:this.details
                },
                {
                  id: 3,
                  amount: -(this.deposit),
                  agentId:this.agency.agent_id,
                  agencyId: this.agency.agency_id,
                  referenceId:null,
                  details:this.details
                },
                {
                  id: 2,
                  amount: -(this.totalPriceNR - (this.deposit +  this.points)),
                  agentId:this.agency.agent_id,
                  agencyId: this.agency.agency_id,
                  referenceId:null,
                  details:this.details
                },
              ],
            }
          }
        }

        if(this.points == 0){
          if(this.deposit > this.totalPriceNR){
            data = this.payOnlydeposit();
          }

          if(this.deposit < this.totalPriceNR){
            data = {
              paymentMethods: [
                {
                  id: 3,
                  amount: -(this.deposit),
                  agentId:this.agency.agent_id,
                  agencyId: this.agency.agency_id,
                  referenceId:null,
                  details:this.details
                },
                {
                  id: 2,
                  amount: -(this.totalPriceNR - this.deposit),
                  agentId:this.agency.agent_id,
                  agencyId: this.agency.agency_id,
                  referenceId:null,
                  details:this.details
                }
              ],
            }
          }

        }

        console.log("data", data, JSON.stringify(data));
        localStorage.setItem('nrPayment', JSON.stringify(data));

        if(close){
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close();
        }

      break;

      case 5:

        if(close){
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close();
        }
      break;

      case 6:

        if(close){
          this.dialogRef.close(true);
        }else{
          this.dialogRef.close();
        }
      break;

      default:

      break;
    }


  }


  pointsReview(){
    if(this.pointsActive){
      this.points = this.fidelityService.creditPointActives ? this.fidelityService.creditPointActives : 0;
    }else{
      this.points = 0;
      // this.points = this.fidelityService.creditPointActives ? this.fidelityService.creditPointActives : 0;
    }
  }

  validateDiferrenceC(points, deposit, totalPriceNR){
    if((points < totalPriceNR && deposit > totalPriceNR) || (points > totalPriceNR && deposit < totalPriceNR) || (points < totalPriceNR && deposit < totalPriceNR)){
      return true;
    }else{
      return false;
    }
  }

  payOnlyPoints():any{
    let data = {
      paymentMethods: [
        {
          id: 1,
          amount: -(this.totalPriceNR),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        }
      ],
    }

    return data;
  }

  payOnlyPointsDeposit():any{
    let data = {
      paymentMethods: [
        {
          id: 1,
          amount: -(this.points),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        },
        {
          id: 3,
          amount: -(this.totalPriceNR - this.points),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        }
      ],
    }

    return data;
  }

  payOnlyPointsDepositCreditCard():any{

    //total NR = 500;
    //  puntos: 200
    // deposito 250
    //targ 50

    let data = {
      paymentMethods: [
        {
          id: 1,
          amount: -(this.points),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        },
        {
          id: 3,
          amount: -(this.deposit),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        },
        {
          id: 2,
          amount: -(this.totalPriceNR - (this.deposit +  this.points)),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        },
      ],
    }

    return data;
  }

  payOnlydeposit():any{
    let data = {
      paymentMethods: [
        {
          id: 3,
          amount: -(this.totalPriceNR),
          agentId:this.agency.agent_id,
          agencyId: this.agency.agency_id,
          referenceId:null,
          details:this.details
        }
      ],
    }
    return data;
  }

  verifyCero(amount){
    if(amount < 0){
      return -(amount)
    }else{
      return amount;
    }
  }
}
