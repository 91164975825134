


<!-- ---------------------------------- NEW ITINERARY -------------------------- -->

<div class="section-topextend" *ngIf="!reload && !chargeClient">
  <a class="loader text-center justify-content-center align-items-center" style="padding-top: .5em;">
    <i class="fa fa-spinner fa-spin"></i><span class="ml-2">Cargando, espere por favor...</span>
  </a>
</div>

<ng-container *ngIf="reload">
<div class="section-topextend" *ngIf="(!saved && loaded)">
  <div class="d-flex justify-content-between container-fluid align-items-center">
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent"> -->
    <div>
      <form [formGroup]="_formGroupItinerary" style="height: 45px" class="d-flex align-items-center" >
          <span class="d-flex align-items-center justify-content-center" style="gap: .3em;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/world-wide-web.svg" style="margin-right: 10px;"
            [ngStyle]="{'fill': sectionHeader?.icon_color}"></svg-icon> -->
            <i class="bi bi-globe" style="font-size: 1.5em;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <input #itineraryName class="form-control menu-sesion input-header me-2" style="width: 230px" type="text" placeholder="Nombre File"
            name="title" aria-label="Title" formControlName="title" maxlength="50" autocomplete="off" />
          </span>
          <span id="autocomplete-container" class="d-flex align-items-center justify-content-center" style=" margin-left: 15px; gap: .3em;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/user.svg" style=" margin-right: 7px;"
            [ngStyle]="{'fill': sectionHeader?.icon_color}"></svg-icon> -->
            <i class="bi bi-person-fill" style="font-size: 1.5em; -webkit-text-stroke: 1.2px;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <input #name class="form-control menu-sesion input-header me-2" style="width: 180px;"  type="text" placeholder="Nombre cliente" name="client"
            formControlName="name" maxlength="50"autocomplete="off" aria-label="Name" />
          </span>
          <span class="d-flex px-0 align-items-center justify-content-start" style=" margin-left: 15px; gap: .3em;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/mail.svg" style="fill: black; margin-left: 15px;"
            [ngStyle]="{'fill': sectionHeader?.icon_color}"></svg-icon> -->
            <i class="bi bi-envelope-fill"  style="font-size: 1.5em;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <input #email class="form-control menu-sesion input-header me-2" style="width: 175px;" type="text"  maxlength="50" placeholder="E-mail" name="email"
            (ngModelChange)="resetSaveFormFields(clientLoaded)" formControlName="email" autocomplete="off" aria-label="Mail" />
            <button type="submit" class="btn btn-sm btn-search" style="margin-left: -5px;" data-placement="bottom" (click)="openModalExtraLarge(templatePrueba)">
              <i class="fa fa-search"></i>
            </button>
          </span>
        <span class="d-flex align-items-center justify-content-start" style=" margin-left: 15px; gap: .3em;">
          <!-- <svg-icon src="../../../assets/img/app/top-extend/smartphone.svg" style="fill: black;margin-left: 10px;"
          [ngStyle]="{'fill': sectionHeader?.icon_color}"></svg-icon> -->
          <i class="bi bi-telephone-fill" style="font-size: 1.5em;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
          <input
                    id="phone_code"
                    name="phone_code"
                    matInput
                    class="form-control menu-sesion input-header me-2 no-spinner"
                    style="font-family: 'Poppins', sans-serif !important; font-size: 13px;font-weight:500 !important;padding-left: 15px; padding-top: 5px; width: 180px;margin-top: 1px;height: 26px !important; color:#464646"
                    formControlName="phone_code"
                    aria-label="phone_code"
                    [matAutocomplete]="auto"
                    (input)="filterCountries($event.target.value)"
                    (focus)="getCountries()"
                    autocomplete="off"
                    placeholder="País + Cod. telefónico"
                    />
                    <!-- (change)="phoneCode($event.target.value)" -->
                    <mat-autocomplete id="matCountry" #matCountry #auto="matAutocomplete" [displayWith]="displayCountry">
                      <mat-option *ngFor="let country of filteredCountries"
                      [value]="country.phone_code">
                      {{ country.country_name }}</mat-option>
                    </mat-autocomplete>
            <input #phone class="form-control menu-sesion input-header me-2 no-spinner" style="width: 125px;" type="number"  maxlength="50" placeholder="N° Teléfono" name="phone"
            formControlName="phone" autocomplete="off" aria-label="Phone">
        </span>
        <button type="submit" class="btn btn-sm btn-save-client" data-placement="bottom" style="height: 26px; width: 125px; margin-left: 30px; text-align: start;padding: 5px 18px; line-height: 1;"
          (click)="saveFirstItinerary();" [disabled]="!_formGroupItinerary.valid" *ngIf="this.clientLoaded" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
          GUARDAR
        </button>
        <button type="submit" class="btn btn-sm btn-save-client" data-placement="bottom" style="height: 26px; width: 125px; margin-left: 30px; text-align: start; ;padding: 5px 18px; line-height: 1;"
          (click)="verifyExist();" [disabled]="!isGuardarButtonEnabled()" *ngIf="!this.clientLoaded" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
          GUARDAR
        </button>
      </form>
    </div>
    <!-- <div class="text-end" style=" margin-left: 60px">
      <button type="button" class="badge rounded-pill text-white" style="height: 25px; width: 125px;"><span class="sign-more">+</span>&nbsp;Itinerario</button>
    </div> -->
    <!-- <div class="text-end">
      <a href="https://moebius.tur.ar/"
        target="_blank"
        class="badge rounded-pill border-2 border-black text-black bg-white"
        style="height: 25px; width: 125px; font-weight: 600"
        data-placement="bottom">
        TARIFARIO
      </a>
    </div> -->
  </div>
  <!-- </div> -->


</div>
</ng-container>
<!-- ---------------------------------- ERROR FORM -------------------------- -->

<div class="error error-container">
  <div >
    <span *ngIf="(_formGroupItinerary.get('title').touched && _formGroupItinerary.get('title').hasError('required'))">
      El campo "Nombre del file" es obligatorio.
    </span>

    <span *ngIf="_formGroupItinerary.get('title').touched && _formGroupItinerary.get('title').hasError('spaceOnlyBlank')">The
      El campo no acepta sólo espacios en blanco.
    </span>
  </div>

  <div>
    <span
      *ngIf="!_formGroupItinerary.get('name').hasError('required') && _formGroupItinerary.get('name').touched && _formGroupItinerary.get('name').hasError('invalidname')">
      Por favor ingrese un nombre completo válido. Ejemplo: 'Primero Último'.
      </span>

    <span
      *ngIf="!_formGroupItinerary.get('email').hasError('required') && _formGroupItinerary.get('email').touched && _formGroupItinerary.get('email').hasError('invalidemail')">
      Por favor ingrese un formato de correo electrónico válido. Ejemplo: 'mi.usuario@dominio.com'.
    </span>
  </div>

  <div>
    <span
      *ngIf="(!_formGroupItinerary.get('email').hasError('required') && _formGroupItinerary.get('email').touched && _formGroupItinerary.get('email').hasError('emailTaken')) || clientEmailTake">
      Este correo electronico ya esta en uso.
    </span>
  </div>
    <span
    *ngIf="!_formGroupItinerary.get('phone').hasError('required') && _formGroupItinerary.get('phone').touched && _formGroupItinerary.get('phone').hasError('invalidcellphone')">
      Por favor introduzca un teléfono con una longitud de entre 6 y 20 números.
  </span>
</div>


<!-- ---------------------------------- iTINERARY CLIENT -------------------------- TODO ACA-->


<div class="section-topextend" *ngIf="!loaded && chargeClient">
  <a class="loader text-center justify-content-center align-items-center" style="padding-top: .5em;">
    <i class="fa fa-spinner fa-spin"></i><span class="ml-2">Cargando cliente, espere por favor...</span>
  </a>
</div>

<ng-container *ngIf="reload">
<nav id="new-itinerary" class="section-topextend mx-0 mt-0" *ngIf="(saved && loaded)">
  <div class="d-flex justify-content-between container-fluid align-items-center">
    <form [formGroup]="_formGroupItinerary" style="height: 45px; width: 100%" class="d-flex align-items-center">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex justify-content-start">
          <div class="d-flex align-items-center" style="margin-right: 25px;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/world-wide-web.svg"  [ngStyle]="{'fill': sectionHeader?.icon_color}" style="margin-right: 10px;"></svg-icon> -->
            <i class="bi bi-globe" style="font-size: 1.5em;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <span class="menu-sesion input-header text-ellipsis ml-2"
                  [ngStyle]="{ 'color':  sectionHeader?.icon_color }"
                  style="opacity: 0.75; display: flex; align-items: center">{{title.value}}</span>
          </div>
          <div class="d-flex align-items-center"  style="margin-right: 25px;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/user.svg" [ngStyle]="{'fill': sectionHeader?.icon_color}" style="margin-right: 7px;"></svg-icon> -->
            <i class="bi bi-person-fill" style="font-size: 1.5em; -webkit-text-stroke: 1.2px;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <span class="menu-sesion input-header text-ellipsis ml-2"
                  [ngStyle]="{ 'color':  sectionHeader?.icon_color }"
                  style=" opacity: 0.75; display: flex; align-items: center;">{{name.value}}</span>
          </div>
          <div class="d-flex align-items-center"  style="margin-right: 25px;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/mail.svg" [ngStyle]="{'fill': sectionHeader?.icon_color}" style="margin-right: 10px;"></svg-icon> -->
            <i class="bi bi-envelope-fill"  style="font-size: 1.5em;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <span class="menu-sesion input-header text-ellipsis ml-2"
                  [ngStyle]="{ 'color':  sectionHeader?.icon_color }"
                  style="opacity: 0.75; display: flex; align-items: center;">{{email.value}}</span>
          </div>
          <div class="d-flex align-items-center"  style="margin-right: 25px;">
            <!-- <svg-icon src="../../../assets/img/app/top-extend/smartphone.svg" [ngStyle]="{'fill': sectionHeader?.icon_color}" style="margin-right: 7px;"></svg-icon> -->
            <i class="bi bi-telephone-fill" style="font-size: 1.5em;" [ngStyle]="{'color': sectionHeader?.icon_color}"></i>
            <span class="menu-sesion input-header text-ellipsis ml-2"
                  [ngStyle]="{ 'color':  sectionHeader?.icon_color }"
                  style="opacity: 0.75; display: flex; align-items: center;">{{phoneCodeClient(phone_code.value)}} {{phone.value}}</span>
          </div>


      </div>


        <!-- Dates and buttons -->
        <div class="d-flex align-items-center justify-content-end" style="max-width: 604px; min-width: 420px;"
          *ngIf="userService.itineraryActive.length > 0">
          <div class="d-flex" style="margin-right: 12px;" *ngIf="userService.itineraryActive[0].items.length > 0">
            <div class="justify-content-end cursor-pointer mr-2">
              <span class="font-poppins date-in-out" *ngIf="userService.itineraryArrivalDate">
                Check In: {{userService.itineraryArrivalDate | date:'ddMMMyy' | uppercase}}
              </span>
            </div>
            <div class="justify-content-end cursor-pointer mr-2">
              <span class="font-poppins date-in-out" *ngIf="userService.itineraryDepartureDate">
                / Check Out: {{userService.itineraryDepartureDate | date:'ddMMMyy' | uppercase}}
              </span>
            </div>
          </div>

          <div *ngIf="userService?.itineraryActive?.length > 0">
            <span *ngIf="userService.itineraryActive[0]?.currency"
                  class="menu-sesion input-header"
                  [ngStyle]="{ 'color':  sectionHeader?.icon_color }"
                  style=" opacity: 0.75; display: flex; align-items: center;width: auto">Moneda: {{userService.itineraryActive[0]?.currency}}</span>

          </div>


          <!-- <div class="d-flex" style="margin-right: 12px;" *ngIf="userService.itineraryActive[0].items.length == 0">
            <div class="justify-content-end cursor-pointer mr-2">
              <span class="font-poppins date-in-out">
                Check In: -
              </span>
            </div>
            <div class="justify-content-end cursor-pointer mr-2">
              <span class="font-poppins date-in-out">
                / Check Out: -
              </span>
            </div>
          </div> -->

          <div class="d-flex">
            <!-- <button
                      style="cursor:pointer;padding: 0 10px 0 10px !important; height: 30px !important; position: relative;"
                      class="btn btn-outline-success justify-content-end cursor-pointer mx-0 mr-2 font-poppins"
                      data-placement="bottom" title="Go Itinerary"
                      *ngIf="href == '/home'
                                  && userService.itineraryActive.length > 0
                                  && userService.itineraryActive[0].arrivalDate
                                  && userService.itineraryActive[0].destination.length>=1"
                      (click)="active()"
                      > -->
            <button style="cursor:pointer;padding: 0 10px 0 10px !important; height: 30px !important; position: relative;"
              class="btn btn-outline-success justify-content-end cursor-pointer mx-0 mr-2 font-poppins" data-placement="bottom"
              title="Go Itinerary" *ngIf="href == '/home' " (click)="active()">
              Activo
              <i class="icon-active-green"></i>
            </button>

            <!-- <button style="cursor:pointer;padding: 0 10px 0 10px !important; position: relative;" id="newItinerary"
              class="btn btn-outline-danger justify-content-end cursor-pointer font-lato font-poppins" data-placement="bottom"
              title="New itinerary" (click)="openNewItinerary()" [disabled]="!_formGroupItinerary.valid">
              New itinerary
              <i class="icon-add-red"></i>
            </button> -->
            <!-- <div class="text-end">
              <a href="https://moebius.tur.ar/?agencia={{agencyEmail}}&id_agencia={{agencyID}}&agencia_logged=1"
                target="_blank"
                id="newItinerary"
                class="badge rounded-pill border-2 border-black text-black bg-white"
                style="height: 25px; width: 125px; font-weight: 600"
                data-placement="bottom">
                TARIFARIO
              </a>
            </div> -->
            <div class="text-end" style=" margin-left: 15px">
              <button id="newItinerary" type="button" class="badge rounded-pill text-white btn-new-itinerary" style="height: 25px; width: 125px;"
              data-placement="bottom"
              title="Nuevo file" (click)="openNewItinerary()"
            ><span class="sign-more">+</span>&nbsp;FILE</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</nav>
</ng-container>
<!-------------------------- CREACIÓN DE CLIENTES -------------------------------->


<div *ngIf="clientExists" class="col-10 client-exists client-exists-container">
  <span class="d-flex align-center justify-content-center" style="height: 30px; align-items:center">
    <div class="error-outline"></div>
    <span>Cliente ya existente. Verifique que los datos sean adecuados</span>
  </span>
</div>

<div *ngIf="clientCreated" class="col-10 client-add client-add-container">
  <span class="d-flex align-center justify-content-center" style="height: 30px; align-items:center">
    <!-- mat-icon -->
    <!-- style="font-size: 28px; color: black;margin-bottom: 6px; margin-right: .5em; font-weight: 500 " -->
    <!-- check_circle_outline -->
    <div class="check-outline"></div>
     <span>Cliente creado satisfactoriamente </span>
  </span>
</div>

<div *ngIf="clientConectError" class="col-10 client-error-conect client-error-conect-container">
  <span class="d-flex align-center justify-content-center" style="height: 30px; align-items:center">
    <mat-icon rel="preload" style="font-size: 28px; color: white;margin-bottom: 6px; margin-right: .5em; font-weight: 500 ">highlight_off</mat-icon>
    <span>Error al crear el cliente. Intente nuevamente más tarde</span>
  </span>
</div>



<!-- ---------------------------------- MODAL CLIENT INFORMATION -------------------------- -->


<ng-template #templatePrueba>
  <div class="modal-body" style="padding: 0px !important; border-radius: 25%">
    <ng-container *ngIf="loading">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <mat-spinner style="width: 200px;height: 200px;"></mat-spinner>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
        <div class="d-flex px-3 align-items-center justify-content-between px-4" style="background-color: black; height: 4.5em;">
          <h2 class="title-modal mb-0">Listado de clientes</h2>
          <button class="btn close-modal pr-1" (click)="closeModal();">
            <i class="bi bi-x" style="color: white;"></i>
          </button>
        </div>

        <div class=" pb-1 pt-3 px-4" >
          <div>
            <div class="row ">
              <div class="col-6 d-flex align-items-center">
                <span class="title-modal">Buscar Cliente</span>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button class="btn btn-reset" type="button" (click)="resetSearch()">Borrar</button>
                <button class="btn btn-usblick btn-buscar ml-2" type="button" (click)="filterClients()">Buscar</button>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-2">
              <div class="col-4">
                <div class="form-group">
                  <label for="name" class="label-search">Nombre:</label>
                  <input id="name" class="form-control" type="text" name="" placeholder="Name" [(ngModel)]="filterClient.first_name"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>

              <div class="col-4">
                <div class="form-group">
                  <label for="email" class="label-search">Apellido:</label>
                  <input id="email" class="form-control" type="text" name="" placeholder="Apellido" [(ngModel)]="filterClient.last_name"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>

              <div class="col-4">
                <div class="form-group">
                  <label for="email" class="label-search">Email:</label>
                  <input id="email" class="form-control" type="text" name="" placeholder="Email" [(ngModel)]="filterClient.email"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>


            </div>
          </div>
        </div>

        <div class=" pb-1 pt-3 px-4" >
        <div class="card mt-3">
          <ul class="list-group list-group-flush">

            <li class="list-group-item head-list">
              <div class="row">
                <div class="col d-flex justify-content-start">
                  <span class="title-head">
                    Nombre
                  </span>
                </div>

                <div class="col d-flex justify-content-start">
                  <span class="title-head">
                    Apellido
                  </span>
                </div>

                <div class="col d-flex justify-content-start">
                  <span class="title-head">
                    Email
                  </span>
                </div>

                <div class="col d-flex justify-content-end">
                  <span class="title-head">
                    Seleccionar
                  </span>
                </div>

              </div>
            </li>

            <li class="list-group-item" *ngFor="let client of clientList | paginate: { itemsPerPage: 5, currentPage: p }">

              <div class="row">

                <div class="col d-flex align-items-center justify-content-start result" style="font-weight: 700 !important;">
                  {{client?.first_name}}
                </div>

                <div class="col d-flex align-items-center justify-content-start result">
                  {{client?.last_name ? client.last_name : ''}}
                </div>

                <div class="col d-flex align-items-center justify-content-start result" style="overflow: hidden;">
                  {{client?.email}}
                </div>

                <div class="col d-flex align-items-center justify-content-end">
                  <button *ngIf="booleanSelectDisable"  class="btn btn-info btn-s m" type="button"
                                            (click)="selectClient(client);closeModal();">Select
                 </button>
                  <label class="custom-radio-checkbox">
                    <input class="custom-radio-checkbox__input" type="checkbox" name="" (change)="selectClient(client);closeModal();">
                    <span class="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                  </label>
                </div>

              </div>

            </li>

          </ul>

          <!-- pagination -->
          <div class="d-flex justify-content-end align-items-center nav-pagination" *ngIf="lastPage > 1">
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center m-0">
                <li class="pagination-item" *ngIf="currentPage > 1" (click)="showMoreResult('-')">
                  <a class="pagination-link" tabindex="-1">
                    < </a> </li> <li class="pagination-item" [ngClass]="{'active': currentPage == 1}" (click)="goPage(1)"><a
                        class="pagination-link">1</a></li>
                <li class="pagination-item" [ngClass]="{'active': currentPage == 2}" *ngIf="lastPage > 1" (click)="goPage(2)"><a
                    class="pagination-link">2</a></li>
                <li class="pagination-item" [ngClass]="{'active': currentPage == 3}" *ngIf="lastPage > 2" (click)="goPage(3)"><a
                    class="pagination-link">3</a></li>
                <li class="pagination-item" *ngIf="lastPage > 4">
                  <p class="pagination-link m-0">...</p>
                </li>
                <li class="pagination-item" *ngIf="lastPage > 4"><a class="pagination-link">{{lastPage}}</a></li>
                <li class="pagination-item" (click)="showMoreResult('+')">
                  <a class="pagination-link"> > </a>
                </li>
              </ul>
            </nav>
          </div>

      </div>

        </div>

                  <div class="row d-flex justify-content-center mt-2 ml-0 mr-0 mb-5">
                      <button type="button text-center" class="btn btn-outline-danger btn-md"
                          (click)="closeModal()">Cancelar</button>
                  </div>

    </ng-container>


  </div>
</ng-template>

<!-- ---------------------------------- OLD NEW ITINERARY -------------------------- -->

<!-- <div class="section-topextend" *ngIf="(!saved && loaded)">
  <form [formGroup]="_formGroupItinerary" style="height:45px" class="d-flex align-items-center">
    <div class="d-flex align-content-center justify-content-start col-sm-12 m-0 p-0" style="height: 45px;">
      <div class="nav pl-3 justify-content-start flex-xs-row col-xg-12 col-lg-12 col-md-12 col-sm-12 text-left" style="height: 45px;">

        <div class="d-flex align-items-center justify-content-center inputs-owner px-0">

          <i class="icon-world-header mr-2"></i>
          <input class="menu-sesion input-header px-3" placeholder="Name of the itinerary" name="title" formControlName="title"
            maxlength="50" autocomplete="off" />

        </div>

        <div id="autocomplete-container" class="d-flex align-items-center justify-content-center px-0 inputs-owner">
          <i class="icon-user-header mr-2"></i>
          <input class="menu-sesion input-header m-0 px-3" placeholder="Name" name="client" formControlName="name" maxlength="50"
            autocomplete="off" />
        </div>

        <div class="d-flex px-0 align-items-center justify-content-center inputs-owner">
          <i class="icon-mail-header mr-2"></i>
          <input class="menu-sesion input-header px-3 input-header-mail" maxlength="50" placeholder="E-mail" name="email" type="email"
            (ngModelChange)="resetSaveFormFields(clientLoaded)" formControlName="email" autocomplete="off"
            style="border-bottom-right-radius: 0pt;     border-top-right-radius: 0pt;" />
          <button type="submit" class="btn btn-sm btn-search" data-placement="bottom" (click)="openModalExtraLarge(templatePrueba)">
            <i class="fa fa-search"></i>
          </button>
        </div>

        <div class="d-flex px-0 align-items-center justify-content-center inputs-owner">
          <i class="icon-tel-header mr-2"></i>
          <input class="menu-sesion input-header px-3" maxlength="50" placeholder="Phone" name="phone"
            (ngModelChange)="resetSaveFormFields(clientLoaded)" formControlName="phone" autocomplete="off" />
        </div>
        <div class="d-flex pl-2 align-items-center justify-content-end">
          <button type="submit" class="btn btn-sm btn-usblick ml-2" data-placement="bottom" style="height: 32px !important;"
            (click)="saveFirstItinerary();" [disabled]="!_formGroupItinerary.valid" *ngIf="this.clientLoaded" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_1}">
            Guardar
          </button>
          <button type="submit" class="btn btn-sm btn-usblick ml-2" data-placement="bottom" style="height: 32px !important;"
            (click)="verifyExist();" [disabled]="!_formGroupItinerary.valid" *ngIf="!this.clientLoaded">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </form>
</div> -->
