import { Component, OnInit, ViewContainerRef, TemplateRef, OnDestroy, ViewChild } from '@angular/core';
import { ItineraryService } from '../itinerary.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CustomValidator } from '../customValidators';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { RestService } from '../rest.service';
import { UserService } from '../../pages/com.usblick.common/auth/user.service';
import { SelectAgencyModalComponent } from '../select-agency-modal/select-agency-modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { FilterPipe } from 'ngx-filter-pipe';
import { MaterialModalConfirmComponent } from '../material-modal-confirm/material-modal-confirm.component';
import { setting } from '../../../../setting';
import { Subject, Subscription } from 'rxjs';
import { AuthorizationService } from 'app/core/authorization/authorization.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'usb-itinerary-owner',
  templateUrl: './itinerary-owner.component.html',
  styleUrls: ['./itinerary-owner.component.scss']
})
export class ItineraryOwnerComponent implements OnInit {
  @ViewChild("itineraryName") itineraryName: HTMLInputElement;
  @ViewChild("name") nameClient: HTMLInputElement;
  @ViewChild("email") emailClient: HTMLInputElement;
  @ViewChild("codephone") codePhone: HTMLInputElement;
  @ViewChild("phone") phoneClient: HTMLInputElement;
  modalRef: BsModalRef;
  loaded = false;
  chargeClient = false;
  saved: boolean = false;
  itineraryData: any;
  client: any;
  clientEmailTake = false;
  clientLoaded = false;
  draftLoaded = false;
  firstItinerary = true;
  _cookieClient: any = undefined;
  agencyID: number;
  agencyEmail: string;

  clientListAux: any;
  clientList: any;
  loading = true;
  p: number = 1;
  public sectionHeader: any;
  public clientExists: boolean = false;
  public clientCreated: boolean = false;
  public clientConectError: boolean = false;
  public reload: boolean = true;
  public countriesData: any[] = [];
  selectedPhoneCode: any;
  currencyItinerary: any;

  bsModalRef?: BsModalRef;

  filterClient = {
    email: "",
    first_name: "",
    last_name:"",
  }

  currentPage:number = 1;
  lastPage:number;
  booleanSelectDisable:boolean = false;
  filteredCountries = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private clientsAllSubscription: Subscription;
  private verifyExistSubscription: Subscription;
  private activeUserSubscription:Subscription;
  private moduleToProfileSubscription: Subscription;

  _formGroupItinerary: FormGroup;

  //_formGroupItinerary: FormGroup;
  clientPut = false;

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  itineraryArrivalDate:any;
  itineraryDepartureDate:any;

  itinerary$: Observable<any[]>;

  href: string;

  public getClientsSettingAppBooking: any;
  public moduleProfile: any[] = [];

  constructor(
    private itineraryService: ItineraryService,
    private formBuilder: FormBuilder,
    public vcr: ViewContainerRef,
//
    private modalService: BsModalService,
    private _router: Router,
    private _cookieService: CookieService,
    private restService: RestService,
    public userService: UserService,
    public dialog: MatDialog,
    private ngxFilter: FilterPipe,
    private authService: AuthorizationService
  ) {
    ////this.toastr.setRootViewContainerRef(this.vcr);

    this.chargeClient = false;
  }


  ngAfterViewInit(): void {

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this._router.events
    .filter(event => event instanceof NavigationEnd)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(() => {
      this.href =this._router.routerState.snapshot.url;
    })

  }


  ngOnInit() {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.chargeClient = false;
    // this.getCountries();
    this._formGroupItinerary = this.formBuilder.group({
      name: [null, Validators.required],
      phone_code_selected: [null],
      phone_code: [null, Validators.required],
      phone: [null, Validators.required, CustomValidator.soloNumerosValidator],
      email: [null, Validators.required, CustomValidator.customEmailValidator],
      title: [null, Validators.required],
      client_id: null,
      status: null
    });

    this.sectionHeader = this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_header;
// console.log(this.sectionHeader)
    // setTimeout(() => {
      this.getAgencyEmail()
      this.agencyId();
    // }, 1500);

    var observableUser = false;
    //var observableUser = false;

   this.activeUserSubscription = this.userService.activeUserSubject.asObservable().subscribe(
      (response: any) => {
        console.log(response)
        observableUser = true;
        if (response.data.length == 1) {
          //Valida si llega una sola agencia directamente entra
          this.userService._agencyName = response.data[0].name_agency;
          this.userService._agentID = response.data[0].agent_id;
          this.userService._agencyID = response.data[0].agency_id;
          localStorage.setItem('selectedAgency', JSON.stringify(response.data[0]));
          this.itineraryService.consultActiveItinerary(this.userService._agentID);

          //console.log(" this.userService._agencyName",  this.userService._agencyName);
          //console.log("  this.userService._agentID",   this.userService._agentID);
          //this._initComponent();
          this._formGroupItinerary = this._initFormItinerary();
        }

        if (response.data.length > 1) {
          //si el usuario tiene varias agencias entra aqui

          //console.log("hay varias agencias", response.data);
          //console.log("hay mas de un agente registrado para este usuario");

          //aqui valida si ya se guardo una agencia en localstorage y la vuelve a asignar las variables
          if (localStorage.getItem('selectedAgency')) {

            this.userService.itineraryActive = JSON.parse(localStorage.getItem('selectedAgency'));
            this.userService._agencyName = this.userService.itineraryActive.name_agency;
            this.userService._agentID = this.userService.itineraryActive.agent_id;
            this.userService._agencyID = this.userService.itineraryActive.agency_id;
            //this._initComponent();
            //this._formGroupItinerary = this._initFormItinerary();
            //console.log("encontro una agencia seleccionada anteriormente ", this.userService.itineraryActive);
            this._initComponentWithService();
          } else {
            //Si el localstorage esta vacio es porque no se ha seleccionado y debe abrir el modal para que seleccione una y empiece de cero
            //console.log("No se encontro una agencia seleccionada, abre modal", this.userService.itineraryActive);
            this.openAgencyModal(response.data);
            this._initComponent();
            //this._formGroupItinerary = this._initFormItinerary();
          }


        }

      }
    );

    this.itinerary$ = this.itineraryService.getItinerary$();
    this.itinerary$.subscribe((data:any) => {
      //console.log("data this.itinerary$.subscribe", data);
      this.itineraryService.itinerary =  this.userService.itineraryActive[0];
      this._initComponentWithService();
      this.itineraryArrivalDate = this.itineraryService.itineraryArrivalDate;
      this.itineraryDepartureDate = this.itineraryService.itineraryDepartureDate;
      //console.log("data this $$ fechas: ",this.itineraryArrivalDate, this.itineraryDepartureDate);
    });

    setTimeout(() => {
      if (!observableUser) {
        //console.log("no existe subscribe user y inicia componente con servicio");
        this._initComponentWithService();
        //this._formGroupItinerary = this._initFormItinerary();
        //this.itineraryService.consultActiveItinerary(this.userService._agentID);
      }
    }, 5000);

    this.chargeClient = false;

    this.itineraryService.currencySuccessEmit.subscribe({
      next: (res: any) => {
        if(res) {
          this.currencyItinerary = res;
        }
      }
    })

  }

  isGuardarButtonEnabled(): boolean {
    const nameControl = this._formGroupItinerary.get('name');
    const emailControl = this._formGroupItinerary.get('email');
    const phoneControl = this._formGroupItinerary.get('phone');
    const phoneCodeControl = this._formGroupItinerary.get('phone_code');
    const titleControl = this._formGroupItinerary.get('title');

    return nameControl.valid && phoneCodeControl.valid && emailControl.valid && phoneControl.valid && titleControl.valid;
  }


  openAgencyModal(data) {
    var dialogRef = this.dialog.open(SelectAgencyModalComponent, {
      //width: '250px',
      data: data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this._initComponentWithService();
    });
  }


  verifyCookie(): boolean {

    this._cookieClient = (this._cookieService.get('clientActive') != undefined) ? this._cookieService.get('clientActive') : undefined;
    //console.log("this._cookieClient", this._cookieClient);
console.log(this._cookieClient)
    //console.log("this.data", data);

    if (this._cookieClient !== undefined) {
      //console.log('el cookie init component distinto de undefined: ' + this._cookieClient);
      let data = this._cookieClient.split("||");

      //console.log("data split", data);

      this._formGroupItinerary = this.formBuilder.group({
        name: [{ value: data[2].length <= 1 ? '' : data[2].trim(), /* disabled: true */ }, [Validators.required/*, CustomValidator.fullNameValidator*/]],
        phone_code: [{ vale: data[6].trim(), /* disabled: true */}, Validators.required],
        phone: [{ value: data[4].trim(), /* disabled: true */ }, Validators.required],
        email: [{ value: data[3].trim(), /* disabled: true */ }, Validators.required],
        title: [{ value: data[1].trim(), /* disabled: true */ }, Validators.required],
        client_id: data[0],
        status: (data[5] !== undefined) ? data[5] : ''
      });



      //esto esta bien si existe data pasar a true de inmediato
      this.loaded = true;
      // confuso
      this.saved = true;

      //console.log("coloca data del coockie");

      return true;
    }
    return false;
  }

  _initComponent() {


    this.itinerary$ = this.itineraryService.getItinerary$();
    this.itinerary$.subscribe((data:any) => {
      //this.itineraryService.activeItinerary.subscribe((data: any) => {
      if (data.length > 1) { //aqui sabemos si efectivamente hay un cliente
        this.itineraryArrivalDate = this.itineraryService.itineraryArrivalDate;
        this.itineraryDepartureDate = this.itineraryService.itineraryDepartureDate;

        //console.log("data this $$ fechas: ",this.itineraryArrivalDate, this.itineraryDepartureDate);

        this._cookieService.put('clientActive', data[0].client_id + '||' + data[0].title + '||' + ((data[1].first_name == null || data[1].first_name == "false") ? '' : data[1].first_name) + ' ' + (data[1].last_name == null ? '' : data[1].last_name) + '||' + (data[1].email == null ? '' : data[1].email) + '||' + (data[1].phone == null ? '' : data[1].phone) + '||' + (data[1].state == null ? '' : data[1].state));
        // console.log("this.cookie nuevo", this._cookieService.get('clientActive'));

        let names;
        if (data[1].first_name !== '<first_name>' && data[1].first_name !== 'false') {
          names = data[1].first_name + ' ' + ((data[1].last_name == null) ? '' : data[1].last_name);
        } else {
          names = '';
        }

        if (this._cookieClient === undefined) {
          this._formGroupItinerary = this.formBuilder.group({
            name: [{ value: (names && names.length <= 1) ? '' : names, /* disabled: true */ }, [Validators.required/*, CustomValidator.fullNameValidator*/]],
            phone: [{ value: data[1].phone, /* disabled: true */ }, Validators.required],
            email: [{ value: data[1].email, /* disabled: true */ }, Validators.required],
            title: [{ value: data[0].title, /* disabled: true */ }, Validators.required],
            client_id: data[1].id,
            status: data[1].status
          });
        }
        this.itineraryData = data[0];
        this.loaded = true;
        this.saved = true;
        //console.log("this.itineraryData", this.itineraryData);

      } else {

        //console.log("de active itinerary no viene client,deja ingresar nuevo");

        this._cookieService.put('clientActive', undefined);
        this._cookieClient = undefined;
        this.saved = false;
        this.loaded = true;
        this.draftLoaded = false;

        this._formGroupItinerary = this.formBuilder.group({
          name: [null, [Validators.required,/*CustomValidator.fullNameValidator*/]],
          phone_code_selected: [null],
          phone_code: [null, Validators.required],
          phone: [null, Validators.required],
          email: [null, Validators.required],
          title: [null, Validators.required],
          client_id: null
        });
      }
      //})

    });
  }

  _initComponentWithService() {
    this.userService.calculateDates();
    if (this.userService.itineraryActive) {

      if (this.userService.itineraryActive.length > 1) { //aqui sabemos si efectivamente hay un cliente

        this.itineraryArrivalDate = this.itineraryService.itineraryArrivalDate;
        this.itineraryDepartureDate = this.itineraryService.itineraryDepartureDate;
        //console.log("data fechas: cargando en _initComponentWithService",this.itineraryArrivalDate, this.itineraryDepartureDate);

        //console.log("de active itinerary service viene client, asigna el que llega", this.userService.itineraryActive);
        this._cookieService.put('clientActive', this.userService.itineraryActive[0].client_id + '||' + this.userService.itineraryActive[0].title + '||' + ((this.userService.itineraryActive[1].first_name == null || this.userService.itineraryActive[1].first_name == "false") ? '' : this.userService.itineraryActive[1].first_name) + ' ' + (this.userService.itineraryActive[1].last_name == null ? '' : this.userService.itineraryActive[1].last_name) + '||' + (this.userService.itineraryActive[1].email == null ? '' : this.userService.itineraryActive[1].email) + '||' + (this.userService.itineraryActive[1].phone == null ? '' : this.userService.itineraryActive[1].phone) + '||' + (this.userService.itineraryActive[1].state == null ? '' : this.userService.itineraryActive[1].state)) + '||' + (this.userService.itineraryActive[1].phone_code);

        // console.log("this.cookie nuevo", this._cookieService.get('clientActive'));

        let names;
        if (this.userService.itineraryActive[1].first_name !== '<first_name>' && this.userService.itineraryActive[1].first_name !== 'false') {
          names = this.userService.itineraryActive[1].first_name + ' ' + ((this.userService.itineraryActive[1].last_name == null) ? '' : this.userService.itineraryActive[1].last_name);
        } else {
          names = '';
        }

        this._formGroupItinerary = this.formBuilder.group({
          name: [(names && names.length <= 1) ? '' : names, [Validators.required/*, CustomValidator.fullNameValidator*/]],
          phone_code: [this.userService.itineraryActive[1].phone_code, Validators.required],
          phone: [this.userService.itineraryActive[1].phone, Validators.required],
          email: [ this.userService.itineraryActive[1].email, Validators.required],
          title: [this.userService.itineraryActive[0].title, Validators.required],
          client_id: this.userService.itineraryActive[1].id,
          status: this.userService.itineraryActive[0].status
        });

        this.itineraryData = this.userService.itineraryActive[0];
        this.loaded = true;
        this.saved = true;
        //this.clientLoaded = true;

      } else {

        //console.log("de active itinerary no viene client,deja ingresar nuevo");

        this._cookieService.put('clientActive', undefined);
        this._cookieClient = undefined;
        this.saved = false;
        this.loaded = true;
        this.draftLoaded = false;

        this._formGroupItinerary = this.formBuilder.group({
          name: [null, [Validators.required/*, CustomValidator.fullNameValidator*/]],
          phone_code_selected: [null],
          phone_code: [null, Validators.required],
          phone: [null, Validators.required],
          email: [null, [Validators.required,  CustomValidator.customEmailValidator]],
          title: [null, Validators.required],
          client_id: null
        });
        //this.clientLoaded = false;
      }
    }

    setTimeout(() => {
    }, 3000);
  }

  _initFormItinerary(): FormGroup {
    if (this._cookieClient !== undefined) {
      //console.log('el cookie init form: ' + this._cookieClient);
      let data = this._cookieClient.split("||");
      const _itinerary: FormGroup = this.formBuilder.group({
        name: [{ value: data[2].length <= 1 ? '' : data[2].trim(), /* disabled: true */ }/*, CustomValidator.fullNameValidator*/],
        phone: [{ value: data[4], /* disabled: true */ }, Validators.required],
        phone_code: [{value: data[6], /* disabled: true */}, Validators.required],
        email: [{ value: data[3], /* disabled: true */ }, Validators.required,  /* CustomValidator.customEmailValidator */],
        title: [{ value: data[1], /* disabled: true */ }, Validators.required],
        client_id: data[0],
        status: (data[5] !== 'undefined') ? data[5] : ''
      });
      return _itinerary;
    } else {
      const _itinerary: FormGroup = this.formBuilder.group({
        name: [null/*, CustomValidator.fullNameValidator*/],
        phone_code: [null, Validators.required],
        phone_code_selected: [null],
        phone: [null, Validators.required],
        email: [null, [Validators.required, Validators.email]],
        title: [null, [Validators.required, /* CustomValidator.notOnlySpaceBlank */]],
        client_id: null,
        status: null
      });
      return _itinerary;
    }
  }

  resetSaveFormFields(flag: boolean) {
    if (flag) {
      this._formGroupItinerary = this.formBuilder.group({
        name: [null, [Validators.required/*, CustomValidator.fullNameValidator*/]],
        phone_code: [null, Validators.required],
        phone_code_selected: [null],
        phone: [null, Validators.required],
        email: [null, Validators.required],
        title: [null, [Validators.required, /* CustomValidator.notOnlySpaceBlank */]],
        client_id: null,
        status: null
      });


      this.displayCountry(null);


      console.log(this._formGroupItinerary)
      this.clientLoaded = false;
    }
  }

  saveFirstItinerary() {


    let newItinerary = true;
    this.loaded = false;
    this.saved = false;
    this.chargeClient = true;

    if (!this.clientLoaded) {
      let fullName;

      if (this._formGroupItinerary.value['name'] !== null && this._formGroupItinerary.value['name'] !== undefined &&
        this._formGroupItinerary.value['name'].trim() !== '') {
        fullName = this._formGroupItinerary.value['name'].trim().split(' ', 2);
      } else {
        fullName = ['false', ''];
      }

      const data: any = {
        first_name: fullName[0],
        last_name: (fullName[1] === undefined || fullName[1] === null) ? '' : fullName[1],
        email: this._formGroupItinerary.value['email'] ? this._formGroupItinerary.value['email'] : '',
        phone_code: this._formGroupItinerary.value['phone_code'] ? this._formGroupItinerary.value['phone_code'] : '',
        phone: String(this._formGroupItinerary.value['phone'] ? this._formGroupItinerary.value['phone'] : ''),
        itineraryOwner: true
      };

      if (this.draftLoaded === true) {
        newItinerary = false;
      }

      this.itineraryService.postDataClient(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response:any) => {

console.log(response)
          if (response.success) {

            const itinerary: any = {
              client_id: response.id,
              title: this._formGroupItinerary.value['title'],
              // agent_id: this.authService._agentID
              //agency_id: this.authService._agencyID
            };

            if (newItinerary) {
              console.log('aca')
              this.itineraryService.postDataItinerary(itinerary, 'Saved');
            } else {
              console.log('aca')
              this.itineraryData.status = 'Saved';
              this.itineraryData.client_id = itinerary.client_id;
              this.itineraryData.title = itinerary.title;
              //this.itineraryData.agency_id= this.authService._agencyID;
              this.itineraryData.agent_id = this.userService._agentID;
              this.itineraryData.currency = this.userService.itineraryActive ? this.userService.itineraryActive[0].currency : null;
              console.log(this.itineraryData)
              this.itineraryService.updateActiveItinerary(this.itineraryData, true);
            }

            let agent = JSON.parse(localStorage.getItem('selectedAgency'));

            const client = {
              client_id: response.id,
              agency_id: this.userService._agencyID ? this.userService._agencyID : agent.agency_id
            }
            this.itineraryService.addClientToAgency(client)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
              next: (response:any) => {
                  const success = response.success;
                  if(!success) {
                    this.clientExists = true;

                    setTimeout(() => {
                      this.clientExists = false;
                    }, 5000);
                  } else {
                    this.clientCreated = true;

                    setTimeout(() => {
                      this.clientCreated = false;
                    }, 5000);
                  }
                console.log("RESPONSE CLIENT", response);
              },
              error: (error: Error) => {
                this.clientConectError = true;

                setTimeout(() => {
                  this.clientConectError = false;
                }, 5000);
              }
            });

            this.chargeClient = false;
            this.loaded = true;
            this.saved = true;
            //this._cookieService.put('clientActive',itinerary.client_id+'||title||oscar mora||ocs@gmail.com||231231312312312||Saved');
          }

        }, Error => {
          const errorParsed = JSON.parse(Error);

          this._cookieService.put('clientActive', undefined);

          if (!errorParsed.success && errorParsed.message === 'Client Exist') {

            this.restService.doGet('app', 'clientsAll?adult=true')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
              (data: any) => {
                this.clientListAux = data.data;
                this.clientList = this.clientListAux;

                let email =  this._formGroupItinerary.value['email'];

                let clientFound = this.clientList.find( element => element.email == email);

                this.selectClient(clientFound);

                const itinerary: any = {
                  client_id: this.client.id,
                  title: this._formGroupItinerary.value['title']
                };
                if (this.draftLoaded === true) {
                  newItinerary = false;
                }
                if (newItinerary) {
                  this.itineraryService.postDataItinerary(itinerary, 'Saved');
                } else {
                  this.itineraryData.status = 'Saved';
                  this.itineraryData.client_id = itinerary.client_id;
                  this.itineraryData.title = itinerary.title;
                  this.itineraryService.updateActiveItinerary(this.itineraryData, true);
                }
              },

              error => {
                console.log(error);
              }
            );

          }

          else if (typeof (Error) === 'string') {
            if (Error !== undefined) {
              console.warn('Client Error');
              const errores = JSON.parse(Error);
              if (errores.errors.email !== undefined && errores.errors.email[0]) {
                const errorish = errores.errors.email[0];
                if (errorish === 'The email has already been taken.') {
                  this.clientEmailTake = true;
                  this._formGroupItinerary.get('email').setErrors({ emailTaken: true });
                }
              }
            }
          }

        }

      );

      this.clientLoaded = false;


    } else {
      const data: Object = {
        client_id: this._formGroupItinerary.value['client_id'],
        title: this._formGroupItinerary.value['title'],
      };
      const itinerary: any = {
        client_id: this.client.id,
        title: this._formGroupItinerary.value['title']
      };
      if (this.draftLoaded === true) {
        newItinerary = false;
      }
      if (newItinerary) {
        this.itineraryService.postDataItinerary(itinerary, 'Saved');
      } else {
        this.itineraryData.status = 'Saved';
        this.itineraryData.client_id = itinerary.client_id;
        this.itineraryData.title = itinerary.title;
        this.itineraryService.updateActiveItinerary(this.itineraryData, true);
      }
    }

  }

/**
 * Verifica la existencia de un cliente y guarda un nuevo itinerario.
 *
 * Este método realiza una verificación en el backend para determinar si un cliente ya existe en la base de datos
 * a partir del email proporcionado. Si el cliente no existe, guarda un nuevo itinerario llamando a `saveFirstItinerary()`.
 * En caso de que el cliente ya exista, se abre un modal para confirmar si se desea guardar como nuevo cliente.
 * Si se confirma, se procede a guardar el itinerario.
 *
 * @payload -> objeto que contiene el email a verificar.
 * @verifyExistSubscription -> suscripción al servicio de verificación.
 * @res.exist -> respuesta del backend que indica si el cliente existe o no.
 * @bsModalRef -> referencia al modal de confirmación para decidir si guardar como nuevo cliente.
 *
 * @restService.doPost('app', 'checkClient', payload) -> hace una petición POST para verificar la existencia del cliente.
 */
verifyExist(): void {

  const payload = {
    email: this._formGroupItinerary.get('email').value
  }

  this.verifyExistSubscription = this.restService.doPost('app', 'checkClient', payload)
    .subscribe({
      next: (res: any) => {
        console.log(res)
        if (res) {
          if (res.exist === true) {
            this.saveFirstItinerary(); // Guarda un nuevo itinerario si el cliente no existe.
          } else {
            const initialState: ModalOptions = {
              initialState: {
                message: 'Se guardará como nuevo cliente. ¿Está seguro?' // Mensaje en el modal de confirmación.
              }
            };
            this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);

            const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
              if (result) {
                this.saveFirstItinerary(); // Guarda el itinerario si se confirma la acción en el modal.
              }
              confirmSubscription.unsubscribe(); // Finaliza la suscripción al confirmar la acción.
            });
          }
        }
      }
    });
}


  newItinerary() {
    this.reload = false;

    let agent = localStorage.getItem("selectedAgency");
   /// localStorage.clear();
    localStorage.setItem("selectedAgency", agent);

    this._cookieService.put('clientActive', undefined);
    this._cookieService.remove('clientActive');
    this._cookieClient = undefined;

    // console.log('itinerarydata ->', this.itineraryData);

    if (this.itineraryData != undefined) {
      this.itineraryData.active = false;
      this.itineraryData.status = 'Saved';
    } else {
      this.itineraryData = this.userService.itineraryActive[0];
    }


    this.resetSaveFormFields(true);
    this.draftLoaded = false;
    this.loaded = true;
    this.itineraryData.currency = this.userService.itineraryActive[0] ? this.userService.itineraryActive[0].currency : null;
    this.itineraryService.updateActiveItinerary(this.itineraryData, false);


    setTimeout(() => {
      this.reload = true;
      this.saved = false;
      // Comprobar la ruta actual
      const currentRoute = this._router.url;
      if (currentRoute !== '/home') {
        window.location.href = `${setting.appBookingUrl}`;
      }
    }, 1000);

  }

  openNewItinerary(){
    this.restService.setPassengersData(null);
    const initialState: ModalOptions = {
      initialState: {
        message: 'Esto guardará su itinerario actual y comenzará un nuevo borrador de file. ¿Está seguro?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
    const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
      if(result) {
        this.userService.itineraryActive = [];
        this.newItinerary();
      } else {

      }
      confirmSubscription.unsubscribe();
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  openModalExtraLarge(template: TemplateRef<any>) {
    this.loadClients(this.currentPage);
    this.modalRef = this.modalService.show(template, { class: 'modal-lg modal-edit-search' });
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  loadClients(page:number) {
    let goPage = (page>1) ? '&page='+page : '';
    const url = `clientsAll?agent_id=${this.userService._agencyID}${goPage}&paginate=5&adult=true`;
  this.clientsAllSubscription = this.restService.doGet('app', url)
  .pipe(takeUntil(this._unsubscribeAll))
  .subscribe(
      (data: any) => {
        console.log('data paginate ->', data);
        this.clientListAux = data.data;
        this.clientList = this.clientListAux.data;
        this.lastPage = this.clientListAux.last_page;
        this.currentPage = this.clientListAux.current_page;
        this.loading = false;
      },
      error => {
        console.log("error get clients");
      }
    );
  }

  showMoreResult(more){
    if(more == '+'){
      this.currentPage = this.currentPage+1;
      this.loadClients(this.currentPage);
    }else{
      this.currentPage = this.currentPage-1;
      this.loadClients(this.currentPage-1);
    }
  }

  resetFilterClient(event){
    this.loadClients(this.currentPage);
  }

  goPage(event){
    this.loadClients(event);
  }

  selectClient(client: any) {

    let phone_code: any;
    const phoneCodeSelected = this.countriesData.find( c => c.phonecode === client.phone_code);
    this.client = client;
    this._formGroupItinerary = this.formBuilder.group({
      name: [null, [Validators.required/*, CustomValidator.fullNameValidator*/]],
      phone_code_selected: [null],
      phone_code: [null, [Validators.required]],
      phone: [null, CustomValidator.cellphoneValidator],
      email: [null, [Validators.required,CustomValidator.customEmailValidator]],
      title: [ this._formGroupItinerary.value['title'], Validators.required],
      client_id: null
    });

    this.getCountries(true, client.phone_code);
    this._formGroupItinerary.patchValue({
      name: client.first_name + (client.last_name ? (' ' + client.last_name)  : '' ),
      email: client.email,
      phone_code_selected: client.phone_code,
      phone_code: phone_code,
      phone: client.phone,
      client_id: client.id
    })


    // this._formGroupItinerary.controls['name'].setValue(client.first_name + (client.last_name ? (' ' + client.last_name)  : '' ));
    // this._formGroupItinerary.controls['phone_code_selected'].setValue(client.phone_code ? client.phone_code : '');
    // this._formGroupItinerary.controls['phone_code'].setValue(client.phone_code ? client.phone_code : '');
    // this._formGroupItinerary.controls['phone'].setValue(client.phone);
    // this._formGroupItinerary.controls['email'].setValue(client.email);
    // this._formGroupItinerary.controls['client_id'].setValue(client.id);
    this.clientLoaded = true;

  }


  filterClients() {
    const first_name  = this.filterClient.first_name;
    const email  = this.filterClient.email;
    console.log('results firstName', first_name);
    console.log('results firstName', email);
    //llame al metodo para poder pasar el name y el email
    this.filterByclients(this.currentPage, first_name, email)

    //var result = this.clientList.filter(element => { this.email == element.email && this.name == element.first_name });
    // let result = this.ngxFilter.transform(this.clientListAux, this.filterClient);
    // if (result) {

    //   this.clientList = result;
    // }

  }

  resetSearch() {
    this.clientList = this.clientListAux;
    this.filterClient.email = null;
    this.filterClient.first_name = null;
    this.filterClient.last_name = null;
    this.loadClients(this.currentPage);
  }

  setDateHead(date: string) {
    if (date) {
      let dateSplit = date.split("-");
      return '' + dateSplit[2] + '-' + moment().month(parseInt(dateSplit[1]) - 1).format('MMM-Y');
    }
  }

  setArrivalDate():string{
    if (this.itineraryArrivalDate) {
      let dateSplit = this.itineraryArrivalDate.split("-");
      return '' + dateSplit[2] + '-' + moment().month(parseInt(dateSplit[1]) - 1).format('MMM-Y');
    }else{
      return '';
    }
  }

  setDepartureDate():string{
    if(this.itineraryDepartureDate){
      let dateSplit = this.itineraryDepartureDate.split("-");
      return '' + dateSplit[2] + '-' + moment().month(parseInt(dateSplit[1]) - 1).format('MMM-Y');
    }else{
      return '';
    }
  }

  active() {

    if (this.userService.itineraryActive[0].arrivalDate) {
      // this._router.navigate(['/multi-destination']);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.userService.itineraryActive[0].id,
          //'stat': this.status,
          'screen': 'detail'
        }
      };
      this._router.navigate(['/itinerary-detail'], navigationExtras);

    }

  }

  get name() { return this._formGroupItinerary.get('name'); }
  get email() { return this._formGroupItinerary.get('email'); }
  get phone_code() { return this._formGroupItinerary.get('phone_code'); }
  get phone() { return this._formGroupItinerary.get('phone'); }
  get title() { return this._formGroupItinerary.get('title'); }

  agencyId(): number {
    this.agencyID = this.userService._agencyID;
   // console.log (this.agencyID)
    return this.agencyID ? this.agencyID : null;
  }

  getAgencyEmail(): string {
    this.agencyEmail = this.userService._email;
//console.log (this.agencyEmail)
    return this.agencyEmail ? this.agencyEmail : null;
  }

  getCountries(selectClientModal?: boolean, phone_code?: any) {

    this.itineraryService._restService.doGet("api", "/utilities/countries")
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe({
      next: (res: any) => {
        this.countriesData = res.Countrys;
        this.filteredCountries = this.countriesData;

        if(selectClientModal === true) {
          // Inicializa el valor del input de país
          this.filterCountries('');
          const country = this.countriesData.find(c => c.phone_code === phone_code);
          if (country) {
            this._formGroupItinerary.patchValue({
              phone_code: Number(country.phone_code)
            });
          }
        }
      }
    })


  }

  phoneCode(code: any) {
    code = Number(code);
    this._formGroupItinerary.controls['phone_code'].setValue(code);
  }

  filterCountries(searchText: string) {
    if (searchText) {
      this.filteredCountries = this.countriesData.filter(country =>
        country.country_name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredCountries = this.countriesData;
    }
  }

  displayCountry = (country: any) => {
    if(country) {
      if (!this.filteredCountries) {
        console.error('filteredCountries is not defined');
        return '';
      }
      let countryData = this.filteredCountries.find(c => Number(c.phone_code) === Number(country));
      if(country === null) {
        countryData = null;
      }
      return countryData ? `${countryData.country_name} (+${countryData.phone_code})` : '';
    }
  }

  // getPhoneCode(phoneCode: string): string {
  //   console.log(typeof phoneCode, phoneCode)
  //   const match = phoneCode.match(/\(\+\d+\)/);
  //   if (match) {
  //     return match[0];
  //   }
  //   return ''; // Devuelve una cadena vacía si no se encuentra el patrón
  // }

  phoneCodeClient(phoneCode: string): string {
    if(phoneCode) {
      const match = phoneCode.match(/\(\+\d+\)/);
      if (match) {
        return match[0];
       } else {
        return `(+${phoneCode})`;
        }
      }
      return ''; // Devuelve una cadena vacía si no se encuentra el patrón
    }

    filterByclients(page:number, name?:string, emailValue?:string, last_nameValue?:string) {
      let goPage = (page>1) ? '&page='+page : '';

      let first_name = name? '&first_name='+name : '';
      let last_name = last_nameValue? '&last_name='+last_nameValue : '';
      let email = emailValue? '&email='+emailValue : '';

      this.clientsAllSubscription = this.restService.doGet('app', 'clients?&agency_id='+this.userService._agencyID+goPage+"&paginate=1"+first_name+last_name+email+ "&adult=true")
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
          (data: any) => {
            console.log('data paginate ->', data);
            this.clientListAux = data.data;
            this.clientList = this.clientListAux.data;
            this.lastPage = this.clientListAux.last_page;
            this.currentPage = this.clientListAux.current_page;
            this.loading = false;
          },
          error => {
            console.log("error get clients");
          }
        );
      }

}
