import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { setting } from '../../../../setting';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ArticleService } from './article.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'usb-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {

  public getClientsSettingAppAuth: any;
  public routeUploadFile: string;
  public wsp_number: any;
  public articleId: string;
  public typeArticle: string;
  public activeArticle: number;
  public clientId: number;

  public article: any;
  public images: any[] = [];
  public headerArr: any[] = [];

  article1Visible = true;
  article2Visible = false;
  article3Visible = false;

  showChange: boolean = false;

  visibleCount: number = 0;

  loadedVisual: boolean = false;
  galeryImageLoad: boolean = false;

  p:number = 0;

  showScrollToTop: boolean = false;
  appLoading: boolean = true;
  splashFinish: boolean = false;

  private routeSubscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(

              public _router:Router,
              public activatedRoute: ActivatedRoute,
              private articleService: ArticleService,
              private cdr: ChangeDetectorRef
             ) {



              }


  ngOnInit(): void {
    this.article = [];
    this.headerArr = [];
    this.images = [];
    this.routeUploadFile =  setting.routeUploadFile;
    this.getClientsSettingAppAuth =  JSON.parse((localStorage.getItem(setting.name)));
    let wspNumber = this.getClientsSettingAppAuth.wsp_phone_number;

    // Eliminar el signo + y los espacios en blanco
    wspNumber = wspNumber.replace(/\+/g, '').replace(/\s/g, '');

    this.wsp_number = wspNumber;
    this.suscriptionParam();

  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  suscriptionParam(showChange?: boolean, articleId?: number): void {

    this.routeSubscription = this.activatedRoute.params
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(params => {

      this.typeArticle = params['typeArticle'];
      this.articleId = params['id'];
      this.activeArticle = Number(params['active']);
      this.clientId = Number(params['clientId']);

            if(this.showChange === false) {
              if(this.typeArticle === 'small') {
                if(this.article) {
                  this.dataArticle(1, Number(this.articleId), this.clientId);
                }
              }
              if(this.typeArticle === 'big') {
                if(this.article) {
                  this.dataArticle(2, Number(this.articleId), this.clientId);
                }
              }
              if(this.typeArticle === 'segment') {
                if(this.article) {
                  this.dataArticle(3, Number(this.articleId), this.clientId);
                }
              }

            // this.cdr.detectChanges();
            }
            if (showChange === true) {
              if(this.typeArticle === 'small') {
                this.appLoading = true;
                this._router.navigate([`/article/${this.typeArticle}/${this.articleId}/${this.getClientsSettingAppAuth.id}/${articleId}`]);
                setTimeout(() => {
                  this.appLoading = false;
                }, 500);
              }
              if(this.typeArticle === 'big') {
                this.appLoading = true;
                this._router.navigate([`/article/${this.typeArticle}/${this.articleId}/${this.getClientsSettingAppAuth.id}/${articleId}`]);
                setTimeout(() => {
                  this.appLoading = false;
                }, 500);
              }
              if(this.typeArticle === 'segment') {
                this.appLoading = true;
                this._router.navigate([`/article/${this.typeArticle}/${this.articleId}/${this.getClientsSettingAppAuth.id}/${articleId}`]);
                setTimeout(() => {
                  this.appLoading = false;
                }, 500);
              }

            // this.cdr.detectChanges();
            }
          });
  }

  dataArticle(type: number, id: number, clientId: number): void {
    console.log(type)
    console.log(id)
    console.log(clientId)
    if(type && id && clientId) {
      this.articleService.getArticle(type, id, clientId).subscribe({
        next: (res: any) => {
          console.log(res)
          if(res && res.det) {
            this.article = [res.det];
            this.articleMap();
            this.articleDataRender();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
        }
      })
    } else {
      console.log('Error al procesar información de consulta');
    }

  }

  articleMap(): void {
    this.article = this.article.map((art: any) => {
      art.imageHeader = [JSON.parse(art.imageHeader)];
      art.photos = [JSON.parse(art.photos)];
      art.relationated = [JSON.parse(art.relationated)];
      art.text = [JSON.parse(art.text)];
      art.title = [JSON.parse(art.title)];
      art.visible = this.articleId;
      return art
    });
  }

  articleDataRender(): void {
    console.log(this.article)
    const photosObject = this.article[0]?.photos[0];
    this.images = Object.values(photosObject);
    const textArticles = this.article[0]?.text[0];
    this.article[0].text = Object.values(textArticles);
    this.headerArr = this.article[0]?.imageHeader[0];
    console.log(this.headerArr)
    this.findMaxVisibleIndex();
  }

  findMaxVisibleIndex(): void {
    if (this.article && this.article[0] && this.article[0].relationated) {
      this.article[0].maxVisibleIndex = Math.max(...this.article[0].relationated.map((relation, index) => index));
    }
  }

  showArticle(articleId: number): void {
    this.showChange = true;
    this.suscriptionParam(this.showChange, articleId);

      // Desplazarse hacia arriba de la pantalla
      window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  isSecondVisibleElement(index: number): boolean {
    // Resetea el contador de elementos visibles cada vez que se recalcula la vista
    this.visibleCount = 0;

    for (let i = 0; i < this.article[0].relationated[0]?.info.length; i++) {
      if (this.activeArticle !== i) {
        this.visibleCount++;
        if (this.visibleCount === 2 && i === index) {
          return true;
        }
      }
    }
    return false;
  }

  imageCharge(event: any): void {
      this.loadedVisual = true;
      this.appLoading = false;
  }

  navigateHome(): void {
    this._router.navigate(['/home']);
  }

  loadImagenGalery(): void {
    setTimeout(() => {
      this.appLoading = false;
    }, 500);
  }

}
