
<!-- header de rent car con el filtro -->
<div class="header-filtro" *ngIf="this._origin=='RENT-A-CAR'">
  <div class="bg-top m-0 p-0 row justify-content-center">
    <img src="/assets/img/app/rent-car/encabezado.png" class="bg-top-resp" alt="bg-top">
  </div>

  <!-- <div *ngIf="filtersRentacar?.category?.categoryArray.length>0" class="container">
    <div class="row justify-content-center" style="max-height:147px;">
      <div class="MultiCarousel" data-items="1,3,5,6" data-slide="1" id="MultiCarousel" data-interval="1000">
        <div class="MultiCarousel-inner">
          <div *ngFor="let value of filtersRentacar?.category?.categoryArray; let i = index">
            <div class="item">
              <div class="pad15">
                <div class="col mx-1 px-0 filter-car">
                  <div class="content-filter filter-unselected"
                    [ngClass]="{'filter-selected':filtersRentacar.category.checkedArray[i],'filter-selected-none':filtersRentacar.category.categoryCant[i] == 0}">
                    <div class="row m-0 p-0">
                      <div style="width: 100%; background-color: #fff">
                        <img src="{{ filtersRentacar.category.img[i] }} " class="img-fluid" style="width: 100%;">
                      </div>
                      <label>
                        <div class="d-flex">
                          <div class="row m-0 p-0" style="cursor: pointer; width: 100%;line-height: 30px;">
                            <div class="col-0">
                              <input type="checkbox" style="visibility:hidden;" [disabled]="filtersRentacar.category.categoryCant[i] ==0 "
                                class="example-margin" color="primary" [(ngModel)]="filtersRentacar.category.checkedArray[i]"
                                (change)="applyFilter($event, value, 'category', i)">
                            </div>
                            <div class="col-10" style="text-align: right;">
                              <span class="label-mat-checkbox" style="font-size:12px; color: #fff">{{ value }}</span>
                            </div>
                            <div class="col-1"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
        <button class="btn btn-primary leftLst">
          < </button> <button class="btn btn-primary rightLst">>
        </button>



      </div>
    </div>
  </div> -->



  <div class="navbar navbar-expand-xg navbar-expand-lg navbar-expand-md align-items-center top-content" style="margin-top: -55px;">
    <div class="col-xg-12 col-lg-12 col-md-12 col-xs-12">
      <div id="slider-shortcuts" class="carouseller">
        <a href="javascript:void(0)" class="carouseller__left mt-4">&nbsp;</a>
        <div class="carouseller__wrap">
          <div class="carouseller__list">
            <div *ngIf="filtersRentacar?.category?.categoryArray.length>0" style="display: flex">
              <div *ngFor="let value of filtersRentacar?.category?.categoryArray; let i = index">
                <div class="car__2">




                   <div class="col mx-1 px-0 filter-car">
                    <div class="content-filter filter-unselected"
                      [ngClass]="{'filter-selected':filtersRentacar.category.checkedArray[i],'filter-selected-none':filtersRentacar.category.categoryCant[i] == 0}">
                      <div class="row m-0 p-0">
                        <div style="width: 100%; background-color: #fff">
                          <img src="{{ filtersRentacar.category.img[i] }} " class="img-fluid" style="width: 100%;">
                        </div>
                        <label>
                          <div class="d-flex">
                            <div class="row m-0 p-0" style="cursor: pointer; width: 100%;line-height: 30px;">
                              <div class="col-0">
                                <input type="checkbox" style="visibility:hidden;" [disabled]="filtersRentacar.category.categoryCant[i] ==0 "
                                  class="example-margin" color="primary" [(ngModel)]="filtersRentacar.category.checkedArray[i]"
                                  (change)="applyFilter($event, value, 'category', i)">
                              </div>
                              <div class="col-10" style="text-align: right;">
                                <span class="label-mat-checkbox" style="font-size:12px; color: #fff">{{ value }}</span>
                              </div>
                              <div class="col-1"></div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:void(0)" class="carouseller__right mt-4">&nbsp;</a>
        </div>
      </div>
    </div>
  </div>

</div>

<div id="content-result" class="container-fluid">
  <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
  <!-- <div class="row">
    <div id="head-band justify-content-between" class="col-xg-12 col-lg-12 col-md-12 col-sm-12"
      *ngIf='_origin == "MULTIDESTINATION"'>
      <usb-head-band></usb-head-band>
    </div>
  </div> -->

  <div class="row">
    <div class="col-12">
      <!-- CINTILLO DE VUELO -->
      <usb-head-band></usb-head-band>
    </div>

    <div id="main-content" class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div id="category-result-component" class="d-flex flex-column" style="width: calc(100% - 347px);">

          <!-- <div id="tabs" class="col-12" *ngIf='_origin == "MULTIDESTINATION"'>

              <div class="row">

                <div class="col-3 tab">
                  <button class="btn" [class.active]="_tabSelected === 'HOTELS'" (click)="_redirectTo('HOTELS');">HOTELS</button>
                </div>
                <div class="col-3 tab">
                 <button  class="btn" [class.active]="_tabSelected === 'TRANSPORTS'" (click)="_goTo('TRANSPORTS');openModalWithClass(templateRooms);">TRANSPORTS</button>

                  <ng-container *ngIf="!multiModal else noMulti">
                    <button class="btn" [class.active]="_tabSelected === 'TRANSPORTS'"
                      (click)="_goTo('TRANSPORTS');openModalWithClass(templateRooms);">TRANSPORTS</button>
                  </ng-container>
                  <ng-template #noMulti>
                    <button class="btn" [class.active]="_tabSelected === 'TRANSPORTS'"
                      (click)="_redirectTo('TRANSPORTS');">TRANSPORTS</button>
                  </ng-template>
                </div>
                <div class="col-3 tab">
                  <button class="btn" [class.active]="_tabSelected === 'TOURS'" (click)="_redirectTo('TOURS');">TOURS</button>
                </div>

                <div class="col-3 tab">
                  <button class="btn" [class.active]="_tabSelected === 'SUMMARY'" (click)="_redirectTo('SUMMARY');">SUMMARY</button>
                </div>

              </div>
            </div> -->

          <!--  <usb-search-field (textEmitter)="textEmitter($event)" class="row"></usb-search-field> -->


          <!--  <div class="filters row">
             <div class="col-12">
               <div class="row">
                   <div class="col-lg-12 col-xg-12 col-md-10 col-sm-9 filter">
                       <div class="row justify-content-center">
                         <div class=" col-md-1 col-sm-3">
                           <a href="#">Highlights</a>
                         </div>
                         <div class="col-md-2 col-sm-3">
                           <a href="#">Lowest Price</a>
                         </div>
                         <div class="col-md-2 col-sm-3">
                           <a href="#">Best Location</a>
                         </div>
                         <div class="col-md-2 col-sm-3">
                           <a href="#">Best Commision</a>
                         </div>
                         <div class="col-md-1 col-sm-3">
                           <a href="#">Stars</a>
                         </div>
                         <div class="col-lg-2 col-md-2 col-sm-3">
                           <a href="#">Best Guests</a>
                         </div>
                         <div class="col-lg-1 col-md-2 col-sm-3">
                           <a href="#">With Pool</a>
                         </div>
                       </div>
                     </div>

               <div class="col-lg-1 col-md-2 col-sm-3">
                   <button type="button" class="btn btn-gray py-2">
                     <i class="fa fa-plus"></i>
                   </button>
                 </div>
               </div>
             </div>
           </div>-->

          <ng-container *ngIf="_results.length > 0 && _results!=undefined  else noResultsFound">
            <div *ngFor="let result of _results; let i = index" class="single-result">
              <usb-category-result (arrayOut)="updateArray($event)" [payload]="getPayloadCategory(result?.categoryName, i)"
                [results]="result" [origin]="_origin" [tabSelected]="_tabSelected" [resultPaged]="_resultPaged"
                [categoryName]="result?.categoryName" (isLoading)="_onLoading($event)" (_filtersRentacar)="_filtersRentacar($event)"
                [_multiModal]="multiModal" (isMultiModal)="_onMultimodal($event)" [_applyFilter]="_applyFilter" [_pax]="pax"
                [_payloadForm]="payloadForm" (payload_)="loadPaylod($event)" [deleteProduct]="deleteProduct" [_paxAdults]="paxAdults" [_paxChildren]="paxChildren"
                (emitReSearch)="_emitReSearch($event)" [index]="i" (emitViewMap)="viewMap=$event">
              </usb-category-result>
            </div>
          </ng-container>
          <ng-template #noResultsFound>
            <div class="col-12 pt-10 text-center">
              <!-- <h4>No results found for this search</h4> -->
            </div>
          </ng-template>

        </div>
        <div id="left-sidebar" class="d-flex flex-column" style="width: 347px;">
          <div class="row" id="itinerary-component">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <usb-itinerary-sidebar [results]="_results" [payload]="_payload" [modeView]="'RESULT'" [origin]="_origin"
                (changeTabEmitter)="changeTab($event)" (productDeleteEmitter)="productDeleteEmitter($event)">
              </usb-itinerary-sidebar>
            </div>
          </div>
          <div class="row" id="visited-component" *ngIf="!viewMap">
            <div class="col-xg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <usb-visited></usb-visited>
            </div>
          </div>
        </div><!-- left-sidebar -->
      </div>
    </div><!-- /main-content -->
  </div>
</div>


<ng-template #templateRooms>
  <div *ngIf="multiModal">
    <ng-container *ngFor="let result of _results; let index = index" class="single-result">
      <div style="background-color: #032541 !important; color: #fff !important; height:44px">
        <button id="bt" class="btn btn-link btn-block text-white clearfix" (click)="isCollapsed[index] = !isCollapsed[index]"
          [attr.aria-expanded]="!isCollapsed[index]" [attr.aria-controls]="'collapseViewMore-' + index" accordion-heading>
          <div class="pull-left float-left">{{getPayloadCategory(result?.categoryName, index).destination}}</div>
          <div id="{{ 'check-p' +index }}" class="badge badge-danger float-right pull-right">pending</div>
          <div id="{{ 'check-r' +index }}" class="badge badge-info float-right pull-right" style="display:none;">ready
          </div>
          <i style="color:white" class="pull-right float-xs-right glyphicon"
            [ngClass]="{'glyphicon-chevron-down': group?.isOpen, 'glyphicon-chevron-right': !group?.isOpen}"></i>
        </button>
      </div>

      <!-- <div [id]="'collapseViewMore-' + index" [ngbCollapse]="isCollapsed[index]">
        <usb-modals-search bsModal #ModalRooms="bs-modal" tabindex="-1" [_index]="index" role="dialog"
          aria-hidden="true" [modalRoomRef]="ModalRooms"
          [_ori]="getPayloadCategory(result?.categoryName, index).destination" [origin]="tabSelected"

          [cityIataCode]="getPayloadCategory(result?.categoryName, index).destination"

          (emitIndex)="reloadIndex($event,index)" (emitReload)="reloadSearch($event, index)">
        </usb-modals-search>
        [__multiModal]=_multiModal
         [__payload]="getPayloadCategory(result?.categoryName, index)"

      </div> -->

    </ng-container>
    <div class="flex-row py-3 px-2 ">
      <button type="button" class="pull-right btn btn-sm btn-usblick" (click)="_redirectTo('TRANSPORTS');">Continuar</button>
    </div>
    <br>
  </div>
</ng-template>
