
<div>
<div class="position-relative" usbScrollToTopDirective [showAfter]="100">
  <button *ngIf="splashFinish" type="button" class="btn-home px-2 bg-white" (click)="navigateHome()"><i class="bi bi-house-door-fill mr-1"></i>IR AL INICIO</button>


<!-- Articulos pequeños del home -->
<div
      usbLoadingSplash
      [appLoading]="appLoading"
      [fullscreen]="true"
      [component]="'article'">
<!-- <div
> -->
  <img *ngIf="headerArr"
        src="{{routeUploadFile + headerArr['photo' + (activeArticle + 1)]}}"
       class="img-header"
       alt="Header"
       (load)="imageCharge($event)">
</div>
<ng-container *ngIf="loadedVisual">
  <article class="article-main" >
    <ng-container *ngIf="article && article[0]">
      <ng-container *ngFor="let text of article[0].title[0]?.info; let i = index">
        <ng-container *ngIf="activeArticle === i">
          <h1 class="article-main_title">{{text.title}}</h1>
        </ng-container>
      </ng-container>
    </ng-container>
      <div class="article-main_body">
        <ng-container *ngFor="let text of article[0].title[0]?.info; let i = index">
          <ng-container *ngIf="activeArticle === i">
            <h2 class="article-main_copy" style="text-align: center; font-size: 22px !important"><b>{{text.description}}</b>
            </h2>
          </ng-container>
        </ng-container>
        <br>
        <ng-container *ngFor="let text of article[0].text; let i = index">
          <ng-container *ngIf="activeArticle === i">
            <ng-container *ngIf="typeArticle === 'small'">
              <div class="article-main_content" [innerHTML]="text"></div>
            </ng-container>
            <ng-container *ngIf="typeArticle === 'big'">
              <div class="article-main_content" [innerHTML]="text"></div>
            </ng-container>
            <ng-container *ngIf="typeArticle === 'segment'">
              <div class="article-main_content" [innerHTML]="text"></div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
  </article>

<!-- Articulos destacados del home -->


  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->
    <div class="d-flex flex-column justify-content-center" >

        <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }; let i = index"
              >
          <img src="{{routeUploadFile + image}}" class="img-fluid" alt="img" loading="lazy" (load)="loadImagenGalery()">

        <div class="d-flex justify-content-end" style="margin-top: 22px">
          <pagination-controls previousLabel=""
          nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
    </div>

  </section>

  <!---------------------------------------  RELACIONADOS  --------------------------------------------->
  <ng-container *ngIf="article[0].relationated[0]?.info && article[0].relationated[0]?.info.length > 0">
  <section class="section-related">
    <p class="section-article_title">artículos relacionados</p>

  <div class="d-flex">


      <ng-container *ngFor="let relation of article[0].relationated[0]?.info; let i = index; let last = last">
        <ng-container *ngIf="activeArticle !== i">
          <div class="article-related-grid">
            <div class="article-card " [ngClass]="{'card-black': isSecondVisibleElement(i)}">
            <figure class="article-card_figure">
              <img src="{{routeUploadFile + relation.image}}" loading="lazy" alt="{{relation.title}}">
            </figure>
            <div class="article-card_body ">
              <div class="d-flex flex-column" id="style-card-text">
                <h3 class="article-card_subtitle">
                  Recomendaciones
                </h3>
                <h2 class="article-card_title" style="letter-spacing: 0px; line-height: 1em;">
                  {{relation.title}}
                </h2>
                <p class="article-card_copy">
                  {{relation.subtitle}}
                </p>
                <!-- <a href="" class="article-card_link">view</a> -->
                <button class="article-relation" (click)="showArticle(i)"
                  [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}"
                  style="margin-bottom:15px;">ver</button>
              </div>
            </div>
          </div>
          </div>
          </ng-container>
        </ng-container>
      </div>
    </section>
  </ng-container>
</ng-container>
</div>
</div>
