<div class="porta-sub-inst-seach py-6" id="porta-sub-inst-seach">
  <div class="container-fluid pl-5 pr-5">
    <div class="row py-5">
      <ng-container *ngFor="let article of articleSmallArr; let i = index;">
        <div class=" col-md-4 col-sm-12 col-12  text-center p-3" id="image-to-cover">
          <div class="image-position position-relative porta-sub-inst-seach-imag">
            <span class="image-container bd-placeholder-img card-img-top position-relative">
              <!-- [lazyImage]="routeUploadFile + article.image" -->

              <img
                      usbLazyLoadImage
                      loading="lazy"
                      [lazyImage]="routeUploadFile + article.image"
                      class="bd-placeholder-img card-img-top"
                      alt="{{ article.title }}">
                        <div class="placeholder position-absolute"
                              [ngStyle]="{'background-color':clientSettingGlobal?.color_main_1}"
                              role="status"
                              style=" top: 50%; left: 50%; transform: translate(-50%, -50%);">
                        </div>
              <div class="capa" [ngStyle]="{'background':clientSettingGlobal?.color_main_3}"></div>
            </span>
            <div class="porta-sub-inst-seach-box text-center">
              <ul>
                <li>
                  <img
                        loading="lazy"
                        src="{{ routeUploadFile+article.logo }}"
                        class="img-fluid"
                        alt="logo">
                </li>
              </ul>
            </div>
            <h5 class="mb-1 text-left pt-4 px-md-3 text-center text-uppercase">{{ article.title }}</h5>
            <h6 class="mb-1 text-left mb-6 px-md-3 text-center">{{ article.subtitle }}</h6>
            <hr>
            <p class="pb-5 pl-4 pr-4">{{ article.description }}</p>

          </div>
          <div class="text-center">
              <button
                      class="btn rounded-pill m-3"
                      style="color:black; height: 55px;  color: white"
                      [ngStyle]="{'background-color': clientSettingGlobal?.color_main_1}"
                      (click)="articleRedirect(i + 1)"
              >
                ver
              </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

  <!-- <div class="porta-sub-inst-seach pb-5">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <button class="boton"
            [ngStyle]="{'color':clientSettingGlobal?.color_main_1, 'border':  '1px solid' + clientSettingGlobal?.color_main_1}">
            VER +
          </button>
        </div>
      </div>
    </div>
  </div> -->
