import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//import { ToastsManager } from 'ng2-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FidelityService } from '../../../fidelity/fidelity.service';
import { AgentsService } from '../../../agents/agents.service';
import { UserService } from '../../../pages/com.usblick.common/auth/user.service';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { RestService } from '../../rest.service';
import { element } from 'protractor';
import { Console } from 'console';

import { NgxSpinnerService } from 'ngx-spinner';
import { setting } from '../../../../../setting';
import { HttpErrorResponse } from '@angular/common/http';
import { ItineraryService } from 'app/shared/itinerary.service';


@Component({
  selector: 'usb-payment-items',
  templateUrl: './payment-items.component.html',
  styleUrls: ['./payment-items.component.scss']
})
export class PaymentItemsComponent implements OnInit {

  public payPalConfig?: IPayPalConfig;

  @Input() items: any;
  @Input() totalPrice: any;
  @Input() paymentMethodsArr: any[] = [];
  @Input() creditCardPending: boolean = false;
  @Input() creditCardDataPending: any[] = [];
  @Input() creditCardPayPartial: boolean = false;
  @Input() currencyItinerary: string;
  @Input() itineraryItems: any[] = [];
  @Output() leaveTotal = new EventEmitter<any>();

  @ViewChild("inputPoints") inputPoints: HTMLInputElement;
  @ViewChild("inputCredits") inputCredits: HTMLInputElement;
  @ViewChild("inputTranfer") inputTranfer: HTMLInputElement;
  @ViewChild("inputCard") inputCard: HTMLInputElement;

  @ViewChild("icons-caret") iconsCaret: ElementRef;

  agency: any;
  maxDateAdult: any;

  isPointsSelected: boolean = false;
  isDepositSelected: boolean = false;
  isTranferSelected: boolean = false;
  isCardSelected: boolean = false;
  isCreditSelected: boolean = false;
  isSelectModePay: boolean = false;
  onApprove: boolean = false;
  amountPoints: any = null;
  amountDeposit: any = null;
  amountTranfer: any = null;
  amountCard: any = null;
  amountCredit: any = null;

  errorPoints: boolean = false;
  errorDeposit: boolean = false;
  errorTranfer: boolean = true;
  errorCard: boolean = true;
  errorCredit: boolean = false;
  totalPayCreditCard: boolean = false;

  creditBlickoinsActives: any = 0;
  creditDepositActives: any = 0;
  creditsActives: any = 0;

  leftTotal: any = 0;

  filesArray: any = [];
  fileName: any = null;
  payload:  any = [];
  payloadDetails:  any = [];
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  filter: any = '';

  currentPage: number = 1;
  paymentConditions: [] = [];
  paymentMethods: any = [];
  paymentCreditCards: any = [];


  totalPaymentCreditCards: any = 0;
  // Variable de almacenamiento de monto pendiente de pagos con tarjeta de credito pendiente
  amountCreditCard: number = 0;
  amountCreditCardPayPartial: any = 0;
  partialLeft: number = 0;

  public errorImageDimension: boolean = false;
  public errorImageSize: boolean = false;
  public errorImageFormat: boolean = false;

  getClientsSettingAppBooking: any;

  public blickoinsPay: boolean = false;
  public creditCardPay: boolean = false;
  public creditDepositPay: boolean = false;
  public transferPay: boolean = false;
  public creditPay: boolean = false;
  public cardPayment: boolean = false;

  constructor(
    public fidelityService: FidelityService,
    public vcr: ViewContainerRef,
    public agentsService: AgentsService,
    public userService: UserService,
    private _restService: RestService,
    public ngSpinner: NgxSpinnerService,
    private itineraryService: ItineraryService
  ) {
    this.dpConfig.containerClass = 'theme-red';
  }

  ngOnInit() {
    //this.toastr.setRootViewContainerRef(this.vcr);
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.initConfig();
    this.getPaymentConditions(this.currentPage);
    console.log(this.creditCardDataPending)
   // this.filter  =  'itinerary_id=' + this.items[0].itinerary_id + '&&payment_masters_id=0';
   if(!this.creditCardPending && this.creditCardPayPartial) {
     this.isCardSelected = true;
    this.verifyPaymentMethod(2);

    console.log('1')
       if(this.isCardSelected) {

         this.filter  =  'itinerary_id=' + this.creditCardDataPending[0].itinerary_id;
         let creditCardPartialNull = this.creditCardDataPending.filter(c => c.payment_masters_id === null);
         console.log(creditCardPartialNull)
        // Crear un conjunto para almacenar los IDs de los elementos en creditCardPartialNull
        const paymentIds = new Set<number>();
        creditCardPartialNull.forEach(payment => {
            paymentIds.add(Number(payment.details[0].uid));
        });

        // Sumar los valores solo para los elementos en this.itineraryItems que tienen un ID coincidente en paymentIds
        this.itineraryItems.forEach(item => {
            if (paymentIds.has(item.id)) {
                this.partialLeft += item.optionNightsNetTotalProf;
            }
        });

  console.log(this.partialLeft)
        //  this.paymentMethods = creditCardPartialNull
         this.amountCreditCardPayPartial = this.amountSumCreditCardPending(creditCardPartialNull);
         this.totalPrice = this.partialLeft;
        }
   }
   if(this.creditCardDataPending.length > 0 && !this.creditCardPayPartial) {
     this.isCardSelected = true;
    this.verifyPaymentMethod(2);
    console.log('2')
    if(this.isCardSelected) {
         this.filter  =  'itinerary_id=' + this.creditCardDataPending[0].itinerary_id;
         this.amountCreditCard = this.amountSumCreditCardPending(this.creditCardDataPending);
    }


   } if(!this.creditCardPending && !this.creditCardPayPartial) {
    console.log('3')
     this.filter  =  'itinerary_id=' + this.items[0].itinerary_id;
   }
   this.agency = JSON.parse(localStorage.getItem('selectedAgency'));
    if (this.userService.balanceBlickoins > 0) {
      this.creditBlickoinsActives = this.userService.getBlickoinsCredits(this.userService.balanceBlickoins);
    }
    console.log(this.agency)

    this.creditDepositActives = this.userService.creditsDeposits;
    this.creditsActives = this.userService.credits;
    this.totalPrice = Math.round(this.totalPrice * 100) / 100;
    this.leftTotal = this.totalPrice;

    this.getPaymentCreditCards(this.filter);

console.log(this.totalPrice)
console.log(this.paymentMethodsArr)
  }

  private amountSumCreditCardPending(paymentCreditCards: any) {
    let amount: number = 0;
     // Recorrer el array de paymentCreditCards con el fin de sumar los montos de amount para el monto total
      // de pagos pendientes
    paymentCreditCards.forEach((payment: any) => {
      amount += payment.amount;

    });

    return amount;
  }

  getPaymentConditions(page: number) {
    const goPage = (page > 1) ? 'page=' + page : '';
    const url = 'agencyPaymentCondition/list?' + goPage;
    this._restService.doGet('app', url).subscribe({
      next: (response: any) => {
        this.paymentConditions = response;
        this.availablePaymentConditions(this.paymentConditions);
      },
      error: (error: HttpErrorResponse) => {
        console.log('No existe informacion, Condición de Pagos!', error);
      }
    })
  }

  // Habilitar los métodos de pago activdos en la agencia
  private availablePaymentConditions(dataPaymentCondition: any) {
    const payAgencyActive = dataPaymentCondition.find(c => c.id === this.agency.pay_mode);
    payAgencyActive.payment_methods_conditions.forEach(payment => {
      if(this.creditCardDataPending.length === 0 || this.creditCardPayPartial) {
        this.blickoinsPay = this.blickoinsPay || payment.payment_methods_id === 1;
        this.creditCardPay = this.creditCardPay || payment.payment_methods_id === 2;
        this.creditDepositPay = this.creditDepositPay || payment.payment_methods_id === 3;
        this.transferPay = this.transferPay || payment.payment_methods_id === 4;
        this.creditPay = this.creditPay || payment.payment_methods_id === 5;
      }
      if(this.creditCardDataPending.length > 0 && !this.creditCardPayPartial) {
        this.creditCardPay = this.creditCardPay || payment.payment_methods_id === 2;
      }
    });
  }

  calculateLeftTotal() {
      // this.paymentMethods = [];
      let aux: number;
      aux = this.totalPrice;

    if (this.amountPoints > 0) {
      aux -= this.amountPoints;

      //validacion de errores
      if ((this.amountPoints > this.creditBlickoinsActives) || (this.amountPoints > this.totalPrice) || (this.amountPoints < 0)) {
        this.errorPoints = true;
      } else {
        this.errorPoints = false;
      }
    }
    if (this.amountDeposit > 0) {
        aux -= this.amountDeposit;


       //validacion de errores
       if ((this.amountDeposit > this.creditDepositActives) || (this.amountDeposit > this.totalPrice) || (this.amountDeposit < 0)) {
        this.errorDeposit = true;
      } else {
        this.errorDeposit = false;
      }
    }
    if (this.amountTranfer > 0) {
      aux -= this.amountTranfer;

        //validacion de errores
      if (this.amountTranfer < 0 || this.fileName == null) {
        this.errorTranfer = true;
      } else {
        this.errorTranfer = false;
      }
    }
    if (this.amountCard > 0 || this.isCardSelected) {
      aux -= this.amountCard;

      // validacion de errores  // ||  this.leftTotal > 0
      console.log(this.amountCard)
      console.log(this.totalPaymentCreditCards)
       if (this.amountCard < 0  || this.totalPaymentCreditCards == 0) {
        this.errorCard = true;
      } else {
        this.errorCard = false;
      }
      if(this.totalPaymentCreditCards === this.totalPrice) {
        this.errorCard = false;
      }
    }
    if (this.isCreditSelected) {
      aux -= this.amountCredit;

      //validacion de errores
      if ((this.amountCredit > this.creditsActives) || (this.amountCredit > this.totalPrice) || (this.amountCredit < 0)) {
        this.errorCredit = true;
      } else {
        this.errorCredit = false;
      }
    }

        // Validamos si aux es menor que cero, lo que significa que el monto total restante sería negativo
        if (aux < 0) {
          aux = 0; // Si es así, lo establecemos como cero para evitar valores negativos
      }

    console.log("this.leftTotal con posible error", this.leftTotal);
    console.log("totalPaymentCreditCards", this.totalPaymentCreditCards);

    // Actualiza la información de paymentMethods
    this.updatePaymentMethods();

    this.leftTotal = aux.toFixed(2);


    if (this.leftTotal == -0.00) {
      this.leftTotal = 0;
    }


    if  (this.totalPaymentCreditCards > 0)   {  // se descuemta los pagos por TDC
        this.leftTotal =  this.leftTotal - this.totalPaymentCreditCards;
        if(this.leftTotal === 0 && !this.creditCardPayPartial) {
          this.totalPayCreditCard = true;
        }
      }

      console.log("this.leftTotal arreglado", this.leftTotal);

    let data: any = {};


    if(this.creditCardDataPending.length > 0 && !this.creditCardPayPartial) {
      data = {
        aux: 0,
        paymentMethods: this.paymentMethods
      }
    }
    if(this.creditCardDataPending.length > 0 && this.creditCardPayPartial) {

      data = {
        aux: parseFloat(this.leftTotal),
        paymentMethods: this.paymentMethods
      }
    }
      if(this.creditCardDataPending.length === 0) {
        data = {
          aux: parseFloat(this.leftTotal),
          paymentMethods: this.paymentMethods
        };
      }

    console.log("emit leaveTotal", data);
    this.leaveTotal.emit(data);
  }

  private updatePaymentMethods() {
    if (this.isPointsSelected) {
      this.updatePaymentMethod(1, this.amountPoints, this.errorPoints);
    }

    // Si hay pagos con depósito
    if (this.isDepositSelected) {
      this.updatePaymentMethod(3, this.amountDeposit, this.errorDeposit);
    }

    // Si hay pagos con transferencia
    if (this.isTranferSelected) {
      this.updatePaymentMethod(4, this.amountTranfer, this.errorTranfer);
    }

    // Si hay pagos con tarjeta de crédito
    if (this.isCardSelected) {
      if(this.creditCardDataPending.length > 0 && !this.creditCardPayPartial) {
        this.errorCard = false;
        this.amountCard = this.creditCardDataPending[0].amount;
        if(this.leftTotal === 0) {
          this.creditCardPending = true;
        }
        this.updatePaymentMethod(2, this.amountCard, this.errorCard);
      }
      if(this.creditCardDataPending.length > 0 && this.creditCardPayPartial) {
        this.errorCard = this.amountCard > 0 ? true : false;
        const amountCard = this.totalPaymentCreditCards
        this.updatePaymentMethod(2, amountCard, this.errorCard);
      }
      if(this.creditCardDataPending.length === 0) {
        const amountCard = this.totalPaymentCreditCards;
        this.updatePaymentMethod(2, amountCard, this.errorCard);
      }
    }

    // Si hay pagos con crédito
    if (this.isCreditSelected) {
      this.updatePaymentMethod(5, this.amountCredit, this.errorCredit);
    }
  }

  private updatePaymentMethod(id: number, amount: number, error: boolean) {
    // Método para actualizar un método de pago en la lista paymentMethods
    let itemFound = this.paymentMethods.find(element => element.id == id);
      if (itemFound) {
        let index = this.paymentMethods.indexOf(itemFound);
        if (index > -1) {
          this.paymentMethods.splice(index, 1);
          if(id === 4) {
            this.paymentMethods.push({
              id: id,
              amount: amount,
              error: error,
              filesArray: this.filesArray
            });
          } else {
            this.paymentMethods.push({
              id: id,
              amount: amount,
              error: error
            });
          }
        }
      } else {
        if(id === 4) {
          this.paymentMethods.push({
            id: id,
            amount: amount,
            error: error,
            filesArray: this.filesArray
          });
        } else {
          this.paymentMethods.push({
            id: id,
            amount: amount,
            error: error
          });
        }
      }
  }

  addPhoto(event) {
    this.errorImageDimension = false;
    this.errorImageFormat = false;
    this.errorTranfer = false;
    console.log("file event", event);

    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2 MB
    const maxWidth = 1400; // Ancho máximo en píxeles
    const maxHeight = 2100; // Alto máximo en píxeles

    if (file.size <= maxSize) {
      if (this.validateJpg(file.name)) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= maxWidth && img.height <= maxHeight) {
            this.filesArray.push({
              name: file.name,
              file: file,
              url: null
            });

            this.getURL(file, this.filesArray.length - 1);

            console.log("filesArray ", this.filesArray);

            this.fileName = file.name;
            console.log(this.fileName);

            this.errorTranfer = false;
            this.calculateLeftTotal();
          } else {
            console.log("error con dimensiones");
            //this.toastr.error("Image dimensions should be within A4 size", 'Warning!!');
            this.errorTranfer = true;
            this.errorImageFormat = false;
            this.errorImageSize = false;
            this.errorImageDimension = true;
            this.calculateLeftTotal();
          }
        };
      } else {
        console.log("error con formato");
        //this.toastr.error("Verify file format", 'Warning!!');
        this.errorTranfer = true;
        this.errorImageDimension = false;
        this.errorImageSize = false;
        this.errorImageFormat = true;
        this.calculateLeftTotal();
      }
    } else {
      console.log("error con size");
      //this.toastr.error("Verify Size not exceed 2MB", 'Warning!!');
      this.errorTranfer = true;
      this.errorImageDimension = false;
      this.errorImageFormat = false;
      this.errorImageSize = true;
      this.calculateLeftTotal();
    }
  }

  getURL(file, index) {
    var reader = new FileReader();
    let url;

    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      console.log("event ", event)
      this.filesArray[index].url = event.target.result;
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };
  }

  pointsChange() {
    // this.iconsCaret.nativeElement.style.background = 'black';
    this.isTranferSelected = false;
    this.isDepositSelected = false;
    this.isCardSelected = false;
    this.isPointsSelected=!this.isPointsSelected
    if (!this.isPointsSelected) {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountPoints = this.paymentMethodsArr.find(c => c.id === 1);
        console.log(objAmountPoints);
        if(objAmountPoints) {
          this.amountPoints = objAmountPoints.amount;
          this.calculateLeftTotal();
        }
      } else {
        this.amountPoints = 0;
        this.calculateLeftTotal();
      }
    } else {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountPoints = this.paymentMethodsArr.find(c => c.id === 1);
        console.log(objAmountPoints);
        if(objAmountPoints) {
          this.amountPoints = objAmountPoints.amount;
          this.calculateLeftTotal();
        }
      }
      setTimeout(() => {
        if(this.creditBlickoinsActives > 0) {
          this.inputCredits.focus;
        }
      }, 500);
    }
  }

  creditChange() {
    this.isTranferSelected = false;
    this.isCardSelected  = false;
    this.isPointsSelected = false;
    this.isCreditSelected = false;
    this.isDepositSelected=!this.isDepositSelected
    if (!this.isDepositSelected) {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountDeposit = this.paymentMethodsArr.find(c => c.id === 3);
        console.log(objAmountDeposit);
        if(objAmountDeposit) {
          this.amountDeposit = objAmountDeposit.amount;
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          console.log(this.leftTotal)
        }
      } else {
        this.amountDeposit = 0;
        this.calculateLeftTotal();
      }
    } else {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountDeposit = this.paymentMethodsArr.find(c => c.id === 3);
        console.log(objAmountDeposit);
        if(objAmountDeposit) {
          this.amountDeposit = objAmountDeposit.amount;
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          console.log(this.leftTotal)
        } else {
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          console.log(this.leftTotal)
        }
      }
      setTimeout(() => {
        if(this.creditDepositActives > 0) {
          this.inputCredits.focus;
        }
      }, 500);
    }
  }

  tranferChange() {
    console.log(this.paymentMethodsArr)
    this.isPointsSelected = false;
    this.isCreditSelected = false;
    this.isCardSelected = false;
    this.isDepositSelected = false;
    this.isTranferSelected=!this.isTranferSelected
    if (!this.isTranferSelected) {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountTransfer = this.paymentMethodsArr.find(c => c.id === 4);
        console.log(objAmountTransfer);
        if(objAmountTransfer) {
          this.amountTranfer = objAmountTransfer.amount;
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          // this.calculateLeftTotal();
          console.log(this.leftTotal)
        }
      } else {
        this.amountTranfer = 0;
        this.calculateLeftTotal();
      }
      // this.fileName = null;
    } else {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountTransfer = this.paymentMethodsArr.find(c => c.id === 4);
        console.log(objAmountTransfer);
        if(objAmountTransfer) {
          this.amountTranfer = objAmountTransfer.amount;
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          // this.calculateLeftTotal();
          console.log(this.leftTotal)
        }
        else {
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          // this.calculateLeftTotal();
          console.log(this.leftTotal)
        }
      }
      setTimeout(() => {
        this.inputTranfer.focus;
      }, 500);
    }
  }

  cardChange() {
    console.log(this.paymentMethodsArr)
    this.isPointsSelected = false;
    this.isCreditSelected = false;
    this.isTranferSelected = false;
    this.isDepositSelected = false;
    this.isCardSelected=!this.isCardSelected
    if (!this.isCardSelected) {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountCard = this.paymentMethodsArr.find(c => c.id === 2);
        console.log(objAmountCard);
        if(objAmountCard) {
          this.amountCard = objAmountCard.amount;
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          console.log(this.leftTotal)
          // this.calculateLeftTotal();
        }
      } else {
        this.amountCard = 0;
        this.calculateLeftTotal();
      }

    } else {
      if(this.paymentMethodsArr.length > 0) {
        const objAmountCard = this.paymentMethodsArr.find(c => c.id === 2);
        console.log(objAmountCard);
        if(objAmountCard) {
          this.amountCard = objAmountCard.amount;
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          if(this.amountCard === this.amountCreditCardPayPartial) {
            this.amountCard = null;
          }
          console.log(this.leftTotal)
          // this.calculateLeftTotal();
        }
        else {
          this.leftTotal = this.totalPrice - this.fixAmountLeftTotal();
          console.log(this.leftTotal)
        }
      }
      setTimeout(() => {
        this.inputCard.focus;
      }, 500);
    }
  }

  pointsCreditChange() {
    this.isPointsSelected = false;
    this.isDepositSelected = false;
    this.isTranferSelected = false;
    this.isCardSelected = false;
    this.isCreditSelected=!this.isCreditSelected
    if (!this.isPointsSelected) {
      this.amountPoints = 0;
      this.calculateLeftTotal();
    } else {
      setTimeout(() => {
        this.inputPoints.focus;
      }, 500);
    }
  }

  fixAmountLeftTotal() {
    console.log('inicio')
    // Usar reduce para sumar los valores de 'amount'
    const totalAmount = this.paymentMethods.reduce((accumulator, currentValue) => {
        // Asegurarse de que 'currentValue' tenga la propiedad 'amount'
        if (currentValue.hasOwnProperty('amount')) {
          console.log('sumando')
          console.log(currentValue);
          if(currentValue.amount < 0) {
            currentValue.amount = 0;
          }
          return accumulator + currentValue.amount;
        } else {
            return accumulator;
        }
    }, 0);

    return totalAmount;
}

  payComplete(model) {

    if (this.leftTotal == 0) {
      switch (model) {
        case 1:
          this.amountPoints = 0;
          // this.amountDeposit = 0;
          // this.amountTranfer = 0;
          // this.amountCard = 0;
          break;

        case 2:
          this.amountDeposit = 0;
          // this.amountPoints = 0;
          // this.amountTranfer = 0;
          // this.amountCard = 0;
          break;

        case 3:
          this.amountTranfer = 0;
          // this.amountPoints = 0;
          // this.amountDeposit = 0;
          // this.amountCard = 0;

          break;

        case 4:
          this.amountCard = 0;
          // this.amountPoints = 0;
          // this.amountDeposit = 0;
          // this.amountTranfer = 0;
          break;

        default:
          break;
      }
    } else {
      switch (model) {
        case 1:
          this.amountPoints = this.leftTotal;
          // this.amountDeposit = 0;
          // this.amountTranfer = 0;
          // this.amountCard = 0;
          break;

        case 2:
          this.amountDeposit = this.leftTotal;
          // this.amountPoints = 0;
          // this.amountTranfer = 0;
          // this.amountCard = 0;
          break;

        case 3:
          this.amountTranfer = this.leftTotal;
          // this.amountPoints = 0;
          // this.amountDeposit = 0;
          // this.amountCard = 0;

          break;

        case 4:
          this.amountCard = this.leftTotal;
          // this.amountPoints = 0;
          // this.amountDeposit = 0;
          // this.amountTranfer = 0;
          break;

        default:
          break;
      }
    }
    this.calculateLeftTotal();
  }

  removeIMG(i) {
    console.log("i ", i);
    this.filesArray.splice(i, 1);
    console.log("remove this.filesArray ", this.filesArray);
  }

  verifyPaymentMethod(id: any) {

      // tslint:disable-next-line:max-line-length
      const result = this.itineraryService.paymentConditions.payment_methods_conditions.find(element => parseInt(element.payment_methods_id) == id);
   //  console.log("result", result);
    if (result) {
      return true;
    } else {
      /* this.agentsService.agencyPaymentConditionByID(this.agency.pay_mode).subscribe(
        (response: any) => {
          this.agentsService.paymentConditions = response.payment_methods_conditions;
          console.log("this.agentsService.paymentConditions", this.agentsService.paymentConditions);
        }
        ); */
        return false;
      }

  }

  selectModePay() {
   //this.initConfig();
   this.isSelectModePay = true;
  }

  cancelPayPaypal() {
    this.isSelectModePay = false;
  }

  // tslint:disable-next-line:member-ordering
  public savePaymetCreditsCards(payload: any): void {

    this._restService.doPost('app', 'paymentCreditCards/create', payload).subscribe(
      (response: any) => {
        console.log("responser transaction master", response);
        if (response.success) {
            console.log("pago con tarjeta d credito satisfactorio");

           this.getPaymentCreditCards(this.filter);
         /*  setTimeout(() => {
              this.getPaymentCreditCards(this.filter);
            }, 8000);*/

        } else {
          console.log("error al rgistrar el pago con tarjeta d credito");
        }
      },
      error=>{
        console.log("error");
      }
    )

  }


  public getPaymentCreditCards(filter: any): void {

      const url = 'paymentCreditCards/listPending?' + filter;
      this._restService.doGet('app', url).subscribe((response: any) => {
        if (response.data.data.length > 0) {
          this.totalPaymentCreditCards = 0;
          this.paymentCreditCards = response.data.data;
          console.log(response)
          // Dividir la cadena en dos partes en función del signo igual (=)
          const parts = this.filter.split('=');
          console.log(parts)

          // Tomar solo la primera parte (antes del signo igual)
          const filter = parts[1];
          this.paymentCreditCards = this.paymentCreditCards.filter(c => c.itinerary_id === Number(filter));
          const nullPaymentCard = this.paymentCreditCards.filter(c => c.payment_masters_id === null);
          console.log(this.paymentCreditCards)
          console.log(this.creditCardPending )
          console.log(nullPaymentCard);
          if(this.creditCardPayPartial) {
            console.log('entre aca')
            this.totalPaymentCreditCards = this.amountSumCreditCardPending(nullPaymentCard);
            if(this.leftTotal === 0) {
              this.totalPayCreditCard = true;
            }
          }
          if(this.creditCardPending) {
            console.log('pendiente de pago')
            this.totalPaymentCreditCards = this.amountSumCreditCardPending(nullPaymentCard);

          }
          if(!this.creditCardPending && !this.creditCardPayPartial && nullPaymentCard.length > 0) {
            console.log('entre acá abajo')
            this.totalPaymentCreditCards = this.amountSumCreditCardPending(nullPaymentCard);
          }
          console.log('paymentCreditCards',  this.paymentCreditCards);
          console.log('totalPaymentCreditCards', this.totalPaymentCreditCards);
        }
        if (this.totalPaymentCreditCards > 0 && this.paymentCreditCards.length > 0) {
            this.isCardSelected = true;
            this.calculateLeftTotal();
        }
      }, err => {
        console.log('No existe informacion, Condición de Pagos!');
      });
  }


  private validateJpg(name) {
    var allowedExtensions = ["jpg", "jpeg", "JPG", "JPEG", "PNG", "png"];
    var fileExtension = name.split('.').pop();

    return this.isInArray(allowedExtensions, fileExtension);
  }

  private isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }


  private initConfig(): void {
    let registration_gateway: boolean;
    if(!this.creditCardPending && !this.creditCardPayPartial) {
      console.log(this.itineraryService)
      console.log(this.itineraryService.paymentConditions)
      console.log(this.itineraryService.paymentConditions.registration_gateway)
      registration_gateway = this.itineraryService.paymentConditions.registration_gateway == 0 ? false : true;
    } else {
      registration_gateway = false;
    }
    this.payPalConfig = {
      currency: 'USD',
      clientId: setting.clientIdPaypal,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'USD',
            value: this.amountCard,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: this.amountCard,
              }
            }
          },
          items: [{
            name: 'Booking Subscription',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: 'USD',
              value: this.amountCard,
            },
          }]
        }]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [ { name: 'disable-funding', value: 'credit,card'} ]
      },
      style: {
        layout: 'vertical',  // vertical 2 botones   // horizontal 1 boton
        // layout:  registration_gateway ?  'vertical' : 'horizontal',  // vertical 2 botones   // horizontal 1 boton
        color:   'gold',
        shape:   'pill',
        label:   'paypal',
        height: 40,
        size: 'small',
        tagline: false,

      },

      onApprove: (data, actions) => {
       console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
        //  console.log('onApprove - you can get full order details inside onApprove: ', details);
         console.log(JSON.stringify(details));
          /// enviar a guardar el pago
          let index = 0;


          console.log("items onApprove", this.items);

          if(this.creditCardPayPartial) {
            const items: any = this.creditCardDataPending.filter(c => c.payment_masters_id === null);
            if(items[0].details.length > 1) {

              items.forEach(element => {
                console.log(element);
                const newItem: any = [{
                    product_id: Number(element.details[0].uid),
                    category_id: element.details[0].category_id
                }];

                // Verifica si el elemento ya existe en this.payloadDetails
                const exists = this.payloadDetails.some(item => item.product_id === newItem.product_id && item.category_id === newItem.category_id);

                // Si el elemento no existe en this.payloadDetails, lo agrega
                if (!exists) {
                    this.payloadDetails.push(newItem);
                }
            });


              } else {
                this.payloadDetails = [{
                  product_id: Number(items[0].details[0].uid),
                  category_id: items[0].details[0].category_id
                 }]
              }
          } else {
            this.items.forEach(element => {
             // this.payloadDetails.push(element.id);

             this.payloadDetails[index] = {
                 product_id: element.id,
                 category_id: element.category_id
                }
                index = index + 1;
            });
          }



          this.payload = {
            payment_masters_id: '',
            payment_id: details.id,
            payer_id: details.payer.payer_id,
            payer_name: details.payer.name.given_name + ' ' + details.payer.name.surname,
            payer_email: details.payer.email_address,
            amount: details.purchase_units[0].amount.value,
            products:  this.payloadDetails,
            status: details.status,
            currency: this.currencyItinerary,
            agent_id: this.userService._agentID,
            agency_id: this.userService._agencyID
           };

           if(this.creditCardPayPartial) {
            this.payload.itinerary_id = this.creditCardDataPending[0].itinerary_id;
          } else {
            this.payload.itinerary_id = this.items[0].itinerary_id;
          }

          this.amountCard = 0; // recinicar campo de tdc
          // this.filter  =  'itinerary_id=' + this.items[0].itinerary_id;
          this.savePaymetCreditsCards(this.payload);

          //    this.filter  =  'itinerary_id=' + this.items[0].itinerary_id + '& payment_masters_id=null';

         /* setTimeout(() => {
            this.getPaymentCreditCards(this.filter);
          }, 5000); */

        //  this.getPaymentCreditCards(this.filter);

        });
      },
      onClientAuthorization: (data) => {
       // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
       // console.log(JSON.stringify(data));
        this.onApprove = true;
        this.isSelectModePay = false;
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        console.log(JSON.stringify(data));
        //this.showCancel = true;
      },
      onError: err => {
        console.log('OnError', err);
        //this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        console.log(JSON.stringify(data));
        //this.resetStatus();
      }
    };
  }


  parseDate(date){
    const fecha = date.split(' ')[0].split('-')
    return `${fecha[2]}/${fecha[1]}/${fecha[0]}`
  }

  parseHour(date){
    const hora = date.split(' ')[1]
    return hora
  }

  payTotal(methods: number) {
    if (methods === 1) {
        if(this.amountPoints) {
          this.amountPoints = this.amountPoints + Number(this.leftTotal);
        } else {
          this.amountPoints = this.leftTotal;
        }
        this.calculateLeftTotal();
    } else if (methods === 2) {
        if(this.amountDeposit > 0) {
          this.amountDeposit = this.amountDeposit + Number(this.leftTotal);
        } else {
          this.amountDeposit = this.leftTotal;
        }
        this.calculateLeftTotal();
    } else if (methods === 3) {
        if(this.amountTranfer > 0) {
          console.log(typeof this.amountTranfer, this.amountTranfer)
          console.log(typeof this.leftTotal, this.leftTotal)
          this.amountTranfer = this.amountTranfer + Number(this.leftTotal);
          console.log(this.amountTranfer)
        } else {
          this.amountTranfer = this.leftTotal;
        }
        this.calculateLeftTotal();
    } else if (methods === 4) {
      if(this.amountCard > 0) {
        this.amountCard = this.amountCard + Number(this.leftTotal);
      } else {
        this.amountCard = this.leftTotal;
      }
        this.calculateLeftTotal();
    }
}

paymentMethodSelected(paymentMethodsId: number) {
  switch(paymentMethodsId) {
    case 1 :
      break;
    case 2 :
      break;
    case 3 :
      break;
    case 4 :
      break;
  }
}

onAmountChange(newValue: number) {
  // Manejar el cambio del modelo y asegurarse de que no exceda totalPrice
  if (newValue > this.totalPrice) {
    this.amountDeposit = this.totalPrice;
  }
}

filesArrayTransfer(): boolean {
  if(this.filesArray.length >= 3) {
    return true;
  }
 return false;
}

preventScroll(event: WheelEvent): void {
  event.preventDefault();
}

}
