<table *ngIf="dailyPrices.length < 5">
  <tr>
    <ng-container *ngFor="let dailyPrices of dailyPrices">
      <th>
        <p class="week-days">{{dailyPrices.dayWeek}}</p>
      </th>
    </ng-container>
  </tr>
  <tr>
    <ng-container *ngFor="let dailyPrices of dailyPrices">
      <td>
        <p class="number-day" >{{dailyPrices.date | date: 'dd-MM-yyyy'}}</p>
        <p class="price" >{{dailyPrices.currency}} {{dailyPrices.price | number : "1.2-2"}}</p>
      </td>
    </ng-container>
  </tr>
</table>

<table *ngIf="dailyPrices.length > 5" style="width: 100%; margin-left: 80px; margin-right: 80px;">
  <tr>
        <th>
          <p class="m-0" style="height: 0px">&nbsp;</p>
        </th>
  </tr>
  <tr>
    <owl-carousel-o class="dailyPrices-owl-carousel" [options]="customOptions">
      <ng-container *ngFor="let dailyPrice of dailyPrices">
        <ng-template carouselSlide [width]="270">
          <td >
            <p class="week-days">{{dailyPrice.dayWeek}}</p>
            <p class="number-day">{{dailyPrice.date | date: 'dd-MM-yyyy'}}</p>
            <p class="price"> {{dailyPrice.currency}} {{dailyPrice.price}}</p>
          </td>
          </ng-template>
        </ng-container>
    </owl-carousel-o>
  </tr>
</table>

