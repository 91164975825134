import { Component, Input, OnInit } from '@angular/core';
import { setting } from '../../../../setting';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'usb-capacitation',
  templateUrl: './capacitation.component.html',
  styleUrls: ['./capacitation.component.scss']
})
export class CapacitationComponent implements OnInit {
  @Input() getClientsSettingAppBooking: any;
  public routeUploadFile: string;
  public videoRoute: string;

  private isVideoInitialized: boolean = false; // Estado para controlar si el video ya fue cargado
  private isPlaying: boolean = false; // Estado para controlar si el video está en reproducción

  constructor() {
    this.routeUploadFile =  setting.routeUploadFile;
  }

  ngOnInit() {

  }


loadVideo(event: Event): void {
  const clientVideo = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_video_home);
  const videoElement = event.target as HTMLVideoElement;

  // Si el video está en reproducción, pausarlo y actualizar el estado
  if (this.isPlaying) {
    videoElement.pause();
    this.isPlaying = false;
    return;
  }

  // Si el video no está inicializado, cargar la fuente
  if (!this.isVideoInitialized) {
    this.videoRoute = `${this.routeUploadFile}${clientVideo?.video}`;
    videoElement.load(); // Forzar la carga de la fuente del video
    this.isVideoInitialized = true; // Marcar como inicializado
  }

  // Reproducir el video y actualizar el estado
  videoElement.play();
  this.isPlaying = true;
}



}
