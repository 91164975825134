import { forEach } from 'lodash';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../material-modal-confirm/material-modal-confirm.component';
import { RestService } from '../rest.service';
import { setting } from '../../../../setting';
import { ItineraryService } from '../itinerary.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';

@Component({
  selector: 'usb-modal-post-booking',
  templateUrl: './modal-post-booking.component.html',
  styleUrls: ['./modal-post-booking.component.scss']
})
export class ModalPostBookingComponent implements OnInit {

  allItems:any = [];
  selectedItems:any = [];
  step = 1;

  totalPrice:any=0;
  leaveTotal:any;

  paymentMethods:any[] = [];
  details:any = [];

  agency:any;
  itinerary: any;

  getClientsSettingAppBooking: any;
  creditCardPending: boolean = false;
  creditCardPayPartial: boolean = false;
  creditCardDataPending: any= [];
  amountCreditCard: number = 0;
  // Variable de almacenamiento de monto pendiente de pagos con tarjeta de credito pendiente
  amountFilterItineraryId: any = 0;
  amountFilterNull: any = 0;
  partialLeftCreditCard: any = 0;

  amountBLickoinsItineraryPay: number = 0;
  amountCardItineraryPay: number = 0;
  amountDepositItineraryPay: number = 0;
  amountTransferItineraryPay: number = 0;
  consultingPay: boolean = false;
  paymentSync: boolean = false;
  public currencyItinerary: string;
  type: string = '';

  bsModalRef?: BsModalRef;

  constructor(
    public dialogRef: MatDialogRef<ModalPostBookingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog,
    private _restService:RestService,
    private _itineraryService: ItineraryService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) {
  }

  ngOnInit() {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.allItems = [];
    this.paymentMethods = [];
    this.itinerary = this.data.itinerary;
    this.type = this.data.type;
    console.log("itinerary", this.itinerary);
    if(this.type === 'payment') {
      for (let i = 0; i < this.itinerary.items.length; i++) {
        this.itinerary.items[i].selected = false;
        // tslint:disable-next-line:max-line-length

        if((this.itinerary.items[i].status_id == 2 &&  this.itinerary.items[i].isPendigApprovePay==false && this.itinerary.items[i].isPaidAgency==false && this.itinerary.items[i].isPaidAgency==false && this.itinerary.items[i].isPaidProvider==false && this.itinerary.items[i].isBilled==false)){
          this.allItems.push(this.itinerary.items[i]);
          this.getPaymentCreditCards();
        }

      }

      if(this.allItems.length == 0){
        //this.dialogRef.close(false);
      }

      this.agency = JSON.parse(localStorage.getItem('selectedAgency'));
      this.paymentMasterById(this.itinerary.id);
      this.currencyItinerary = this.itinerary.currency;
    }
    else if(this.type === 'request-billed') {

    }
  }

  changeStep(step){
    console.log("recibo step", step);
    this.step = step;
  }

  handleItem(item){
    console.log("recibo item", item);
    //this.step = step;
    let itemFound = this.selectedItems.find(element => element.id == item.id);
    if(itemFound){
      let index = this.selectedItems.indexOf(itemFound);
      if (index > -1) {

        this.selectedItems.splice(index, 1);
      }
    }else{
      this.selectedItems.push(item);
    }
    this.setPriceTotal();
  }


  setPriceTotal(){
    this.totalPrice = 0;

    this.selectedItems.forEach(element => {
      this.totalPrice = this.totalPrice + parseFloat(element.netPriceAgent); // totalPrice
    });
    console.log("el precio total ", this.totalPrice);
    this.totalPrice = Math.round(this.totalPrice * 100) / 100;
    console.log("el precio total es ", this.totalPrice);
  }

  close(){
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Estás seguro de cerrar?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
        const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
          if(result){
            this.dialogRef.close(true);

            // Emitir el evento solo si el paso actual es 4
            if (this.step === 4) {
              this._itineraryService.emitCloseModalPaymentUpdate(); // Emitir el evento
            }

          }else{
            //this.openModalExtraLarge(templateNewClient);
          }
        });

  }

  closeTop(){
    const initialState: ModalOptions = {
      initialState: {
        message: '¿Estás seguro de cerrar?'
      }
    };
    this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
        const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
          if(result){
            this.dialogRef.close(false);

            // Emitir el evento solo si el paso actual es 4
            if (this.step === 4) {
              this._itineraryService.emitCloseModalPaymentUpdate(); // Emitir el evento
            }

          }else{
            //this.openModalExtraLarge(templateNewClient);
          }
        });

  }

  goBack(){
    if(this.step == 2){
      this.selectedItems = [];
      this.totalPrice = 0;
      this.allItems.forEach(element => {
        element.selected = false;
      });
    }
    this.step = (this.step - 1)
  }

  verifyNext():boolean{

    switch (this.step) {
      case 1:

        if(this.selectedItems.length > 0){
          return false;
        }else{
          return true;
        }

      case 3:
        const paymentCardSuccess = this.paymentMethods.find(element => element.id == 2);
        if(this.leaveTotal === 0 || paymentCardSuccess) {
          let aux = this.paymentMethods.find(element => element.error == true);
          if(aux){
            return true;
          }else{
            return false;
          }

        }else{
          return true
        }

      default:
        break;
    }

  }

  next(){
    switch (this.step) {
        case 3:
          let dataModal: any;
          if(this.paymentMethods && this.paymentMethods.length > 0) {
            dataModal = {payment: this.paymentMethods};
          };

          const initialState: ModalOptions = {
            initialState: {
             paymentModal: dataModal,
             currencyItinerary: this.itinerary.currency
            }
          };
          this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
          const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
            this.spinner.show();
            if(result) {
              this.paymentSync = true;
              console.log(result)
              //si es el paso 3 de pago registra la transaccion
              this.setItemDetails();
              this.savePayments();
            } else {
              this.paymentSync = false;
              this.spinner.hide();
              return;
            }
          });
      break;

      default:
        //cambia el paso al siguiente
        this.step = (this.step + 1);
        break;
    }
  }

  setItemDetails() {

    if(!this.creditCardPending && !this.creditCardPayPartial) {

      this.details = [];
      console.log("selectedItems", this.selectedItems);
      this.selectedItems.forEach(element => {
      this.details.push({
        category_id: element.category_id,
        id: element.id,
        amount: element.netPriceBooking  //totalPrice
      });
    });
  }

    console.log("details", this.details);
  }

  savePayments(){

    const data = {
      paymentMethods: []
    };



    console.log("final data string", this.paymentMethods);
    this.paymentMethods = this.paymentMethods.filter(payment => payment.amount !== null);
    console.log(this.paymentMethods)
    this.paymentMethods.forEach(element => {
      data.paymentMethods.push({
        payment_methods_id: element.id,
        total_amount:  String(element.amount),
        agent_id:this.agency.agent_id,
        agency_id: this.agency.agency_id,
        itinerary_id:this.itinerary.id,
        user_id_create:this.agency.agent_id,
        paymentMasterDetails:this.details,
        currency: this.currencyItinerary,
        fileArray: element.id == 4 ? element.filesArray : null,
      })
    });


    console.log("final data string", JSON.stringify(data));


    this._restService.doPost('app', 'paymentMasters/create', data).subscribe(
      (response: any) => {
        console.log("responser payment Masters", response);
        if (response.success){
          console.log('entre acá')
          this.spinner.hide();
          this.step = (this.step + 1);
          this.paymentMasterById(this.itinerary.id);
        } else {
          console.log("error al ejecutar endpoint payment Master");
        }
      },
      error=> {
        console.log("error");
      }
    )
  }


  handleLeaveTotal(data: any) {
    this.leaveTotal = data.aux;
    console.log("recibo ", data);

    // Filtrar los objetos con amount diferente de null
    const filteredPaymentMethods = data.paymentMethods.filter((paymentMethod: any) => paymentMethod.amount !== null);

    // Limpiar el array this.paymentMethods
    this.paymentMethods = [];

    // Iterar sobre los datos filtrados
    filteredPaymentMethods.forEach((paymentMethod: any) => {
        // Buscar si ya existe un objeto con el mismo ID en el array
        const existingIndex = this.paymentMethods.findIndex((item: any) => item.id === paymentMethod.id);

        // Si ya existe un objeto con el mismo ID
        if (existingIndex !== -1) {
            // Actualizar el valor del objeto existente
            this.paymentMethods[existingIndex] = paymentMethod;
        } else {
            // Si no existe, agregarlo al array
            this.paymentMethods.push(paymentMethod);
        }
    });

    console.log(this.paymentMethods);
}


  verifyCategory(category):boolean{

    let aux;

    switch (category) {
      case "HOTELS":
        aux = this.selectedItems.find(element => element.categoryName=='HOTELS');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "TOURS":
        aux = this.selectedItems.find(element => element.categoryName=='TOURS');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "RENT-A-CAR":
        aux = this.selectedItems.find(element => element.categoryName=='RENT-A-CAR');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "TRANSPORTS":
        aux = this.selectedItems.find(element => element.categoryName=='TRANSPORTS');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "PTHEMES":
        aux = this.selectedItems.find(element => element.categoryName=='PTHEMES');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "RENT-A-CAR":
        aux = this.selectedItems.find(element => element.categoryName=='RENT-A-CAR');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "CRUISES":
        aux = this.selectedItems.find(element => element.categoryName=='CRUISES');
        if(aux){
          return true;
        }else{
          return false;
        }

      case "DISNEY":
        aux = this.selectedItems.find(element => element.categoryName=='DISNEY');
        if(aux){
          return true;
        }else{
          return false;
        }

      default:
        break;
    }
  }

  verifyQuantityCategory(category):number{
    let aux = 0;
    switch (category) {
      case "HOTELS":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='HOTELS'){
            aux++;
          };
        });
        break;
      case "TOURS":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='TOURS'){
            aux++;
          };
        });
        break;

      case "TRANSPORTS":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='TRANSPORTS'){
            aux++;
          };
        });
        break;

      case "PTHEMES":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='PTHEMES'){
            aux++;
          };
        });
        break;

      case "RENT-A-CAR":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='RENT-A-CAR'){
            aux++;
          };
        });
        break;
      case "CRUISES":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='CRUISES'){
            aux++;
          };
        });
        break;

      case "DISNEY":
        this.selectedItems.forEach(element => {
          if(element.categoryName=='DISNEY'){
            aux++;
          };
        });
        break;

      default:
        break;
    }

    return aux;
  }

/**
 * Realiza una solicitud HTTP GET para obtener las tarjetas de crédito pendientes de pago y realiza
 * acciones basadas en la respuesta recibida.
 */
getPaymentCreditCards() {
  // Construye la URL para la solicitud HTTP GET
  const url = 'paymentCreditCards/listPending?' + this.itinerary.items[0].itinerary_id;

  // Realiza la solicitud HTTP GET al backend
  this._restService.doGet_('app', url).subscribe({
      next: (response: any) => {
        console.log(response);
          // Verifica si la respuesta contiene datos
          if (response.data.data.length > 0) {
              // Filtra los elementos de this.allItems basándose en los datos de las tarjetas de crédito pendientes
              this.filterAllItemsWhitCreditCardPending(response.data.data);

              // Ejecuta una acción relacionada con las tarjetas de crédito pendientes
              this.questPaymentCreditCardPending(response.data.data);
          }
      },
      error: (error: HttpErrorResponse) => {
          // Manejo de errores en caso de que ocurra un problema durante la solicitud
      }
  });
}

paymentMasterById(itineraryId: number): void {
console.log(itineraryId)
  const url = 'paymentMasters/listByItinerary/' + itineraryId;

  this._restService.doGet('app', url).subscribe({
    next: (response: any) => {
      console.log(response)
      if(response && response.length > 0) {
        response.forEach((item, index) => {
          switch (item.payment_methods_id) {
            case 1:
              this.amountBLickoinsItineraryPay += item.total_amount;
              this.amountBLickoinsItineraryPay.toFixed(2);
              break;
            case 2:
              this.amountCardItineraryPay += item.total_amount;
              this.amountCardItineraryPay.toFixed(2);
              break;
            case 3:
              this.amountDepositItineraryPay += item.total_amount;
              this.amountDepositItineraryPay.toFixed(2);
              break;
            case 4:
              this.amountTransferItineraryPay += item.total_amount;
              this.amountTransferItineraryPay.toFixed(2);
              break;
          };
          this.consultingPay = true;
        })
      }
    },
    error: (error: HttpErrorResponse) => {
      console.log(error)
    }
  })
}


/**
 * Filtra los elementos de this.allItems basándose en los datos de las tarjetas de crédito pendientes recibidas.
 * @param creditCardData Datos de las tarjetas de crédito pendientes.
 */
private filterAllItemsWhitCreditCardPending(creditCardData: any) {
  // Arreglo temporal para almacenar los ID a filtrar
  const idsToFilter = [];

  // Recorre los datos de las tarjetas de crédito pendientes y agrega los IDs al arreglo temporal
  creditCardData.forEach((payment) => {
      idsToFilter.push(payment.details[0].uid); // Agregar el ID al arreglo temporal
  });

  // Filtra this.allItems una sola vez utilizando los ID almacenados en idsToFilter
  this.allItems = this.allItems.filter(item => !idsToFilter.includes(item.id.toString()));
}


  /**
   * Filtra los datos de los pagos con tarjeta de crédito pendientes para el itinerario actual.
   * Actualiza la variable 'paymentCreditCards' con los datos recibidos y filtra los pagos
   * que coinciden con el ID del itinerario y que aún no tienen un ID de maestro de pago asignado.
   * Si hay pagos pendientes que coinciden con los criterios de filtrado, emite un evento
   * indicando que hay pagos pendientes de tarjeta de crédito para el itinerario actual.
   *
   * @param creditCardData - Un arreglo que contiene los datos de los pagos con tarjeta de crédito.
   * @returns No hay retorno explícito. Sin embargo, la función emite un evento 'paymentCreditCardPending'
   * si hay pagos pendientes de tarjeta de crédito para el itinerario actual.
   */
  private questPaymentCreditCardPending(creditCardData: any[]) {
    // Actualiza la variable 'paymentCreditCards' con los datos recibidos
    let paymentCreditCards = creditCardData;


    // Filtra los pagos que corresponden al ID del itinerario actual
    paymentCreditCards = paymentCreditCards.filter(payment => payment.itinerary_id === this.itinerary.items[0].itinerary_id);
    const payNull: any =  paymentCreditCards.filter(payment => payment.payment_masters_id === null);

    if(paymentCreditCards.length > 0 && payNull.length > 0) {

      let foundMatch: any = false;

      foundMatch = this.foundMatchItemUid(payNull);

      if(!foundMatch) {

        if(this.itinerary.totalPriceByNetReserved > this.amountFilterItineraryId) {
          this.amountFilterItineraryId = this.itinerary.totalPriceByNetReserved - this.amountFilterItineraryId;
          this.creditCardDataPending = paymentCreditCards;
          console.log(this.creditCardDataPending)
          this.creditCardPayPartial = true;
          this.amountSumCreditCardNullPending(paymentCreditCards);
          this.creditCardPayDetails(this.creditCardDataPending);
          console.log(this.details)
          this.step = 3;
        }

      }
    }

    // Filtra los pagos que aún no tienen un ID de maestro de pago asignado
    paymentCreditCards = paymentCreditCards.filter(payment => payment.payment_masters_id === null);

    if (paymentCreditCards.length > 0 && !this.creditCardPayPartial) {
    this.amountFilterNull = this.amountSumCreditCardPending(paymentCreditCards);
      // Verifica si hay pagos pendientes después del filtrado
      this.creditCardDataPending = paymentCreditCards;
      this.creditCardPending = true;
      this.creditCardPendingDetails(this.creditCardDataPending);
      this.step = 3;
      console.log(this.details)
    }
  }

/**
 * Agrega los detalles de una transacción de pago con tarjeta de crédito a this.details si no existen previamente.
 * @param creditCardData Los detalles de la transacción de pago con tarjeta de crédito.
 */
private creditCardPayDetails(creditCardData: any): void {
  // Itera sobre los detalles de la transacción de pago con tarjeta de crédito
  creditCardData[0].details.forEach((element: any) => {
      // Verifica si el elemento ya está en this.details antes de agregarlo
      const exists = this.details.some(detail => detail.id === Number(element.uid));

      // Si el elemento no existe en this.details, lo agrega
      if (!exists) {
          this.details.push({
              category_id: element.category_id,
              id: Number(element.uid),
              amount: this.totalPrice  //totalPrice
          });
      }
  });
}


/**
 * Agrega los detalles de una transacción de tarjeta de crédito pendiente a this.details si no existen previamente.
 * @param creditCardData Los detalles de la transacción de tarjeta de crédito pendiente.
 */
private creditCardPendingDetails(creditCardData: any): void {
  // Itera sobre los detalles de la transacción de tarjeta de crédito pendiente
  creditCardData[0].details.forEach((element: any) => {
      console.log(element); // Registra en la consola el elemento actual

      // Verifica si el elemento ya está en this.details antes de agregarlo
      const exists = this.details.some(detail => detail.id === Number(element.uid));

      // Si el elemento no existe en this.details, lo agrega
      if (!exists) {
          this.details.push({
              category_id: element.category_id,
              id: Number(element.uid),
              amount: this.itinerary.totalPriceByNetReserved //totalPrice
          });
      }
  });
}

private amountSumCreditCardNullPending(paymentCreditCards: any) {

  let creditCardPartialNull = paymentCreditCards.filter(c => c.payment_masters_id === null);

   // Crear un conjunto para almacenar los IDs de los elementos en creditCardPartialNull
   const paymentIds = new Set<number>();
   creditCardPartialNull.forEach(payment => {
       paymentIds.add(Number(payment.details[0].uid));
   });

   // Sumar los valores solo para los elementos en this.itineraryItems que tienen un ID coincidente en paymentIds
   this.itinerary.items.forEach(item => {
       if (paymentIds.has(item.id)) {
        this.partialLeftCreditCard += item.optionNightsNetTotalProf;
       }
   });
}

  private amountSumCreditCardPending(paymentCreditCards: any) {
    let amount: number = 0;
     // Recorrer el array de paymentCreditCards con el fin de sumar los montos de amount para el monto total
      // de pagos pendientes
    paymentCreditCards.forEach((payment: any) => {
      amount += payment.amount;

    });

    return amount;
  }

  private foundMatchItemUid(payNotNull: any): boolean {
    let foundMatch = false;

    // Recorre los elementos de this.itinerary.items
    this.itinerary.items.forEach((item) => {
        // Recorre los objetos en payNotNull.details.uid
        payNotNull.forEach((payment) => {
            // Compara el id del elemento actual de this.itinerary.items con el uid del objeto actual de payNotNull.details.uid
            if (item.id === Number(payment.details[0].uid)) {
                // Si hay una coincidencia, añade el id al array itemArr
                // itemArr.push(item.id); // No parece necesario
                // Verifica si el valor de item.optionNightsNetTotalProf es igual al amount del objeto actual
                if (item.optionNightsNetTotalProf === payment.amount) {
                    foundMatch = true;
                }
            }
        });
    });

    return foundMatch;
}


}
