<div
      usbLoadingSplash
      [appLoading]="appLoading"
      [fullscreen]="true"
      [component]="'yourtrip'"
>
  <div class="row px-2">
    <div class="col-8">
      <div class="container row">
        <!-- <div style="padding-right: 20px !important;">
          <button class="btn btn-partial font-poppins" style="background-color: #000 !important; height: 50px;" (click)="_partialReserve()" [disabled]="!continueToReserve">
            PARTIAL RESERVE
          </button>
        </div> -->
        <div class="data-name-itinerary font-poppins">
          <h4 style="color: #000000;">{{ destinySecuency }}</h4>
          <p>In: {{ userService.itineraryArrivalDateFull  | date:'dd MMM yyyy'}} &nbsp; Out: {{ userService.itineraryDepartureDateFull  | date:'dd MMM yyyy'}}
            &nbsp;
          <span *ngIf="existHotel()" [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">
            Estadia: {{qtyDays }} Días</span> </p>
        </div>
      </div>
    </div>
    <div class="col-4 text-right d-flex justify-content-end align-items-center">
     <span class="price-total-summary font-poppins mx-4 mb-4 align-middle" style="font-weight: 500;">TARIFA NETA</span>
     <div class="d-flex flex-column">
      <span
        *ngIf="getOrigin != 'RENT-A-CAR'"
        class="price my-1 font-poppins align-middle"
        style="font-weight: 500;height: 30px;"
        [ngStyle]="{
          'font-size': totalNetPriceBooking | dynamicFontSize: 26 : fontSizeAdjustments
        }"
         >{{currencyItinerary}} {{  totalNetPriceBooking > 0 ?  totalNetPriceBooking : totalNetPriceBookingFreeTax | number:'1.2-2'}}
      </span>
      <span class="price my-1 font-poppins align-middle"
      style="font-weight: 500;height: 30px;" *ngIf="getOrigin == 'RENT-A-CAR'">{{currencyItinerary}} {{ totalNetPriceBookingFreeTax | number:'1.2-2'}}
      </span>
      <p style="font-size: 11px;font-weight: 600;">imp. incluidos</p>
      </div>
    </div>
  </div>

  <!-- <div>
    <div class="d-flex">
      <span class="netPrice">
        TARIFA NETA
      </span>
    </div>
    <div class="d-flex">
      <a class="loader text-center justify-content-center" *ngIf="totalNetPriceBooking == 0">
        <i class="fa fa-spinner fa-spin"></i>
      </a>
      <span *ngIf="totalNetPriceBooking> 0" class="netPriceMount" class="price-yourtrip text-end"> $ {{ totalNetPriceBooking | number:'1.2-2'}}</span>
    </div>
  </div> -->
  <!--<span>
    <h3>{{ nameItinerary }} </h3>
  </span>-->
  <div class="d-flex justify-content-between my-3">
    <span class="col font-summary mx-2" style="display: flex; align-items: center">

      <span class="px-2" style="font-size: 13px; display: flex; align-items: center;" *ngIf="getOrigin != 'RENT-A-CAR'"> <!-- adults-->
        <!-- <i class="icon icon-multiple-users-silhouette" style="color: #000000" aria-hidden="true"></i> -->
        <i class="bi bi-people-fill" style="font-size: 20.1px;" aria-hidden="true"></i>
        <p class="mb-0">&nbsp;{{ actualItinerary.totalAdultsMax }} Adultos</p>
      </span>
      <span class="px-2" style="font-size: 13px; display: flex; align-items: center; " *ngIf="getOrigin != 'RENT-A-CAR'"> <!-- children-->
        <i class="icon icon-child" style="top: -.5px; padding: 9px !important" aria-hidden="true"></i>
       <p class="mb-0">
         &nbsp;{{ actualItinerary.totalChildrenMax }}
         Niños
       </p>
      </span>
      <!-- <span class="px-2" style="font-size: 13px;" *ngIf="existHotel()">
        <i class="fas fa-moon" ></i>
        &nbsp;{{ nigths }} Nigths
      </span> -->
      <span class="px-2" style="font-size: 13px; display: flex; align-items: center; " *ngIf="existHotel()">
        <i class="icon i-bed mr-2" style="top: -.7px;"></i>
       <p class="mb-0">
         &nbsp;{{ qtyNights }} Noches
       </p>
      </span>
      <!-- <span class="px-2" style="font-size: 13px;" *ngIf="getOrigin != 'RENT-A-CAR' && getOrigin != 'TOURS'">
        <i class="icon icon-fly" aria-hidden="true" style="color: #000000"></i>&nbsp;{{ flyQty }}
        Vuelo
      </span> -->

      <span class="px-2" style="font-size: 13px;" *ngIf="userService.verifyCategory('TOURS')">
        <!-- <object type="image/svg+xml" data="/assets/img/app/little-icons/moon_grisclaro.svg" width="10px" height="10px"></object> -->
        <span class="icon i-ticket"></span>
        <span class="text-bar-detail">&nbsp;&nbsp;{{userService.verifyQuantityCategory('TOURS')}} Tickets Tours</span>
      </span>

    </span>
    <span class="d-flex justify-content-end font-summary-buttons text-right">

      <div class="row pl-2 pr-2 d-flex justify-content-between align-items-center" style="color: #000000; position: absolute; margin-top: -70px;margin-left: -80px;width: 255px;">
        <!-- <div class="d-flex">
          <span class="netPrice">
            TARIFA NETA
          </span>
        </div>
        <div class="d-flex">
          <a class="loader text-center justify-content-center" *ngIf="totalNetPriceBooking == 0">
            <i class="fa fa-spinner fa-spin"></i>
          </a>
          <span *ngIf="totalNetPriceBooking> 0" class="netPriceMount" class="price-yourtrip text-end"> $ {{ totalNetPriceBooking | number:'1.2-2'}}</span>
        </div> -->
      </div>
      <!-- <div class="col-12 p-3 mt-4" style="border: 1px solid #ff9900;border-radius: 5px;"> -->
        <!-- <div class="row pl-2 pr-2 d-flex justify-content-between align-items-center" style="position: absolute;  margin-top: -35px; margin-left: -80px; width: 255px;">
          <div class="d-flex align-items-center justify-content-start">
            <img src="../../../../assets/img/app/little-icons/badge-bks.png" style="margin-right: 5px;" width="18px" alt="">
            <span class="font-blickoins">
              Blickoins
            </span>
          </div>
          <div class="d-flex align-items-center">
            <span class="font-blickoins" >{{ totalBlickoins ? totalBlickoins : 0 }} Bks.</span>
          </div>
        </div>
      </div> -->
      <button class="btn rounded-pill px-5" (click)="goToReserve()" [disabled]="(partialReserve && qtyItemsToPartial==0) || continueToReserve"
        [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}" style="font-family: 'Poppins' sans serif !important;font-size: 16px; font-weight: 600;height: 48px; width: 260px;">
        CONTINUAR
      </button>
    </span>
  </div>
</div>
