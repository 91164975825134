import { Component, OnInit, Inject, ViewChild, AfterViewInit } from "@angular/core";
// import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ItineraryService } from "../itinerary.service";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { setting } from '../../../../setting';
import { RestService } from "../rest.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "usb-modal-loading",
  templateUrl: "./modal-loading.component.html",
  styleUrls: ["./modal-loading.component.scss"],
})
export class ModalLoadingComponent implements OnInit {
  @ViewChild('owlElement') owlElement: any;
  urlSafe: SafeResourceUrl;
  children = 0;
  adults = 0;
  data: any;
  slides: any = [];
  public baseUrl: string;
	public apiBookingUrl: string;
	public domain: string;
	public getClientsSettingAppBooking: any;
  public routeUploadFile:any;
	public loadSetting : boolean;
  public sectionModalLoading: any;
  public formData: any = {};
  public destination: any;
  optionsTabName:string = '';
  tabNameOrig:string = '';
  imgZonesArr:any[] = [];
  loadingProducts: boolean = false;

  customOptionsModalLoading: OwlOptions = {
    autoHeight: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplaySpeed: 750,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    items: 1,

    }


  constructor(
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public payload: any,
    public dialogRef: MatDialogRef<ModalLoadingComponent>,
    public sanitizer: DomSanitizer,
    private restService:RestService,
  ) {
    this.routeUploadFile =  setting.routeUploadFile;
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.sectionModalLoading  = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_modal_loading);
    this.restService.optionsTab$.subscribe((res:any) => {
      if(res !== undefined && res !== null && res !== '') {
        if(res === 'HOTELS'){
          this.optionsTabName = 'hoteles';
        }else{
          this.optionsTabName = res;
        }
      }
    })
  }


  ngOnInit() {
    this.formData = JSON.parse(localStorage.getItem("form"));
    // && (this.formData.city_iata_code && this.formData.city_iata_code.length > 0 && this.formData.city_iata_code[this.formData?.city_iata_code.length -1 ])
    if (this.formData) {
      this.imgZonesArr = this.formData.destination.inmg_zones;
    }
    // console.log('this.formData ->', this.formData);


    this.payload = JSON.parse(localStorage.getItem("payload"));
    // console.log("modal loading paylod",  this.payload);

    if(this.payload && this.payload.length > 0){
      this.data = this.payload[0];
    }else{
      this.data = this.payload;
    }

    // console.log('data del payload ->', this.data);



/*
    if (this.payload == undefined || this.payload.length === 0) {
      this.payload = JSON.parse(localStorage.getItem("payload"));
    }

    if (this.payload[0]) {
      this.data = this.payload[0];
    } else {
      this.data = this.payload;
    }*/



    switch (this.data.categoriesId[0]) {

      case 1 :  // hotel


        for (let index = 0; index < this.sectionModalLoading?.slide.length; index++) {
          this.slides[index] =  { image: this.routeUploadFile + this.sectionModalLoading?.slide[index], text:index }
        }
      const rooms = this.data.others.hotels.roomConfiguration;

      rooms.forEach((room) => {
        this.children += this.data ? room["children"].length : 0;
        this.adults += this.data ? parseInt(room["adults"]) : 0;
      });
      break;


      case 2:  // rentacar

        this.slides = [
          { image: "assets/img/app/loader-images/rentacar/load1.jpg", text: "First" },
          { image: "assets/img/app/loader-images/rentacar/load2.jpg", text: "Second" },
          { image: "assets/img/app/loader-images/rentacar/load3.jpg", text: "Third" },
        ];
      break;


      case 6:  // tour

        this.slides = [
          { image: "assets/img/app/loader-images/tour/load1.jpg", text: "First" },
          { image: "assets/img/app/loader-images/tour/load2.jpg", text: "Second" },
          { image: "assets/img/app/loader-images/tour/load3.jpg", text: "Third" },
        ];
      break;



      default:
      break;
    }

    this.sendValueObs();
    this.getTabName();


  }


  closeModal(): void {
    this.dialogRef.close();


  }

  sendValueObs():void{
    this.restService.numberAdultsObs$.next(this.adults)
  }

  getTabName():void{
    let nameOrig = localStorage.getItem("origin");
    if(nameOrig === 'HOTELS'){
      this.tabNameOrig = 'hoteles'
    }else{
      this.tabNameOrig = nameOrig;
    }
  }

}
