<div class="row bg-white content-detail">
  <div class="col-md-9">
    <div class="row">
        <div class="col-3">
            <div class="detail-number px-5 py-3" [ngStyle]="{'background-color':getClientsSettingAppBooking?.clients_setting_global?.color_main_2}">
                <span class="align-middle font-poppins text-number-detail font-weight-bold"
                [ngStyle]="{'color':getClientsSettingAppBooking?.clients_setting_global?.color_main_3}">FILE N° {{itinerary?.id}}</span>
                <!-- N° 000 -->
            </div>
        </div>
        <div class="col-9">
            <div class="detail-title">
                <span class="h2 font-poppins text-uppercase" style="color: #000000;">{{destinySecuency}}</span>
                <p style="color: #000000;">Check In: {{ userService.itineraryArrivalDateFull | date:'dd MMM yyyy'}} &nbsp; Check Out: {{ userService.itineraryDepartureDateFull  | date:'dd MMM yyyy'}}
                    &nbsp;
                <span *ngIf="existHotel()" >Estadia: {{qtyDays}} Días</span> </p>
            </div>
        </div>
    </div>
    <div class="row mr-5">
        <div class="col-12">
            <div class="detail-service px-4 pt-2">
                <ul class="nav">
                    <li class="nav-item">
                        <ul class="nav">
                            <li class="nav-item">
                                <img src="/assets/img/app/little-icons/user.svg" class="icon-adult-people" alt="">
                            </li> <!-- adults-->
                            <li class="nav-item"><span class="font-poppins f-12 mr-3">&nbsp;{{ itinerary?.totalAdultsMax }} Adultos</span></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <ul class="nav">
                            <li class="nav-item">
                                <img src="/assets/img/app/little-icons/child.svg" class="icon-childrens" alt="">
                            </li> <!-- children -->
                            <li class="nav-item"><span class="font-poppins f-12 mr-3">&nbsp;{{ itinerary?.totalChildrenMax }} Niños</span></li>
                        </ul>
                    </li>
                    <li class="nav-item" *ngIf="existHotel()">
                        <ul class="nav">
                            <li class="nav-item">

                                <span class="icon i-bed mr-2"></span>
                            </li> <!-- nigths-->
                            <li class="nav-item"><span class="font-poppins f-12 mr-3">{{ qtyNights }} Noches</span></li>
                        </ul>
                    </li>
                    <!-- <li class="nav-item">
                        <ul class="nav">
                            <li class="nav-item">
                                <img src="/assets/img/app/little-icons/plane1.svg" class="icon-fly" alt="">
                            </li>
                            <li class="nav-item"><span class="font-poppins f-12 mr-3">&nbsp;{{ flyQty }} Vuelo</span></li>
                        </ul>
                    </li>  -->

                    <li class="nav-item" *ngIf="userService.verifyCategory('TOURS')">
                        <ul class="nav">
                            <li class="nav-item">
                                <span class="icon i-ticket"></span>
                            </li>
                            <li class="nav-item">
                                <span class="font-poppins f-12 mr-3">&nbsp;&nbsp;{{userService.verifyQuantityCategory('TOURS')}} Tickets Tours</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
  <div class="col-md-3 content-user-detail">
      <div class="row">
          <div class="col-12">
              <p class="detail-name-user font-poppins font-weight-bold" style="color: #000000;">{{clientData.name}}</p>
              <p class="detail-mail-user font-poppins" style="color: #000000;">{{clientData.email}}</p>
              <p class="detail-phone-user font-poppins" style="color: #000000;">{{clientData.phone}}</p>
          </div>
      </div>
  </div>
</div>
