import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter, ViewChild, AfterViewInit, HostListener, ElementRef, QueryList, ViewChildren, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { RestService } from "../../shared/rest.service";
import { Procesed } from "../../shared/enum-procesed";
import { ItineraryService } from "../../shared/itinerary.service";
import { ModalDirective, BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { PaxListModalComponent } from "../../shared/pax-list-modal/pax-list-modal.component";
import { ModalsSearchComponent } from "../../shared/modals-search/modals-search.component";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { ResultsSearchService } from "../../home/results-search.service";
import { ModalsComponent } from "../../shared/modals/modals.component";
import { MatDialog } from "@angular/material/dialog";
import { MaterialModalConfirmComponent } from "../../shared/material-modal-confirm/material-modal-confirm.component";
import { MaterialModalAlertComponent } from "../../shared/material-modal-alert/material-modal-alert.component";
import { Options } from "@angular-slider/ngx-slider";
import { MatCheckbox } from "@angular/material";
import { TitleFilterPipe } from '../../shared/titleFilter.pipe';
import { setting } from "../../../../setting";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AmenitiesFilter, FiltersHotels, FiltersRentacar, SliderShow } from "./filters-model";
import { PriceFilterPipe } from "app/shared/slider.pipe";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Subscription } from "rxjs";

@Component({
  selector: "usb-category-result",
  templateUrl: "./category-result.component.html",
  styleUrls: ["./category-result.component.scss"],
  providers: [
    TitleFilterPipe,
    PriceFilterPipe
  ]
})
export class CategoryResultComponent implements OnInit, AfterViewInit, OnChanges {
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);

  @Input() payload: any;
  @Input() results: any;
  @Input() _paxAdults: any;
  @Input() _paxChildren: any;
  @Input() index: any;
  @Input() _pax: any;
  @Input() _payloadForm: any;
  @Input() show: any;
  @Input() _multiModal: any;
  @Input() modalRoomRef: any;
  @Input() origin: string;
  @Input() tabSelected: string;
  @Input() categoryName: string;
  @Input() text: string;
  @Input() resultPaged: any;
  @Input() _applyFilter: any;
  @Input() deleteProduct: string = '';

  @Output() arrayOut = new EventEmitter<any>();
  @Output() isLoading = new EventEmitter<boolean>();
  @Output() payload_ = new EventEmitter<any>();
  @Output() results_ = new EventEmitter<any>();
  @Output() _origin = new EventEmitter<any>();
  @Output() _filtersRentacar = new EventEmitter<any>();
  @Output() emitReSearch = new EventEmitter<any>();
  @Output() emitViewMap = new EventEmitter<any>();

  @ViewChild(ModalDirective) modalCompare: ModalDirective;
  @ViewChild("ModalRooms") public formModal: any;
  @ViewChild(PaxListModalComponent) modalPax: PaxListModalComponent;
  @ViewChild(ModalsSearchComponent) modalSearch: ModalsSearchComponent;
  @ViewChild("map") gmapElement: any;
  @ViewChild('filterContainer') filterContainer: ElementRef;
  @ViewChildren('containerResults') containerResults!: QueryList<ElementRef>;
  // filtros
  @ViewChild("popularBreakfast") public popularBreakfast: MatCheckbox;
  @ViewChild("popularBreakfastNo") public popularBreakfastNo: MatCheckbox;

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (this.filterContainer && !this.filterContainer.nativeElement.contains(event.target)) {
      this.showFilters = false;
    }
  }

  coordinatesSidebar = {lat: 0, lng: 0};
  config = {
    backdrop: 'static',  // Evita el cierre al hacer clic fuera del modal
    keyboard: false,     // Desactiva el cierre con la tecla Escape
    class: 'modal-dialog-centered'
  };

  page: any;
  ModalRooms: any;
  modalRef: BsModalRef;
  _actualItinerary: any;
  category: string;
  loaded: boolean;
  _current = 1;
  _total: number;
  contentCollapsed = true;
  _currentGlobal = 1;
  _items: number;
  editable = false;
  itemToCompare: any = [];
  disbaledCompare: boolean = false;
  ori: any;
  emitLoad: boolean = false;
  orderFilter = 0;
  nightsTotalDiff:number = 0;
  cityNameForm:any;
  currentInfo:any;
  markerArray: google.maps.Marker[] = [];
  selectedStars: number = 0; // Número de estrellas seleccionadas
  booleanHideIcons:boolean = false;
  selectedAmenities: string[] = [];
  filterInitialized: boolean = false;
  resultTotal: number;
  priceResetLow:number = 0;
  priceResetHigh:number = 0;
  private hasExecutedElse: boolean = false;
  orderPriceby =   "priceLow";
  orderOptions = {
    priceLow: "Menor Precio",
    priceHigh: "Mayor Precio",
    masRelevantes: "Mas Relevantes",
  };
  showOrder = false;
  showFilters = false;

  isChecked = true;

  countFilterApply = 0;
  selectedFilters: any = [];
  resultReload: boolean = false;
  noLoadingForModal: boolean = false;

  public getClientsSettingAppBooking: any;
  textBarCharge: string = 'Cargando más resultados...';


  // MISCELANEOS
  resultFilterCount = [];
  totalProduct = 0;
  filterLowPrice: any = 0;
  filterHighPrice: any = 0;
  initialLowPrice: any;
  initialHigthPrice: any;
  optionsSliderPrice: Options = SliderShow;
  bsModalRef?: BsModalRef;


  // HOTELES
  public amenitiesFilter: any[] = AmenitiesFilter;
  public filters: any = FiltersHotels;

  // RENT-A-CAR
  public  filtersRentacar: any = FiltersRentacar;

  viewMapsResult = false;
  viewCardResult = false;

  allpost;
  notEmptyPost = true;
  notscrolly = true;
  resultsFilter: any;
  resultsAux: any;
  init = false;
  nuevoObjeto = [];
  nuevoObjetoAux = [];
  private intervalId: any; // Variable para almacenar el ID del intervalo
  first_charge: boolean = true;
  categoryId: number;
  resultsReset: any;
  reloadAgain: boolean = true;
  private reloadAgainSubject = new BehaviorSubject<boolean>(this.reloadAgain);
  currencyItinerary: any = null;
  arrivalDate:string;
  departureDate:string;
  finishReload: boolean = false;
  totalResponse: number;
  indexResolveResult = 0;

  public pausedByMouseEnter = false; // Flag para pausar/reanudar recarga
public isSearchRunning = false; // Flag para saber si una búsqueda está en progreso

confirmSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    public vcr: ViewContainerRef,
    private _restService: RestService,
    public serviceItinerary: ItineraryService,
    public userService: UserService,
    public resultsSearch: ResultsSearchService,
    private dialog: MatDialog,
    private titleFilter: TitleFilterPipe,
    private sliderFilter: PriceFilterPipe,
    private itineraryService: ItineraryService,
    private route: Router,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.init = false;
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  }
  // ngOnDestroy(): void {
  //   // this.confirmSubscription.unsubscribe();
  // }


  ngOnInit() {
    // console.log(this.userService)
    // console.log('payload ->', this.payload);
    this.arrivalDate = this.payload.arrivalDate;
    this.departureDate = this.payload.departureDate;

    console.log('this.results ->', this.results);

    if(this.origin === 'TOURS'){
      // console.log('results tours ->', this.results);
      this.resultPaged = this.results;
    }

    this.cityNameForm = JSON.parse(localStorage.getItem('form')); // Carga el formulario del LocalStorage
    this.categoryId = this.payload.categoriesId[0]; // id de categoría


    this.handleCategoryValidation(); // Validación según la categoría seleccionada
    this.subscribeReloadSearch();
    if(this.categoryId === 6) {
      this.initializeRentacarFilters(); // Inicializa filtros de Rent-a-Car
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.deleteProduct === 'delete-product') {
      this.deleteProduct = '';
      if(this.userService?.itineraryActive[0]?.currency) {
        this.getPagesNew(this.userService?.itineraryActive[0]?.currency, false);
        this.orderPriceLow();
      } else {
        this.getPagesNew(null, false);
        this.orderPriceLow();
      }
      this.cdr.detectChanges();
    }
  }

 /**
 * Suscribe el componente a cambios en `reloadAgainSubject`.
 *
 * Cuando `reloadAgainSubject` emite un valor `true`, se inicia el proceso de recarga.
 * Además, emite un valor inicial para configurar el comportamiento al iniciar.
 */
subscribeReloadSearch(): void {
  this.reloadAgainSubject.subscribe((value) => {
    // console.log("Valor recibido en reloadAgainSubject:", value);
    if (value) {
      this.processSearchReload();
    }
  });
  this.reloadAgain = true;
  this.reloadAgainSubject.next(this.reloadAgain);
}

/**
 * Procesa la recarga de la búsqueda.
 *
 * Si `reloadAgain` es falso, detiene la recarga. De lo contrario, ejecuta la búsqueda.
 *
 * @param payload -> Datos necesarios para ejecutar la búsqueda.
 * @param reload -> Indica si la búsqueda es una recarga.
 */
processSearchReload(): void {
  if (!this.reloadAgain) {
    // console.log("Deteniendo el proceso de recarga. Estado reloadAgain:", this.reloadAgain);
    return;
  }
  this.reloadSearch(this.payload, true);
}

/**
 * Pausa la recarga al detectar el evento `mouseenter` en un contenedor.
 *
 * Cambia el estado de `reloadAgain` y actualiza el texto de la barra de carga.
 *
 * @param event -> Evento del mouse al ingresar al contenedor.
 */
onMouseEnter(event: MouseEvent): void {
  const container = event.currentTarget as HTMLElement;
  if (container && this.reloadAgain) {
    this.pausedByMouseEnter = true;
    this.reloadAgain = false;
    this.reloadAgainSubject.next(false);
    this.textBarCharge = 'Carga frenada por selección';
    // console.log('Recarga pausada por mouseEnter.', container);
  }
}

/**
 * Reanuda la recarga al detectar el evento `mouseleave` del contenedor.
 *
 * Si no hay elementos modales abiertos, retoma la recarga.
 *
 * @param event -> Evento del mouse al salir del contenedor.
 */
onMouseLeave(event: MouseEvent): void {
  const container = event.currentTarget as HTMLElement;
  const relatedTarget = event.relatedTarget as HTMLElement;
  const modalPresent = document.querySelector('mat-dialog-container');

  if (relatedTarget && (container.contains(relatedTarget) || modalPresent) && this.finishReload === false) {
    this.reloadAgain = false;
    this.reloadAgainSubject.next(false);
    this.noLoadingForModal = true;
    this.textBarCharge = 'Carga frenada por selección';
    return;
  }

  if (this.pausedByMouseEnter) {
    this.noLoadingForModal = false;
    this.pausedByMouseEnter = false;
    this.reloadAgain = true;
    this.reloadAgainSubject.next(true);
    this.textBarCharge = 'Cargando más resultados...';
    // console.log('Recarga reanudada por mouseLeave.');
  }
}

/**
 * Pausa manualmente el proceso de recarga.
 *
 * Actualiza `reloadAgain` y detiene la recarga activa.
 */
stopResearch(): void {
  this.pausedByMouseEnter = true;
  this.reloadAgain = false;
  this.reloadAgainSubject.next(false);
  this.textBarCharge = 'Carga frenada por selección';
  // console.log('Recarga pausada');
}

/**
 * Retoma la recarga si estaba pausada.
 *
 * Cambia el estado de `reloadAgain` y actualiza la barra de carga.
 */
startReSearch(): void {
  this.reloadAgain = true;
  if (this.reloadAgain) {
    this.noLoadingForModal = false;
    this.pausedByMouseEnter = false;
    this.reloadAgainSubject.next(true);
    this.textBarCharge = 'Cargando más resultados...';
    // console.log('Recarga por cierre de modal');
  }
}

/**
 * Gestiona la lógica al cerrar un modal, reanudando o finalizando la recarga.
 *
 * Si no hay recarga finalizada, retoma la búsqueda; de lo contrario, la finaliza.
 *
 * @param event -> Evento relacionado con el cierre del modal.
 */
modalClosedemitter(event: any): void {
  this.reloadAgain = true;
  if (this.reloadAgain && this.finishReload === false) {
    this.noLoadingForModal = false;
    this.pausedByMouseEnter = false;
    this.reloadAgainSubject.next(true);
    this.textBarCharge = 'Cargando más resultados...';
    // console.log('Recarga por cierre de modal');
  } else {
    this.reloadAgain = false;
    this.finishReload = true;
    this.reloadAgainSubject.next(false);
    // console.log('Finalizado con cierre de modal');
    this.textBarCharge = 'Carga de resultados completada';
  }
}


  /**
   * Valida los resultados según la categoría seleccionada.
   *
   * Si la categoría es hotel (id 1), ajusta los resultados y el estado de visibilidad de íconos.
   * Si no, verifica si los resultados están vacíos y ajusta los íconos en consecuencia.
   *
   * @results -> arreglo de resultados que se muestra en pantalla.
   * @resultTotal -> cantidad total de resultados.
   * @booleanHideIcons -> estado para ocultar o mostrar íconos.
   */
  handleCategoryValidation(): void {
    if (this.categoryId === 1) {
     this.deleteResponsePropertiesHotel(); // elimina varias propiedades específicas del objeto `results`
      this.resultsAux = [...this.results];
      this.resultTotal = this.results.length;
      this.booleanHideIcons = false;

    } else {
      if (this.results.length == 0) {

        this.booleanHideIcons = true;
      }
      this.resultsAux = this.results;
      this.resultTotal = this.results.length;
    }
    // console.log('category-results', this.results);

    if(this.results && this.results.noResultMessage) {
      const initialState: ModalOptions = {
        initialState: {
          data: 'No se encontraron datos, para los parámetros suministrados'
        }
      }
      const modalOptions = Object.assign({}, initialState, this.config);
      this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);

        this.confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
          if(result) {
            this.route.navigate(['/home']);
          }
        });
    } else {
      this.handleOrderByPriceLow(); // Ordenar por precio bajo si es necesario
      this.configureCategorySpecificActions(); // Acciones según la categoría (HOTEL o RENT-A-CAR)
      this.prepareResultsForFilter(); // Preparar resultados y aplicar filtros
      this.setItinerary(); // Configura el itinerario
      this.subscribeToItineraryService(); // Maneja eventos del servicio de itinerario
      if(this.userService?.itineraryActive[0]?.currency) {
        this.resultTotal = this.results.length;
      }
    }
  }

  /**
 * @method deleteResponsePropertiesHotel
 *
 * @description
 * Este método elimina varias propiedades específicas del objeto `results`. Las propiedades eliminadas son las que contienen datos relacionados con la búsqueda y respuesta del hotel, como la fecha de llegada, la categoría del hotel, el destino, y otros detalles adicionales.
 *
 * @returns {void}
 * No retorna ningún valor.
 *
 * @example
 * Llamada al método para eliminar propiedades innecesarias de un objeto de resultados
 * this.deleteResponsePropertiesHotel();
 *
 */
  deleteResponsePropertiesHotel(): void{
    delete this.results['arrivalDate'];
    // delete this.results['categoryName'];
    delete this.results['destination'];
    delete this.results['others'];
    delete this.results['payload'];
    delete this.results['qtyPax'];
    delete this.results['searchId'];
    delete this.results['form'];
  }

  /**
   * Inicializa los filtros de Rent-a-Car.
   *
   * Este método resetea las listas de selección de categoría y marca en la búsqueda de Rent-a-Car.
   *
   * @filtersRentacar.category.checkedArray -> lista de categorías seleccionadas.
   * @filtersRentacar.brand.checkedArray -> lista de marcas seleccionadas.
   */
  initializeRentacarFilters(): void {
    this.filtersRentacar.category.checkedArray = [];
    this.filtersRentacar.brand.checkedArray = [];
  }

  /**
   * Ordena los resultados por precio más bajo.
   *
   * Si la variable `orderPriceby` tiene el valor "priceLow", se llama al método `orderPriceLow`
   * para ordenar los resultados por precio ascendente.
   *
   * @orderPriceby -> parámetro que determina si los resultados deben ser ordenados por precio.
   */
  handleOrderByPriceLow(): void {
    if (this.orderPriceby == "priceLow") {
      this.orderPriceLow();
    }
  }

  /**
   * Configura acciones específicas basadas en la categoría seleccionada.
   *
   * Dependiendo del id de la categoría (`categoriesId`), se ejecutan funciones específicas:
   * - HOTEL (id 1): no realiza acciones adicionales.
   * - RENT-A-CAR (id 2): llama al método `setCarGroup`.
   *
   * @payload.categoriesId -> lista de ids de categorías.
   */
  configureCategorySpecificActions(): void {
    switch (this.categoryId) {
      case 1: // HOTEL
        break;
      case 2:  // RENT-A-CAR
        this.setCarGroup();
        break;
      default:
        break;
    }
  }

  /**
   * Prepara los resultados para aplicar filtros.
   *
   * Este método prepara los resultados, aplicando filtros y obteniendo el total de resultados.
   * Además, si los resultados no son nulos o indefinidos, se aplica un filtro adicional y se
   * obtienen los precios mínimos y máximos.
   *
   * @resultsFilter -> resultados filtrados para ser mostrados.
   * @total -> total de resultados.
   */
  prepareResultsForFilter(): void {
    this.resultsFilter = {...this.results};

    if (this.results !== undefined && this.results !== null) {
      this._total = this.results.length;
      this.resetFilter();
      this.searchFilter();
      this.searchPriceLowAndHigh();
      this.filterResults();
    }
  }

  /**
   * Suscribe el componente a eventos del servicio de itinerario.
   *
   * Este método maneja dos eventos emitidos por `itineraryService`:
   * - `modalClosed$`: actualiza el formulario de la ciudad cuando el modal se cierra.
   * - `currencySuccessEmit`: muestra un mensaje de éxito y actualiza las páginas cuando
   *   se establece una nueva moneda en el itinerario.
   * - `currencyRemoveEmit`: resetea los resultados cuando se remueve la moneda del itinerario.
   *
   * @itineraryService.modalClosed$ -> evento para actualizar el formulario de ciudad.
   * @itineraryService.currencySuccessEmit -> evento para cambiar la moneda del itinerario.
   * @itineraryService.currencyRemoveEmit -> evento para quitar la moneda del itinerario.
   */
  subscribeToItineraryService(): void {
    this.itineraryService.modalClosed$.subscribe(() => {
      // console.log('actualizando nombre ciudad');
      this.cityNameForm = JSON.parse(localStorage.getItem('form'));
    });

    this.itineraryService.currencySuccessEmit
    // .pipe(
    //   take(1)
    // )
    .subscribe({
      next: (res: any) => {
        // console.log(res)
        if (res && !this.currencyItinerary) {
          this.currencyItinerary = res;
          this.hasExecutedElse = false;
          if(this.userService.itinerary.length < 2) {
            this.toastr.success(`Se ha establecido ${res} como moneda del file activo`);
          }
          this.getPagesNew(res);
        }
      }
    });

    this.itineraryService.currencyRemoveEmit.subscribe({
      next: (res: any) => {
        if (res) {
          this.hasExecutedElse = false;
          this.results = this.resultsAux;
          this.currencyItinerary = null;
          this.getPagesNew(null);
        }
      }
    });
  }



  /**
 * Método para alternar la visualización de filtros.
 *
 * Este método invierte el estado de la variable `showFilters` para mostrar u ocultar los filtros.
 */
toggleFilters() {
  this.showFilters = !this.showFilters;
}

/**
* Emisor de texto para aplicar filtros.
*
* Este método actualiza el valor de `text` y filtra los resultados
* con base en el texto proporcionado.
*
* @param text -> Texto utilizado para filtrar resultados.
*/
textEmitter(text: any) {
  this.text = text;
  this.results = this.titleFilter.transform(this.resultsAux, this.text);
  this.hasExecutedElse = false;
  this.filterResults();
  // this.getPagesNew();
}

/**
* Genera una lista de íconos de estrellas.
*
* Este método crea un arreglo con la ruta de íconos de estrellas.
*
* @returns Lista de rutas de íconos de estrellas.
*/
stars() {
  const stars = [];
  for (let i = 0; i < 5; i++) {
      stars.push('/assets/img/app/star-icon.png');
  }
  return stars;
}

/**
* Filtra resultados por clasificación de estrellas.
*
* Este método filtra los resultados según la calificación de estrellas seleccionada.
* Si no se selecciona ninguna clasificación, muestra todos los resultados.
*
* @param selectedStars -> Número de estrellas seleccionadas.
*/
filterStar(selectedStars: number) {
  this.selectedStars = selectedStars;

  if (this.selectedStars >= 0) {
      this.results = this.resultsAux.filter(result => {
          if (result.additionalInfo && result.additionalInfo.starRating) {
              const starRating = parseFloat(result.additionalInfo.starRating);
              return starRating === this.selectedStars + 1;
          }
          return false;
      });
      this.hasExecutedElse = false;
      this.filterResults();
      this.getPagesNew();
  } else {
      this.results = this.resultsAux;
  }
}

/**
* Filtra resultados por amenidades.
*
* Este método permite agregar o quitar amenidades a la lista de filtros activos
* y ajusta los resultados en consecuencia.
*
* @param event -> Estado del checkbox para una amenidad (true o false).
* @param amenitie -> Nombre de la amenidad a filtrar.
*/
filterAmenities(event: boolean, amenitie: string): void {
  if (event) {
      if (!this.selectedAmenities.includes(amenitie)) {
          this.selectedAmenities.push(amenitie);
      }
  } else {
      const index = this.selectedAmenities.indexOf(amenitie);
      if (index > -1) {
          this.selectedAmenities.splice(index, 1);
      }
  }

  this.results = this.resultsAux;

  if (this.selectedAmenities.length > 0) {
      this.results = this.results.filter(result => {
          if (result.additionalInfo && result.additionalInfo.amenities) {
              return this.selectedAmenities.every(amenity =>
                  result.additionalInfo.amenities.some(a => a.name === amenity)
              );
          }
          return false;
      });
  }

  this.hasExecutedElse = false;
  this.filterResults();
  this.getPagesNew();
}

 /**
 * Busca la cantidad de veces que aparece un valor en los resultados según el tipo especificado.
 *
 * @param value -> Valor a buscar.
 * @param type -> Tipo de filtro (marca, categoría o tipo).
 * @returns Cantidad de elementos encontrados.
 */
getQtyItem(value: any, type: any) {
  let item: any = this.results;

  switch (String(type)) {
      case 'name': // Marca
          return item.filter(o => o.additionalInfo.carRental.optionRates.suplier.name === value).length;
      case 'category': // Categoría
          return item.filter(o => o.additionalInfo.carRental.category === value).length;
      case 'type': // Puerta
          return item.filter(o => o.additionalInfo.carRental.type === value).length;
      default:
          return 0;
  }
}

/**
* Obtiene la imagen o la cantidad de elementos según el valor y el tipo proporcionados.
*
* @param value -> Valor a buscar.
* @param type -> Tipo de filtro (marca, categoría o tipo).
* @returns Imagen o cantidad de elementos encontrados según el tipo.
*/
getImgItem(value: any, type: any) {
  let item: any = this.resultsFilter;
  let imag: any;

  switch (String(type)) {
      case 'name': // Marca
          return item.filter(o => o.additionalInfo.carRental.optionRates.suplier.name === value);
      case 'category': // Categoría
          imag = item.filter(o => o.additionalInfo.carRental.category === value)
                     .map(res => res.additionalInfo.images[0].image)[0];
          return imag;
      case 'type': // Puerta
          return item.filter(o => o.additionalInfo.carRental.type === value).length;
      default:
          return null;
  }
}

/**
* Aplica los filtros y cuenta los productos según el tipo de búsqueda (hotel o rent-a-car).
*/
searchFilter() {
  this.resultFilterCount = [];
  this.totalProduct = 0;
  this.resultFilterCount = this.results;

  if (this.resultFilterCount.length > 0) {
      this.resultFilterCount.map(item => {
          switch (String(this.payload.categoriesId[0])) {
              case '1': // Hotel
                  this.totalProduct++;
                  break;
              case '2': // Rent-a-Car
                  // Filtrar por marca
                  if (item.additionalInfo.carRental.optionRates.suplier.name) {
                      this.filtersRentacar.brand.brandArray.push(item.additionalInfo.carRental.optionRates.suplier.name);
                      this.filtersRentacar.brand.brandArray = this.filtersRentacar.brand.brandArray.filter((item_, index) =>
                          this.filtersRentacar.brand.brandArray.indexOf(item_) === index
                      );
                  }
                  // Filtrar por categoría
                  if (item.additionalInfo.carRental.category) {
                      this.filtersRentacar.category.categoryArray.push(item.additionalInfo.carRental.category);
                      this.filtersRentacar.category.categoryArray = this.filtersRentacar.category.categoryArray.filter((item_, index) =>
                          this.filtersRentacar.category.categoryArray.indexOf(item_) === index
                      );
                  }
                  // Filtrar por tipo (puerta)
                  if (item.additionalInfo.carRental.type) {
                      this.filtersRentacar.type.typeArray.push(item.additionalInfo.carRental.type);
                      this.filtersRentacar.type.typeArray = this.filtersRentacar.type.typeArray.filter((item_, index) =>
                          this.filtersRentacar.type.typeArray.indexOf(item_) === index
                      );
                  }
                  this.totalProduct++;

                  // Buscar cantidad de marca
                  this.filtersRentacar.brand.brandArray.forEach((brand, index) => {
                      this.filtersRentacar.brand.brandCant[index] = this.getQtyItem(brand, 'name');
                  });

                  // Buscar cantidad de categoría y obtener imágenes
                  this.filtersRentacar.category.categoryArray.forEach((category, index) => {
                      this.filtersRentacar.category.categoryCant[index] = this.getQtyItem(category, 'category');
                      this.filtersRentacar.category.img[index] = this.getImgItem(category, 'category');
                  });

                  // Buscar cantidad de tipo (puerta)
                  this.filtersRentacar.type.typeArray.forEach((type, index) => {
                      this.filtersRentacar.type.typeCant[index] = this.getQtyItem(type, 'type');
                  });
                  break;
              default:
                  break;
          }
      });
  }
}

/**
* Filtra un arreglo según los campos y valores proporcionados.
*
* @param array -> Arreglo a filtrar.
* @param fields -> Campos a evaluar.
* @param value -> Valores correspondientes a los campos.
* @returns Arreglo filtrado.
*/
filterArray(array: any, fields: any, value: any) {
  return array.filter(item => {
      return fields.every((field, index) => {
          if (field === 'name') {
              return item.additionalInfo.carRental.optionRates.suplier[field] === value[index];
          }
          if (field === 'category' || field === 'type') {
              return item.additionalInfo.carRental[field] === value[index];
          }
          return false;
      });
  });
}

/**
* Restaura los filtros a su estado inicial.
*/
resetFilter() {
  this.countFilterApply = 0;
  this.selectedStars = 0;
  this.selectedFilters = [];
  this.resultFilterCount = [];
  this.selectedAmenities = [];
  this.filtersRentacar.category.checkedArray = [];
  this.filtersRentacar.brand.checkedArray = [];
  this.filtersRentacar.type.checkedArray = [];
}

/**
 * Método para aplicar filtros a los resultados según el tipo de categoría.
 *
 * Este método gestiona la lógica de filtrado para diferentes categorías
 * (hotel y rent-a-car), ordena los resultados por precio y actualiza los datos visibles.
 *
 * @filterInitialized -> Indica si los filtros han sido inicializados.
 * @orderPriceby -> Ordena los resultados por precio ascendente o descendente.
 * @results -> Arreglo de resultados filtrados.
 * @selectedFilters -> Filtros seleccionados para rent-a-car.
 */
filterResults() {
  this.filterInitialized = true;
  switch (String(this.payload.categoriesId[0])) {
      case '1': // HOTEL
          if (this.orderPriceby == "priceLow") {
              this.orderPriceLow();
          }
          if (this.orderPriceby == "priceHigh") {
              this.orderPriceHigh();
          }
          this.getPagesNew();
          this.searchFilter();
          break;
      case '2': // RENT-A-CAR
          if (this.selectedFilters.length > 0) {
              let results = [];
              this.selectedFilters.forEach(filter_ => {
                  results = this.filterArray(this.results, [filter_.type], [filter_.value]);
                  if (results != undefined) {
                      this.results = results;
                  }
              });
          }
          this.mapMarker();
          this.getPagesNew();
          this.searchFilter();
          this._filtersRentacar.emit(this.filtersRentacar);
          break;
      default:
          break;
  }
}

/**
 * Método para aplicar o remover filtros según la interacción del usuario.
 *
 * Dependiendo de la categoría seleccionada, actualiza los filtros aplicados
 * y ajusta la lista de resultados visibles.
 *
 * @param event -> Objeto del evento que dispara la acción (click o slider).
 * @param value -> Valor del filtro seleccionado.
 * @param type -> Tipo de filtro aplicado (por ejemplo, categoría o precio).
 * @param _id -> Identificador único del filtro.
 * @selectedFilters -> Lista de filtros activos para rent-a-car.
 */
applyFilter(event: any, value: any, type: any, _id: any) {
  let selectedFiltersAux = [];
  let filter: any;
  const id = type + '_' + _id;
  if (event.target) {
      switch (String(this.payload.categoriesId[0])) {
          case '1': // HOTEL
              if (event.target.checked) {
                  this.countFilterApply++;
              } else {
                  this.countFilterApply = Math.max(this.countFilterApply - 1, 0);
              }
              break;
          case '2': // RENT-A-CAR
              if (event.target.checked) {
                  filter = {
                      order: this.orderFilter + 1,
                      id: id,
                      type: type,
                      value: value
                  };
                  this.selectedFilters.push(filter);
              } else {
                  selectedFiltersAux = this.selectedFilters.filter(element => element.id !== id);
                  this.selectedFilters = selectedFiltersAux;
              }
              break;
          default:
              break;
      }
  } else {
      switch (String(this.payload.categoriesId[0])) {
          case '1': // Slider filter for hotel prices
              if (event.pointerType === 0) {
                  this.filters.lowPrice = event.value;
              } else {
                  this.filters.HighPrice = event.highValue;
              }
              if (event !== "") {
                  this.results = this.sliderFilter.transform(this.resultsAux, this.filterLowPrice, this.filterHighPrice);
                  (this.results as any).categoryName = this.resultsFilter.categoryName;
                  this.filterResults();
              }
              break;
          default:
              this.filterResults();
              break;
      }
  }
}

/**
 * Método para limpiar los filtros aplicados y restaurar los resultados iniciales.
 *
 * Este método elimina los filtros activos, resetea los valores y vuelve a cargar los resultados originales.
 *
 * @results -> Arreglo con los resultados visibles.
 * @selectedStars -> Filtros de estrellas seleccionados para hoteles.
 * @text -> Texto del filtro de búsqueda.
 */
clearFilter() {
  this.results = [];
  this.results = this.resultsAux;
  this.resetFilter();
  this.searchFilter();
  this.searchPriceLowAndHigh();
  this.filterResults();
  this.selectedStars = 0;
  this.text = '';
  this.resetPriceSlider();
}

/**
 * Ordena la lista de resultados en función del precio más bajo.
 *
 * Dependiendo del tipo de categoría (HOTEL, RENT-A-CAR, TOUR), aplica un algoritmo específico para ordenar:
 * - Para HOTELS: selecciona el precio más bajo por cada habitación disponible.
 * - Para RENT-A-CAR: ordena según el precio neto del alquiler del auto.
 * - Para TOURS: ordena según el precio mínimo disponible para las opciones de tour.
 *
 * @categoryId -> Identificador de la categoría para determinar el tipo de entidad a ordenar.
 * @results -> Arreglo de resultados que se ordenan según el criterio de la categoría.
 */
orderPriceLow() {
  switch (this.categoryId) {
    case 1: // HOTEL
      this.results.sort((a, b) => {
        const aRooms = a?.additionalInfo?.rooms ?? [];
        const bRooms = b?.additionalInfo?.rooms ?? [];

        const aMinPrice = aRooms.length
          ? Math.min.apply(null, aRooms.map(room => room.room.optionNightsNetTotalProf))
          : Infinity;
        const bMinPrice = bRooms.length
          ? Math.min.apply(null, bRooms.map(room => room.room.optionNightsNetTotalProf))
          : Infinity;

        return aMinPrice - bMinPrice;
      });
      break;
    case 2: // RENT-A-CAR
      this.results.sort((a, b) => a.additionalInfo.carRental.netPriceProf - b.additionalInfo.carRental.netPriceProf);
      break;
    case 6: // TOUR
      this.results.sort((a, b) => {
        return Math.min.apply(null, a.additionalInfo.tours.options.map(option => option.totalPriceProf)) -
               Math.min.apply(null, b.additionalInfo.tours.options.map(option => option.totalPriceProf));
      });
      break;
    default:
      break;
  }
  this.page = 1;
}

/**
 * Ordena la lista de resultados en función del precio más alto.
 *
 * Dependiendo del tipo de categoría (HOTEL, RENT-A-CAR, TOUR), aplica un algoritmo específico para ordenar:
 * - Para HOTELS: selecciona el precio más alto por cada habitación disponible.
 * - Para RENT-A-CAR: invierte la lista ordenada por precio neto del alquiler del auto.
 * - Para TOURS: selecciona el precio máximo disponible para las opciones de tour.
 *
 * @payload.categoriesId[0] -> Identificador de la categoría para determinar el tipo de entidad a ordenar.
 * @results -> Arreglo de resultados que se ordenan según el criterio de la categoría.
 */
orderPriceHigh() {
  switch (String(this.payload.categoriesId[0])) {
    case '1': // HOTEL
      this.results.sort((a, b) => {
        const aRooms = a?.additionalInfo?.rooms ?? [];
        const bRooms = b?.additionalInfo?.rooms ?? [];

        const aMinPrice = aRooms.length
          ? Math.min.apply(null, aRooms.map(room => room.room.optionNightsNetTotalProf))
          : Infinity;
        const bMinPrice = bRooms.length
          ? Math.min.apply(null, bRooms.map(room => room.room.optionNightsNetTotalProf))
          : Infinity;

        return bMinPrice - aMinPrice;
      });
      break;
    case '2': // RENT-A-CAR
      this.results.reverse((a, b) => b.additionalInfo.carRental.netPriceProf - a.additionalInfo.carRental.netPriceProf);
      break;
    case '6': // TOUR
      this.results.reverse((a, b) => {
        return Math.max.apply(null, a.additionalInfo.tours.options.map(option => option.totalPriceProf)) -
               Math.max.apply(null, b.additionalInfo.tours.options.map(option => option.totalPriceProf));
      });
      break;
    default:
      break;
  }
  this.page = 1;
}

 /**
 * @method searchPriceLowAndHigh
 * @description Searches for the lowest and highest prices within the results based on category.
 * Populates `filterLowPrice` and `filterHighPrice` with the respective values.
 * Updates `optionsSliderPrice` to set the slider range between the minimum and maximum prices.
 */
searchPriceLowAndHigh() {
  let resulPriceLow = [];
  let resulPriceHigh = [];

  if (this.results.length > 0) {
      switch (String(this.payload.categoriesId[0])) {
          case '1': // HOTEL
              resulPriceLow = this.results.map((data: any) => {
                  if (data.additionalInfo) {
                      const lowestPrice = Math.min.apply(
                          null,
                          data.additionalInfo.rooms.map((room: any) =>
                              room?.room?.optionNightsNetTotalProf || Infinity
                          )
                      );
                      return lowestPrice !== Infinity ? lowestPrice : undefined;
                  }
                  return undefined;
              });

              const flattenedPrices = resulPriceLow.reduce((acc: any[], val: any[]) => acc.concat(val), []);
              const validPrices = flattenedPrices.filter((price: any) => price !== undefined);
              const minPrice = Math.min(...validPrices);
              resulPriceLow = [minPrice];

              resulPriceHigh = this.results.map((data: any) => {
                  if (data && data.additionalInfo) {
                      const highestPrice = Math.max.apply(
                          null,
                          data.additionalInfo.rooms.map((room: any) =>
                              room?.room?.optionNightsNetTotalProf || -Infinity
                          )
                      );
                      return highestPrice !== -Infinity ? highestPrice : undefined;
                  }
                  return undefined;
              });

              const flattenedPricesHigh = resulPriceHigh.reduce((acc: any[], val: any[]) => acc.concat(val), []);
              const validHighPrices = flattenedPricesHigh.filter((price: any) => price !== undefined);
              const maxPrice = Math.max(...validHighPrices);
              resulPriceHigh = [maxPrice];

              this.filterLowPrice = Math.floor(Math.min.apply(null, resulPriceLow));
              this.filterHighPrice = Math.floor(Math.max.apply(null, resulPriceHigh));
              break;

          case '6': // TOURS
              this.filterLowPrice = this.results.map(el => el.additionalInfo.tours.options[0].netPriceProf)
                  .reduce((prevEl, el) => Math.min(prevEl, el));
              this.filterHighPrice = this.results.map(el => el.additionalInfo.tours.options[0].netPriceProf)
                  .reduce((prevEl, el) => Math.max(prevEl, el));
              break;

          case '2': // RENT-A-CAR
              this.filterLowPrice = this.results.map(el => el.additionalInfo.carRental.netPriceProf)
                  .reduce((prevEl, el) => Math.min(prevEl, el));
              this.filterHighPrice = this.results.map(el => el.additionalInfo.carRental.netPriceProf)
                  .reduce((prevEl, el) => Math.max(prevEl, el));
              break;

          default:
              break;
      }

      this.optionsSliderPrice.floor = this.filterLowPrice;
      this.optionsSliderPrice.ceil = this.filterHighPrice;
  }
}

/**
* @method setCarGroup
* @description Filters the car rental results to remove duplicates based on the title property.
* Ensures that only unique entries remain in the results.
*/
setCarGroup() {
  this.results = this.results.filter((data, index, j) =>
      index === j.findIndex(t => t.title == data.title)
  );
}

/**
 * Método para obtener y filtrar resultados basados en la moneda seleccionada.
 *
 * Este método permite filtrar resultados según la moneda proporcionada y manejar
 * los casos en los que no hay resultados disponibles o los datos deben ser recargados.
 * Además, controla la visualización de mensajes y redirecciones según el contexto.
 *
 * @param currency (opcional) - Moneda utilizada para filtrar los resultados.
 * Si no se proporciona, se utiliza la moneda del itinerario activo.
 * @param reload (opcional) - Indica si los datos deben recargarse tras la ejecución.
 *
 * Variables:
 * - `currencyFilterEmpty`: Booleano que determina si el filtro de moneda no arrojó resultados.
 * - `currencyFilterNewResultEmpty`: Booleano que identifica si no hay resultados para la moneda actual.
 *
 * Lógica:
 * 1. Se establece la moneda a filtrar si no se especifica.
 * 2. Se filtran los resultados según la moneda.
 * 3. Se muestran mensajes y modales si no hay resultados para la moneda seleccionada.
 * 4. Se asignan resultados a `resultPaged` según el origen de la búsqueda.
 * 5. Se manejan redirecciones y validaciones de categoría según el valor de `reload`.
 */
  getPagesNew(currency?: any, reload?: boolean) {

    let currencyFilterEmpty: boolean = false;
    let currencyFilterNewResultEmpty: boolean = false;
    this.resultPaged = [];

    if(currency !== null) {
      if(!currency) {
        currency = this.userService?.itineraryActive[0]?.currency;
        // currency = 'ARS';
      }
      if (currency) {
        // Filtrar los objetos que cumplen con la condición
        this.results = this.results.filter((item: any) => {
          // console.log(item)
          if(item && item.additionalInfo) {
            // return item.some((element: any) => {
              // Comprobar si el elemento tiene la propiedad adicional y si el currency coincide
              const currencyItem = item.additionalInfo?.rooms?.[0]?.room?.pricing?.currency;
              return currencyItem === currency;
            // });
          }
        });
        if(this.totalResponse > 0 && this.results.length === 0) {
          this.toastr.info('No hay resultados para la moneda establecida en el file');

          let textAlert = '';

          if(this.userService.itinerary.length > 0) {
            textAlert = 'Será redireccionado al detalle de la reserva';
          } else if(this.userService.itinerary.length === 0) {
            textAlert = 'Será redireccionado al inicio';
          }
          const initialState: ModalOptions = {
            initialState: {
              data: textAlert
            }
          }
          const modalOptions = Object.assign({}, initialState, this.config);
          this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);
          currencyFilterEmpty = true;
          currencyFilterNewResultEmpty = true;
          this.confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
            if(result) {
              if(this.userService?.itinerary.length > 0) {
                this.route.navigate(['/summary']);
              } else if(this.userService?.itinerary.length === 0) {
                this.route.navigate(['/home']);
              }
            }
          });
          return;
        } else if(!currencyFilterNewResultEmpty && this.results.length === 0) {
          this.toastr.info('No hay resultados para la moneda establecida en el file');
          let textAlert = '';

          if(this.userService.itinerary.length > 0) {
            textAlert = 'Será redireccionado al detalle de la reserva';
          } else if(this.userService.itinerary.length === 0) {
            textAlert = 'Será redireccionado al inicio';
          }
          const initialState: ModalOptions = {
            initialState: {
              data: textAlert
            }
          }
          const modalOptions = Object.assign({}, initialState, this.config);
          this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);
          currencyFilterEmpty = true;
          this.confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {

            if(result) {
              if(this.userService?.itinerary.length > 0) {
                this.route.navigate(['/summary']);
              } else if(this.userService?.itinerary.length === 0) {
                this.route.navigate(['/home']);
              }
            }
          });
          return;
        } else if(this.first_charge || this.userService?.itinerary.length < 2) {
          this.first_charge = false;
          this.toastr.info('Resultados filtrados por moneda establecida de file');
        }

      }


    }
    if (this.results.length > 0) {
      const searchID =
        this.results["searchId"] === null || this.results["searchId"] === undefined
          ? this.results[0]["searchId"]
          : this.results["searchId"];
      if(this.origin === 'HOTELS') {
        // if(this.first_charge === false) {
          this.resultPaged = [this.results];

        // } else {
          // this.resultPaged = this.results;
        //   this.first_charge = false;
        // }
        this._items = this.results.length;
      } else {
        this.resultPaged = this.results;
        //  this._colocarSearchIdEnlosHoteles(this.results);
        this._items = this.results.length;
      }
      if (this.index == 0) {
        this._emitLoading(false);
      }
      if(reload === true) {
        this.handleCategoryValidation(); // Validación según la categoría seleccionada
      }
      this._total = this.resultPaged.length;
      if(reload === false) {
        this._setArrivalAndDepartureDate(this.resultPaged, this.results.form);
        this.serviceItinerary.sendMessageSubscriptorItemSelected(this.results.destination, this.categoryName);
      }

    } else {
      if(!currencyFilterEmpty) {
        if(this.origin == 'RENT-A-CAR'){
          this.filterInitialized = false;
        }
        if(this.filterInitialized === false) {
          const initialState: ModalOptions = {
            initialState: {
              data: 'No se encontraron datos, para los parámetros suministrados'
            }
          }
          const modalOptions = Object.assign({}, initialState, this.config);
          this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);

            this.confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
              if(result) {
                this.route.navigate(['/home']);
              }
            });
        } else {
          // console.log('estoy sin resultados y con el filtro')
          if(this.hasExecutedElse === false) {
            this.hasExecutedElse = true;
            const initialState: ModalOptions = {
              initialState: {
                data: 'No se encontraron datos, para los parámetros suministrados'
              }
            }
            const modalOptions = Object.assign({}, initialState, this.config);
            this.bsModalRef = this.modalService.show(MaterialModalAlertComponent,  modalOptions);
            this.clearFilter();

           }

        }
      }
      }
  }

  /**
 * Capitaliza la primera letra de una cadena de texto.
 *
 * @param str - Cadena de texto a procesar.
 * @returns Cadena con la primera letra en mayúscula.
 */
capitalizeFirstLetter(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Alterna el estado de colapso del contenido.
 */
toggleCollapse() {
  this.contentCollapsed = !this.contentCollapsed;
}

/**
 * Emite un evento con el payload proporcionado.
 *
 * @param payload - Datos a emitir.
 */
_onPayload(payload: any) {
  this.payload_.emit(payload);
}

/**
 * Emite un evento con los resultados proporcionados.
 *
 * @param results - Resultados a emitir.
 */
_onResults(results: any) {
  this.results_.emit(results);
}

/**
 * Emite un evento para cambiar el estado de carga.
 *
 * @param isLoading - Estado del proceso de carga.
 */
_onLoading(isLoading: boolean) {
  this.isLoading.emit(isLoading);
}

/**
 * Emite un evento con el origen proporcionado.
 *
 * @param origin - Origen a emitir.
 */
_onOrigin(origin: any) {
  this._origin.emit(origin);
}

/**
 * Cambia la página actual y desplaza la ventana al inicio.
 *
 * @param page - Número de página al que se debe cambiar.
 */
onPageChange(page: number) {
  this.page = page;
  window.scrollTo(0, 0);
}

/**
 * Abre un modal con información del producto.
 *
 * @param result - Datos del producto para mostrar en el modal.
 */
openModal(result: any) {
  var dialogRef = this.dialog.open(ModalsComponent, {
    height: "680px",
    width: "900px",
    data: {
      modalRoomRef: this.modalRef,
      product: result,
      productQty: this.payload.qtyProduct,
      form: this.results.form,
      origin: "HOTELS",
      arrayResults: this.results,
    },
    // disableClose: true
  });

  dialogRef.afterClosed().subscribe((result) => {
    // Acción a realizar después de cerrar el modal
  });
}


  moveMapToCoordinates(hotelSelected) {
    // console.log(hotelSelected);

    // let icon = `<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
    // <image href="assets/logo.png" height="200" width="200"/>
    // </svg>`;

    // //se inicializa el mapa con zoom 4
    // let geocoder: any;
    // let mapa: any;
    // let infowindow = null;
    // let currentInfoWindows = null;
    // let mapOptions = {
    // center: new google.maps.LatLng(28.3256749, -81.51555309999999),
    // zoom: 4,
    // mapTypeId: google.maps.MapTypeId.ROADMAP,
    // zoomControl: true,
    // };

    // mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
    // // const resultados = await this.resultPaged;
    // const resultados = this.results;


    // for (let i = 0; i < resultados.length; i++) {
    //   const item = hotelSelected;
    //   const data = {
    //   image: item.additionalInfo.images.length > 0 ? item.additionalInfo.images[0].image : "",
    //   title: item.title ? item.title : "",
    //   rooms: item.additionalInfo.rooms ? item.additionalInfo.rooms : null,
    //   netPriceProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPriceProf : "",
    //   netPrice: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPrice : "",
    //   roomPackageRefPriceProf: item.additionalInfo.roomsPackage ? item.additionalInfo.roomsPackage[0].netPriceProf : "",
    //   optionNightsNetTotal: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotal : "",
    //   // tslint:disable-next-line:max-line-length
    //   optionNightsNetTotalProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotalProf : "",
    //   blickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].blickoins : "",
    //   creditBlickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].creditBlickoins : "",
    //   percentage: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].percentage : "",
    //   latlng: item.coordinates ? item.coordinates : "",

    // };


    //   geocoder = new google.maps.Geocoder();
    //   const title = item.title;
    //   let addIcon = "favorite_border";
    //   // (click)="openModal(${item})"
    //   const infowindow = new google.maps.InfoWindow({
    //       content: `
    //       <div id="hotel-result-component" class="d-flex justify-content-between" style="width: 30rem;height: 12em; background: white !important;">
    //         <div class="d-flex container-img" style="width:40% !important; overflow: hidden">
    //           <img class="img-responsive" style="width: 80%;height: 100% !important; object-fit: contain" src="${
    //           data?.image
    //           }" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
    //         </div>
    //         <div class="d-flex flex-column container-detalles p-3" style="width: 60% !important;">
    //           <div class="d-flex justify-content-end">
    //             <span class="material-icons add-icon add_circle" id="fav${i}"></span>
    //             <span class="material-icons add-icon cancel" id="close"></span>

    //         </div>
    //          <div class="d-flex" style="max-height: 50px; overflow: hidden;margin-top: .7em;">
    //           <p class="m-0"
    //             style="font-size: 18px; letter-spacing: 0px; line-height: 1; color: #272727; font-weight: 600; font-family: "Poppins", sans-serif;"
    //             title="${data.title}">${data.title}</p>
    //         </div>
    //         <div class="d-flex flex-column">
    //           <div class="d-flex">
    //             <p class="m-0" style="font-size: 20px; letter-spacing: -1px; line-height: 1; color: #272727; font-weight: 600; font-family: "Poppins", sans-serif;">USD <span>${
    //               data?.netPriceProf ? data.netPriceProf : ""
    //             }</span></p>
    //         </div>
    //         <div>
    //          <p style="font-size: 10px; font-weight: 500;margin-bottom:0;">Estadia total</p>
    //         </div>

    //         <!-- <div class="d-flex flex-column">
    //           <p class="m-0" style="font-size:10px; color: red;  font-weight: bold;">P. Costo:&nbsp;${data.netPrice}</p>
    //           <p class="m-0" style="font-size: 13px !important; color: #272727; font-weight: 600; font-family: "Poppins", sans-serif;">P/Night</p>
    //         </div> -->
    //         </div>
    //           <div class="d-flex justify-content-between margin-bottom: 5px;">
    //           <div class="d-flex align-items-middle">
    //             <i class="icon icon-medal mr-1"></i>
    //             <span class="font-point d-flex align-items-center">
    //               ${data.blickoins ? data.blickoins : "X"}&nbsp;Bks
    //             </span>
    //             <span class="font-point d-flex align-items-center">
    //               <p class="p-night m-0 pl-2">$&nbsp;${data.blickoins ? data.creditBlickoins : "X"}</p>
    //               </span>
    //           </div>
    //           <div class="d-flex flex-row">
    //           <!--  <span class="font-desc" style=" font-family: 'Lato', sans-serif  !important;">15</span>
    //             <i class="icon icon-descuento"></i> -->
    //           </div>
    //           </div>
    //         </div>
    //       </div>

    //   `,
    //   });

    //   const latlng = {
    //   lat: parseFloat(data.latlng.latitude),
    //   lng: parseFloat(data.latlng.longitude),
    //   };

    //   // setTimeout( () => {
    //   geocoder.geocode({ location: latlng }).then((response) => {
    //     if (response.results[0]) {
    //     const marker = new google.maps.Marker({
    //       position: latlng,
    //       map: mapa,
    //       title: title,
    //       draggable: false,
    //       label: {
    //         text: "USD " + data.netPriceProf.toString(),
    //         className: "tooltip-one",
    //       },
    //       icon: " ",
    //   });

    //   this.markerArray.push(marker);

    //   if (hotelSelected) {
    //     // Cerrar la ventana de información si se hace clic afuera del modal
    //     const closeInfoWindowOnOutsideClick = (event: any) => {
    //       const infowindowContent = document.querySelector('.gm-style-iw') as HTMLElement;
    //       if (infowindowContent && !infowindowContent.contains(event.target)) {
    //         infowindow.close();
    //         document.removeEventListener('click', closeInfoWindowOnOutsideClick);
    //       }
    //     };

    //     // Agregar evento de clic al documento para cerrar la ventana de información al hacer clic afuera
    //     document.addEventListener('click', closeInfoWindowOnOutsideClick);

    //     if (currentInfoWindows) {
    //       currentInfoWindows.close();

    //     }

    //     infowindow.open(mapa, marker);
    //     currentInfoWindows = infowindow;
    //   }


    //   marker.addListener('click', () =>{

    //     if(currentInfoWindows){
    //     currentInfoWindows.close()
    //     }

    //     infowindow.open(mapa, marker);
    //     currentInfoWindows = infowindow;
    //   });

    //   google.maps.event.addListener(infowindow, "domready", () => {
    //     const el = document.getElementById("fav" + i);
    //     const close = document.getElementById("close");
    //     el.addEventListener("click", (event) =>{
    //       this.openModal(item);
    //   })

    //   close.addEventListener('click', (event) =>{
    //     infowindow.close();
    //   })

    //   });

    //   google.maps.event.trigger(mapa, "resize");
    //     mapa.panTo(marker.getPosition());
    //     mapa.setZoom(10);
    //     } else {
    //     console.log("No se obtuvo la ubicacion del mapa: " + status);
    //     }
    //     });
    //   // }, 2000 * i);

    //          // Abre la ventana de información cuando se carga hotelSelected


    //   }
    //   mapa.set("parent", this);



}

ngAfterViewInit() {
  this.mapMarker();
}


async mapMarker() {
  // if (!this.gmapElement) {
  //   return;
  // }

  // let icon = `<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
  //               <image href="assets/logo.png" height="200" width="200"/>
  //             </svg>`;

  // // Inicializa el mapa con zoom 4
  // let geocoder: any;
  // let mapa: any;
  // let infowindow = null;
  // let currentInfoWindows = null;
  // let mapOptions = {
  //   center: new google.maps.LatLng(28.3256749, -81.51555309999999),
  //   zoom: 4,
  //   mapTypeId: google.maps.MapTypeId.ROADMAP,
  //   zoomControl: true,
  // };

  // mapa = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);

  // const resultados = await this.results;

  // for (let i = 0; i < resultados.length; i++) {
  //   const item = resultados[i];
  //   const data = {
  //     image: item.additionalInfo.images.length > 0 ? item.additionalInfo.images[0].image : "",
  //     title: item.title ? item.title : "",
  //     rooms: item.additionalInfo.rooms ? item.additionalInfo.rooms : null,
  //     netPriceProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPriceProf : "",
  //     netPrice: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].netPrice : "",
  //     roomPackageRefPriceProf: item.additionalInfo.roomsPackage ? item.additionalInfo.roomsPackage[0].netPriceProf : "",
  //     optionNightsNetTotal: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotal : "",
  //     optionNightsNetTotalProf: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].optionNightsNetTotalProf : "",
  //     blickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].blickoins : "",
  //     creditBlickoins: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].creditBlickoins : "",
  //     percentage: item.additionalInfo.rooms ? item.additionalInfo.rooms[0].percentage : "",
  //     latlng: item.coordinates ? item.coordinates : "",
  //   };

  //   geocoder = new google.maps.Geocoder();
  //   const title = item.title;
  //   let addIcon = "favorite_border";
  //   const infowindow = new google.maps.InfoWindow({
  //     content: `
  //     <div id="hotel-result-component" class="d-flex justify-content-between" style="width: 30rem;height: 12em; background: white !important;">
  //       <div class="d-flex container-img" style="width:40% !important; overflow: hidden">
  //         <img class="img-responsive" style="width: 100%;height: 100% !important;" src="${data?.image}" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">
  //       </div>
  //       <div class="d-flex flex-column container-detalles p-3" style="width: 60% !important;">
  //         <div class="d-flex justify-content-end">
  //           <span class="material-icons add-icon add_circle" id="fav${i}"></span>
  //           <span class="material-icons add-icon cancel" id="close"></span>
  //         </div>
  //         <div class="d-flex" style="max-height: 50px; overflow: hidden;margin-top: .7em;">
  //           <p class="m-0" style="font-size: 18px; letter-spacing: 0px; line-height: 1; color: #272727; font-weight: 600; font-family: 'Poppins', sans-serif;" title="${data.title}">${data.title}</p>
  //         </div>
  //         <div class="d-flex flex-column">
  //           <div class="d-flex">
  //             <p class="m-0" style="font-size: 20px; letter-spacing: -1px; line-height: 1; color: #272727; font-weight: 600; font-family: 'Poppins', sans-serif;">USD <span>${data?.netPriceProf ? data.netPriceProf : ""}</span></p>
  //           </div>
  //           <div>
  //             <p style="font-size: 10px; font-weight: 500;margin-bottom:0;">Estadia total</p>
  //           </div>
  //         </div>
  //         <div class="d-flex justify-content-between margin-bottom: 5px;">
  //           <div class="d-flex align-items-middle">
  //             <i class="icon icon-medal mr-1"></i>
  //             <span class="font-point d-flex align-items-center">${data.blickoins ? data.blickoins : "X"}&nbsp;Bks</span>
  //             <span class="font-point d-flex align-items-center">
  //               <p class="p-night m-0 pl-2">$&nbsp;${data.blickoins ? data.creditBlickoins : "X"}</p>
  //             </span>
  //           </div>
  //           <div class="d-flex flex-row"></div>
  //         </div>
  //       </div>
  //     </div>`,
  //   });

  //   const latitude = parseFloat(data.latlng.latitude);
  //   const longitude = parseFloat(data.latlng.longitude);

  //   if (!isNaN(latitude) && !isNaN(longitude)) {
  //     const latlng = {
  //       lat: latitude,
  //       lng: longitude,
  //     };

  //     geocoder.geocode({ location: latlng }).then((response) => {
  //       if (response.results[0]) {
  //         const marker = new google.maps.Marker({
  //           position: latlng,
  //           map: mapa,
  //           title: title,
  //           draggable: false,
  //           label: {
  //             text: "USD " + data.netPriceProf.toString(),
  //             className: "tooltip-one",
  //           },
  //           icon: " ",
  //         });

  //         this.markerArray.push(marker);

  //         marker.addListener('click', () => {
  //           if (currentInfoWindows) {
  //             currentInfoWindows.close();
  //           }

  //           infowindow.open(mapa, marker);
  //           currentInfoWindows = infowindow;
  //         });

  //         google.maps.event.addListener(infowindow, "domready", () => {
  //           const el = document.getElementById("fav" + i);
  //           const close = document.getElementById("close");
  //           el.addEventListener("click", (event) => {
  //             this.openModal(item);
  //           });

  //           close.addEventListener('click', (event) => {
  //             infowindow.close();
  //           });
  //         });

  //         google.maps.event.trigger(mapa, "resize");
  //         mapa.panTo(marker.getPosition());
  //         mapa.setZoom(10);
  //       } else {
  //         console.log("No se obtuvo la ubicacion del mapa: " + status);
  //       }
  //     });
  //   } else {
  //     // console.error("Invalid latlng:", latitude, longitude);
  //   }
  // }
  // mapa.set("parent", this);
}


  _getCitys(param: any) {
 /*   this._restService.doGet("api", "/searchlocations/" + param).subscribe(
      (res) => {
        for (let index = 0; index < res["Cities"].length; index++) {
          if (res["Cities"][index]["cityIataCode"] === param) {
            this.ori = res["Cities"][index]["cityName"];
          }
        }
      },
      (Error) => {
        console.log("Error", Error);
      }
    );*/
  }
/**
 * Establece el itinerario actual, ya sea desde el servicio `userService` o llamando al servicio `serviceItinerary` para obtenerlo.
 * Si no hay un itinerario activo, se realiza una suscripción para obtener el itinerario.
 */
setItinerary() {
  if (this.userService.itineraryActive.length > 0) {
    this._actualItinerary = this.userService.itineraryActive[0];
  } else {
    let itinerary$ = this.serviceItinerary.getItinerary$();
    itinerary$.subscribe(
      (data: any) => {
        if (data !== undefined && data !== null) {
          if (data[0] !== undefined) {
            this._actualItinerary = data[0];
          }
        }
      },
      (error) => {
        console.log("Error: ", error);
      }
    );
  }
}

/**
 * Emite un evento para actualizar la vista del mapa.
 *
 * @param event - Valor booleano que indica el estado de la vista del mapa.
 */
_emitViewMap(event: boolean) {
  this.emitViewMap.emit(event);
}

/**
 * Emite un evento para realizar una nueva búsqueda.
 *
 * @param event - Evento que desencadena la acción de búsqueda.
 */
_emitReSearch(event: Event) {
  this.emitReSearch.emit(event);
}

/**
 * Realiza una búsqueda de itinerarios con los parámetros proporcionados.
 * La búsqueda puede ser recargada o refrescada según los parámetros opcionales `reload` y `refresh`.
 *
 * @param payload - Datos para la búsqueda.
 * @param reload - Determina si se debe recargar la búsqueda.
 * @param refresh - Determina si se debe refrescar la búsqueda.
 */
reloadSearch(payload: any, reload?: boolean, refresh?: boolean): void {
  // console.log("Iniciando búsqueda con payload:", payload);
  if(!this.noLoadingForModal) {

    this.arrivalDate = payload.arrivalDate;
    this.departureDate = payload.departureDate;

    if (reload === false) {
      this.contentCollapsed = true;
      this.results = [];
      this.resultPaged = [];
      this._onPayload(payload);
      this.payload = payload;
      this._emitLoading(true);
      this.modalPax._initLoadData(payload);

      if (refresh) {
        refresh = false;
        this.subscribeReloadSearch();
      }
    }

    if (this.modalSearch && reload === false) {
      this.modalSearch._initLoadData(payload);
    }

    if (reload === true) {
      this.resultReload = true;
    }

    this._restService.doPost("app", "search", payload).subscribe({
      next: (response: any) => {
        // console.log("Respuesta recibida:", response);
        if(!this.noLoadingForModal) {

          if (response && response.results) {
            // console.log(response.results.length)
            const totalResult = {...response.results.length};
            // console.log(totalResult)
            this.totalResponse = totalResult;
            // console.log(this.totalResponse)
            this.results = response.results;

            if (this.payload.categoriesId[0] === 1) {
              this.results.sort((a, b) => {
                const valueA = a.additionalInfo.rooms[0]?.optionNightsNetTotalProf || 0;
                const valueB = b.additionalInfo.rooms[0]?.optionNightsNetTotalProf || 0;
                return valueA - valueB;
              });
            }

            if (this.results.length > 0) {
              this.getPagesNew(this.currencyItinerary, reload);

              if (reload) {
                if (this.cancelResearch(response.results.length)) {
                  this.reloadAgain = false;
                  this.finishReload = true;
                  // console.log(this.finishReload)
                  this.reloadAgainSubject.next(false);
                  this.textBarCharge = 'Carga de resultados completada';
                } else {
                  // console.log("Recargando búsqueda...");
                  this.reloadAgain = true;
                  this.processSearchReload();
                }
              } else {
                this._emitLoading(false);
                this.itineraryService.notifyModalClosed(false);
              }
            } else {
              this.handleNoResults();
            }
          } else {
            this.handleNoResults();
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error("Error en la búsqueda:", error);
        this.isSearchRunning = false; // Marcar que la búsqueda terminó con error
        if (!reload) {
          this._emitLoading(false);
          // this.showErrorModal();
        } else {
          // console.log("Error en recarga. Deteniendo proceso.");
          this.reloadAgain = false;
          this.reloadAgainSubject.next(false);
        }
      },
      complete: () => {
        this.isSearchRunning = false; // Marcar que la búsqueda terminó
      },
    });
  }
}

/**
 * Maneja el caso cuando no se encuentran resultados en la búsqueda.
 * Detiene el proceso y muestra un modal indicando que no se encontraron resultados.
 */
handleNoResults(): void {
  // console.log("No se encontraron resultados. Deteniendo el proceso.");
  this.reloadAgain = false;
  this.reloadAgainSubject.next(false);

  const initialState: ModalOptions = {
    initialState: { message: 'No se encontraron Resultados' },
  };
  this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
}

/**
 * Cancela la investigación si la longitud de los resultados no ha cambiado.
 *
 * @param responseLength - Longitud de los resultados obtenidos.
 * @returns `true` si no ha habido cambios, `false` en caso contrario.
 */
cancelResearch(responseLength: number): boolean {
  if (this.userService?.itineraryActive[0]?.currency) {
    // if(this.OldResults === this.results.length) {
      return true;
    // } else {
    //   return false;
    // }
  }

  if (responseLength === this.results.length) {
    return true;
  }

  return false;
}

/**
 * Asocia un `searchId` a cada hotel en los resultados de la búsqueda.
 *
 * @param response - Respuesta de la búsqueda que contiene los hoteles.
 */
_colocarSearchIdEnlosHoteles(response: any) {
  response.results.forEach((item) => {
    item.searchId = response.searchId;
  });
}

/**
 * Establece la cantidad de niños para la búsqueda, activando la edición.
 *
 * @param qty - Cantidad de niños (no se utiliza en este método, pero se activa la edición).
 */
setQtyChildren(qty: any) {
  this.editable = true;
}

/**
 * Obtiene la cantidad de niños en la configuración de habitaciones de la búsqueda.
 *
 * @returns El número total de niños en la configuración de habitaciones.
 */
getQtyChildren() {
  let children = 0;
  const rooms = this.payload.others.hotels.roomConfiguration;
  rooms.forEach((room) => {
    children += room["children"].length;
  });
  return children;
}

/**
 * Establece la categoría de la búsqueda en función de la respuesta.
 *
 * @param searchResponse - La categoría recibida de la respuesta.
 */
setCategory(searchResponse: string) {
  switch (searchResponse) {
    case Procesed.TOURS:
      this.category = "Tours";
      break;
    case Procesed.HOTELS:
      this.category = "Hotels";
      break;
    case Procesed.TRANSPORTS:
      this.category = "Transports";
      break;
    case Procesed.INSURANCE:
      this.category = "Insurance";
      break;
  }
}


 /**
 * Emite el estado de carga.
 *
 * @param isLoading - Indica si la carga está en proceso (`true`) o ha terminado (`false`).
 */
_emitLoading(isLoading: boolean) {
  this.loaded = isLoading;
  this.isLoading.emit(isLoading);
}

/**
 * Establece las fechas de llegada y salida en los elementos de la respuesta.
 *
 * @param response - Respuesta que contiene los elementos a los que se les asignarán las fechas.
 * @param form - El formulario que contiene las fechas de llegada y salida.
 */
_setArrivalAndDepartureDate(response: any, form: any) {
  if (form !== undefined) {
    /*const arrival = form.value['arrivalDate'] !== undefined ? form.value['arrivalDate'] : form.value['firstArrivalDate'];
    const departure = form.value['arrivalDate'] !== undefined ? form.value['departureDate'] : form.value['secondArrivalDate'];
    response.forEach(item => {
      item.dateIni = moment(arrival.toString().split(',')[0]).format('YYYY-MM-DD');
      item.dateEnd = moment(departure.toString().split(',')[0]).format('YYYY-MM-DD');
    }); */
  }
  this.arrayOut.emit(this.resultPaged);
}

/**
 * Cierra o abre el colapso de un contenedor de información.
 *
 * @param close - Si es `true`, cierra el contenedor; si es `false`, lo abre.
 */
closeCollapse(close: boolean) {
  this.contentCollapsed = close;
}

/**
 * Devuelve la URL de la imagen asociada a un parque temático basado en su título.
 *
 * @param title - El título del parque temático.
 * @returns La URL de la imagen correspondiente al parque temático.
 */
getUrlImagePark(title: string) {
  let url = "assets/img/app/parque-tematico.jpg"; // default
  if (title !== undefined && title !== null) {
    title = title.toUpperCase();
    if (title.includes("UNIVERSAL")) {
      return (url = "assets/img/app/universal-ptheme.jpg");
    } else if (title.includes("DISNEY")) {
      return (url = "assets/img/app/walt-disney-world.jpg");
    } else if (title.includes("SEAWORLD")) {
      return (url = "assets/img/app/seaword-park-theme.jpg");
    } else if (title.includes("DISCOVERY COVE")) {
      return (url = "assets/img/app/pthemes-discovery-cove.png");
    } else if (title.includes("LEGOLAND")) {
      return (url = "assets/img/app/legoland-florida.jpg");
    } else if (title.includes("VOLCANO BAY")) {
      return (url = "assets/img/app/themeparks-volcanobay.png");
    } else if (title.includes("ISLAND")) {
      return (url = "assets/img/app/island-aventure-themeparks.png");
    }
    return url;
  }
}

/**
 * Abre el modal de comparación de elementos si se cumplen las condiciones.
 *
 * Verifica que la cantidad de elementos a comparar sea 2 o 3 antes de permitir la comparación.
 */
toCompare() {
  if (this.itemToCompare.length < 2 || this.itemToCompare.length > 3) {
    //this.toastr.error('You can only make a comparison with 2 or 3 articles');
  } else {
    //abrir modal
    this.modalCompare.show();
  }
}

/**
 * Agrega o elimina un artículo de la lista de comparación.
 *
 * @param item - El artículo que se agregará o eliminará de la lista de comparación.
 */
addToCompare(item: any) {
  if (item.action == "add") {
    if (this.itemToCompare.length <= 3) {
      this.itemToCompare.push(item.item);
      if (this.itemToCompare.length == 3) {
        this.disbaledCompare = true;
      }
    }
  } else {
    let index = this.itemToCompare.findIndex((val) => val.title == item.item.title);
    this.itemToCompare.splice(index, 1);
    this.disbaledCompare = false;
  }
}


  modalCompareClose(event: any) {
    this.modalCompare.hide();
    //this.toastr.success('Product added successfully to your itinerary');
  }

  public changePage(event: any) {
    this._current = event;
  }

  public more() {
    this._currentGlobal++;
  }

  /**
 * Método para calcular la rentabilidad de diferentes tipos de servicios.
 *
 * Este método procesa la rentabilidad para varios tipos de servicios (hoteles, paquetes, tours, transportes, alquiler de coches, entre otros),
 * haciendo peticiones al backend para obtener los datos de rentabilidad y actualizando los objetos correspondientes.
 *
 * @param results - Arreglo de elementos (de tipo `any`) que contienen la información de los servicios.
 * @returns void
 *
 * El método maneja diferentes tipos de servicios:
 * - HOTELS: Actualiza la rentabilidad de habitaciones y paquetes de habitaciones.
 * - MULTIDESTINATION, TRANSPORTS: Actualiza la rentabilidad de transportes y vehículos.
 * - TOURS: Actualiza la rentabilidad de opciones de tours.
 * - RENT-A-CAR: Actualiza la rentabilidad de alquileres de coches.
 * - PACKAGES: Actualiza la rentabilidad de paquetes turísticos.
 * - DISNEY: Actualiza la rentabilidad de habitaciones en destinos Disney.
 * - PTHEMES: Actualiza la rentabilidad de opciones de parques temáticos.
 * - INSURANCE: Actualiza la rentabilidad de seguros.
 */
setProfitability(results: any) {
  results.forEach((item) => {
    switch (this.origin) {
      case "HOTELS":
        // Si es room
        if (item.additionalInfo.rooms) {
          this.serviceItinerary
            .getProfitability(item.additionalInfo.rooms[0].netPrice, item.categoryId, item.additionalInfo.rooms[0].providerId)
            .subscribe((data: any) => {
              item.additionalInfo.rooms[0].netPriceProf = parseFloat(data["totalPrice"]);
              item.additionalInfo.rooms[0].percentage = parseFloat(data["percentage"]);
              item.additionalInfo.rooms[0].blickoins = parseFloat(data["blickoins"]);
              item.additionalInfo.rooms[0].creditBlickoins = parseFloat(data["creditBlickoins"]);
            });
        }

        // Si es package
        if (item.additionalInfo.roomsPackage) {
          this.serviceItinerary
            .getProfitability(item.additionalInfo.roomsPackage[0].netPrice, item.categoryId, item.providerId)
            .subscribe((data: any) => {
              item.additionalInfo.roomsPackage[0].netPriceProf = data.split("-")[0];
              item.additionalInfo.roomsPackage[0].percentage = data.split("-")[2];
            });
          item.additionalInfo.roomsPackage.forEach((room) => {
            this.serviceItinerary.getProfitability(room.optionNightsNetTotal, item.categoryId, item.providerId).subscribe((data: any) => {
              room.optionNightsNetTotalProf = data.split("-")[0];
              room.percentage = data.split("-")[2];
            });
          });
        }
        // Si es tour
        if (item.additionalInfo.tours) {
          item.additionalInfo.tours.options.forEach((option) => {
            this.serviceItinerary.getProfitability(option.prices.adult, item.categoryId, item.providerId).subscribe((data: any) => {
              option.prices.adultProf = data.split("-")[0];
              option.prices.adultFlag = data.split("-")[1];
            });
            this.serviceItinerary.getProfitability(option.prices.child, item.categoryId, item.providerId).subscribe((data: any) => {
              option.prices.childProf = data.split("-")[0];
              option.prices.childFlag = data.split("-")[1];
              option.prices.percentage = data.split("-")[2];
            });
            this.serviceItinerary.getProfitability(option.totalPrice, item.categoryId, item.providerId).subscribe((data: any) => {
              option.totalPriceProf = data.split("-")[0];
              option.totalPriceFlag = data.split("-")[1];
              option.percentage = data.split("-")[2];
              option.blickoins = parseFloat(data.split("-")[3]);
              option.creditBlickoins = data.split("-")[4];
            });
          });
        }
        // Si es transport
        if (item.additionalInfo.transfers) {
          this.serviceItinerary
            .getProfitability(item.additionalInfo.transfers.totalPrice, item.categoryId, item.providerId)
            .subscribe((data: any) => {
              item.additionalInfo.transfers.totalPriceProf = parseFloat(data.split("-")[0]);
              item.additionalInfo.transfers.totalPriceFlag = data.split("-")[1];
              item.additionalInfo.transfers.percentage = parseFloat(data.split("-")[2]);
              item.additionalInfo.transfers.vehicles[0].units = parseFloat(data.split("-")[2]);
              item.additionalInfo.transfers.blickoins = parseFloat(data.split("-")[3]);
              item.additionalInfo.transfers.creditBlickoins = data.split("-")[4];
            });

          // calcular rentabilidad a los vehículos de transporte
          item.additionalInfo.transfers.vehicles.forEach((vehicle) => {
            this.serviceItinerary.getProfitability(vehicle.price, item.categoryId, item.providerId).subscribe((data: any) => {
              vehicle.PriceProf = parseFloat(data.split("-")[0]);
            });
          });
        }
        break;
      // Otros casos de servicios
      //...
    }
  });
  this.arrayOut.emit(this.resultPaged);
}


  /**
   *
   * @description Reset valores de precios (slider)
   * @returns void
   */
  resetPriceSlider():void {
    if(this.results.length > 0){
      switch (String(this.payload.categoriesId[0])) {
        case '1': //HOTELS
        this.priceResetLow;
        this.priceResetHigh;
          break;
        case '6': //TOURS
        this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
        this.filterLowPrice =  this.results.map(function(el: any) {return el.additionalInfo.tours.options[0].netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
          break;
        case '2': //RENTACAR
        this.filterHighPrice = this.results.map(function(el: any) {return el.additionalInfo.carRental.netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.min(prevEl, el); });
        this.filterLowPrice = this.results.map(function(el: any) {return el.additionalInfo.carRental.netPriceProf; }).reduce(function(prevEl: any, el: any) { return Math.max(prevEl, el); });
          break;

        default:
          this.filterHighPrice = 0;
          this.filterLowPrice = 0;
          break;
      }

      // console.log('price low', this.priceResetLow);
      // console.log('price high', this.priceResetHigh);
      // this.filterLowPrice = this.priceResetLow;
      // this.filterHighPrice = this.priceResetHigh;
    }

  }


}


function take(arg0: number): import("rxjs").OperatorFunction<any, unknown> {
  throw new Error("Function not implemented.");
}

