import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { AuthService } from "../../../pages/com.usblick.common/auth/auth.service";
import { UsblickCommonEnum } from "../../../pages/com.usblick.common/usblick-common-enum";
import { AuthorizatedGuard } from "../../../pages/com.usblick.common/guard/authorizated.guard";
import { UserService } from "../../../pages/com.usblick.common/auth/user.service";
import { CookieService } from "ngx-cookie";
import { MatDialog } from "@angular/material/dialog";
import { FidelityService } from "../../../fidelity/fidelity.service";
import { AgentsService } from "../../../agents/agents.service";
import { AgentsSummaryService } from "../../../agents-summary/agents-summary.service";
import { MaterialModalConfirmComponent } from "../../../shared/material-modal-confirm/material-modal-confirm.component";
import { setting } from "../../../../../setting";
import { Store } from "@ngrx/store";
import { AppState } from "app/core/profiles/storeProfile";
import { clearAuthState } from '../../profiles/profiles.actions';
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit,OnChanges {
  @Input() clientsSettings: any;
  @Input() namePaymentCondition: any;
  @Input() iconPaymentCondition: any;
  @Input() isAgencyPaymentCondition: boolean = false;
  @Input() creditDeposits: any = 0;
  @Input() creditBlickoins: any = 0;
  @Input() creditLimits: any = 0;
  @Input() permissionsMap: { [key: string]: boolean } = {};
  fechaa = moment().format("DD - MM - YYYY");
  menuHidden = true;
  isCollapsed = false;
  pointsAvailable: any = 0;

  creditsDeposits: any = 0;
  notLogoHeader:any;

  public getClientsSettingAppBooking: any;
  public sectionHeader: any;

  public routeUploadFile: any;
  public apiRouteFile: any;

  public agencyLogo: any;
  public getClientsSettingAppAuth: any;
  public wsp_number: string;
  public moduleProfile: any[] = [];
  arraySocials:any[] = [];

  bsModalRef?: BsModalRef;


  constructor(
                private router: Router,
                private authenticationService: AuthService,
                private authorizatedGuard: AuthorizatedGuard,
                public userService: UserService,
                private _cookieService: CookieService,
                private _router: Router,
                public dialog: MatDialog,
                public fidelityService: FidelityService,
                public agentsService: AgentsService,
                public agentsSummaryService: AgentsSummaryService,
                private store: Store<AppState>,
                private cdr: ChangeDetectorRef,
                private modalService: BsModalService,
              ) { }

  ngOnInit() {
    this.setRoutes();
    this.clientData();
  }

  ngAfterViewInit(): void {

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClientsSettingAppBooking = this.clientsSettings;
    this.cdr.detectChanges();
  }

/**
 * Método para configurar las rutas de carga de archivos y almacenamiento.
 *
 * Este método establece las rutas utilizadas para cargar archivos y para acceder al almacenamiento
 * de la aplicación, tomando los valores de la configuración proporcionada en `setting`.
 *
 * @routeUploadFile-> Ruta para cargar los archivos.
 * @apiRouteFile-> Ruta para acceder al almacenamiento de los archivos de reservas.
 */
setRoutes(): void {
  this.routeUploadFile = setting.routeUploadFile;
  this.apiRouteFile = setting.apiStorageBooking;
}

/**
* Método para obtener los datos del cliente y configurar elementos relacionados.
*
* Este método establece el logo del encabezado en caso de no tener logo (con una imagen por defecto),
* obtiene el número de teléfono de WhatsApp del cliente, elimina el signo "+" y los espacios en blanco,
* y luego configura el número de WhatsApp (`wsp_number`). Además, extrae la configuración del encabezado
* de la sección desde la configuración de los clientes.
*
* @notLogoHeader-> Ruta de la imagen por defecto para el logo si no está disponible.
* @wsp_number-> Número de WhatsApp del cliente, con signos "+" y espacios eliminados.
* @sectionHeader-> Configuración del encabezado de la sección obtenida de la configuración del cliente.
*/
clientData(): void {
  this.notLogoHeader = "../../../../assets/img/sin-logo.png"

  let wspNumber = this.getClientsSettingAppBooking?.wsp_phone_number;

  // Eliminar el signo + y los espacios en blanco
  wspNumber = wspNumber.replace(/\+/g, '').replace(/\s/g, '');

  this.wsp_number = wspNumber;
  this.sectionHeader = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_header);
}

/**
* Método para determinar si se debe ocultar una sección según la ruta actual.
*
* Este método devuelve `true` si la ruta actual corresponde a una de las rutas específicas
* (`/article-one`, `/article-two`, `/article-three`), lo que indica que la sección debe ser oculta.
*
* @currentRoute-> Ruta actual de la aplicación.
* @returns-> `true` si se debe ocultar la sección, `false` en caso contrario.
*/
shouldHideSection(): boolean {
  const currentRoute = this.router.url;
  return currentRoute === "/article-one" || currentRoute === "/article-two" || currentRoute === "/article-three";
}

/**
* Método para alternar la visibilidad del menú.
*
* Este método invierte el valor de la variable `menuHidden`, controlando así la visibilidad del menú.
*
* @menuHidden-> Estado de la visibilidad del menú (oculto o visible).
*/
toggleMenu() {
  this.menuHidden = !this.menuHidden;
}

/**
* Método para cambiar el idioma de la aplicación.
*
* Este método establece el idioma de la aplicación. Actualmente, el código está comentado
* y no realiza ninguna acción, pero puede ser útil para futuras implementaciones de cambio de idioma.
*
* @language-> Idioma a establecer en la aplicación.
*/
setLanguage(language: string) {
  //this.i18nService.language = language;
}

/**
* Método para navegar a diferentes rutas según el valor proporcionado.
*
* Este método permite navegar a distintas rutas dependiendo del valor de `value`. Si el valor es
* "pointxagent", navega a la ruta `mannagement/fidelity`, y si el valor es "creditLimits",
* navega a la ruta `mannagement/credits-limit`.
*
* @value-> Valor que determina la ruta a la que navegar.
* @url-> Ruta a la que se navega según el valor de `value`.
*/
goTo(value: any) {
  let url;
  switch (value) {
    case "pointxagent":
      url = "mannagement/fidelity";
      break;
    case "creditLimits":
      url = "mannagement/credits-limit";
      break;
  }
  this._router.navigate([url]);
}

 /**
 * Método para cerrar sesión del usuario.
 *
 * Este método muestra un modal de confirmación para verificar si el usuario desea cerrar sesión.
 * Si el usuario confirma, se realiza la acción de logout, se elimina el token de autenticación,
 * se limpia el estado de autenticación en el store y se redirige al usuario a la página correspondiente.
 *
 * @initialState-> Estado inicial para el modal de confirmación.
 * @bsModalRef-> Referencia del modal de confirmación mostrado.
 * @confirmSubscription-> Suscripción a la respuesta del modal de confirmación.
 */
logout() {
  const initialState: ModalOptions = {
    initialState: {
      message: 'Saliendo de booking ¿Estás seguro?'
    }
  };

  this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
  const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
    if(result) {
      if (result) {
        this.authenticationService
          .logout({
            token: this.authenticationService.getCookie(UsblickCommonEnum.cookieName),
            email: this.authenticationService._email,
          })
          .subscribe(
            (data: any) => {
              if (data.res == 1) {
                this.authenticationService.setCookie("auth_token", "", -1);
              } else {
                this.authenticationService.setCookie("auth_token", "", -1);
              }
              this.store.dispatch(clearAuthState());
              //    localStorage.clear();
              this._cookieService.remove("clientActive");
              this.authorizatedGuard.letsRedirect();
            },
            (error) => {
              this.store.dispatch(clearAuthState());
              localStorage.removeItem("selectedAgency");
              this.authorizatedGuard.letsRedirect();
            }
          );
      } else {
        //console.log("no dijo nada");
      }
    } else {

    }
    confirmSubscription.unsubscribe();
  });
}

/**
* Método para navegar a la página de itinerarios.
*
* Este método redirige al usuario a la ruta `itinerary` para mostrar la página de itinerarios.
*/
itinerary() {
  this.router.navigate(["itinerary"]);
}

/**
* Método para navegar a la página principal (home).
*
* Este método redirige al usuario a la página principal de la aplicación.
*/
_home() {
  this.router.navigate(["/"]);
}

/**
* Método para obtener el nombre del usuario.
*
* Este método obtiene el nombre del usuario desde el servicio `userService`.
* Si el nombre no está disponible, retorna `null`.
*
* @returns-> El nombre del usuario si está disponible, de lo contrario, `null`.
*/
get username(): string {
  const name = this.userService._name;
  return name ? name : null;
}

/**
* Método para obtener el nombre de la agencia.
*
* Este método obtiene el nombre de la agencia desde el servicio `userService`.
* Si el nombre no está disponible, retorna `null`.
*
* @returns-> El nombre de la agencia si está disponible, de lo contrario, `null`.
*/
get agencyName(): string {
  const agencyName = this.userService._agencyName;
  return agencyName ? agencyName : null;
}

/**
* Método para obtener la imagen del logotipo de la agencia.
*
* Este método obtiene la imagen del logotipo de la agencia desde el servicio `userService`.
* Si la imagen no está disponible o es igual a la ruta del archivo de la API,
* retorna una imagen por defecto.
*
* @returns-> Ruta de la imagen del logotipo de la agencia o imagen por defecto si no está disponible.
*/
get agencyImagen(): string {
  let agencyImagen = this.userService._imagen_logotype;
  if (agencyImagen === this.apiRouteFile || agencyImagen === '') {
      return agencyImagen =  'assets/img/sin-logo.png';
  } else {
      return agencyImagen;
  }
}

/**
* Método para manejar el error de carga de imagen.
*
* Este método establece una imagen alternativa en caso de que la imagen de la agencia no se cargue correctamente.
*
* @event-> Evento que se dispara cuando ocurre un error en la carga de la imagen.
*/
onImageError(event: Event): void {
const imgElement = event.target as HTMLImageElement;
imgElement.src = 'assets/img/sin-logo.png'; // Imagen alternativa
}

/**
* Método para navegar a la página de itinerarios de gestión.
*
* Este método redirige al usuario a la ruta `/management/10` para acceder a la página de itinerarios de gestión.
*/
goItinerary() {
  this._router.navigate(["/management", 10]);
}

/**
* Método para redirigir a una página de gestión específica según la opción proporcionada.
*
* Este método redirige al usuario a una ruta de gestión específica basada en la opción proporcionada.
*
* @mannagementOption-> Opción de gestión que define a qué ruta se debe redirigir al usuario.
*/
mannagementRedirect(mannagementOption: any) {
  this.router.navigate([`mannagement/${mannagementOption}`]);
}

/**
* Método para navegar a la página de inicio (home).
*
* Este método redirige al usuario a la página de inicio de la aplicación.
*/
goHome() {
  this._router.navigate(['home']);
}

}
