import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ResultsSearchService } from '../home/results-search.service';
import { TitleFilterPipe } from '../shared/titleFilter.pipe';
import { RestService } from '../shared/rest.service';
import { ResultsMultidestinations } from './category-result/results.multidestinations';
import { PayloadCategoryMultidestinations } from './category-result/payload.category';
import { Procesed } from '../shared/enum-procesed';
import { ItineraryService } from '../shared/itinerary.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalLoadingComponent } from '../shared/modal-loading/modal-loading.component';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../shared/material-modal-confirm/material-modal-confirm.component';
import { UserService } from '../pages/com.usblick.common/auth/user.service';
import { PACKAGES } from '../home/search-box/categories/packages/response/searchResponsepackages';
import { CategoryResultComponent } from './category-result/category-result.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'usb-content-result',
  templateUrl: './content-result.component.html',
  styleUrls: ['./content-result.component.scss'],
  providers: [
    TitleFilterPipe
  ]
})

export class ContentResultComponent implements OnInit {

  @ViewChild(CategoryResultComponent) CategoryResultComponent: CategoryResultComponent;

  @Output() isMultiModal = new EventEmitter<any>();
  payloadCategory: PayloadCategoryMultidestinations;
  resultsMultidestinations: ResultsMultidestinations;
  modalRef: BsModalRef;
  group: any;
  _payload: any;
  _results: any;
  _resultPaged: any;
  _origin: string;
  _tabSelected: string;
  tabSelected: string;
  isLoading: boolean = true;
  multiModal = false;
  text: string;
  auxiliarArray: any;
  itinerary: any;
  ori: any;
  pax = 0;
  paxAdults = 0;
  paxChildren = 0;
  payloadForm: any;
  viewMap=false;
  deleteProduct: string = '';
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };

  resultsFound = false;
  _payloadTransport = [];
  _applyFilter = [];
  _emit = false;
  _contentCollapsed = true;
  public isCollapsed = {};
  public items = ['item 1', 'item 2', 'item 3'];

  filtersRentacar: any;

  bsModalRef?: BsModalRef;

  /// carrusel
  noWrapSlides = false;
  showIndicator = true;

  currency_exchange: any[] = [];

  private searchLocationssubscription: Subscription;


  constructor(
    private modalService: BsModalService,
    private resultsSearch: ResultsSearchService,
    private _restService: RestService,
    private _router: Router,
    private serviceItinerary: ItineraryService,
    private titleFilter: TitleFilterPipe,
    public userService: UserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.isLoading  = false;
    this._results = this.resultsSearch._getResponse();
    this._payload = this.resultsSearch._getPayload();
    this._origin = this.resultsSearch._getOrigin();
    let offline = false;

    this.initResultsMultidestination();
    this.initPayloadCategoryMultidestination();

    if (!this._results || !this._payload) {

      this._origin = localStorage.getItem('origin');
      this._payload = JSON.parse(localStorage.getItem('payload'));
      offline = true;
    }

    /// ESTO ES TEMPORAL HASTA QUE EXISTA REUSLTADO DE BUSQUEDA DE DYSNEY
    if ((this._origin == "DISNEY") || (this._origin == "PTHEMES")) {
      offline = false;
    }

    if (this._origin === Procesed.MULTIDESTINATION) {
      this._tabSelected = Procesed.HOTELS;
      this.resultsFound = true;
    } else {
      if (this._results !== undefined && this._results !== null) {
        // tslint:disable-next-line:max-line-length
        this.resultsFound = this._results[0] !== undefined ? this._results[0]['noResultMessage'] !== undefined ? false : true : this._results['noResultMessage'] !== undefined ? false : true;
      }
    }
    if (this._results !== undefined) {
      for (let index = 0; index < this._results.length; index++) {
        this.isCollapsed[index] = true;
      }
     }

    if (offline) {
      this.loadResult('HOTELS', this._payload);
    }

    //this.getItinerary(this.userService.itineraryActive[0]);
  }

 /**
 * Método para almacenar los filtros de Rentacar.
 *
 * Este método se utiliza para almacenar el valor de los filtros de Rentacar
 * en la propiedad `filtersRentacar`.
 *
 * @value -> valor que se almacenará en la propiedad `filtersRentacar`.
 */
_filtersRentacar(value: any) {
  this.filtersRentacar = value;
}

/**
 * Método para aplicar un filtro a los resultados.
 *
 * Este método invoca el método `applyFilter` del componente `CategoryResultComponent`,
 * permitiendo aplicar filtros específicos sobre los resultados.
 *
 * @event -> evento que genera la acción de aplicar el filtro.
 * @value -> valor con el que se aplica el filtro.
 * @category -> categoría a la que se le aplica el filtro.
 * @id -> identificador asociado al filtro que se aplica.
 */
applyFilter(event: any, value: any, category: any, id: any ) {
  this.CategoryResultComponent.applyFilter(event, value, category, id);
}

/**
 * Método para alternar el estado de colapso de un contenido.
 *
 * Este método cambia el estado de la propiedad `_contentCollapsed`, alternando entre
 * verdadero y falso para colapsar o expandir un contenido.
 */
toggleCollapse() {
  this._contentCollapsed = !this._contentCollapsed;
}

/**
 * Método para cerrar o abrir el contenido según el valor proporcionado.
 *
 * Este método ajusta el estado de `_contentCollapsed` según el parámetro `close`,
 * permitiendo controlar si el contenido está colapsado o no.
 *
 * @close -> valor booleano que indica si el contenido debe estar colapsado (true) o expandido (false).
 */
closeCollapse(close: boolean) {
  this._contentCollapsed = close;
}

/**
 * Método para procesar un itinerario y calcular la cantidad de pasajeros.
 *
 * Este método recibe un itinerario, recorre sus destinos y elementos, y calcula el número total
 * de pasajeros (adultos y niños) basándose en la información contenida en los elementos de transporte
 * dentro del itinerario.
 *
 * @itinerary -> objeto que representa el itinerario, con destinos y elementos relacionados.
 */
getItinerary(itinerary: any) {
  this.itinerary = itinerary;

  this.pax = 0;
  this.paxAdults = 0;
  this.paxChildren = 0;
  if (itinerary[0] !== undefined) {
    for (let i = 0; i < itinerary[0].destination.length; i++) {
      const destiny = itinerary[0].destination[i];
      for (let j = 0; j < itinerary[0].items.length; j++) {
        const item = itinerary[0].items[j];
        let payload = item.payloadUpdate && item.payloadUpdate.products ? item.payloadUpdate.products[0] : item.payload;
        if ((item.categoryName === 'TRANSPORTS') && (payload && payload.destination === destiny.iata_code)) {
          this.pax = this.pax + item.paxAdults;
          this.paxAdults = this.paxAdults + item.paxAdults;
          this.paxChildren = this.paxChildren + item.paxChildren;
        }
      }
    }
  }
}

/**
 * Método para actualizar un array y aplicar un filtro si es necesario.
 *
 * Este método recibe un array, lo almacena en la propiedad `auxiliarArray`, y si existe un texto
 * de filtro, lo aplica a los resultados utilizando el transformador `titleFilter`.
 *
 * @array -> nuevo array que reemplaza a la propiedad `auxiliarArray`.
 */
updateArray(array: any) {
  this.auxiliarArray = array;
  this._resultPaged = this.text ? this.titleFilter.transform(this.auxiliarArray, this.text) : this._resultPaged;
}

  /**
 * Método para redirigir a diferentes rutas según el valor seleccionado.
 *
 * Este método verifica el valor del parámetro `selected` y realiza la redirección correspondiente.
 * Si `selected` es igual a 'SUMMARY', se navega a la ruta `/summary`. De lo contrario, se actualiza
 * la propiedad `_tabSelected` y llama al método `setResultTab`.
 *
 * @selected -> valor que determina la ruta a la que se debe redirigir o el tab seleccionado.
 */
_redirectTo(selected: string) {
  if (this.multiModal) {
    this.modalService._hideModal(1);
  }
  if (selected === 'SUMMARY') {
    this._router.navigate(['/summary']);
  } else {
    if (selected !== 'SUMMARY') {
      this._tabSelected = selected;
      this.setResultTab(selected);
    }
  }
}

/**
 * Método para obtener las ciudades desde una API.
 *
 * Este método realiza una petición GET al endpoint `/searchlocations/{param}` para obtener una lista de ciudades
 * y almacena el nombre de la ciudad en la propiedad `ori`.
 *
 * @param param -> parámetro utilizado en la URL para buscar las ubicaciones.
 */
_getCitys(param: any) {
  this.searchLocationssubscription = this._restService.doGet('api', '/searchlocations/' + param).subscribe(res => {
    return this.ori = res['data'][0]['cityName'];
  },
  Error => {
    console.log('Error', Error);
  });
}

/**
 * Método para seleccionar una pestaña y limpiar el payload de transporte.
 *
 * Este método establece el valor de `tabSelected` como el valor de `selected` y limpia el contenido de la propiedad
 * `_payloadTransport`.
 *
 * @selected -> valor que representa la pestaña seleccionada.
 */
_goTo(selected: string) {
  this.tabSelected = selected;
  this._payloadTransport = [];
}

/**
 * Método para cargar un nuevo payload.
 *
 * Este método establece el valor de la propiedad `payloadForm` con el payload recibido como parámetro.
 *
 * @payload -> objeto de tipo payload que se almacenará en la propiedad `payloadForm`.
 */
loadPaylod(payload: any) {
  this.payloadForm = payload;
}

/**
 * Método para recargar la búsqueda con un nuevo payload.
 *
 * Este método añade un destino al payload, actualiza el formulario de payload y muestra el resultado correspondiente
 * a través de la actualización de los elementos HTML.
 *
 * @payload -> objeto de tipo payload que se añadirá a la búsqueda.
 * @i -> índice utilizado para modificar la visibilidad de los elementos HTML correspondientes.
 */
reloadSearch(payload: any, i: any) {
  this.addDestination(payload);
  this.payloadForm = payload;

  this.payloadCategory.payloadTransport = this._payloadTransport;
  document.getElementById('check-p' + i).style.display = 'none';
  document.getElementById('check-r' + i).style.display = 'block';
}

/**
 * Método para recargar el índice de un destino.
 *
 * Este método cambia el estado de colapso de dos elementos de destino, asegurando que el índice actual se colapse
 * mientras que el siguiente índice se expanda.
 *
 * @index -> índice de la lista de destinos que se está procesando.
 * @i -> índice utilizado para manipular los elementos de la lista.
 */
reloadIndex(index: any, i: any) {
  this.isCollapsed[i] = true;
  this.isCollapsed[i + 1] = false;
}

/**
 * Método para añadir un nuevo destino al array de transporte.
 *
 * Este método verifica si el destino ya existe en la lista `_payloadTransport`. Si es así, lo actualiza con el nuevo
 * valor; de lo contrario, lo agrega como un nuevo elemento en el array.
 *
 * @newItem -> objeto que representa el nuevo destino que se añadirá o actualizará.
 */
addDestination(newItem: any) {
  let _new = false;
  for (let i = 0; i < this._payloadTransport.length; i++) {
    if (this._payloadTransport[i]['origin'] === newItem['origin']) {
      this._payloadTransport[i] = newItem;
      _new = true;
    }
  }
  if (_new === false) {
    this._payloadTransport.push(newItem);
  }
}

/**
 * Método para gestionar el estado de carga y abrir o cerrar el modal de carga.
 *
 * Este método establece el valor de la propiedad `isLoading` con el valor recibido en `isLoading`.
 * Si el estado de carga es verdadero, se abre el modal de carga con la información del payload almacenado en `localStorage`.
 * Si el estado de carga es falso, se cierra el modal.
 *
 * @isLoading -> valor booleano que indica si está en proceso de carga o no.
 */
_onLoading(isLoading: boolean) {
  this.isLoading = isLoading;
  if  ((this.isLoading)) {
      this.openModalLoading(JSON.parse(localStorage.getItem('payload')));
  } else {
      this.dialog.closeAll();
  }
}

/**
 * Método para abrir el modal de carga.
 *
 * Este método abre un modal de carga utilizando el componente `ModalLoadingComponent`.
 * El modal se muestra con un ancho de 500px, el id "loadModal" y los datos del payload.
 *
 * @data -> datos del payload que se pasarán al modal.
 */
openModalLoading (data: any) {
  var dialogRef = this.dialog.open(ModalLoadingComponent, {
    width: '500px',
    id: "loadModal",
    data: data,
    disableClose: true
  });

  /* dialogRef.afterClosed().subscribe(result => {
  });*/
}

/**
 * Método para establecer el resultado de una pestaña seleccionada.
 *
 * Este método se encarga de asignar el resultado correspondiente a una categoría seleccionada.
 * Dependiendo del valor de `selected`, el método verificará si ya existen resultados previamente
 * cargados y, si no es así, cargará los resultados utilizando el payload adecuado.
 *
 * @selected -> valor que representa la categoría seleccionada, como 'TOURS', 'HOTELS' o 'TRANSPORTS'.
 */
setResultTab(selected: string) {
  switch (selected) {
    case Procesed.TOURS:
      if (this.resultsMultidestinations.resultsTours !== undefined) {
        this._results = this.resultsMultidestinations.resultsTours;
      } else {
        const payload = this.payloadCategory.payloadTours !== undefined ? this.payloadCategory.payloadTours : this.loadPayloadTours(this._payload);
        this.loadResult(selected, payload);
      }
      break;
    case Procesed.HOTELS:
      if (this.resultsMultidestinations.resultsHotel !== undefined) {
        this._results = this.resultsMultidestinations.resultsHotel;
      } else {
        this.payloadCategory.payloadHotel = this._payload;
        this.loadResult(selected, this._payload);
      }
      break;
    case Procesed.TRANSPORTS:
      if (this.resultsMultidestinations.resultsTransport !== undefined) {
        this._results = this.resultsMultidestinations.resultsTransport;
      } else {
        this.verifiResult();
        this.payloadCategory.payloadTransport = this._payloadTransport;
        this.loadResult(selected, this._payloadTransport);
      }
      break;
  }
}

/**
 * Método para verificar los resultados y añadir los destinos que no están en el payload de transporte.
 *
 * Este método compara los destinos de `_payload` con los de `_payloadTransport` para encontrar los destinos
 * que aún no han sido incluidos en el array de transporte. Si se encuentran destinos que no están en el array
 * de transporte, se añaden mediante el método `_loadPayloadTransport`.
 */
verifiResult() {
  const array = [];
  for (let i = 0; i < this._payload.length; i++) {
    let igual = false;
    for (let j = 0; j < this._payloadTransport.length && !igual; j++) {
      if (this._payload[i]['destination'] === this._payloadTransport[j]['origin']) {
        igual = true;
      }
    }
    if (!igual) {
      array.push(this._payload[i]);
    }
  }
  array.forEach(element => {
    this._loadPayloadTransport(this._payload, element.destination);
  });
}


/**
 * Método para inicializar los resultados de multidestinos.
 *
 * Este método establece los valores iniciales de `resultsMultidestinations`, donde las propiedades
 * `resultsHotel`, `resultsTours` y `resultsTransport` se inicializan como `undefined`.
 */
initResultsMultidestination() {
  this.resultsMultidestinations = {
    resultsHotel: undefined,
    resultsTours: undefined,
    resultsTransport: undefined
  };
}

/**
 * Método para inicializar la categoría de payload para multidestinos.
 *
 * Este método establece los valores iniciales de `payloadCategory`, donde las propiedades
 * `payloadHotel`, `payloadTours` y `payloadTransport` se inicializan como `undefined`.
 */
initPayloadCategoryMultidestination() {
  this.payloadCategory = {
    payloadHotel: undefined,
    payloadTours: undefined,
    payloadTransport: undefined
  };
}

/**
 * Método para realizar una búsqueda en el endpoint.
 *
 * Este método gestiona la carga de resultados al realizar una solicitud al servidor.
 * Si la categoría en el payload es '7', se carga un paquete temporal. Si no, realiza
 * una solicitud HTTP POST con el payload para obtener los resultados. En ambos casos,
 * los resultados se almacenan en `_results`.
 *
 * Además, maneja el estado de carga mostrando un modal mientras se realiza la búsqueda,
 * y muestra mensajes de error en caso de no encontrar resultados o si ocurre un error en la solicitud.
 *
 * @category -> nombre de la categoría para la cual se están buscando resultados (como 'HOTELS', 'TOURS', etc.).
 * @payload -> un array de objetos con los parámetros de búsqueda que serán enviados en la solicitud.
 */
async loadResult(category: string, payload: any[]) {
  this.isLoading = true;

  if  ((this.isLoading)) {
      this.openModalLoading(JSON.parse(localStorage.getItem('payload')));
  }

  if (payload[0].categoriesId[0] == '7') {  // temporal 12/10/2020
    // Cargar paquete temporal
    this._results[0] = PACKAGES;
    this._results[0].qtyPax = payload[0].qtyPax;
    this._results[0].others = payload[0].others;
    this._results[0].destination = payload[0].destination;
    this._results[0].payload = payload[0];
    this._results[0].categoryName = category;
  } else { // fin temporal
    if (payload !== undefined && payload.length > 0) {
      this.resultsSearch._setPayload(payload);
      this._results = [];
      this._restService.doPost('app', 'search', payload[0]).subscribe({
        next: (response: any) => {
          if (response !== null && response.results !== null) {
            this._results[0] = response.results;
            this._results[0].qtyPax = payload[0].qtyPax;
            this._results[0].others = payload[0].others;
            this._results[0].destination = payload[0].destination;
            this._results[0].arrivalDate = payload[0].arrivalDate;
            this._results[0].payload = payload[0];
            this._results[0].categoryName = category;
            this.isLoading = false;
            this._setSearchIdInResults(response);
            this.dialog.closeAll();
          } else {
            const initialState: ModalOptions = {
              initialState: {
                message:'No se encontraron Resultados'
              }
            };
            this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
          }
        },
        error: (error: HttpErrorResponse ) => {
          console.log(error)
          const initialState: ModalOptions = {
            initialState: {
              message: 'Ocurrió un error al intentar encontrar productos. Inténtalo de nuevo'
            }
          };

          this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);
          const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
            if(result) {
              if (result) {
                this.loadResult(category, payload);
              } else {
                this.modalRef.hide();
                this._router.navigate(["/home"]);
              }
            }
            confirmSubscription.unsubscribe();
          });
        }
      });
    }
  }
}

  /**
 * Método para emitir una nueva búsqueda de resultados.
 *
 * Este método toma un evento, lo asigna a `_payload`, y actualiza el payload de búsqueda
 * en `resultsSearch` con el nuevo evento. Luego, realiza una nueva búsqueda de resultados
 * para la categoría 'HOTELS'.
 *
 * @event -> El evento que contiene los datos necesarios para realizar la búsqueda.
 */
_emitReSearch(event: Event) {
  this._payload = event;
  this.resultsSearch._setPayload(event);
  this.loadResult('HOTELS', this.resultsSearch._getPayload());
}

/**
 * Método para asignar los resultados de búsqueda a la categoría correspondiente.
 *
 * Este método se encarga de asignar los resultados de búsqueda (`_results`) a las propiedades
 * adecuadas dentro de `resultsMultidestinations` según la categoría proporcionada.
 *
 * @category -> La categoría para la que se asignan los resultados ('TOURS', 'HOTELS', 'TRANSPORTS').
 */
setObject(category: string) {
  switch (category) {
    case Procesed.TOURS:
      this.resultsMultidestinations.resultsTours = this._results;
      break;
    case Procesed.HOTELS:
      this.resultsMultidestinations.resultsHotel = this._results;
      break;
    case Procesed.TRANSPORTS:
      this.resultsMultidestinations.resultsTransport = this._results;
      break;
  }
}

/**
 * Método para cargar el payload para la categoría 'TOURS'.
 *
 * Este método toma un array de objetos de tipo `pay`, y construye un array de objetos `payloadTours`
 * que se utilizarán para realizar las solicitudes correspondientes a la categoría 'TOURS'.
 * Cada objeto dentro del array `payloadTours` contiene información detallada sobre el tour, como el origen,
 * destino, fechas de llegada y salida, y otros detalles relevantes.
 *
 * @pay -> Un array de objetos con los datos de los tours para los cuales se va a generar el payload.
 * @returns Un array de objetos `payloadTours` listos para su uso en la solicitud de búsqueda.
 */
loadPayloadTours(pay: any): any[] {
  var payloadTours = [];
  if (pay !== undefined && pay.length > 0) {

    pay.forEach(element => {
      const pax = this.getPaxList(element);

      const payload = {
        providersId: element.providersId,
        categoriesId: [6],
        origin: element.origin,
        destination: element.destination,
        priceFrom: 0,
        priceTo: 20000,
        arrivalDate: element.arrivalDate,
        departureDate: element.departureDate,
        qtyProduct: 1,
        qtyPax: element.qtyPax,
        others: {
          maxRequestTimeOnSeconds: 20,
          maxNumResultsPerAdapter: "",
          tours: pax
        }
      };
      payloadTours.push(payload);
    });
    this.payloadCategory.payloadTours = payloadTours;
  }
  return payloadTours;
}

/**
 * Método para cargar el payload para la categoría 'TRANSPORTS'.
 *
 * Este método toma un array de objetos de tipo `pay`, y para cada objeto, si el destino coincide con el valor
 * proporcionado en `des`, genera un objeto `payload` para la categoría 'TRANSPORTS'. Este payload contiene
 * detalles sobre el transporte, como el tipo de vehículo, hora de recogida y entrega, y la lista de pasajeros.
 *
 * @pay -> Un array de objetos con los datos de transporte para los cuales se va a generar el payload.
 * @des -> El destino que se utiliza para filtrar los elementos de `pay`.
 * @returns Un array de objetos `payloadTransport` listos para su uso en la solicitud de búsqueda.
 */
_loadPayloadTransport(pay: any, des: any): any[] {
  if (pay !== undefined && pay.length > 0) {
    pay.forEach(element => {
      if (des === element.destination) {
        const pax = this.getPaxList(element);
        const payload = {
          providersId: null,
          categoriesId: [8],
          origin: element.origin,
          destination: element.destination,
          priceFrom: null,
          priceTo: null,
          arrivalDate: element.arrivalDate,
          departureDate: element.departureDate,
          qtyProduct: 1,
          qtyPax: element.qtyPax,
          others: {
            maxRequestTimeOnSeconds: 20,
            minNumResults: 3,
            maxNumResultsPerAdapter: "",
            transfers: {
              transferType: 'Regular / Premium',
              pickupTime: "01:00",
              dropoffTime: "01:00",
              pickupLocation: {
                id: "MCO-",
                type: "airport",
                providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                nameAdapter: "Hotel_Beds_Address"
              },
              dropoffLocation: {
                id: "540005",
                type: "hotel",
                providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
                nameAdapter: "Hotel_Beds_Address"
              },
              paxList: pax,
              vehiclesDist: [
                pax
              ],
              oneWay: false
            }
          }
        };
        this._payloadTransport.push(payload);
      }
    });
  }
  return this._payloadTransport;
}

/**
 * Método para cargar el payload para la categoría 'TRANSPORTS'.
 *
 * Este método toma un array de objetos `pay`, y para cada objeto, genera un objeto `payload` para la categoría 'TRANSPORTS'.
 * El payload contiene información detallada sobre el transporte, incluyendo el origen, destino, fechas, y detalles del tipo de transporte
 * como el tipo de vehículo, hora de recogida y entrega, y la lista de pasajeros.
 *
 * @pay -> Un array de objetos con los datos de transporte para los cuales se va a generar el payload.
 * @returns Un array de objetos `payloadTransport` listos para su uso en la solicitud de búsqueda.
 */
loadPayloadTransport(pay: any): any[] {
  var payloadTransport = [];
  if (pay !== undefined && pay.length > 0) {
    pay.forEach(element => {
      const pax = this.getPaxList(element);
      const payload = {
        providersId: null,
        categoriesId: [8],
        origin: element.origin,
        destination: element.destination,
        priceFrom: null,
        priceTo: null,
        arrivalDate: element.arrivalDate,
        departureDate: element.departureDate,
        qtyProduct: 1,
        qtyPax: element.qtyPax,
        others: {
          maxRequestTimeOnSeconds: 20,
          minNumResults: 3,
          maxNumResultsPerAdapter: "",
          transfers: {
            transferType: 'Regular / Premium',
            pickupTime: "01:00",
            dropoffTime: "01:00",
            pickupLocation: {
              id: "MCO",
              type: "airport",
              providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
              nameAdapter: "Hotel_Beds_Address"
            },
            dropoffLocation: {
              id: "540005",
              type: "hotel",
              providerId: "c9f2c93e-ca2a-4546-8ade-8978f93adb75",
              nameAdapter: "Hotel_Beds_Address"
            },
            paxList: pax,
            vehiclesDist: [
              pax
            ],
            oneWay: false
          }
        }
      };
      payloadTransport.push(payload);
    });
    this.payloadCategory.payloadTransport = payloadTransport;
  }
  return payloadTransport;
}

/**
 * Método para obtener la lista de pasajeros (adults y children) de un objeto de tipo `element`.
 *
 * Este método procesa la propiedad `others.hotels.roomConfiguration` dentro del `element` para calcular el número total
 * de adultos y los niños en las habitaciones configuradas. El resultado es un objeto con el número de adultos y los niños
 * por cada habitación.
 *
 * @element -> El objeto que contiene la información de configuración de habitaciones y otros detalles relevantes.
 * @returns Un objeto con las propiedades `adults` (número de adultos) y `children` (lista de niños).
 */
getPaxList(element: any): any {
  var children = [];
  var adults = 0;
  if (element.others !== undefined && element.others !== null) {
    element.others.hotels.roomConfiguration.forEach(room => {
      adults += parseInt(room.adults);
      children = room.children;
    });
  }
  return { adults, children };
}

/**
 * Método para obtener el payload de una categoría específica.
 *
 * Este método obtiene el payload correspondiente a una categoría ('TOURS', 'TRANSPORTS', 'HOTELS') dependiendo
 * del valor de `categoryName`. Si la categoría ya tiene un payload almacenado, devuelve el valor almacenado, de lo contrario,
 * genera un nuevo payload llamando al método correspondiente (como `loadPayloadTours` o `loadPayloadTransport`).
 *
 * @categoryName -> El nombre de la categoría para la cual se obtiene el payload ('TOURS', 'TRANSPORTS', 'HOTELS').
 * @index -> El índice del payload dentro de la categoría solicitada.
 * @returns El payload correspondiente a la categoría y el índice proporcionado.
 */
getPayloadCategory(categoryName: string, index: number) {
  let payload;
  if (this._origin === Procesed.MULTIDESTINATION) {
    switch (categoryName) {
      case Procesed.TOURS:
        payload = this.payloadCategory.payloadTours !== undefined ? this.payloadCategory.payloadTours[index] : this.loadPayloadTours(this._payload)[index];
        break;
      case Procesed.TRANSPORTS:
        payload = this.payloadCategory.payloadTransport !== undefined ? this.payloadCategory.payloadTransport[index] : this.loadPayloadTransport(this._payload)[index];
        break;
      case Procesed.HOTELS:
        payload = this.payloadCategory.payloadHotel !== undefined ? this.payloadCategory.payloadHotel[index] : this._payload[index];
        break;
      default:
        payload = this._payload[index];
    }
  } else {
    payload = this._payload[index];
  }

  return payload;
}

  /**
 * Método que obtiene un objeto de carga desde el servicio `serviceItinerary` y realiza un `subscribe`.
 * Este método puede ser utilizado para controlar el estado de carga en la interfaz de usuario.
 * Actualmente, la línea que actualiza la variable `isLoading` está comentada, pero puede ser utilizada
 * para mostrar un indicador de carga en el UI según el valor recibido en la respuesta.
 *
 * @returns Ninguno. Solo se realiza una suscripción al observable del servicio.
 */
loading() {
  this.serviceItinerary.getLoadingObject().subscribe((response: any) => {
    //this.isLoading = Boolean(response); // Descomentar si se necesita controlar el estado de carga
  });
}

/**
 * Método que establece el `searchId` en los resultados de búsqueda.
 *
 * Este método recibe un objeto `response` que contiene los resultados y un `searchId`.
 * Luego, agrega el `searchId` a cada uno de los resultados en el array `response.results`.
 *
 * @response -> Objeto que contiene los resultados de búsqueda y un `searchId` asociado.
 * @returns Ninguno. Modifica los resultados de búsqueda en su lugar.
 */
_setSearchIdInResults(response: any) {
  [ response.results ].forEach(obj => {
    obj.searchId = response.searchId;
  });
}

/**
 * Método que abre un modal con una clase personalizada.
 *
 * Este método utiliza `modalService` para abrir un modal y le asigna una clase CSS personalizada (`'gray modal-lg'`).
 * La clase se pasa como parte de las configuraciones adicionales, además de cualquier otra configuración
 * que pueda estar definida en `this.config`.
 *
 * @template -> Plantilla `TemplateRef` que se va a mostrar en el modal.
 * @returns Ninguno. Muestra el modal utilizando `modalService`.
 */
openModalWithClass(template: TemplateRef<any>) {
  this.multiModal = true;
  this.modalRef = this.modalService.show(
    template,
    Object.assign({}, this.config, { class: 'gray modal-lg' })
  );
}

/**
 * Método que cambia entre pestañas en función de la pestaña seleccionada.
 *
 * Este método maneja la lógica para cambiar de pestaña en la interfaz de usuario. Según el valor de
 * `this._tabSelected`, el método redirige a una nueva pestaña (por ejemplo, 'HOTELS', 'TRANSPORTS', 'SUMMARY').
 *
 * @event -> Evento que se pasa al método, aunque no se utiliza directamente en este caso.
 * @returns Ninguno. Realiza una redirección a otra pestaña basada en el valor de `this._tabSelected`.
 */
changeTab(event) {
  switch (this._tabSelected) {
    case 'HOTELS':
      this._redirectTo('TRANSPORTS');
      break;

    case 'TRANSPORTS':
      this._redirectTo('TOURS');
      break;

    case 'SUMMARY':
      this._redirectTo('SUMMARY');
      break;

    default:
      break;
  }
}

productDeleteEmitter(event: any) {
  console.log(event)
  this.deleteProduct = 'delete-product';
}


  _onMultimodal(event){

  }



}
