import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { formatArrivalDate, generateArray } from '../../../../utils/Functions';
import { checkOutHotel } from './checkOutHotel';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { NrBlockModalComponent } from '../../../../shared/nr-block-modal/nr-block-modal.component';
import { UserService } from '../../../../pages/com.usblick.common/auth/user.service';
import { Router } from '@angular/router';
import { ItineraryService } from '../../../../shared/itinerary.service';
import { Observable } from 'rxjs';
import { ResultsSearchService } from '../../../../home/results-search.service';
import { RestService } from '../../../../shared/rest.service';
import { DatePipe } from '@angular/common';
import { ResearchService } from '../../../../shared/research.service';
import { setting } from '../../../../../../setting';
import { DynamicFontSizePipe } from 'app/shared/dynamic-font-size.pipe';

enum ECategoryName {
  Hotels = 'HOTELS',
  Transports = 'TRANSPORTS',
  Tours = 'TOURS',
  Disney = 'DISNEY',
  Rentacar = 'RENT-A-CAR',
  Ptheme = 'PTHEMES',
  Insurance = 'INSURANCE'
}

interface IItemItinerary {
  date: Date;
  position: number;
  checkout: boolean;
}

@Component({
  selector: 'usb-destiny-summary',
  templateUrl: './destiny-summary.component.html',
  styleUrls: ['./destiny-summary.component.scss'],
  providers: [DynamicFontSizePipe]
})
export class DestinySummaryComponent implements OnInit {

  @Input() actualItinerary: any;
  @Input() partialReserve: boolean;
  @Output() itemToPartial = new EventEmitter<number>();
  @Input() qtyItemsToPartial: number = 0;
  @Input() suggestions:any
  totalTarifaNeta  :number = 0;
  totalTarifa  :number = 0;
  totalCreditBlickoins: number = 0;

  totalNetPriceBooking = 0;
  totalBlickoins = 0;

  totalDiffNights:number = 0;

  public items: any[] = [];
  public showCategoryVehicle: any[] = [];
  public showCategoryTour: any[] = [6];
  public categoryName = ECategoryName;
  public formatDate = formatArrivalDate;
  roomsLoop:any[] = [];
  dataRooms:any[] = [];

  public itemHotel: checkOutHotel[];

  public heightLineTime: number = 0;
  public hiddenLine: boolean = true;

  public itemsDestination: any[] = [];

  itinerary$: Observable<any[]>;
  itinerary: any[];

  totalTaxPrice: number = 0;
  totalNetPriceBookingFreeTax: number = 0;
  fontSizeAdjustments = { 4: 0, 6: -2, 9: -6 };

  public getClientsSettingAppBooking: any;
  getOrigin:string = '';
  public currencyItinerary: string;
  /**
   * Contine el bloque de titulos generados a partir de la key destination.
   * @type {string[]}
   */
  public citiesName: string[] = [];
  // qtyItemsToPartial: number = 0;
  constructor(
    private dialog:MatDialog,
    public userService:UserService,
    private router:Router,
    public itineraryService:ItineraryService,
    public _restService:RestService,
    private reSearchService:ResearchService
  ) {
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    }

  ngOnInit() {

    this.getOrigin = localStorage.getItem('origin');

    this._loadCityName();
    this._loadCheckout();
    if (this.suggestions) {
        this.suggestions.forEach(element => {
          this.showSuggVechicle(element);
        });
      }


    let suscription = this.itineraryService.getItinerary$();
    suscription.subscribe((data:any) => {
      if (data) {
 console.log(data)
        this.actualItinerary = data[0];
        if (this.actualItinerary) {
          this.totalNetPriceBooking =  this.actualItinerary.totalNetPriceBooking;
          this.totalNetPriceBookingFreeTax = this.actualItinerary.totalNetPriceBookingFreeTax;
          this.totalBlickoins =  this.actualItinerary.totalBlickoins;
          this.totalCreditBlickoins = this.actualItinerary.totalCreditBlickoins;
          this.currencyItinerary = this.actualItinerary.currency;
        }
        this._loadCheckout();
      }
    });
console.log(this.actualItinerary)
    this.totalNetPriceBooking =  this.actualItinerary.totalNetPriceBooking;
    this.totalBlickoins =  this.actualItinerary.totalBlickoins;
    this.totalCreditBlickoins = this.actualItinerary.totalCreditBlickoins;
    this.totalTaxPrice = this.actualItinerary.totalTaxPrice;
    this.totalNetPriceBookingFreeTax = this.actualItinerary.totalNetPriceBookingFreeTax;
    this.currencyItinerary = this.actualItinerary.currency;
    // this.roomLoopItems();

  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.timeLine()
    }, 2000);
  }

  public showSuggVechicle (element: any) {
    let rentCar: any;
    let transport: any;
    rentCar = element.results.find(element_ => element_[0].categoryId === 2);
    transport = element.results.find(element_ => element_[0].categoryId === 8);


    if (rentCar) {
      this.showCategoryVehicle[0] = rentCar[0].categoryId;
    } else {
      if (transport)  {
        this.showCategoryVehicle[0] = transport[0].categoryId;
      }
    }

    if (transport) {
      this.showCategoryVehicle[1] = transport[0].categoryId;
      }else {
        if (rentCar) {
          if (rentCar[0].categoryId !== undefined) {
            this.showCategoryVehicle[1] = rentCar[0].categoryId;
          }
        }
    }
    }

  public isCategoryNameAndDestination(iataCode: string, item: any, categoryName: string): boolean {
    if (!item.categoryName.localeCompare(categoryName)) {
      switch (categoryName) {
        case ECategoryName.Hotels:
          this.fillItemHotel(item);
          return !(item.payloadUpdate.products[0].destination.localeCompare(iataCode));
        case ECategoryName.Transports:
          return !(item.payload.destination.localeCompare(iataCode));
        case ECategoryName.Tours:
         return !(item.payloadUpdate.products[0].destination.localeCompare(iataCode));
        case ECategoryName.Rentacar:
          return !(item.payloadUpdate.products[0].destination.localeCompare(iataCode));
        case ECategoryName.Disney:
          return !(item.destination.iata_code.localeCompare(iataCode));
        case ECategoryName.Ptheme:
          return !(item.payloadUpdate.products[0].destination.localeCompare(iataCode));
        case ECategoryName.Insurance:
          return !(item.payloadUpdate.products[0].destination.localeCompare(iataCode));
      }
    }
    return false;
  }

  public fillItemHotel(item: any) {
    if (!this.itemHotel) {
      this.itemHotel = [];
    }
    var ite: checkOutHotel = {
      destiny: item.destination,
      checkOutItem: item
    }
    this.itemHotel.push(ite);
  }

  _removeItem(item: any) {

    var index;
    if (this.itemHotel) {
      this.itemHotel.forEach(element => {
        if (element.checkOutItem === item) {
          var index = this.itemHotel.indexOf(element);
          return;
        }
      });
      this.itemHotel.splice(index, 1);
    }

  }

  getItemCheckOutForDestination(destiny: string): any {
    var item;
    if (this.itemHotel) {
      this.itemHotel.forEach(element => {
        if (element.checkOutItem.payloadUpdate.products[0].destination === destiny) {
          item = element.checkOutItem;
        }
      });
    }
    return item;
  }

  /**
   * Genera el bloque de titulos a partir de la key destination.
   * @private
   */
  private _loadCityName() {
    const temp = [];
    this.citiesName = Array(this.actualItinerary.destination.length).fill(0);
    for (let i = 0; i < this.actualItinerary.destination.length; i++) {
      temp[i] = this.actualItinerary.destination[i].destination_info.cityName;
    }
    // this.actualItinerary.destination.forEach(item => {
    //   temp[item.destination_info.position - 1] = item.destination_info.cityName;
    // });
    //this.citiesName = this.citiesName.map((_, i) => [...[...temp].splice(0, i).concat(temp[i])].join(' > '));
    for ( let i = 0; i < this.citiesName.length; i++) {
      if ( i === 0 ) {
        if (temp[i]) {
          // this.citiesName[i] = temp[i].concat(' > ');
          this.citiesName[i] = temp[i];
        }
      } else {
        if (temp[i]) {
          this.citiesName[i] = temp[i - 1].concat(' > ').concat(temp[i]);
        }
      }
      if (this.citiesName[i].toString() === '0') {
        this.citiesName.splice(i, 1);
        temp.splice(i, 1);
        i--;
      }
    }
  }

  /**
   * construye un array de formato IItemItinerary con:
   * checkout false con arrivalDate.
   * checkout true con departureDate.
   * Luego se ordena el array en base a la fecha y por ultimo
   * crea un nuevo objeto con el orden correcto, y el flag checkout.
   * @private
   */
  private _loadCheckout() {

    this.items = [];

    if(this.actualItinerary !== undefined) {


    this.items = (<IItemItinerary[]>[
      ...this.actualItinerary.items.map((x, i) => (
        {
          date: new Date(x.arrivalDate),
          position: i,
          checkout: false
        }
      )),
      ...this.actualItinerary.items.map((x, i) => ({ date: new Date(x.departureDate), position: i, checkout: true }))
    ]).sort((a, b) => a.date.valueOf() - b.date.valueOf())
      .map(x => ({ ...this.actualItinerary.items[x.position], ckeckout: x.checkout }));

    }
    if(this.items.length == 0){
      this.router.navigateByUrl('/home');
    }
  }

/**
 * Construye un nuevo array con los items que vienen del Itinearary,
 * recibe como parametro destination iata_code para agrupar
 * todos los items correspondientes al destino que se le pasa
 * @param destiny
 */
  // tslint:disable-next-line:member-ordering
  itemsOrderedDestination(destiny){
    let items:any[] = this.priorityItems(this.items);
    let array_items_destino:any[] = []
    items.map(item => {
      (item.destination.iata_code === destiny) ? array_items_destino.push(item) : false;
    })
    return array_items_destino;
  }

  // tslint:disable-next-line:member-ordering
  priorityItems(items){
    let itemsDay:any[] = []
    let orderItems:any[] = []
    let allItemsOrdered:any[] = []
    let bf:any = {}
    items.map( (item,i) => {
      if(!(item.ckeckout)){
        if(i == 0){
          itemsDay.push(item)
        }else{
          if(item.arrivalDate == bf.arrivalDate){
            itemsDay.push(item)
          }else{
            itemsDay.map((el,j) => {
              if(j===0){
                orderItems.push(el);
              }else{
                switch(el.categoryName) {
                  case 'TRANSPORTS': orderItems.splice(0,0,el); break;
                  case 'HOTELS': (orderItems.length>1)? orderItems.splice(1,0,el) : (((orderItems[0].categoryName==='TRANSPORTS')||((orderItems[0].categoryName==='RENT-A-CAR')&&(orderItems[0].payloadUpdate.products[0].others.carRental.pickupLocation.address.toLocaleLowerCase().includes('airport'))))? orderItems.push(el) :orderItems.splice(0,0,el)); break;
                  case 'TOURS': (orderItems.length>1)? orderItems.splice(2,0,el) : orderItems.push(el); break;
                  // tslint:disable-next-line:max-line-length
                  case 'RENT-A-CAR': (el?.payloadUpdate?.products[0]?.others?.carRental?.pickupLocation?.address?.toLocaleLowerCase().includes('airport'))? orderItems.splice(0,0,el) : orderItems.push(el); break;
                }
              }
            })
            orderItems.map(ord => {
              allItemsOrdered.push(ord)
            })
            itemsDay = []
            orderItems = []
            itemsDay.push(item)
          }
        }
        bf = item
      }else{
        if(itemsDay.length>0){
          itemsDay.map((el,j) => {
            if(j==0){
              orderItems.push(el)
            }else{
                switch(el.categoryName){
                case 'TRANSPORTS': orderItems.splice(0,0,el); break;
                case 'HOTELS': (orderItems.length>1)? orderItems.splice(1,0,el) : (((orderItems[0].categoryName==='TRANSPORTS')||((orderItems[0].categoryName==='RENT-A-CAR')&&(orderItems[0].payloadUpdate.products[0].others.carRental.pickupLocation.address.toLocaleLowerCase().includes('airport'))))? orderItems.push(el) :orderItems.splice(0,0,el)); break;
                case 'TOURS': (orderItems.length>1)? orderItems.splice(2,0,el) : orderItems.push(el); break;
                case 'RENT-A-CAR': (el?.payloadUpdate?.products[0]?.others?.carRental?.pickupLocation?.address?.toLocaleLowerCase().includes('airport'))? orderItems.splice(0,0,el) : orderItems.push(el); break;
              }
            }
          })
          orderItems.map(ord => {
            allItemsOrdered.push(ord)
          })
          itemsDay = []
          orderItems = []
        }
        allItemsOrdered.push(item)
      }
    })
    return allItemsOrdered
  }


  _itemToPartial(qty: number){
    this.qtyItemsToPartial = this.qtyItemsToPartial + qty;
    this.itemToPartial.emit(this.qtyItemsToPartial);
  }



  openNRNotAvailable(){

    var dialogRef = this.dialog.open(NrBlockModalComponent, {
      width: '770px',
      height: '340px',
      panelClass: 'background-modal'
      //disableClose: true
    });

  }

  timeLine(){
    const itinerary = this.actualItinerary
    for (const destination of itinerary.destination) {
      let cantItems = this.itemsOrderedDestination(destination.iata_code).length - 1
      let stop = false
      let lastItem
      let altura = 0

      while(!stop && cantItems > 0){
        lastItem = document.getElementById(`${destination.iata_code}-${cantItems}`);
        if(lastItem.clientHeight > 1){
          this.heightLineTime = lastItem.clientHeight;
          stop = true
        }else{
          cantItems--
        }
      }

      const destinyTimeLine = document.getElementById(`line-${destination.iata_code}`)
      destinyTimeLine.style.height = `calc(100% - ${this.heightLineTime}px)`
    }
    this.hiddenLine = false
  }

  reSearch(event){
    // this.makePayload(event);
    this.reSearchService.reSearch(event);
  }

  // roomLoopItems():void{
  //   this.actualItinerary.items[0].form.room.forEach(e => console.log( 'datos de las rooms', e));

  //   // this.roomsLoop = this.actualItinerary.items[0].form.room;
  //   // this.roomsLoop.forEach(e => {
  //   //   this.dataRooms.push(e)
  //   //   console.log('push data room ---->', this.dataRooms);
  //   // });
  // }
}
