import { Injectable } from '@angular/core';
import { RestService } from '../shared/rest.service';
import { HttpClient } from '@angular/common/http';
import {setting} from '../../../setting';

@Injectable()
export class AgentsService {

  paymentConditions:any;
	public getClientsSettingAppBooking: any;
  constructor(private http: HttpClient, private restService: RestService) {}

  getSummaryFidelityAgent(agentId: any): any {
	let url =  setting.apiBookingUrl;
    /*return this.http.get(url + 'agentSummaryFidelity/' + agentId, { headers: this.restService._headers()})
    .map((response:any) => response); */
   }

  getSummaryCreditDepositAgent(agentId: any, agencytId: any): any {
  let url =  setting.apiBookingUrl;
    // tslint:disable-next-line:max-line-length
  /*  return this.http.get(url + 'agentSummaryCreditDeposit/' + agentId + '/' + agencytId, { headers: this.restService._headers()})
    .map((response:any) => response); */
  }



  getBalanceAgent(agentId: any){
    let url =  setting.apiBookingUrl;

    return this.http.get(url + 'fidelitys/agentBalanceGeneral/' + agentId, {
       headers: this.restService._headers()})
       .map((response:any) => response);
  }

  getBalanceAgency(agencytId: any, agentId?: any){
    let url = `${setting.apiBookingUrl}agencysBalanceGeneral/${agencytId}/${agentId}`;

    return this.http.get(url, {
       headers: this.restService._headers()})
       .map((response:any) => response);
  }


  agencyPaymentConditionByID(payModeiID: any){
    const url =  setting.apiBookingUrl;
    return this.http.get(url + 'agencyPaymentCondition/detail/' + payModeiID, {
      headers: this.restService._headers()})
      .map((response:any) => response);
    }

}
